import { Stack, Slider, Grid } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ProgenFinetune = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [prompt, setPrompt] = useState("");
        const [numSequences, setNumSequences] = useState(100);
        const [temp, setTemp] = useState(1);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const config = {fastaFile: filePaths.join(","), context:prompt, numSequences:numSequences, temp:temp};
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "progen2-finetune");
                navigate("/app/results");
        }

        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="progen2-finetune"/>
                <Typography variant='body'>Given a fasta file of sequences, finetune Progen2 on the sequences and sample from your fine tuned model.  </Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Fasta File (sequences to fine tune on):</Typography>
                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['fasta', 'fa']}/>
                <TextField 
                        type="number"
                        label="# of Sequences to sample"              
                        value={numSequences}
                        onChange={(e) => setNumSequences(e.target.value)}
                        error={numSequences > 1000}
                        helperText={numSequences > 1000 ? 'Max # sequences is 1000' : ''}
                />
                <TextField label="Context"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)} placeholder="1MEVVIVTGMSGAGK"></TextField>
                <Grid item xs={12}>
                <Typography id="slider-label" gutterBottom>
                Temperature: 0 (deterministic) to 2 (more weight to unlikely AAs)
                </Typography>
                <Slider
                value={temp}
                onChange={(e, val) => setTemp(val)}
                min={0}
                max={2}
                step={0.1}
                valueLabelDisplay="auto"
                aria-labelledby="slider-range"
                />
                </Grid>

                <SubmitButton redir="progen-finetune" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
