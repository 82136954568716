import {useNavigate} from "react-router-dom";
import {useUser} from "@clerk/clerk-react";  
import {useState, useEffect} from 'react';
import { Button, Typography, Dialog, DialogTitle, DialogContent, Box, Tooltip, Stack, Chip } from '@mui/material'
import PaymentUI from './Payment/PaymentUI';
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';

function CustomerSignUpButton(props) {
    // {duplicate, exceed, onSubmit, length}

    const [tier, setTier] = useState("")
    const [paymentTab, setPaymentTab] = useState(false)
    const [processing, setProcessing] = useState(false)
    const { isLoaded, isSignedIn, user } = useUser();  

    const checkPaying = () => {
        if (!isLoaded || !isSignedIn) {
            return
        }
        axios.get('/api/getCustomerTier').then(
            res => {
                setTier(res.data)
            }
        )
    }

    useEffect(() => {
        setTier(props.tier)
    }, [])


    const features = ["Pay as you go", "First 10 jobs of each month remain free", "Premium support", "Billed monthly", "Unlimited data storage"]

    return <>
        {
            isLoaded && tier == "Free" ? 
            <Box style={{marginTop:"10px"}}>
            <Chip label="Join Professional Tier" sx={{textTransform:"none"}} style={{width:"200px"}} onClick={() => setPaymentTab(true)}></Chip> 
            </Box> : 
            null
        }
    <Dialog open={paymentTab} onClose={() => {setPaymentTab(false)}}>
        <DialogTitle>
            <Typography variant="h5" color="primary">Tamarind Professional Tier Sign Up</Typography>
        </DialogTitle>
        <DialogContent>
        <Typography>Tamarind's free tier is limited to 10 jobs per month. Sign up for our <u>professional tier</u> to continue using our tools at scale: </Typography>
            <ul>
            {features.map((feature, index) => (
                <li key={index}>{feature}</li>
            ))}
            </ul>
            <br></br>
            <Box>
                {
                    tier != "Free" ? 
                    <Typography style={{ color: '#4CAF50' }}>Payment Info Saved. Thanks for using Tamarind!</Typography> : 
                    <>
                       <PaymentUI user={user} checkPaying={checkPaying} setProcessing={setProcessing}></PaymentUI>
                       <br></br>
                        <Box
                        margin="auto"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        >
                        {processing ? <CircularProgress /> : null}
                    </Box>
                    </>
                }
               
            </Box>

        </DialogContent>

          <Button onClick={() => {setPaymentTab(false)}} color="primary">
            Close
          </Button>

          <Typography sx={{ margin: '20px' }}>Please email <a href={`mailto:info@tamarind.bio`}>info@tamarind.bio</a> with any questions about payment or to discuss alternative payment plans e.g. subscription, license. </Typography>

      </Dialog>
    </>
}

export {CustomerSignUpButton}