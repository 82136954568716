import { Stack, Button, Autocomplete, TextField,  Typography } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {ChooseOrUploadFile, Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const LigandMPNN = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [fixedResidues, setFixedResidues] = useState("")
        const [designedResidues, setDesignedResidues] = useState("")
        const [numDesigns, setNumDesigns] = useState(8)

        // const [numSequences, setNumSequences] = useState(5)
        const [temperature, setTemperature] = useState(0.1)
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const temps = [0.1, 0.15, 0.2, 0.25, 0.3];

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
			return false;
                }

                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const batchName = filePaths.length > 1 ? jobName : ""
                for (let i = 0 ; i<filePaths.length ; i++) {
                        const configs = {
                                // const filesConfig = `${filePaths.join(",")}`;
                                "filename": filePaths[i],
                                "fixedResidues": fixedResidues,
                                'designedResidues': designedResidues,
                                "temp": temperature,
                                "verify": false,
                                "numSequences":numDesigns
                        }
        
                        const cost = pay ? OTHER_JOB_BASE : 0
                        let thisJobName = filePaths.length > 1 ? jobName + "-" + i : jobName
                        addJobToQueue(thisJobName, cost, user, JSON.stringify(configs), "ligandmpnn", "", "In Queue", batchName, "");
                        startLambdaForType("ligandmpnn")
                }
                if (filePaths.length > 1) {
                        addJobToQueue(jobName, 0, user, "ligandmpnn", "batch", "", "In Queue", "");
                }
                navigate("/app/results");
        }

        let disableReasons = []
	if (files.length == 0) {
		disableReasons.push("No pdb file")
	}

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="ligandmpnn"/>      
                <Typography >LigandMPNN is a deep learning model which extends the design tool ProteinMPNN to include enzymes, protein-nucleic acids, protein-metal, and protein-small molecule binders. Given an input pdb which contains a protein and a small molecule, nucleotide, or metal, LigandMPNN returns a set of sequences for that backbone.</Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography>Upload your input pdb containing a protein AND a small molecule, nucleotide, or metal</Typography>
                <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["chemical/x-pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
                />
                <Typography>Specify residues in the [chain][index] format, seperated by a space. For example "C1 C2 C3" will mean specifying the first three residues of the C chain.</Typography>
                <TextField 
                        label="Fixed Residues (optional)"              
                        value={fixedResidues}
                        onChange={(e) => setFixedResidues(e.target.value)}
                />
                <TextField 
                        label="Residues to be designed (optional)"              
                        value={designedResidues}
                        onChange={(e) => setDesignedResidues(e.target.value)}
                />

               <Autocomplete
                disablePortal
                options={temps}
                value={temperature}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Temperature" />}
                onChange={(event, value) => setTemperature(value)} // prints the selected value
                />

                <Autocomplete
                        freeSolo
                        disablePortal
                        options={["1", "2", "4", "8", "16", "32"]}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Number of Sequences" />}
                        defaultValue={"8"}
                        onChange={(e, val) => setNumDesigns(val)}
                /> <br></br>

                <SubmitButton redir="ligandmpnn" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
