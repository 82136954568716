import { Button, Stack } from "@mui/material";
import Navigation from "./Navigation";
import {useState} from 'react';
import { Typography } from "@mui/material";
import { TextField } from "@mui/material";
import {useUser} from "@clerk/clerk-react";  
import axios from "axios";
import {useNavigate} from "react-router-dom";
import { addJobToQueue, OTHER_JOB_BASE  } from "../utils";
import {NameField} from './NameField';
import {SubmitButton} from './SubmitButton';
import { InlineWidget } from 'react-calendly';
import Swal from "sweetalert2";
  
export const VirtualScreening = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [email, setEmail] = useState('');
    const [library, setLibrary] = useState('ZINC');
	const [ligands, setLigands] = useState('1,000,000,000');
    const [details, setDetails] = useState('');
	const [institution, setInstitution] = useState('');
	const [name, setName] = useState('');
	const { isLoaded, isSignedIn, user } = useUser();  
	const navigate = useNavigate();

	const submit = () => {
		if (!email || !library) {
			Swal.fire("Please make sure you've filled in all fields. Thank you.");
			return;
		}

		/*
        const data = {
            "email": email,
			"library": library,
            "ligands": ligands,
        	"details": details
		}
		*/

		axios.get('/api/notifyUs', {params:{'title': `Virtual screening form submission ${name} (${email}) `, 'message': `${library} | \n ${ligands} | \n ${institution} | \n ${details}`}});
		Swal.fire({title: "Thank you for contacting us, we'll get back to you as soon as possible.", icon: 'success'});
	
	}

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
            	<Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind Virtual Screening Services</Typography>
				<Typography variant=''>Screen up to billions of ligands against target receptors. We offer the ZINC library(4.5 billion ligands), as well as custom libraries. </Typography>
				<Typography variant=''>We will work with you to pick best docking method for your task, including evaluating whether to use machine learning methods or physics-based ones.</Typography>
				<Typography variant=''>Both structure-based and ligand-based screening, at any scale.</Typography>

				<TextField value={email} label='Email' onChange={e => setEmail(e.target.value)} required></TextField>
				<TextField value={institution} label='Company/Institution Name' onChange={(e) => setInstitution(e.target.value)} required/>
				<TextField value={name} label='Full Name' onChange={e => setName(e.target.value)}></TextField>
				<TextField value={library} label='Library Preference' onChange={(e) => setLibrary(e.target.value)}/>
				<TextField value={ligands} label='Expected Number of Ligands' onChange={(e) => setLigands(e.target.value)}/>
				<Typography variant=''>Let us know additional details about your virtual screening, such as what tool you'd like to use, your use case, budget, etc.</Typography>
                <TextField     
				multiline
            	rows={7}
				value={details} label='Additional Details' onChange={(e) => setDetails(e.target.value)}/>
				<Button onClick={submit}>Submit</Button>
				<br></br>
				<Typography variant=''>Feel free to schedule a meeting with us below if you'd prefer to chat briefly about your needs. We look forward to learning how we can better help you!</Typography>
				<InlineWidget url="https://calendly.com/founders-cji" />
				{/* <SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton> */}
			</Stack>
		</>
	)
}
