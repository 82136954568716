import React from 'react';
import { Typography, Link, Paper, Box } from '@mui/material';
import rf2uiImg from "./rf2na.png"
import { useNavigate } from 'react-router';
import Navigation from '../Navigation';

export const RoseTTaFold2NAPost = () => {

  const navigate = useNavigate();

  return (
    <Paper elevation={3} sx={{ p: 4, margin: 'auto', maxWidth: 900 }}>
      <Navigation />
      <Typography variant="h4" gutterBottom>
        RoseTTAFold2NA: Protein-Nucleic Acid Complex Structure Prediction
      </Typography>
      <Typography variant="body1" paragraph>
        Machine learning models such as <Link href="/alphafold" target="_blank">AlphaFold</Link> and many others have considerably advanced the quality of protein structure predictions we have access to. The authors of RoseTTAFold2NA apply these new approaches to the task of predicting the structures of protein-DNA and protein-RNA complexes. Looking at the authors' results, RoseTTAFold2NA shows great promise in predicting the structure of naturally occuring protein/nucleic acid complexes, along with designing sequence-specific RNA or DNA binding proteins.
      </Typography>
      <Typography variant="body1" paragraph>
        Instead of the AlphaFold approach to producing a single multiple sequence alignment for an input protein sequence, RoseTTAFold2NA produces a joint MSA of the nucleic acid and protein sequence. This can also be applied to having complexes of multiple types, i.e. you can structure prediction for a complex of protein, RNA, and DNA.  
      </Typography>


      <Typography variant="h5" gutterBottom>
        Results Analysis
      </Typography>

      <Typography variant='body1' paragraph>
        The authors have tested the model on 224 protein-nucleic acid complexes, with an average "Local Distance Difference Test (lDDT) of 0.73 and 29% of models with lDDT greater than 0.8". Like the previous protein structure prediction methods, RoseTTAFold2NA also produces a model confidence metric known as plDDT, which the authors have found correlates nearly one to one with comparing the predicted structure to a naturally existing one using the lDDT method. By the CAPRI prediction metrics, %80 of all confident predictions have been shown to reach "acceptable" or better. To gauge overfitting, they saw similar accuracy to sequences that were significantly different compared to the training sequences.
      </Typography>

      <Typography variant='body1' paragraph>
        Where RoseTTAFold2NA does fail in producing a high quality prediction, the most common cause of this error seems to arise in individual subunits, typically seen in large RNAs and small single stranded inputs. 
      </Typography>

      <Typography variant="body1" paragraph>
        Try RoseTTAFold2NA <Link href='/rosettafold2na' target='_blank'>here</Link>, no coding or setup required!
      </Typography>
      <Link href='/rosettafold2na' target='_blank'>
        <Box
            src={rf2uiImg}
            component="img"
            alt="RoseTTAFold2NA Online Tool User Interface"
            sx={{ width: '100%', height: 'auto', my: 2 }}
        />
      </Link>

    </Paper>
  );
};
