import { Button } from "@mui/material";
import {useState} from 'react';
import { Typography, Box, Tab } from "@mui/material";
import Navigation from "./Navigation";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {useUser} from "@clerk/clerk-react";  
import {useNavigate} from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';

import {detectFileTypeGenerator, addJobToQueue, returnS3PathsAndUploadFiles, OTHER_JOB_BASE} from '../utils';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const AlphaLink = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind AlphaLink Online Tool</Typography>
				<Typography variant='body'>Modelling protein complexes with crosslinking mass spectrometry and deep learning. </Typography>
				<Typography variant='body'>Submit your fasta file and crosslinks csv to receive the structure prediction!</Typography>

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
				<FileUpload user={user} isSignedIn={isSignedIn} jobName={jobName} exceed={exceed}/>
			</Stack>
		</>
		)
	}

const FileUpload = ({user, isSignedIn, jobName, duplicateJob, exceed}) => {
	const navigate = useNavigate();
	const [proteinFiles, setProteinFiles] = useState([]);
	const [crosslinkFiles, setcrosslinkFiles] = useState([]);	

	const submitFile = (pay) => {
		if (proteinFiles.length === 0 || crosslinkFiles === 0) {
			alert("Make sure you've submitted both files!");
			return false;
		}

		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
		const ligandFilePaths = crosslinkFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

		const config = {proteinFiles:proteinFilePaths.join(","), crosslinkFiles:ligandFilePaths.join(",")}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), "alphalink");
		navigate("/app/results");
	}

	return (
		<Stack spacing={2}>
			
			<Typography variant='h6'>Sequence</Typography>
			<FilePond
			files={proteinFiles}
			allowReorder={true}
			allowMultiple={true}
			onupdatefiles={setProteinFiles}
			labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your fasta file'
			credits={[]}
			acceptedFileTypes={["fasta"]}
			fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta"})}
			/>
			
			<Typography variant='h6'>Crosslinks</Typography>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Details on crosslinks file format (From the AlphaLink2 Colab Notebook)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>(residues are 1-indexed, whitespace separated)</Typography>
                    <Typography>residueFrom chain1 residueTo chain2 FDR</Typography>

                    <Typography>Example:</Typography>

                    <Typography>1 A 50 B 0.2</Typography>
                    <Typography>5 A 5 A 0.1</Typography>
                    <Typography>The chains are enumerated from A,B,... according to the sequence order in the FASTA file.</Typography>
                </AccordionDetails>
            </Accordion>

			<FilePond
			files={crosslinkFiles}
			allowReorder={true}
			allowMultiple={true}
			onupdatefiles={setcrosslinkFiles}
			labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your crosslink csv file'
			credits={[]}
			acceptedFileTypes={['text/csv']}
			/>
				
			<SubmitButton redir="alphalink" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
			
					
		</Stack>
	)
}
