import { Stack, Button } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE, startLambdaForType } from "../utils";
import {Header} from './UIComponents'
registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const PepPatch = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const config = {"pdbFile":filePaths.join(",")}
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "peppatch");
                startLambdaForType("peppatch")
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                        <Header type="peppatch"/>
                <Typography variant='body'>Given a protein structure, quantify the electrostatic potential on the surface in terms of surface patches.  </Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Protein:</Typography>
                <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"pdb"})}
                />

                <SubmitButton redir="af2bind" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
