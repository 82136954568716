import {Autocomplete, Grid, Stack, TextField, Typography, Box, Button, Alert, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Link} from '@mui/material';
import {List, ListItem, Chip } from '@mui/material';
import Navigation from './Navigation';
import { useDropzone } from 'react-dropzone';
import {useCallback, useEffect, useReducer, useState} from 'react';
import { addJobToQueueObject, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE, addJobToQueue } from '../utils';
import { useUser } from "@clerk/clerk-react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router';
import {NameField} from './NameField';
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import {SubmitButton} from './SubmitButton';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode);

function Instructions() {
    return (
        <>
        </>
    )
}

export const PDBViewer = ({ PDB_ID }) => {
    // Use a state to hold a unique key that changes whenever PDB_ID changes
    const [key, setKey] = useState(PDB_ID);

    useEffect(() => {
        // Whenever PDB_ID changes, generate a new unique key to force re-render
        setKey(`${PDB_ID}-${new Date().getTime()}`);
    }, [PDB_ID]); // Dependence on PDB_ID ensures this effect runs when it changes

    return (
        <div style={{ width: '80%', height: '500px', position: 'relative', margin: "auto" }}>
            <pdbe-molstar key={key} id="pdb-viewer" custom-data-format="pdb"
                sequence-panel	
                landscape="true"
                bg-color-r="255" bg-color-g="255" bg-color-b="255"
                reactive="true"
                hide-water="true"
                custom-data-url={`https://files.rcsb.org/download/${PDB_ID}.pdb`}>
            </pdbe-molstar>
        </div>
    );
};


const PDB_Id = ({duplicateJob, exceed, user, jobName}) => {
    const [pdb_id, setPdb_id] = useState('1haz');
    const [ligandCode, setLigandCode] = useState("CYC");
    const [contigs, setContigs] = useState("A15-15");
    const [numDesigns, setNumDesigns] = useState("1")
    const navigate = useNavigate();

    const submitJob = (pay) => { 
        let email = user.emailAddresses[0].emailAddress
        const cost = pay ? OTHER_JOB_BASE : 0
        let configObj = {contigs: `[${contigs}]`, pdb_id: pdb_id, ligandCode:ligandCode, numDesigns:numDesigns};

        addJobToQueueObject(jobName,cost,user,JSON.stringify(configObj), "binding_site_design", '', 'In Queue');
        navigate('/app/results');
    }

    return (
    
    <>
    <br></br>
    <PDBViewer PDB_ID={pdb_id}/>

    <Typography>Specify the residues that make up the binding site in the form [chain][start]-[end] inclusive. E.g. A15-17, would define the residues 15, 16, 17 in the A chain as the binding site and will keep that structural motif in the protein design. </Typography>
    <Typography>To include multiple ranges, simply seperate them with commas like so: "A15-15, A20-22, A19"</Typography>

    <Typography>Use the above visualization to identify the numbering of your preferred residue and to get the 3 letter name of your ligand!</Typography>
    <TextField label='Contigs' value={contigs} onChange={e => setContigs(e.target.value)}/>

    <Typography>Pick any publicly available protein in the protein databank as the starting point!</Typography>
    <TextField label='Protein PDB ID' value={pdb_id} onChange={e => setPdb_id(e.target.value)}/>
    <br></br>
    
    <Typography>Not sure how to find your ligand? See our <Link href='https://drive.google.com/file/d/1208WSrbIfMLzq3IIsS58cTlMXUBkVx4a/view?usp=sharing' target="_blank">example video.</Link></Typography>
    <TextField label='Ligand Name' value={ligandCode} onChange={e => setLigandCode(e.target.value)}/>
    
    <Autocomplete
        disablePortal
        options={["1", "2", "4", "8", "16", "32"]}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Number of Designs" />}
        defaultValue={"1"}
        onChange={(e, val) => setNumDesigns(val)}
    /> 

    <SubmitButton redir="rf-diffusion-all-atom" duplicate={duplicateJob} exceed={exceed} onSubmit={submitJob}>Submit</SubmitButton>
    </>
    )
}


const FileUpload = ({duplicateJob, exceed, user, jobName}) => {
    const [ligandCode, setLigandCode] = useState("CYC");
    const [contigs, setContigs] = useState("A15-15");
    const [files, setFiles] = useState([]);
    const navigate = useNavigate();
    const [numDesigns, setNumDesigns] = useState("1")

	const submitFile = (pay) => {
		if (files.length === 0) {
			alert("Make sure you've submitted your pdb file!");
			return false;
		}

		const proteinFilePaths = files.map(f =>  returnS3PathsAndUploadFiles(user, f.file));

		const configs = {
				"proteinFile": `${proteinFilePaths.join(",")}`,
                contigs: `[${contigs}]`,
                ligandCode: ligandCode,
                numDesigns: numDesigns
		}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(configs), "binding_site_design");
        navigate("/app/results");
	}

    return (
    
    <>

    <Typography>To visualize your protein and identify residue numberings, ligand name etc. upload below.</Typography>
    <PDBViewer PDB_ID={''}/>

    <Typography>Upload your protein pdb file below. The file should contain both the protein and the relevant ligand.</Typography>

    <FilePond
        files={files}
        allowReorder={true}
        allowMultiple={false}
        onupdatefiles={setFiles}
        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
        credits={[]}
        acceptedFileTypes={["chemical/x-pdb"]}
        fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
    />

    <Typography>Use the above visualization to identify the numbering of your preferred residue and to get the 3 letter name of your ligand!</Typography>

    <Typography>Specify the residues that make up the binding site in the form [chain][start]-[end] inclusive. E.g. A15-17, would define the residues 15, 16, 17 in the A chain as the binding site and will keep that structural motif in the protein design. </Typography>
    <Typography>To include multiple ranges, simply seperate them with commas like so: "A15-15, A20-22, A19"</Typography>

    <TextField label='Contigs' value={contigs} onChange={e => setContigs(e.target.value)}/>

    <br></br>
    
    <Typography>Not sure how to find your ligand? See our <Link href='https://drive.google.com/file/d/1208WSrbIfMLzq3IIsS58cTlMXUBkVx4a/view?usp=sharing' target="_blank">example video.</Link></Typography>
    <TextField label='Ligand Name' value={ligandCode} onChange={e => setLigandCode(e.target.value)}/>
    
    <Autocomplete
        disablePortal
        options={["1", "2", "4", "8", "16", "32"]}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Number of Designs" />}
        defaultValue={"1"}
        onChange={(e, val) => setNumDesigns(val)}
    /> 
    <SubmitButton redir="rf-diffusion-all-atom" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
    </>
    )
}

export default function BindingSiteRedesign() {
    const [contigs, setContigs] = useState("A15-15");
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [files, setFiles] = useState([]);
    const [binderDesignState, setBinderDesignState] = useState({
        "chain": "A",
        'binding_site_range': ['1', '50'],
        'binder_length_range': ['1', '20'],
        'numDesigns': '1',
    });
    const [motifScaffoldingState, setMotifScaffoldingState] = useState({
        "chain": "A",
        'scaffold_range': ['1', '50'], 
        'n_terminus_length': ['1', '10'], 
        'c_terminus_length': ['1', '10'], 
    });

    const [partialDiffusionState, setPartialDiffusionState] = useState({
        'structure_length': ['50', '50'], 
        'partial_temperature': '20', 
    });

    const [hotspot, setHotspot] = useState('');
	const [exceed, setExceed] = useState(false);
    const [mode, setMode] = useState(0);

    const { isLoaded, isSignedIn, user } = useUser();  
    const navigate = useNavigate();
    

    const [pdb_id, setPdb_id] = useState('1haz');
    const [ligandCode, setLigandCode] = useState("CYC");

    const [fileUploadMode, setFileUploadMode] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.ebi.ac.uk/pdbe/pdb-component-library/js/pdbe-molstar-component-3.1.3.js';
        document.body.appendChild(script);
    
        const script2 = document.createElement('script');
        script2.src = "https://cdn.jsdelivr.net/npm/babel-polyfill/dist/polyfill.min.js"
        document.body.appendChild(script2);
    
        const script3 = document.createElement('script');
        script3.src = "https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs/webcomponents-lite.js"
        document.body.appendChild(script3);
    
        const script4 = document.createElement('script');
        script4.src = "https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js"
        document.body.appendChild(script4);
    
        const link = document.createElement('link');
        link.rel = "stylesheet"
        link.type = "text/css"
        link.href = 'https://www.ebi.ac.uk/pdbe/pdb-component-library/css/pdbe-molstar-light-3.1.3.css';
        document.body.appendChild(link);
    
        return () => {
          document.body.removeChild(script);
          document.body.removeChild(script2);
          document.body.removeChild(script3);
          document.body.removeChild(script4);
          document.body.removeChild(link);
        };
      }, []); 

    return (
        <Stack spacing={2} style={{padding: '10px' }}>
            
            <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind Binding Site Redesign Online Tool</Typography>
            <Typography>Uses the RFdiffusion-All-Atom pipeline to optimize a given protein structure's binding affinity to a given ligand (small molecule, nucleic acid, and more!) by redesigning the its binding site.</Typography>

            <Button onClick={() => setFileUploadMode(!fileUploadMode)}>{fileUploadMode ? "Switch to PDB ID input" : "Switch to pdb file upload"}</Button>

            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
            
            {fileUploadMode ? <FileUpload duplicateJob={duplicateJob} exceed={exceed} jobName={jobName} user={user}/> : <PDB_Id duplicateJob={duplicateJob} exceed={exceed} jobName={jobName} user={user}/>}
        </Stack>
    )
}

/*
export default function RFDiffusion() {
    const [numDesigns, setNumDesigns] = useState("1");
    const [contigs, setContigs] = useState("100");
    const [numIterations, setNumIterations] = useState("1");
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [success, setSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [pdb, setPdb] = useState('');
    const [hotspot, setHotspot] = useState('');
    const [filterChecked, setFilterChecked] = useState(false);
	const [exceed, setExceed] = useState(false);

    const { isLoaded, isSignedIn, user } = useUser();  

    const navigate = useNavigate();

    const submitJob = (pay) => {
        if (files.length === 0) {
            Swal.fire(`Please make sure to upload a file, unconditional generation is currently not supported. \n Your contig is: ${contigs}`)
            return;
        }
        
        const fnames = files.map(file => file.filename);
        //const = `Name:${jobName} Contigs:${contigs} NumDesigns:${numDesigns} pdb:${fnames.join(' ')}${pdb} hotspot: ${hotspot}`;

        let configObj = {name: jobName, contigs: contigs, numDesigns: numDesigns, hotspot: hotspot, filter: filterChecked};
        let email = user.emailAddresses[0].emailAddress
        configObj['uploaded_file'] = fnames.map(fname => `${email}/${fname}`).join(' ');
        configObj['pdb_id'] = pdb;

        const cost = pay ? OTHER_JOB_BASE : 0
        addJobToQueueObject(jobName,cost,user,JSON.stringify(configObj), "rfdiffusion", '', 'In Queue');
        files.map(file => uploadFile(file.file, user, () => {}));
        startLambdaForType("rfdiffusion");  

        navigate('/user');
    }

    return (
        <Stack spacing={2} style={{padding: '10px' }}>
            <Grid container>
                <Navigation />
            </Grid>

        <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind <b>RFdiffusion</b> Protein Design </Typography>
    

        <Instructions />
        
        <Typography>
            Set your settings and steer RFDiffusion to design a protein to your choosing. You can track the status of your job under the "My Workflows" tab! 
        </Typography>

        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
        <Contigs contigs={contigs} setContigs={setContigs}/>
        
        <Autocomplete
            disablePortal
            options={["1", "2", "4", "8", "16", "32"]}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Number of Designs" />}
            defaultValue={"1"}
            onChange={(e, val) => setNumDesigns(val)}
        />

        <TextField onChange={e => setHotspot(e.target.value)} label='Hotspot'></TextField>
    
        <div>
            <Typography variant='body'>Enable filtering with ProteinMPNN and AlphaFold?</Typography>
            <Checkbox onChange={() => setFilterChecked(!filterChecked)}></Checkbox>
        </div>


        <Typography variant='h6'>
            Upload pdb file, or type in the name below.
        </Typography>


        <FilePond
            files={files}
            allowReorder={true}
            allowMultiple={true}
            onupdatefiles={setFiles}
            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
            credits={[]}
            acceptedFileTypes={["chemical/x-pdb"]}
            fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
        />

        <SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submitJob}>Submit</SubmitButton>


        </Stack>
    );
  }
*/