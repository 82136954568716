import {useState} from 'react';
import { Typography} from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {useUser} from "@clerk/clerk-react";  
import {useNavigate} from "react-router-dom";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

import {detectFileTypeGenerator, addJobToQueue, returnS3PathsAndUploadFiles, startLambdaForType, OTHER_JOB_BASE } from '../utils';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const LightDock = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [tab, setTab] = useState("1");
	const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
				<Header type="lightdock"/>
				<Typography>Method for protein-protein docking. Upload two protein pdb files and use LightDock's Glowworm Swarm Optimization (GSO) algorithm to simulate docking. </Typography>
				<Typography>Don't have a structure for your protein? Consider using our structure prediction workflows.</Typography>

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
				<FileUpload user={user} isSignedIn={isSignedIn} jobName={jobName} duplicateJob={duplicateJob} exceed={exceed}/>
			</Stack>
		</>
		)
	}

const FileUpload = ({user, isSignedIn, jobName, duplicateJob, exceed}) => {
	const navigate = useNavigate();
	const [proteinFiles, setProteinFiles] = useState([]);
	const [ligandFiles, setLigandFiles] = useState([]);	

	/*
	const submit = () => {
		const config = `pdb:${proteinString} smiles:${ligandString} numInference:${numInference} numSamples:${numSamples}`;
		addJobToQueue(jobName, 0, user, config, "diff-dock");
	}
	*/

	const submitFile = (pay) => {
		if (proteinFiles.length === 0 || ligandFiles === 0) {
			alert("Make sure you've submitted both files!");
			return false;
		}

		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
		const ligandFilePaths = ligandFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

		const config = {proteinFiles:proteinFilePaths.join(","), ligandFiles:ligandFilePaths.join(",")}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), "lightdock");
		startLambdaForType("lightdock")
		navigate("/app/results");
	}

	let disableReasons = []
	if (proteinFiles.length == 0) {
		disableReasons.push("No protein file uploaded")
	}
	if (ligandFiles.length == 0) {
		disableReasons.push("No ligand file uploaded")
	}

	return (
		<Stack spacing={2}>
			
			<Typography >Protein</Typography>
			<FilePond
			files={proteinFiles}
			allowReorder={true}
			allowMultiple={true}
			onupdatefiles={setProteinFiles}
			labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your protein pdb files'
			credits={[]}
			acceptedFileTypes={["chemical/x-pdb"]}
			fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
			/>
			
			<Typography >Ligand</Typography>
			
			<FilePond
			files={ligandFiles}
			allowReorder={true}
			allowMultiple={true}
			onupdatefiles={setLigandFiles}
			labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your ligand pdb files'
			credits={[]}
			acceptedFileTypes={["chemical/x-pdb"]}
			fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
			/>
				
			<SubmitButton redir="lightdock" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
			
					
		</Stack>
	)
}
