import React, { useState, useReducer } from 'react';
import { 
    Button, ButtonGroup, Grid, TextField, Typography, Autocomplete, Stack, Chip, Link,
    Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, Alert 
} from '@mui/material';
import AWS from 'aws-sdk';
import axios from 'axios';
import { useUser } from "@clerk/clerk-react";  
import { useNavigate } from "react-router-dom";
import Navigation from './Navigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {NameField} from './NameField';
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { checkValidSequenceInput, submitSequenceToAlphaFold, addJobToQueue, getFlagsDB, startLambdaForType, uploadFileCustomName, detectFileTypeGenerator, calcPrice } from '../utils';
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

function MonomerInput({settings, monomer}) {
  const [jobName, setJobName] = useState("sp_P61823");
  const start_string = monomer ? "MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV" : "MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV:MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV"
  const [chain1, setChain1] = useState(start_string);    
 
  const [pricingDialogue, setPricingDialogue] = useState(false);
  const [confirmDialogue, setConfirmDialogue] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [duplicateJob, setDuplicateJob] = useState(false);
  const [templateFiles, setTemplateFiles] = useState([]);
  const [badChars, setBadChars] = useState("Good");
  const [exceed, setExceed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const { isLoaded, isSignedIn, user } = useUser();

  const returnS3PathsAndUploadFiles = (file) => {
        uploadFileCustomName(file, user, "templates-" + jobName.split(" ").join("_").split("/").join("_") + "/" + file.name);
        return file.name;
    }

  const submitSequence = async (pay) => {
    if (!isSignedIn) return

    // const upper_limit = 3000
    // if (chain1.length > upper_limit) {
    //     Swal.fire(`Unfortunately, we aren't able to support sequences longer than ${3000} residues for everyone. \n\n AlphaFold typically doesn't perform well with complexes or monomers above this length. \n\n We may be able help, please get in touch at: info@tamarind.bio if you have sequences longer than the limit.`)
    //     return;
    // }

    let settingsWithTemplates = settings

    let email = user.emailAddresses[0].emailAddress

    if (settingsWithTemplates[5].selected == "custom" && templateFiles.length == 0){
        alert("Please upload your custom template files")
        return false;
    }else if (settingsWithTemplates[5].selected == "custom" ) {
        settingsWithTemplates[5].name = "templates --custom-template-path"

        for (let i = 0; i < templateFiles.length; i++) {
            if (templateFiles[i].file.name.includes(".pdb")) {
                alert(`Invalid file name, please remove ".pdb" from "${templateFiles[i].file.name}".`);
                return false;
                //continue;
            }
        }
        
        templateFiles.map(f => returnS3PathsAndUploadFiles(f.file));
        settingsWithTemplates[5].selected = "templates/"
    }else if(settingsWithTemplates[5].selected == "pdb100"){
        settingsWithTemplates[5].selected = ""
    }

    setSuccessMessage(true);
    let cost = calcPrice("alphafold", 1, [chain1.length])[0]
    addJobToQueue(jobName, 0, user, JSON.stringify({sequence: chain1}), 'afsample', '', 'In Queue', '');
    startLambdaForType('afsample');
    navigate('/app/results'); 
    }

    const handleDuplicate = (x) => {
        setChain1((chain1 + ":").repeat(x).slice(0, -1));
    }

  return (

        <Stack spacing={2} style={{padding: '10px' }}>
            {
                successMessage ? <Alert onClose={() => {setSuccessMessage(false)}}>{`Your sequence has been submitted and the structure prediction will be emailed to you soon. Please feel free to contact us at info@tamarind.bio with any questions, thank you!`}</Alert> : <></>
            }
            
            {/*
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={chainLengths}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Number of Chains (optional)" />}
            onChange={(e, val) => setNumChains(val)}
            defaultValue="2"            
            />
            */}

            <NameField jobName={jobName} setJobName={setJobName} exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob}></NameField>

            <TextField label={`Chain`} 
            onChange={(e) => 
                {
                    setChain1(e.target.value)
                    if (e.target.value != ""){
                        setBadChars(checkValidSequenceInput(e.target.value))
                    }
                }
            }
            value={chain1}
            multiline
            rows={7}
            />
            {badChars != "Good" ? <Typography color="#FF0000">{badChars}</Typography> : <></>}

            {
                monomer ? <></> :
                <div>
                <Button aria-controls="options-menu" aria-haspopup="true" onClick={(event) => {setAnchorEl(event.currentTarget);}}>
                    Multimerize
                </Button>
                <Menu
                    id="options-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => {setAnchorEl(null)}}
                >
                    <MenuItem onClick={() => handleDuplicate(2)}>2</MenuItem>
                    <MenuItem onClick={() => handleDuplicate(3)}>3</MenuItem>
                    <MenuItem onClick={() => handleDuplicate(4)}>4</MenuItem>
                    <MenuItem onClick={() => handleDuplicate(5)}>5</MenuItem>
                </Menu>
                </div>
            }


            <SubmitButton redir="alphafold" exceed={exceed} duplicate={duplicateJob || badChars != "Good"} onSubmit={submitSequence} toolName="AlphaFold" seqLength={[chain1.length]}></SubmitButton>
            {/* {
                duplicateJob ? null: 
                <Button disabled={duplicateJob || badChars != "Good"} onClick={submitSequence}>
                    Submit Sequence 
                </Button>
            } */}

            {settings[5].selected == "custom" ?

            <>
                <Typography variant='h6'>Templates (optional)</Typography>

                <Typography variant='h7'>Please upload your custom templates as cif files. To convert your pdb files to cif files, feel free to use the service: <Link href="https://mmcif.pdbj.org/converter/index.php?l=en">https://mmcif.pdbj.org/converter/index.php?l=en</Link>. </Typography>
                
                <FilePond
                files={templateFiles}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setTemplateFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your template cif files'
                credits={[]}
                acceptedFileTypes={["cif"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".cif":"cif"})}
                />
            </> : <></> }

        </Stack>
  )
}

const initialSettings = [
    {
        id: 1,
        name: "num-models",
        label:"Number of Models",
        choices: ["1", "2", "3", "4", "5"],
        selected: "5"
    },
    {
        id: 2,
        name: 'msa-mode',
        label:"MSA Mode",
        choices: ["mmseqs2_uniref_env","mmseqs2_uniref","single_sequence"],
        selected: "mmseqs2_uniref_env"
    },
    {
        id: 3,
        name: 'num-recycle',
        label:"Number of Recycles",
        choices: ["3", "1","5","10"],
        selected: "3"
    },
    {
        id: 4,
        name: 'num-relax',
        label:"Number of Relaxations",
        choices: ["none", "1"],
        selected: "none"
    },
    {
        id: 5,
        name: 'pair-mode',
        label:"Pair Mode",
        choices: ["unpaired","paired","unpaired_paired"],
        selected: "unpaired_paired"
    },
    {
        id: 6,
        name: 'templates',
        label:"Templates",
        choices: ["none", "pdb100", "custom"],
        selected: "none"
    }
];

const reducer = (settings, action) => {
    const newSettings = settings;
    return newSettings.map((setting) => {
        if (setting.name === action.settingName) {
          return { ...setting, selected: action.newValue };
        } else {
          return setting;
        }
      });
    }

function AFsample() {
    const [settings, updateSettings] = useReducer(reducer, initialSettings);
    const [selectedFile, setSelectedFile] = useState(null);
    const { isLoaded, isSignedIn, user } = useUser();
    const navigate = useNavigate();

  const [monomer, setMonomer] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);

  return (
        <Grid spacing={3} style={{padding:10}}>
            {/*(!user.unsafeMetadata.credits && user.unsafeMetadata.credits != 0)? setBaseCredits() : <></>*/}


            {!fileUpload ? <Grid style={{padding:10}}>
                <ButtonGroup  aria-label="outlined button group">
                    <Button variant={monomer ? "contained" : "outlined"} onClick={() => setMonomer(true)}>Monomer</Button>
                    <Button variant={monomer ? "outlined" : "contained"} onClick={() => setMonomer(false)}>Multimer</Button>
                </ButtonGroup>
                
                </Grid> : <></>}

            {!monomer && !fileUpload ?
                <Stack spacing={2} style={{padding: '10px' }}>
                    <Header type="afsample" title="AFSample2 Multimer"/>
                    
                     <Typography>
                        Paste your chains into the box below, and put a : between each of your chains to indicate a chain break.
                    </Typography>
                    <MonomerInput settings={settings} monomer={monomer}/>
                </Stack>
            : <></>
            }

            {monomer && !fileUpload ?
                <Stack spacing={2} style={{padding: '10px' }}>
                    <Header type="afsample" title="AFSample2 Monomer"/>
                    <Typography variant='body1'>
                        Want to run a large number of structure predictions? Try our <Link href='https://www.tamarind.bio/batch' target="_blank">Batch Workflows</Link>.
                    </Typography>

                    <MonomerInput settings={settings} monomer={monomer}/>
                </Stack>
            : <></>
            }

            {/*
            <Accordion sx={{justifyContent: "center"}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Optional settings
                    </Typography>
                    
                    <Typography sx={{ color: 'text.secondary' }}>More detailed control of how each prediction is made</Typography>


                </AccordionSummary>
                <AccordionDetails>

                <Stack spacing={2}>
                    {settings.map((setting) => <>
                        <Autocomplete
                            disablePortal
                            options={setting.choices}
                            sx={{ width: 300 }}
                            value={setting.selected}
                            renderInput={(params) => <TextField {...params} label={setting.label} />}
                            onChange={(e, newValue) => updateSettings({settingName:setting.name, newValue: newValue})}
                            freeSolo={false}
                        />
                    </>)}

                </Stack>

                </AccordionDetails>
            </Accordion>
        */}

        </Grid>
  )
}

export default AFsample;