import { Stack, Button, Autocomplete, TextField,  Typography } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import "filepond/dist/filepond.min.css";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

export const DLKcat = () => {
        const navigate = useNavigate();

	    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [model, setModel] = useState("Antibody")
        const { isLoaded, isSignedIn, user } = useUser();  
	    const [exceed, setExceed] = useState(false);

        const [protein, setProtein] = useState('MVHVRKNHLTMTAEEKRRFVHAVLEIKRRGIYDRFVKLHIQINSTDYLDKETGKRLGHVNPGFLPWHRQYLLKFEQALQKVDPRVTLPYWDWTTDHGENSPLWSDTFMGGNGRPGDRRVMTGPFARRNGWKLNISVIPEGPEDPALNGNYTHDDRDYLVRDFGTLTPDLPTPQELEQTLDLTVYDCPPWNHTSGGTPPYESFRNHLEGYTKFAWEPRLGKLHGAAHVWTGGHMMYIGSPNDPVFFLNHCMIDRCWALWQARHPDVPHYLPTVPTQDVPDLNTPLGPWHTKTPADLLDHTRFYTYDQ');
        const [substrate, setSubstrate] = useState('C1=CC=C(C(=C1)O)O');  

        const submit = (pay) => {
                let configs = {
                    sequence: protein,
                    smiles: substrate,
                }
                
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configs), "dlkcat");
                startLambdaForType('dlkcat');
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="dlkcat"/>
                <Typography variant='body'>Deep-learning based prediction tool for enzyme-substrate kcat/turnover number</Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                <TextField 
                    label='Protein Sequence'
                    value={protein}
                    onChange={e => setProtein(e.target.value)}
                    multiline
                    rows={4}
                />

                <TextField 
                    label='Substrate SMILES String'
                    value={substrate}
                    onChange={e => setSubstrate(e.target.value)}
                />
                

                <SubmitButton redir="dlkcat" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
