import React, {useState} from 'react'
import {TextField, Stack, Typography, Alert, Link, Menu, MenuItem, Button} from '@mui/material';
import {
    useUser,
} from "@clerk/clerk-react";  
import { useNavigate } from "react-router-dom";
import { checkValidSequenceInput, submitSequenceToAlphaFold, addJobToQueue, getFlagsDB, startLambdaForType, ourMSAServer} from '../utils';
import {NameField} from './NameField';
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {uploadFileCustomName, detectFileTypeGenerator, calcPrice} from '../utils';
import {SubmitButton} from './SubmitButton';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

function MonomerInput({settings, monomer}) {
    console.log(monomer)
  const [jobName, setJobName] = useState("sp_P61823");
  const start_string = monomer ? "MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV" : 
  "MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV:MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV"
  const [chain1, setChain1] = useState(start_string);    
 
  const [pricingDialogue, setPricingDialogue] = useState(false);
  const [confirmDialogue, setConfirmDialogue] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [duplicateJob, setDuplicateJob] = useState(false);
  const [templateFiles, setTemplateFiles] = useState([]);
  const [badChars, setBadChars] = useState("Good");
  const [exceed, setExceed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [seed, setSeed] = useState("");
  const [maxMsa, setMaxMsa] = useState("1000000");

  const navigate = useNavigate();
  const { isLoaded, isSignedIn, user } = useUser();

  const returnS3PathsAndUploadFiles = (file) => {
        uploadFileCustomName(file, user, "templates-" + jobName.split(" ").join("_").split("/").join("_") + "/" + file.name);
        return file.name;
    }

    useState(() => {
        if (monomer) {
            setChain1("MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV")
        } else {
            setChain1("MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV:MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV")
        }
    }, [monomer])

  const submitSequence = async (pay) => {
    if (!isSignedIn) return

    // const upper_limit = 3000
    // if (chain1.length > upper_limit) {
    //     Swal.fire(`Unfortunately, we aren't able to support sequences longer than ${3000} residues for everyone. \n\n AlphaFold typically doesn't perform well with complexes or monomers above this length. \n\n We may be able help, please get in touch at: info@tamarind.bio if you have sequences longer than the limit.`)
    //     return;
    // }

    let settingsWithTemplates = settings

    let email = user.emailAddresses[0].emailAddress

    if (settingsWithTemplates[5].selected == "custom" && templateFiles.length == 0){
        alert("Please upload your custom template files")
        return false;
    }else if (settingsWithTemplates[5].selected == "custom" ) { 
        settingsWithTemplates[5].name = "templates --custom-template-path"

        for (let i = 0; i < templateFiles.length; i++) {
            if (templateFiles[i].file.name.includes(".pdb")) {
                alert(`Invalid file name, please remove ".pdb" from "${templateFiles[i].file.name}".`);
                return false;
                //continue;
            }
        }
        
        templateFiles.map(f => returnS3PathsAndUploadFiles(f.file));
        settingsWithTemplates[5].selected = "templates/"
    }else if(settingsWithTemplates[5].selected == "pdb100"){
        settingsWithTemplates[5].selected = ""
    }

    if (settingsWithTemplates[6].selected == "custom" && seed == "") {
        alert("Please upload your custom random seed")
        return false;
    } else if (settingsWithTemplates[6].selected == "custom") {
        settingsWithTemplates[6].selected = seed
    }

    // if (settingsWithTemplates[7].selected == "custom" && maxMsa == "") {
    //     alert("Please upload your custom max msa limit")
    //     return false;
    // } else if (settingsWithTemplates[7].selected == "custom") {
    //     settingsWithTemplates[7].selected = maxMsa
    // }

    setSuccessMessage(true);
    let cost = calcPrice("alphafold", 1, [chain1.length])[0]
    if (isSignedIn && await ourMSAServer()){
        let config = {
            sequence: chain1,
            msa: "waiting"
        }
        let flags_info = getFlagsDB(settingsWithTemplates).replace("--msa-mode mmseqs2_uniref_env", "") + " msas"
        addJobToQueue(jobName, 0, user, JSON.stringify(config), "alphafold", flags_info, "In Queue", "", "")
        if (email.includes("septerna") || email == "sherry.tamarind@gmail.com") {
            startLambdaForType("septerna-msa")
        } else {
            startLambdaForType("msa")
        }
    } else {
        submitSequenceToAlphaFold(jobName, chain1, user, pay, "", settings=settingsWithTemplates, "", cost=cost);
    }

    navigate('/app/results'); 
    }

    const handleDuplicate = (x) => {
        setChain1((chain1 + ":").repeat(x).slice(0, -1));
    }

  let error = chain1.length > 5 && /^[ATUGC]+$/.test(chain1)

  let disableReasons = []
  if (chain1.length == 0) {
      disableReasons.push("Empty sequence")
  }
  if (error) {
      disableReasons.push("Nucleic acid sequence")
  }

  return (

        <Stack spacing={2} style={{padding: '0px' }}>
            {/* {
                successMessage ? <Alert onClose={() => {setSuccessMessage(false)}}>{`Your sequence has been submitted and the structure prediction will be emailed to you soon. Please feel free to contact us at info@tamarind.bio with any questions, thank you!`}</Alert> : <></>
            } */}
            
            {/*
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={chainLengths}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Number of Chains (optional)" />}
            onChange={(e, val) => setNumChains(val)}
            defaultValue="2"            
            />
            */}

            <NameField jobName={jobName} setJobName={setJobName} exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob}></NameField>

            <TextField label={`Chain`} 
            onChange={(e) => 
                {
                    setChain1(e.target.value)
                    if (e.target.value != ""){
                        setBadChars(checkValidSequenceInput(e.target.value))
                    }
                }
            }
            value={chain1}
            multiline
            rows={7}
            error={error || badChars != "Good"}
            helperText={error ? "This sequence looks like a nucleic acid, but this tool is for proteins only." : badChars != "Good" ? badChars :''}
            />

            {
                monomer ? <></> :
                <div>
                <Button aria-controls="options-menu" aria-haspopup="true" sx={{textTransform:"none"}} onClick={(event) => {setAnchorEl(event.currentTarget);}}>
                    Multimerize
                </Button>
                <Menu
                    id="options-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => {setAnchorEl(null)}}
                >
                    <MenuItem onClick={() => handleDuplicate(2)}>2</MenuItem>
                    <MenuItem onClick={() => handleDuplicate(3)}>3</MenuItem>
                    <MenuItem onClick={() => handleDuplicate(4)}>4</MenuItem>
                    <MenuItem onClick={() => handleDuplicate(5)}>5</MenuItem>
                </Menu>
                </div>
            }


            <SubmitButton redir="alphafold" disableReasons={disableReasons} exceed={exceed} duplicate={duplicateJob || badChars != "Good"} onSubmit={submitSequence} toolName="AlphaFold" seqLength={[chain1.length]}></SubmitButton>
            {/* {
                duplicateJob ? null: 
                <Button disabled={duplicateJob || badChars != "Good"} onClick={submitSequence}>
                    Submit Sequence 
                </Button>
            } */}

            {settings[5].selected == "custom" ?

            <>
                <Typography variant='h6'>Templates (optional)</Typography>

                <Typography variant='h7'>Please upload your custom templates as cif files. To convert your pdb files to cif files, feel free to use the service: <Link href="https://mmcif.pdbj.org/converter/index.php?l=en">https://mmcif.pdbj.org/converter/index.php?l=en</Link>. </Typography>
                
                <FilePond
                files={templateFiles}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setTemplateFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your template cif files'
                credits={[]}
                acceptedFileTypes={["cif"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".cif":"cif"})}
                />
            </> : <></> }

            {
                settings[6].selected == "custom" ? 
                <>
                <TextField label="Random Seed" value={seed} onChange={(e) => setSeed(e.target.value)}/>
                </> : null
            }

            {/* {
                settings[7].selected == "custom" ? 
                <>
                <TextField label="Max MSA" value={maxMsa} onChange={(e) => setMaxMsa(e.target.value)}/>
                </> : null
            } */}

        </Stack>
  )
}

export default MonomerInput