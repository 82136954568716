import React, { useState, useRef } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem, Tooltip, useTheme, useMediaQuery, Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import MenuIcon from '@mui/icons-material/Menu';
import Image from 'mui-image';
import { UserButton } from '@clerk/clerk-react';

function Navigation() {
  const navigate = useNavigate();
  const { isSignedIn } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElUseCases, setAnchorElUseCases] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenNavMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorEl(null);
  };

  const handleUseCasesClick = (event) => {
    setAnchorElUseCases(event.currentTarget);
  };

  const handleUseCasesClose = () => {
    setAnchorElUseCases(null);
  };

  const handleMouseEnter = () => {
    setAnchorElUseCases(buttonRef.current);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (!popoverRef.current.contains(document.activeElement) && !buttonRef.current.contains(document.activeElement)) {
        handleUseCasesClose();
      }
    }, 200);
  };

  const buttonRef = useRef(null);
  const popoverRef = useRef(null);

  const DotIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
      </svg>
    );
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: "100vw", overflowX: "hidden" }}>
      <AppBar position="static" elevation={0} style={{ backgroundColor: 'transparent' }}>
        <Toolbar>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Button 
              onClick={() => navigate("/app")}
              startIcon={
                <Image src={require('./name-logo.png')} style={{ width: '140px' }} duration={0} alt="Tamarind Bio" />
              }
            >
            </Button>
          </Box>

          {isMobile ? (
            <>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem>
                  <UserButton />
                </MenuItem>
                <MenuItem>
                  <Tooltip title="info@tamarind.bio">
                    <Button sx={{ textTransform: 'none', fontWeight: 'bold' }} href="mailto:info@tamarind.bio">Contact</Button>
                  </Tooltip>
                </MenuItem>
                <MenuItem onClick={() => { handleCloseNavMenu(); navigate("/pricing"); }}>
                  <Button sx={{ textTransform: 'none', fontWeight: 'bold' }}>Pricing</Button>
                </MenuItem>
                <MenuItem onClick={() => { handleCloseNavMenu(); navigate("/blog"); }}>
                  <Button sx={{ textTransform: 'none', fontWeight: 'bold' }}>Blog</Button>
                </MenuItem>
                <MenuItem onClick={() => { handleCloseNavMenu(); navigate("/api-docs"); }}>
                  <Button sx={{ textTransform: 'none', fontWeight: 'bold' }}>API</Button>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Box sx={{ flexWrap: 'nowrap', display: 'flex', justifyContent: "center", alignItems: "center" }}>
              {/* <Button
                sx={{ textTransform: 'none', fontWeight: 'bold', marginRight: 1.5 }}
                onClick={handleUseCasesClick}
                onMouseEnter={handleMouseEnter}
                ref={buttonRef}
              >
                Use Cases
              </Button>
              <Popover
                anchorEl={anchorElUseCases}
                open={Boolean(anchorElUseCases)}
                disableScrollLock={true}
                onClose={handleUseCasesClose}
                className="use-cases-popover"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                ref={popoverRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{ 
                  marginTop: '0.5rem', 
                  maxHeight: '400px', // Adjust as needed
                  overflowY: 'auto', // Allow scrolling if content overflows
                  overflowX: 'hidden', // Prevent horizontal scroll
                }}
                PaperProps={{
                  sx: {
                    boxShadow: '0 2px 4px rgba(0,0,0,0.2), 0 0 0 rgba(0,0,0,0)', // Custom shadow
                    marginTop: '0.5rem',
                    maxHeight: '400px', // Adjust as needed
                    overflowY: 'auto', // Allow scrolling if content overflows
                    overflowX: 'hidden', // Prevent horizontal scroll
                  }
                }}
              >
                <MenuItem 
                  sx={{ 
                    backgroundColor: "#f5f5f5",
                    transition: 'box-shadow 0.3s', // Smooth transition for shadow
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.1)' // Shadow on hover
                    }
                  }} 
                  onClick={() => { handleUseCasesClose(); navigate("/use-case-1"); }}
                >
                  Enzyme Design
                </MenuItem>
                <MenuItem 
                  sx={{ 
                    backgroundColor: "#f5f5f5",
                    transition: 'box-shadow 0.3s', // Smooth transition for shadow
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.1)' // Shadow on hover
                    }
                  }} 
                  onClick={() => { handleUseCasesClose(); navigate("/use-case-2"); }}
                >
                  Antibody Engineering
                </MenuItem>
                <MenuItem 
                  sx={{ 
                    backgroundColor: "#f5f5f5",
                    transition: 'box-shadow 0.3s', // Smooth transition for shadow
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.1)' // Shadow on hover
                    }
                  }} 
                  onClick={() => { handleUseCasesClose(); navigate("/use-case-3"); }}
                >
                  Small Molecule
                </MenuItem>
                <MenuItem 
                  sx={{ 
                    backgroundColor: "#f5f5f5",
                    transition: 'box-shadow 0.3s', // Smooth transition for shadow
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.1)' // Shadow on hover
                    }
                  }} 
                  onClick={() => { handleUseCasesClose(); navigate("/use-case-4"); }}
                >
                  Peptide Discovery
                </MenuItem>
              </Popover> */}
              <Tooltip title="info@tamarind.bio">
                <Button sx={{ textTransform: 'none', fontWeight: 'bold', marginRight: 1.9 }} href="mailto:info@tamarind.bio">Contact</Button>
              </Tooltip>
              <Button sx={{ textTransform: 'none', fontWeight: 'bold', marginRight: 1 }} onClick={() => navigate("/pricing")}>Pricing</Button>
              <Button sx={{ textTransform: 'none', fontWeight: 'bold', marginRight: 0.2 }} onClick={() => navigate("/blog")}>Blog</Button>
              <Button sx={{ textTransform: 'none', fontWeight: 'bold', marginRight: 1 }} onClick={() => navigate("/api-docs")}>API</Button>

              {isSignedIn ? 
                <UserButton sx={{ marginRight: 1 }} afterSignOutUrl="/">
                  <UserButton.UserProfilePage label="account" />
                  <UserButton.UserProfilePage label="security" />
                  <UserButton.UserProfileLink
                    label="View Profile"
                    url="/user"
                    labelIcon={<DotIcon />}
                  />
                </UserButton>
                : <Button sx={{ textTransform: 'none', fontWeight: 'bold', borderWidth: '2px' }} onClick={() => navigate("/sign-up")} variant="outlined">Sign up</Button>
              }
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navigation;
