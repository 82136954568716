import { TextField } from "@mui/material";
import {useState} from 'react';
import { Typography } from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'
export const ProGen2 = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [starting, setStarting] = useState('MIQEKDKYVVASVTILESNQ');
	const [maxLength, setMaxLength] = useState('1024');
	const [numSequences, setNumSequences] = useState('2');
	const [exceed, setExceed] = useState(false);

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay) => {
		const configStr = {name: jobName, context: starting, maxLength:maxLength, numSequences:numSequences}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(configStr), 'progen2');
		navigate("/app/results")
	}

	return (
	<>
		<Stack spacing={2} style={{padding: '10px' }}>
			<Header type="progen2"/>
			<Typography variant='body'>Generate a novel viable protein sequence using ProGen2 models!</Typography>
            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

			<TextField value={starting} label='Context (optional)' onChange={(e) => setStarting(e.target.value)}/>
			<TextField type="number" value={maxLength} label='Sequence Maximum Length' onChange={(e) => setMaxLength(e.target.value)}/>
			<TextField type="number" value={numSequences} label='Number of Sequences to Generate' onChange={(e) => setNumSequences(e.target.value)}/>
			{/* <Button onClick={submitRef}>test api</Button> */}
            <SubmitButton redir="progen2" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
		</Stack>
	</>
	)
}
