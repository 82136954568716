import { Stack, Button } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const Paragraph = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
        const [sequences, setSequences] = useState([])

        const d = {'CYS': 'C', 'ASP': 'D', 'SER': 'S', 'GLN': 'Q', 'LYS': 'K',
        'ILE': 'I', 'PRO': 'P', 'THR': 'T', 'PHE': 'F', 'ASN': 'N', 
        'GLY': 'G', 'HIS': 'H', 'LEU': 'L', 'ARG': 'R', 'TRP': 'W', 
        'ALA': 'A', 'VAL':'V', 'GLU': 'E', 'TYR': 'Y', 'MET': 'M'}

        const parsePdb = require('parse-pdb');

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }

                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));

		const config = {
			proteinFiles: filePaths,
                        heavyChains: sequences
                                .reduce((acc, chain) => {
                                        if (!acc.some(item => item.file === chain.file)) {
                                        acc.push(chain);
                                        }
                                        return acc;
                                }, [])                            
                                .map((chain, index) => chain.chain)
		}

                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "paragraph");
                navigate("/app/results");
        }


        const readFileAsync = (file) => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    resolve(event.target.result);
                  };
                  reader.onerror = (error) => {
                    reject(error);
                  };
                  reader.readAsText(file);
                });
              };
            
            function filterUniqueField(arr, field) {
            const uniqueValues = new Set();
            return arr.filter(obj => {
                const value = obj[field];
                if (uniqueValues.has(value)) {
                return false;
                } else {
                uniqueValues.add(value);
                return true;
                }
            });
            }
        
        //TODO: would be good to have a helper function given to 
        // filepond that extracts each pdb file's sequences + chains, should be simple to move this to take a useState setter for the sequences
	const handleUpdateFiles = async (fileItems) => {
		setFiles(fileItems);
		if (fileItems.length === 0) {
			setSequences([]);
			return;
		}
	
		let allChainsList = [];
	
		for (const fileItem of fileItems) {
			const file = fileItem.file;
			try {
				const text = await readFileAsync(file);
				const structure = await parsePdb(text);
				const uniqueChains = new Set();
				structure.atoms.forEach(item => uniqueChains.add(item.chainID));
				let myChains = Array.from(uniqueChains);
	
				for (const c of myChains) {
					let atoms = structure.atoms.filter(s => s.chainID === c && s.name === "N");
					// make sure unique by resSeq
					atoms = filterUniqueField(atoms, 'resSeq');
					let seq = atoms.map(obj => d[obj.resName]).join("");
					let idxs = atoms.map(obj => obj.resSeq);
					allChainsList.push({
						file: file.name,
						chain: c,
						sequence: seq,
						idxs: idxs,
						checked: true,
						fixed: ""
					});
				}
			} catch (error) {
				console.error('Error processing file:', file.name, error);
			}
		}
	
		// console.log("seqs:", allChainsList);
		setSequences(allChainsList);
		// If you want to set a default chain, you can uncomment and modify this line:
		// setChain(allChainsList[0].file + '_' + allChainsList[0].chain);
	};

        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                {console.log(sequences)}
                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind Paratope Prediction Online Tool</Typography>

                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Antibody Heavy Structures (first chain):</Typography>
                <ChooseOrUploadFile files={files} setFiles={handleUpdateFiles} types={['pdb']} useMultiple={true}/>

                <SubmitButton redir="paragraph" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>


                <Typography>Heavy Chains:</Typography>
                <Typography>(The first chain of the each uploaded pdb file is assumed to be the heavy chain)</Typography>
                
                {sequences 
                        ? sequences
                        .reduce((acc, chain) => {
                                if (!acc.some(item => item.file === chain.file)) {
                                  acc.push(chain);
                                }
                                return acc;
                              }, [])                            
                        .map((chain, index) => (
                                <>
                                <Typography key={index}>
                                {`File: ${chain.file}, input chain name: ${chain.chain} seq: ${chain.sequence.slice(0, 10)}...`}
                                </Typography>
                                </>
                        ))
                        : null
                }


                </Stack>
                </>
        )
}
