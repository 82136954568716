import { useCallback, useState } from 'react';
import { Handle, Position } from 'reactflow';
import {Typography} from '@mui/material'
import {detectFileTypeGenerator} from '../../utils';  
import { FilePond, registerPlugin } from "react-filepond";

export const FileUploadNode = ({ data}) => {
  return (
    <div style={{ width: '200px', background:"white", padding:10}}>
      <div style={{ textAlign: 'center' }}>
      <Typography><b>File Upload</b></Typography>
      </div>
      {
        data.view ? null :
        <FilePond     
        files={data.files}
        allowReorder={false}
        allowMultiple={false}
        onupdatefiles={(files) => {data.files = files}}
        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your file'
        credits={[]}
        />
      }
   
      <Handle type="source" position={Position.Bottom} id="file" />
    </div>
  );
}