import {useState} from 'react';
import { Typography} from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {useUser} from "@clerk/clerk-react";  
import {useNavigate} from "react-router-dom";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {ChooseOrUploadFile, Header} from './UIComponents'

import {detectFileTypeGenerator, addJobToQueue, returnS3PathsAndUploadFiles, startLambdaForType, OTHER_JOB_BASE } from '../utils';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const BindingddG = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [tab, setTab] = useState("1");
	const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
				<Header type="binding-ddg"/>
				<Typography variant="body">Predict changes in binding energy upon mutation for protein-protein complexes. Wild Type and Mutant Protein Complexes must have the same chain lengths. </Typography>

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
				<FileUpload user={user} isSignedIn={isSignedIn} jobName={jobName} duplicateJob={duplicateJob} exceed={exceed}/>
			</Stack>
		</>
		)
	}

const FileUpload = ({user, isSignedIn, jobName, duplicateJob, exceed}) => {
	const navigate = useNavigate();
	const [proteinFiles, setProteinFiles] = useState([]);
	const [ligandFiles, setLigandFiles] = useState([]);	

	/*
	const submit = () => {
		const config = `pdb:${proteinString} smiles:${ligandString} numInference:${numInference} numSamples:${numSamples}`;
		addJobToQueue(jobName, 0, user, config, "diff-dock");
	}
	*/

	const submitFile = (pay) => {
		if (proteinFiles.length === 0 || ligandFiles === 0) {
			alert("Make sure you've submitted both files!");
			return false;
		}

		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
		const ligandFilePaths = ligandFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

		const config = {wildtypeFile:proteinFilePaths.join(","), mutantFile:ligandFilePaths.join(",")}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), "binding-ddg");
		startLambdaForType("binding-ddg")
		navigate("/app/results");
	}

	let disableReasons = []
	if (proteinFiles.length == 0) {
		disableReasons.push("No protein file uploaded")
	}
	if (ligandFiles.length == 0) {
		disableReasons.push("No ligand file uploaded")
	}

	return (
		<Stack spacing={2}>
			
			<Typography >Wild Type Protein Complex</Typography>
			<ChooseOrUploadFile files={proteinFiles} setFiles={setProteinFiles} types={['pdb']} default='./example_wt.pdb'/>
			
			<Typography >Mutated Protein Complex</Typography>
			<ChooseOrUploadFile files={ligandFiles} setFiles={setLigandFiles} types={['pdb']}  default='./example_mut.pdb'/>
				
			<SubmitButton redir="binding-ddg" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
			
		</Stack>
	)
}
