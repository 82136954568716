import { Stack, Button, Autocomplete, TextField,  Typography } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE } from '../utils';
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const BinderDesignFiltering = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [chains, setChains] = useState("A")
        const [numSequences, setNumSequences] = useState(2)
        const [numFilteredSequences, setNumFilteredSequences] = useState(2)
        const [temperature, setTemperature] = useState(0.1)
        const navigate = useNavigate();
        const {isLoaded, isSignedIn, user} = useUser();
	const [exceed, setExceed] = useState(false);

        const temps = [0.1, 0.15, 0.2, 0.25, 0.3];

        const returnS3PathsAndUploadFiles = (file) => {
                let email = user.emailAddresses[0].emailAddress

		uploadFile(file, user, () => null);

		return email + "/" + file.name;
	}

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
			return false;
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, "binder-design-filtering", "batch", "", "", "");

                const filePaths = files.map(f => returnS3PathsAndUploadFiles(f.file));
                const filesConfig = `${filePaths.join(",")}`;

                const configs = {
                        "filename": filesConfig,
                        "chains": chains,
                        "seqs": numSequences,
                        "temp": temperature,
                        "verify": true,
                        "use_soluble_model": false,
                        "model_name": "v_48_020",
                        "omit_AAs":"X"
                }

                // add protein mpnn job
                addJobToQueue(jobName + "-proteinmpnn", 0, user, JSON.stringify(configs), "proteinmpnn", "", "In Queue", jobName);
                startLambdaForType("proteinmpnn")
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind Binder Design Filtering Online Tool</Typography>
                <Typography variant='body'>Given an input protein structure, this workflow runs ProteinMPNN and return a set of sequences for that backbone.</Typography>
                <Typography variant='body'>Then, it will automatically submit the highest scoring sequences (by MPNN score) to Alphafold to verify results</Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["chemical/x-pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
                />
                <TextField 
                        label="Chain(s)"              
                        value={chains}
                        onChange={(e) => setChains(e.target.value)}
                        sx={{width:"300px"}}
                />
                <TextField 
                        type="number"
                        label="Num Sequences"              
                        value={numSequences}
                        onChange={(e) => setNumSequences(e.target.value)}
                        sx={{width:"350px"}}
                        error={numSequences > 1000}
                        helperText={numSequences > 1000 ? 'Max # sequences is 1000' : ''}
                />
                <TextField 
                        type="number"
                        label="# Alphafold Sequences (filtered by MPNN score)"              
                        value={numFilteredSequences}
                        onChange={(e) => setNumFilteredSequences(e.target.value)}
                        sx={{width:"350px"}}
                        error={parseInt(numFilteredSequences) > parseInt(numSequences) || parseInt(numFilteredSequences) > 100}
                        helperText={parseInt(numFilteredSequences) > parseInt(numSequences) ? 'Must be less than # of total sequences' : parseInt(numFilteredSequences) > 100 ? 'Must be at most 100' :''}
                />
               <Autocomplete
                disablePortal
                options={temps}
                value={temperature}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Temperature" />}
                onChange={(event, value) => setTemperature(value)} // prints the selected value
                />
                <SubmitButton redir="binderdesignfiltering" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
