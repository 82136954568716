import {Button, Dialog, DialogTitle, DialogContent, TextField, Stack, Typography, Grid, Box} from '@mui/material'
import {useState, useEffect} from 'react';
import {useUser} from "@clerk/clerk-react";  
import axios from 'axios'
import { InlineWidget } from 'react-calendly';
import Divider from '@mui/material/Divider';

export default function FormButtons() {

    const [openTab, setOpenTab] = useState("None")
    const [email, setEmail] = useState("")
    const [tool, setTool] = useState("")
    const [message, setMessage] = useState("")
    const { isLoaded, isSignedIn, user } = useUser();  

    useEffect(() => {
        if (isSignedIn) {
            setEmail(user.emailAddresses[0].emailAddress)
        }
    },[isLoaded])

    function closeTab() {
        setOpenTab("None")
    }

    const submitForm = () => {
        axios.get('/api/notifyUs', {params:{'title': email + " submitted form for " + openTab + " Tool", 'message': tool + ": " + message}})
        setOpenTab("None")
        setTool("")
        setMessage("")
    }

    return (<>
    <Box display="flex" justifyContent="space-between" style={{width:"300px"}}>
    
    <Button sx={{textTransform:"none", fontWeight:"bold"}} onClick={() => setOpenTab("Request")}>Request a Tool</Button>
    {/* <Button onClick={() => setOpenTab("Submit")}>Submit a Tool</Button> */}
    <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />

    <Button sx={{textTransform:"none", fontWeight:"bold"}} onClick={() => setOpenTab("Schedule")}>Schedule a demo</Button>
    </Box>

    <Dialog open={openTab === "Request" || openTab == "Submit"} onClose={closeTab}>
    <DialogTitle>{openTab === "Request" ? 'Request a Tool' : 'Submit a Tool'}</DialogTitle>
    <DialogContent  sx={{ width: '500px'}}>
        {
            openTab == "Request" ? 
            <Typography>Read about a cool tool in a paper but don't have time to implement it? Tell us about your use case and we'll consider adding to our website! </Typography>
            :
            <Typography>Tell us more about a bioinformatics tool or model you've created and we'll work with you to add it to our website!</Typography>
        }
        <br></br>
        <form>
        <Stack spacing={2} padding={1} sx={{ width: '100%' }}>
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Tool Name"
          variant="outlined"
          fullWidth
          value={tool}
          onChange={(e) => setTool(e.target.value)}
          required
        />
        <TextField
          label="Tool Details"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button onClick={submitForm}>
          Submit
        </Button>
        </Stack>
        </form>
    </DialogContent>
    </Dialog>

    <Dialog open={openTab == "Schedule"} onClose={closeTab}>
    <DialogTitle>Schedule a Meeting with the Founders</DialogTitle>
    <DialogContent  sx={{ width: '500px'}}>
        <Typography>We'd love to hear your feedback, suggestions, problems, or any custom needs you may have. </Typography>
        <InlineWidget url="https://calendly.com/founders-cji" />
    </DialogContent>
    </Dialog>
    </>)
}

