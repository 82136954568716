import { Stack, Button , MenuItem, Select, InputLabel} from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE } from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const CRISPResso2 = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [amplicon, setAmplicon] = useState('AATGTCCCCCAATGGGAAGTTCATCTGGCACTGCCCACAGGTGAGGAGGTCATGATCCCCTTCTGGAGCTCCCAACGGGCCGTGGTCTGGTTCATCATCTGTAAGAATGGCTTCAAGAGGCTCGGCTGTGGTT');
        const [editing, setEditing] = useState("cas9")
        const [sequencing, setSequencing] = useState("paired")
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [files2, setFiles2] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const returnS3PathsAndUploadFiles = (file) => {
                let email = user.emailAddresses[0].emailAddress

		uploadFile(file, user, () => null);

		return email + "/" + file.name;
	}

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your r1 files.");
			return false;
                }
                if (sequencing == "paired" && files2.length === 0) {
                        alert("Please make sure you've inputted your r2 files.");
			return false;
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(f.file));
                const filePaths2 = files2.map(f => returnS3PathsAndUploadFiles(f.file));
                const config = `fastq1: ${filePaths.join(",")} fastq2: ${filePaths2.join(",")} amplicon: ${amplicon} editing: ${editing} sequencing: ${sequencing}`;
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, config, "crispresso2");
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}><Navigation />
                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind CRISPResso2 Online Tool</Typography>
                <Typography variant='body'>Given sequencing data, CRISPResso2 assesses the efficiency and specificity of CRISPR edits by aligning sequencing reads to a reference, quantifying mutations to determine whether genome editing was effective, and summarizing the results using intuitive visualizations.</Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
                <InputLabel >Editing Tool</InputLabel>
                        <Select
                        value={editing}
                        label="Editing Tool"
                        onChange={(e) => 
                                setEditing(e.target.value)
                        }
                        >
                        <MenuItem value={"cas9"}>Cas9</MenuItem>
                        <MenuItem value={"cpf1"}>Cpf1</MenuItem>
                        <MenuItem value={"base"}>Base editors</MenuItem>
                        <MenuItem value={"prime"}> Prime editors</MenuItem>
                        </Select>
                
                <InputLabel >Sequencing Design</InputLabel>
                        <Select
                        value={sequencing}
                        label="Sequencing Design"
                        onChange={(e) => 
                                setSequencing(e.target.value)
                        }
                        >
                        <MenuItem value={"paired"}>Paired end reads</MenuItem>
                        <MenuItem value={"single"}>Single end reads</MenuItem>
                        <MenuItem value={"interleaved"}>Interleaved reads</MenuItem>
                        </Select>

                <Typography variant='body'>Fastq file r1:</Typography>
                <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your fastq file'
                credits={[]}
                acceptedFileTypes={["fastq"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".fastq":"fastq"})}
                />

                {sequencing == "paired" ? 
                <>
                <Typography variant='body'>Fastq file r2:</Typography>
                <FilePond
                files={files2}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles2}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your fastq file'
                credits={[]}
                acceptedFileTypes={["fastq"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".fastq":"fastq"})}/>
                </> : null}

                <Typography variant='body'>Specify the amplicon sequence. </Typography>
                <TextField label='Amplicon' 
                onChange={
                (e) => {
                setAmplicon(e.target.value)
                }} 
                value={amplicon}></TextField>
   

                <SubmitButton redir="crispresso2" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
