import stanfordLogo from './stanford-logo.svg';
import uvaLogo from './uva-logo.png';
import Slider from 'react-infinite-logo-slider'

import React, {useState, useEffect, useRef} from 'react';
import { Box, Avatar, Stack, Typography, Grid } from '@mui/material';

const LogoList = () => {
  const logos = [
     stanfordLogo,
     uvaLogo
  ];

  //
  const logoUrls = [
    "https://identity.stanford.edu/wp-content/uploads/sites/3/2020/07/stanford-university-stacked.png",
    "https://upload.wikimedia.org/wikipedia/commons/2/2f/University_of_Oxford.svg",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/UT_Southwestern_Medical_Center_logo.svg/512px-UT_Southwestern_Medical_Center_logo.svg.png",
    "https://studyarchitecture.com/wp-content/uploads/mit-logo-1-2.png",
    "https://bookface-images.s3.amazonaws.com/logos/45155711679549942ceee4a5e0a4ab46262845b5.png",
    "https://bookface-images.s3.amazonaws.com/logos/a6fb83077d08c96f52638550c466797e98285ac9.png",
    "https://adimab.com/wp-content/uploads/2024/01/logo-adimab-off-canvas.png",
    "https://upload.wikimedia.org/wikipedia/commons/7/70/Harvard_University_logo.svg",
    "https://septerna.com/wp-content/themes/septerna/dist/img/purple_logo.svg",
    "https://upload.wikimedia.org/wikipedia/commons/8/82/University_of_California%2C_Berkeley_logo.svg",
    "https://bookface-images.s3.amazonaws.com/logos/359082e927848c2f9f0f6921260437ddab55fb77.png",
    "https://download.logo.wine/logo/University_of_Cambridge/University_of_Cambridge-Logo.wine.png",
  ];

  return (<Slider
            width="220px"
            duration={80}
            blurBorders={false}
        >
            {logoUrls.map((src, index) => (
            <Slider.Slide>
                <Avatar
                  key={index} // Make sure to provide a unique key when mapping components
                  sx={{
                    width: ['100px', '140px', '170px'], // Adjust the width based on screen width
                    height: ['70px', '100px', '120px'], // Adjust the height based on screen width
                    mr: 2,
                    objectFit: 'contain',
                    bgcolor: '#f5f5f5',
                    mb: 2, // Add margin at the bottom for spacing
                    filter: 'grayscale(10%)', // Apply greyscale filter
                    opacity:0.9
                  }}
                  variant="square"
                >
                  <img
                    sx={{
                      mr: 2,
                    }}
                    width="100%"
                    alt=""
                    src={src}
                  />
                </Avatar>
                </Slider.Slide>
        ))}
        </Slider>
  );
    
}

const UsedBy = () => {

  return (
    <Stack>
      
      <Typography variant="h6"
        style={{
          fontSize: '3vh',
          fontWeight: 'bold',
          fontFamily: 'LTHoop, Helvetica'
        }}
      >
        Trusted By Scientists At:
      </Typography>

      <LogoList />
    </Stack>
  );
}

const keyframes = `
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
`;

export default UsedBy;