import React, { useState } from 'react';
import { Stack, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import 'filepond/dist/filepond.min.css';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { addJobToQueue, detectFileTypeGenerator, startLambdaForType, returnS3PathsAndUploadFiles } from '../utils';
import { useUser } from '@clerk/clerk-react';
import Navigation from './Navigation';
import { SubmitButton } from './SubmitButton';
import { useNavigate } from 'react-router';
import { NameField } from './NameField';
import Swal from 'sweetalert2';
import {Header} from './UIComponents'

// Register FilePond plugins
registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

//name field broken, job type name change
export const ProteinMetrics = () => {
    const [selectedMetric, setSelectedMetric] = useState('Structure Metrics');
    const [targetFiles, setTargetFiles] = useState([]);
    const [referenceFiles, setReferenceFiles] = useState([]);
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [exceed, setExceed] = useState(false);

    const navigate = useNavigate();
    const { user } = useUser();

    const handleMetricChange = (event) => {
        setSelectedMetric(event.target.value);
        setTargetFiles([]);
        setReferenceFiles([]);
    };

    const submit = () => {
        if (selectedMetric !== 'Alignment-based Metrics' && targetFiles.length === 0) {
            Swal.fire("Please make sure you've selected a file.");
            return;
        }
        if (selectedMetric === 'Alignment-based Metrics' && (targetFiles.length === 0 || referenceFiles.length === 0)) {
            Swal.fire("Please make sure you've selected both target and reference files.");
            return;
        }

        const targetPaths = targetFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
        const referencePaths = referenceFiles.length ? referenceFiles.map(f => returnS3PathsAndUploadFiles(user, f.file)) : '';

        const configs = {
            filename: selectedMetric == "Structure Metrics" ? targetPaths : targetPaths[0],
            referenceFilename: referencePaths,
            metricType: selectedMetric
        };

        addJobToQueue(jobName, 0, user, JSON.stringify(configs), 'protein-metrics');
        startLambdaForType("protein-metrics")
        navigate("/app/results");
    };

    return (
        <>
            <Stack spacing={2} style={{padding: '10px'}}>
                <Header type="protein-metrics"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
                <FormControl fullWidth style={{marginBottom: '20px'}}>
                    <InputLabel id="metric-select-label">Select Metric Type</InputLabel>
                    <Select labelId="metric-select-label" value={selectedMetric} label="Select Metric Type" onChange={handleMetricChange}>
                        <MenuItem value="Structure Metrics">Structure Metrics</MenuItem>
                        <MenuItem value="Single Sequence Metrics">Single Sequence Metrics</MenuItem>
                        <MenuItem value="Alignment-based Metrics">Alignment-based Metrics</MenuItem>
                    </Select>
                </FormControl>
                
                
                <Typography>Each of these metrics are modified such that higher (closer to 0 if negative) is a higher predicted likelihood of folding and having function.</Typography>
                            
                {selectedMetric !== 'Alignment-based Metrics' ? (
                    <>
                    {selectedMetric === "Structure Metrics" ? 
                        <>
                            <Typography>Metrics produced are ESM-IF, ProteinMPNN, MIF-ST, and AlphaFold2_pLDDT if the pdb is produced with an AlphaFold structure prediction.</Typography> 
                            
                            <Typography>We assume that the structure only has the chain "A". </Typography>
                        </>
                        : 
                        <>
                            <Typography>Upload a list of sequences to retrieve metrics. Using ESM-1v, ESM-1v mask6, CARP-640m models.</Typography>
                            {/*explain metrics more */}
                            <Typography></Typography>
                        </>
                    }

                    <FilePond
                        files={targetFiles}
                        allowMultiple={true}
                        onupdatefiles={setTargetFiles}
                        credits={[]}
                        acceptedFileTypes={selectedMetric === "Structure Metrics" ? ['chemical/x-pdb'] : ["fasta"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator(selectedMetric === "Structure Metrics" ? {".pdb":"chemical/x-pdb"} : {".fasta":"fasta", ".fa": "fasta"})}            
                        labelIdle={`Drag & Drop or <span class="filepond--label-action">Browse</span> your target ${selectedMetric === "Structure Metrics" ? 'pdb' : 'fasta'} file(s)`}
                    />
                    </>
                ) : (
                    <>
                        <Typography>ESM-MSA, and BLOSUM62 substitution matrix scoring.</Typography>
                        
                        <Typography variant="h6">Target Sequences</Typography>
                        <FilePond
                            files={targetFiles}
                            allowMultiple={true}
                            onupdatefiles={setTargetFiles}
                            acceptedFileTypes={["fasta"]}
                            fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta", ".fa": "fasta"})}            
                            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your target fasta file(s)'
                            credits={[]}
                        />
                        <Typography variant="h6">Reference Sequences</Typography>
                        <FilePond
                            files={referenceFiles}
                            allowMultiple={true}
                            onupdatefiles={setReferenceFiles}
                            acceptedFileTypes={["fasta"]}
                            fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta", ".fa": "fasta"})}            
                            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your reference fasta file(s)'
                            credits={[]}
                        />
                    </>
                )}

                <SubmitButton redir="proteinmetrics" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
            </Stack>
        </>
    );
};
