import {Container, Button, Box, Chip} from '@mui/material'
import {useState} from 'react';

function ToolPaperViewer({tool}) {
    const [showPaper, setShowPaper] = useState(false)

    const papers = {
        "rosettafold2na" : "https://www.nature.com/articles/s41592-023-02086-5.pdf",
        "diffdock": "https://arxiv.org/pdf/2210.01776.pdf",
        "ligandmpnn": "https://www.biorxiv.org/content/10.1101/2023.12.22.573103v1.full.pdf",
        "lightdock": "https://watermark.silverchair.com/bioinformatics_34_1_49.pdf?token=AQECAHi208BE49Ooan9kkhW_Ercy7Dm3ZL_9Cf3qfKAc485ysgAAA3swggN3BgkqhkiG9w0BBwagggNoMIIDZAIBADCCA10GCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMBukLoGDo8zFtVi5lAgEQgIIDLtmWz888R8CPvQ4_T9R4bsnu3PvNboC2Tt-LWdwrVrG1XJmQIIxoRvDKjo3jwN7YYVEQDsK7R7UVCLooYioh3YxPR19HVG9odJZBAZFAuqNAcpBVVu4IwWyod6iBafvkBezk7SLdlRkaumZxDTbGsNL7CnCxwYFo0DXKky24qn8OY1rMlXkryveSym2Khz3A47d4cpTWHnVFmDIHwfheoEZRBScEV6km5Nt07unefll-tiegHu-oCKCsJbgdGX9ynzyuc8kAt0UUmawJI70EvR_WWb47CNUL19QNbffYirObx3NSzXEJzWOPRO1Oob1XhxqIlfvRSqTcuw3cIzW-Pp6rHjV5_G6bnH6Ocp8AhZGOSNrOe5R2sdng-jcg6AXLD9MPheVvvu2J4xl2J3Z8EtfXRSl6fiUMr9ELzikmBhTdFRDC1yvikB8rGBHBE2Hc7cXGUVex0WYY2quXOjHYSlgX2jezMyZ_AaXJZ00DSxgndg4PNNjTJUHdeXyMNUW8WI1Ra1iBNIIwPaWjqjNB36EwVIYVOihybtoFugEHGRiNWeWjjA0u-E_bU1ST8NN2Bfh6kKu5uKsM9eiCmgd9zlq-mPFh8Gv4vds0e3BDou1ALGuGGqo9SDLrIgDFsQkQiQjSYeYJuDV-lmMH74pa5GLx-iNuzFM3Sedgy28Nx-K6czxKzxAX9w4R4M58-wm7M-pseb2-MxNJqDyjxEgmw9Kj3SmjBrrsshV9jwxRBTq1u5TVDqvGJtI2dybU5jAeMcfHMs4akY1AbiOUqoWkraQ3v1aQYUoTBMFmQLKj2YnG0kXdMA0GOYI2LatqmFKMU81TzWHxn1feoPyZ-6mUv6wnfcUZxCIZS_hRWeIg6mQQqKniQGHL7brc4JBEiKz1pzxKiQe-5l_2V6ieycx7qN54r4Kw7_UUILCY8BtnH0xLZ0vGPfVjV-jm9V02ux1VBxF1vWdbpn5JtE-t1PgQixiHPwqum362QXbrqPqb09Bst4bGp8MtQB7L0IN-4xXAFLp8eUL9BHpf4TAzPDAfzUlRDefiDgD4vqb55J2TKIdO6xtNVV3VXuVu0h9bRpM",
        "protgpt2": "https://www.nature.com/articles/s41467-022-32007-7.pdf",
        "proteinmpnn": "https://www.biorxiv.org/content/10.1101/2022.06.03.494563v1.full.pdf",
        "afcluster": "https://www.nature.com/articles/s41586-023-06832-9.pdf",
        "diffsbdd": "https://arxiv.org/pdf/2210.13695.pdf",
        "rf-diffusion": "https://www.nature.com/articles/s41586-023-06415-8.pdf",
        "alphafold": "https://www.nature.com/articles/s41586-021-03819-2.pdf"
    }

    return  ( 
        papers[tool] ? 
                showPaper ? 
                <Container  style={{display: 'flex', flexDirection: 'column' }}>
                <Box height={'100vh'}>
                 <object  height='100%' display={showPaper} data={papers[tool]} type="application/pdf" width="100%">
                    <p>Alternative text - link <a href={papers[tool]}>to the PDF!</a></p>
                </object>
                <br></br>
                </Box> 
            </Container> 
                : 
                <Container style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <br></br>
                <Chip label="View Tool Paper" onClick={() => setShowPaper(!showPaper)} />
                </Container>
        : null
   )
}

export { ToolPaperViewer };