import { TextField, Button, Stack, Grid, InputLabel, MenuItem, Select, Typography, Link} from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE } from '../utils';
import {useState} from 'react'
import {useUser} from "@clerk/clerk-react";  
import { useNavigate } from "react-router-dom";
import Navigation from './Navigation';
import {NameField} from './NameField';
import {SubmitButton} from './SubmitButton';

export default function TDC() {

    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [exceed, setExceed] = useState(false);
    const [model, setModel] = useState('ADME_Properties');
    const [sequence, setSequence] = useState("CC(=O)NC1=CC=C(O)C=C1");
    const { isLoaded, isSignedIn, user } = useUser();
    const navigate = useNavigate();

    // Models:
    // tdc/BBB_Martins-AttentiveFP - Martins, Ines Filipa, et al.

    const submitSequence = async (pay) => {
        const cost = pay ? OTHER_JOB_BASE : 0
        addJobToQueue(jobName, cost, user, sequence, "tdc/" + model)

        navigate("/app/results")
    }

    return(

    <>
        <Stack spacing={2} padding={2}>
            <Grid>
                <Navigation />
            </Grid>
            <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind Therapeutics Data Commons Online Tool</Typography>
            <Typography varient="h8">Therapeutics Data Commons is a resource to access and evaluate AI methods, supporting the development of AI methods, with a strong bent towards establishing the foundation of which AI methods are most suitable for drug discovery applications and why. </Typography>
            <Typography variant="h6">Models</Typography>
            <Typography varient="h8">BBB_Martins-AttentiveFP - Binary classification to predict the activity of the blood brain barrier (BBB)</Typography>
            <Typography component="span" varient="h8">ADME Properties - 21 ADME Properties for Pharmaco-kinetics as described <Link href="https://tdcommons.ai/single_pred_tasks/adme/">here</Link></Typography> 


            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
            <TextField 
                label="SMILES String"              
                value={sequence}
                onChange={(e) => setSequence(e.target.value)}
            />
              <InputLabel id="model">Model</InputLabel>
                <Select
                    labelId="model"
                    value={model}
                    label="Model"
                    onChange={(e) => setModel(e.target.value)}
                >
                    <MenuItem value={"BBB_Martins-AttentiveFP"}>BBB_Martins-AttentiveFP</MenuItem>
                    <MenuItem value={"ADME_Properties"}>ADME Properties</MenuItem>
                    {/* <MenuItem value={"BBB_Martins-CNN"}>tdc/BBB_Martins-CNN</MenuItem>
                    <MenuItem value={"CYP3A4_Veith-CNN"}>CYP3A4_Veith-CNN</MenuItem> */}
                </Select>
            <SubmitButton redir="tdc" duplicate={duplicateJob} exceed={exceed} onSubmit={submitSequence}>Submit</SubmitButton>

        </Stack>
    </>)
}