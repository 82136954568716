// SignOut.js
import { useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import {Typography} from '@mui/material';

const SignOut = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Sign out the user and redirect to the home page
    signOut().then(() => {
      navigate('/');
    });
  }, [signOut, navigate]);

  return (<div></div>)
};

export default SignOut;
