import { Stack, Button, MenuItem, InputLabel, Select } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, OTHER_JOB_BASE } from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

export const AfCycDesign = () => {

    const [files, setFiles] = useState([]);
    const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [len, setLen] = useState(100);
    const [rmAA, setRmAA] = useState("C");
    const [model, setModel] = useState('backbone');
    const [chain, setChain] = useState('A')
    const [duplicateJob, setDuplicateJob] = useState(false);
    const navigate = useNavigate();

    const submit = (pay) => {
        const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));

        let configObj = {name: jobName, pdb: filePaths, seq_len: len, task: model, chain: chain};
        const cost = pay ? OTHER_JOB_BASE : 0
        addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "afcycdesign");
        navigate("/app/results");
}

// const test = async () => {
//     const lambdaURL = 'https://enyc2an36djriimjgyeyqwnfna0fxbvz.lambda-url.us-west-1.on.aws/';
//     // const formData = new FormData();
//     // formData.append('file', files[0].file); // 'file' should be the name of the parameter expected by the server
//     // console.log("form data:", formData)
//     // const payload = {
//     //     formData
//     // };

//     // (function() {
//     //     let formData = new FormData();
//     //     formData.append('file', files[0]); 
//     //     return formData;
//     //   })()

//     const reader = new FileReader();

//     reader.onload = async function(fileLoadedEvent) {
//         const fileContent = fileLoadedEvent.target.result;
//         try{
//             let resp = await axios.post(lambdaURL
//                     , 
//                     JSON.stringify({ "filename": files[0].file.name, "contents" : fileContent})
//                     , {
//                     headers: {
//                         'Access-Control-Allow-Origin': '*',
//                     }
//                     }
//                 )
//             console.log(resp)
//         } catch(err) {
//             console.log(err)
//         }
//     };

//     reader.readAsText(files[0].file);




// }


    return (
        <>

        <Stack spacing={2} style={{padding: '10px' }}>
        <Header type="afcycdesign" title="AlphaFold Cyclic Design"/>
        <Typography variant='body'>
            AfCycDesign modifies the AlphaFold model to design cyclic peptides. It can be used to design peptide backbones given a protein pdb file or to hallucinate cyclic peptides given a desired length. 
                </Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
        <InputLabel >Task</InputLabel>
                <Select
                value={model}
                label="Model"
                onChange={(e) => 
                        setModel(e.target.value)
                }
                >
                <MenuItem value={"backbone"}>Fixed Backbone Design</MenuItem>
                <MenuItem value={"hallucination"}>Hallucination</MenuItem>
                </Select>
    
                {
                    model == "backbone" ? 
                    <>
                    <FilePond
                    files={files}
                    allowReorder={true}
                    allowMultiple={true}
                    onupdatefiles={setFiles}
                    labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                    credits={[]}
                    acceptedFileTypes={["pdb"]}
                    fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"pdb"})}
                    />
                    <TextField label='Chain' 
                    onChange={(e) => {setChain(e.target.value)}} 
                    value={chain}></TextField>
                    {/* <Button onClick={() => test()}>test</Button> */}
                    </>
                    :                 
                    <>
                  <TextField label='Sequence Length' 
                  onChange={(e) => {setLen(e.target.value)}} 
                  value={len}></TextField>

                  <TextField label='Remove AAs' 
                  onChange={(e) => {setRmAA(e.target.value)}} 
                  value={rmAA}></TextField>
                  </>
                }

        <SubmitButton redir="afcycdesign" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

        </Stack>
         </>
    )
}