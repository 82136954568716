import React, { useState, useReducer, useEffect} from "react";
import Papa from 'papaparse';

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { Grid, Stack, Typography, Autocomplete, InputLabel, MenuItem, Select, TextField, ToggleButtonGroup, ToggleButton } from "@mui/material";
import Navigation from "./Navigation";
import {
    useUser,
} from "@clerk/clerk-react";  
import { useNavigate } from "react-router-dom";
import {NameField} from './NameField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {addJobToQueue, returnS3PathsAndUploadFiles, sleep, submitSequenceToAlphaFold, startLambdaForType, detectFileTypeGenerator, OTHER_JOB_BASE, calcPrice, getFlagsDB } from '../utils';
import RFdiffusionBatch from "./BatchUpload/RFdiffusionBatch";
import { VinaBatch } from "./BatchUpload/VinaBatch";
import { ImmuneBuilderBatch } from "./BatchUpload/ImmuneBuilderBatch";
import { BatchFasta } from "./BatchUpload/BatchFasta";
import {SubmitButton} from './SubmitButton';

import axios from 'axios'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode);

let initialSettings = [
  {
      id: 1,
      name: "num-models",
      label:"Number of Models",
      choices: ["1", "2", "3", "4", "5"],
      selected: "5"
  },
  {
      id: 2,
      name: 'msa-mode',
      label:"MSA Mode",
      choices: ["mmseqs2_uniref_env","mmseqs2_uniref","single_sequence"],
      selected: "mmseqs2_uniref_env"
  },
  {
      id: 3,
      name: 'num-recycle',
      label:"Number of Recycles",
      choices: ["3", "1","5","10"],
      selected: "3"
  },
  {
      id: 4,
      name: 'num-relax',
      label:"Number of Relaxations",
      choices: ["none", "1"],
      selected: "none"
  },
  {
      id: 5,
      name: 'pair-mode',
      label:"Pair Mode",
      choices: ["unpaired","paired","unpaired_paired"],
      selected: "unpaired_paired"
  }
]

const reducer = (settings, action) => {
  const newSettings = settings;
  return newSettings.map((setting) => {
      if (setting.name === action.settingName) {
        return { ...setting, selected: action.newValue };
      } else {
        return setting;
      }
    });
  }

export default function BatchUpload() {
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [csvFiles, setCsvFiles] = useState([]);

  const [ddInput, setDdInput] = useState("file");
  const [nameCol, setNameCol] = useState("JobName");
  const [smilesCol, setSmilesCol] = useState("Smiles");
  const [jobNames, setJobNames] = useState([]);

  const [sequences, setSequences] = useState([]);
  const [sequences2, setSequences2] = useState([]);
  const [sequencesNames, setSequencesNames] = useState([]);
  const [sequences2Names, setSequences2Names] = useState([]);

  const [label, setLabel] = useState('Drag & Drop or <span class="filepond--label-action">Browse</span> your fasta files')
  const [ligandLabel, setLigandLabel] = useState('Drag & Drop or <span class="filepond--label-action">Browse</span> your fasta files')

  const [jobType, setJobType] = useState("alphafold-single");
  const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
  const [duplicateJob, setDuplicateJob] = useState(false);
	const [exceed, setExceed] = useState(false);

  const [settings, updateSettings] = useReducer(reducer, initialSettings);

  const navigate = useNavigate();
  const { isLoaded, isSignedIn, user } = useUser();  
  
  const typeToFile = new Map([
    ["alphafold-single-ligand", 'fasta'],
    ['alphafold-pair-ligand', 'fasta'],
    ['alphafold-single-receptor', 'fasta'],
    ['alphafold-pair-receptor', 'fasta'],
    ['lightdock-receptor', 'pdb'],
    ['lightdock-ligand', 'pdb'],
    ['diffdock-receptor', 'pdb'],
    ['diffdock-ligand', 'sdf or mol2']
  ]);

  const submitBatch = (pay) => {

    if (!isSignedIn) return

    // if (isSignedIn && user.emailAddresses[0].emailAddress == "sherryl@stanford.edu" && jobType == "alphafold-single") {
    //   alert("Feature not supported. Please email info@tamarind.bio")
    //   return
    // }

    if ((jobType == "alphafold-single" || jobType == "alphafold-pair") && (sequences.length < files.length || sequences2.length < files2.length)) {
      alert("Error processing files")
      return false;
    }

    if (files.length == 0){
      alert("Please upload your files")
			return false;
    }

    let jobType_ = jobType == "alphafold-single" || jobType == "alphafold-pair" ? "alphafold" : jobType;
    let email = user.emailAddresses[0].emailAddress

    let cost_ = pay && jobType == "diffdock" ? Math.max(files.length, 1) * Math.max(files2.length, 1) * 0.25 : 0
    addJobToQueue(jobName, cost_, user, jobType_, "batch", "", "In Queue", "")

    let seq1TotLen = 0
    for (const seq of sequences) {
      seq1TotLen += seq.length
    }
    let seq2TotLen = 0
    for (const seq of sequences2) {
      seq2TotLen += seq.length
    }
    let totLen = seq1TotLen * sequences2.length + seq2TotLen * sequences.length
    let totJobs = sequences.length * sequences2.length
    let alphaCost = calcPrice("alphafold", totJobs, [Math.round(totLen/totJobs)])[0]

    let ddBatchConfigs = []
    let ddBatchNames = []

    for (let i = 0; i < Math.max(files.length, sequencesNames.length); i++) {
      for (let j = 0; j < Math.max(files2.length, sequences2Names.length); j++) {

        let cost = pay ? OTHER_JOB_BASE : 0

        const proteinFilePath = returnS3PathsAndUploadFiles(user, files[i].file);
        const ligandFilePath = jobType == "alphafold-pair" ? "" : jobType == "diffdock" && ddInput != "file" ? files2[j] : returnS3PathsAndUploadFiles(user, files2[j].file);
        let combinationJobName = ""
        if (jobType == "alphafold-pair") {
          combinationJobName = jobName + "-" + sequencesNames[i] + "-" + sequences2Names[j]
        } else {
          combinationJobName = jobType == "diffdock" && ddInput == "csv" ? jobNames[j] : jobName + "-" + files[i].file.name.split('.')[0] + "-" + (jobType == "diffdock" && ddInput != "file" ? files2[j] : files2[j].file.name.split('.')[0])
        }
        console.log(combinationJobName)

        if (jobType == "alphafold-pair") {
          const config = `${sequences[i]}:${sequences2[j]}`
          cost = pay ? alphaCost * (sequences[i].length + sequences2[j].length) / totLen : 0
          submitSequenceToAlphaFold(combinationJobName, config, user, pay, jobName, settings, "", cost)
        } else if (jobType == "lightdock"){
          const config = {proteinFiles:proteinFilePath, ligandFiles:ligandFilePath}
          addJobToQueue(combinationJobName, cost, user, JSON.stringify(config), jobType, "", "In Queue", jobName);
          startLambdaForType("lightdock")
        } else { // diffdock
          let configs = {
            "proteinFile": proteinFilePath
          }
          if (ddInput != "file") {
            configs["ligandSmiles"] = ligandFilePath
          } else {
            configs["ligandFile"] = ligandFilePath
          }
          ddBatchConfigs.push(JSON.stringify(configs))
          ddBatchNames.push(combinationJobName)
          // addJobToQueue(combinationJobName, cost, user, JSON.stringify(configs), jobType, "", "In Queue", jobName);
          // startLambdaForType("diffdock")
        }
      }
    }

    if (jobType == "diffdock") {
      const postData = {
        sequences: ddBatchConfigs,
        jobNames: ddBatchNames,
        "batchName": jobName, 
        "settings":"", 
        "type":"diffdock",
        "costs":Array(ddBatchConfigs.length).fill(0.25)
        };
      axios.post('/api/addJobToQueueBatch', postData)
    }

    let lambdaType = jobType != "alphafold-pair" ? jobType : sequences[0].length + sequences2[0].length < 1400 ? "alphafold_small" : "alphafold_large"
    for (let i = 0; i < Math.min(5, files.length * files2.length) ; i++) {
      startLambdaForType(lambdaType)
      sleep(5000)
    }
    navigate('/app/results')
  }

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  // const handleUpdateFiles = async (fileItems) => {
  //   setFiles(fileItems)
  //   if (jobType != "alphafold-pair") {
  //     return
  //   }
  //   let texts = []
  //   for (const fileItem of fileItems) {
  //     const file = fileItem.file;
  //     try {
  //       const text = await readFileAsync(file);
  //       texts.push(text.split('\n')[1])
  //     } catch (error) {
  //       console.error('Error reading file:', error);
  //     }
  //   }
  //   setSequences(texts)
  // };

  const handleUpdateFiles = async (fileItems) => {
		setFiles(fileItems)
    if (jobType != "alphafold-pair") {
      return
    }
		let seqs = []
		let names = []
		for (const fileItem of fileItems) {
		  const file = fileItem.file;
		  try {
			const text = await readFileAsync(file);
			const lines = text.split(/[\n\r]/).filter(str => str !== "");
			let new_lines = []
			let curr_seq = ""
			for (let i = 0 ; i<lines.length ; i++) {
				if (lines[i].includes(">")) {
					if (curr_seq != "") {
						new_lines.push(curr_seq.replace(/\r/g, ""))
					}
					new_lines.push(lines[i].replace(/\r/g, ""))
					curr_seq = ""
				} else {
					curr_seq += lines[i]
				}
			}
			new_lines.push(curr_seq.replace(/\r/g, ""))
			if (new_lines.length % 2 != 0) {
				alert("Error parsing file");
				return
			}
			for (let i = 0; i < new_lines.length - 1; i += 2) {
				const line1 = new_lines[i];
				const line2 = new_lines[i + 1];
				seqs.push(line2)
				names.push(line1.substring(1))
			}
		  } catch (error) {
			console.error('Error reading file:', error);
		  }
		}
		console.log("shouldn'e be here:", seqs, names)
		setSequences(seqs)
		setSequencesNames(names)
	  };

  useEffect(() => {
    handleCsvFileLoad(csvFiles)
  }, [nameCol, smilesCol])

  const handleCsvFileLoad = (fileItems) => {
    setCsvFiles(fileItems)
    if (fileItems.length == 0) return
    const file = fileItems[0].file;

    // Parse CSV file using PapaParse
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const data = results.data;
        const extractedColumn = data.map(row => row[smilesCol]).filter(x => x);
        setFiles2(extractedColumn);
        const extractedColumn2 = data.map(row => row[nameCol]).filter(x => x);
        setJobNames(extractedColumn2);
        console.log("extracted:", extractedColumn, extractedColumn2)
      },
      error: (error) => {
        console.error('Error parsing CSV file:', error);
      },
    });
  };

  // const handleUpdateFiles2 = async (fileItems) => {
  //   setFiles2(fileItems)
  //   if (jobType != "alphafold-pair") {
  //     return
  //   }
  //   let texts = []
  //   for (const fileItem of fileItems) {
  //     const file = fileItem.file;
  //     try {
  //       const text = await readFileAsync(file);
  //       texts.push(text.split('\n')[1])
  //     } catch (error) {
  //       console.error('Error reading file:', error);
  //     }
  //   }
  //   setSequences2(texts)
  // };

  const handleUpdateFiles2 = async (fileItems) => {
		setFiles2(fileItems)
    if (jobType != "alphafold-pair") {
      return
    }
		let seqs = []
		let names = []
		for (const fileItem of fileItems) {
		  const file = fileItem.file;
		  try {
			const text = await readFileAsync(file);
			const lines = text.split(/[\n\r]/).filter(str => str !== "");
			let new_lines = []
			let curr_seq = ""
			for (let i = 0 ; i<lines.length ; i++) {
				if (lines[i].includes(">")) {
					if (curr_seq != "") {
						new_lines.push(curr_seq.replace(/\r/g, ""))
					}
					new_lines.push(lines[i].replace(/\r/g, ""))
					curr_seq = ""
				} else {
					curr_seq += lines[i]
				}
			}
			new_lines.push(curr_seq.replace(/\r/g, ""))
			if (new_lines.length % 2 != 0) {
				alert("Error parsing file");
				return
			}
			for (let i = 0; i < new_lines.length - 1; i += 2) {
				const line1 = new_lines[i];
				const line2 = new_lines[i + 1];
				seqs.push(line2)
				names.push(line1.substring(1))
			}
		  } catch (error) {
			console.error('Error reading file:', error);
		  }
		}
		// console.log(seqs, names)
		setSequences2(seqs)
		setSequences2Names(names)
	  };

	const submitJobs = async (pay) => {
		// if (jobType != "alphafold-single" && (files.length === 0 || files2.length === 0) || (jobType == "alphafold-single" && files.length === 0)) {
		// 	alert("Make sure you've uploaded your files!");
		// 	return false;
		// }
    submitBatch(pay)
	}

  return (
    <Stack spacing={2} style={{padding:10}}>
        
        <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind Batch Job Submission
        </Typography>

        <Typography>Use AlphaFold, DiffDock, LightDock, and Autodock Vina at scale in parallel by selecting your inputs. 
        </Typography>

        <InputLabel id="model">Workflow</InputLabel>
          <Select
              sx={{ width: 300 }}
              labelId="model"
              value={jobType}
              // label="Model"
              onChange={(e) => 
                {
                  setJobType(e.target.value)
                  setLabel(
                    e.target.value == "lightdock" || e.target.value == "diffdock" ? 'Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb files' : 'Drag & Drop or <span class="filepond--label-action">Browse</span> your fasta files'
                    )
                  setLigandLabel(`Drag & Drop or <span class="filepond--label-action">Browse</span> your ${typeToFile.get(e.target.value + '-ligand')} files`)
                }
              }
          >
              <MenuItem value={"alphafold-single"}>AlphaFold</MenuItem>
              <MenuItem value={"alphafold-pair"}>AlphaFold Pairwise</MenuItem>
              <MenuItem value={"diffdock"}>Diffdock</MenuItem>
              <MenuItem value={"lightdock"}>Lightdock</MenuItem>
              {/* <MenuItem value={"rfdiffusion"}>RFdiffusion</MenuItem> */}
              <MenuItem value={"vina"}>AutoDock Vina</MenuItem>
              <MenuItem value={"immune-builder"}>ImmuneBuilder</MenuItem>
          </Select>

          {jobType !== "rfdiffusion" && jobType !== 'vina'  && jobType !== 'immune-builder' && jobType != "alphafold-single" ?
            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName} numJobs={Math.max(files.length, 1) * Math.max(files2.length, 1)}></NameField>
            : null}

        {
          // jobType == "alphafold-single" ? 
          // <>
          //   <Typography>
          //     To run many AlphaFold jobs in parallel, upload your amino acid sequence fasta files and receive a prediction for each sequence. 
          //   </Typography>
          //   <Typography>
          //     Please only include one sequence (with one descriptor) per each file. i.e. have only one {'">"'} per file. 
          //   </Typography>
          //   <Typography>
          //     For complexes/multimers, place a : between each of your chains in the fasta file, with one description in total for the whole complex. The colon indicates a chain break. For example, a "input.fasta" file might include:
          //   </Typography>

          //   <TextField multiline={true} maxRows={4} value={">MySequence\nMALKSLVLLSLLVLVL:ACKNGQTNCYQSYSTMSITDCRET"} onChange={() => ''}></TextField>
          // </>  

          // : 
          jobType == "alphafold-pair" ? 
          <>
          <Typography>
            To predict the structures for many pairs of sequences, upload two sets of fasta files and receive a structure prediction for each pairwise combination of sequences. For complexes, put a : between each of your chains. The colon indicates a chain break.
          </Typography> 
          <Typography>
            Please only include one sequence (with one descriptor) per each file. I.e. have only one {'">"'} per file. 
          </Typography>
          </>
          : jobType == "rfdiffusion" ? 
          <></> /* add rfdiffusion description here */
        
          : jobType == "diffdock" ? 
        <Typography>
            DiffDock is a method for molecular docking, which predicts the binding between a small molecule ligand and a protein with no known binding pocket. DiffDock batch workflow allows you to screen many ligands against many receptors. Upload one set of pdb files and one set of sdf/mol2 files to submit a job for each possible protein/ligand pairwise combination. 
        </Typography>
        : jobType == "lightdock" ? <Typography>
            Lightdock is a protein-protein, protein-peptide, and protein-DNA docking tool that uses Glowworm Swarm Optimization (GSO) to predict interactions. Upload two sets of pdb files to submit a job for each possible protein/ligand pairwise combination. 
        </Typography> : null
        }
      
      {["alphafold-pair", "diffdock", "lightdock"].includes(jobType) ? 
      <>
        <Typography>Protein</Typography>
        <FilePond
          files={files}
          allowReorder={true}
          allowMultiple={true}
          onupdatefiles={handleUpdateFiles}
          labelIdle= {label}
          credits={[]}
          acceptedFileTypes={jobType == "lightdock" || jobType == "diffdock" ? ["chemical/x-pdb"] : ["fasta"]}
          fileValidateTypeDetectType= {jobType == "lightdock" || jobType == "diffdock" ? detectFileTypeGenerator({".pdb":"chemical/x-pdb"}) : detectFileTypeGenerator({".fasta":"fasta"})}
          />
      </>
      : null}
  {
    jobType == "diffdock" ? 
    <ToggleButtonGroup color="primary" value={ddInput} exclusive onChange={(e, newType) => {setFiles2([]); setDdInput(newType)}}> 
    <ToggleButton value={"file"}>File</ToggleButton>
    <ToggleButton value={"smiles"}>Smiles</ToggleButton>
    <ToggleButton value={"csv"}>Csv Smiles</ToggleButton>
  </ToggleButtonGroup> : null
  }

    {
      !["alphafold-pair", "diffdock", "lightdock"].includes(jobType) || ddInput != "file" ? null :
      <>
      <Typography>Ligand</Typography>
      <FilePond
          files={files2}
          allowReorder={true}
          allowMultiple={true}
          onupdatefiles={handleUpdateFiles2}
          labelIdle= {ligandLabel} 
          credits={[]}
          acceptedFileTypes={jobType == "diffdock" ? ["chemical/x-mol2", "sdf"] : jobType == "lightdock" ? ["chemical/x-pdb"] : ["fasta"]}
          fileValidateTypeDetectType= {jobType == "diffdock" ? detectFileTypeGenerator({".mol2":"chemical/x-mol2", ".sdf":"sdf"}) : jobType == "lightdock" ? detectFileTypeGenerator({".pdb":"chemical/x-pdb"}) : detectFileTypeGenerator({".fasta":"fasta"})}
      />
      </>
    }

    {
      jobType == "diffdock" && ddInput == "smiles" ? 
      <TextField
      multiline
      minRows={4}
      maxRows={10}
      placeholder={`Enter SMILES strings, each on a new line`}
      variant="outlined"
      fullWidth
      onChange={(e) => setFiles2(e.target.value.split("\n").filter(s => s != ""))}
      /> : null
    }

{
      jobType == "diffdock" && ddInput == "csv" ? 
      <>
      <FilePond
      allowReorder={true}
      allowMultiple={true}
      labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your csv file'
      onupdatefiles={handleCsvFileLoad}
      acceptedFileTypes={ ["csv"] }
      fileValidateTypeDetectType= {detectFileTypeGenerator({".csv":"csv"})}
      />
      <TextField label='Job Name Column' 
      onChange={(e) => {setNameCol(e.target.value)}} 
      value={nameCol}
      error={csvFiles.length != 0 && jobNames.length == 0}
      helperText={csvFiles.length != 0 && jobNames.length == 0 ? 'Column not found in csv' : ''}></TextField>
      <TextField label='Smiles Column' 
      onChange={(e) => {setSmilesCol(e.target.value)}} 
      value={smilesCol}
      error={csvFiles.length != 0 && files2.length == 0}
      helperText={csvFiles.length != 0 && files2.length == 0 ? 'Column not found in csv' : ''}
      ></TextField>
      </>
      : null
    }

    {jobType === 'alphafold-single' ? 
      <BatchFasta jobName={jobName} setJobName={setJobName}/>
    : null}

    {jobType === 'rfdiffusion' ? 
      <RFdiffusionBatch />
    : null}

    {jobType === 'immune-builder' ? 
      <ImmuneBuilderBatch />
    : null}

    {jobType === 'vina' ? 
      <VinaBatch />
    : null}

    {["alphafold-pair", "diffdock", "lightdock"].includes(jobType) ? 
            <>
            {jobType=="alphafold-pair" ? 
            <SubmitButton redir="batch" duplicate={duplicateJob} exceed={exceed} onSubmit={submitJobs} numJobs={Math.max(files.length, 1) * Math.max(files2.length, 1)} toolName="AlphaFold" seqLength={sequences.map((str) => str.trim().length)} seq2Length={sequences2.map((str) => str.trim().length)}>Submit</SubmitButton>
            :
            <SubmitButton redir="batch" duplicate={duplicateJob} exceed={exceed} onSubmit={submitJobs} numJobs={Math.max(files.length, 1) * Math.max(files2.length, 1)}>Submit</SubmitButton>}</>
            : null}

    {
      jobType == "alphafold-pair" ? 

      <Accordion sx={{justifyContent: "center"}}>
      <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Optional settings
          </Typography>
          
          <Typography sx={{ color: 'text.secondary' }}>More detailed control of how each prediction is made</Typography>


      </AccordionSummary>
      <AccordionDetails>

      <Stack spacing={2}>
          {settings.map((setting) => <>
              <Autocomplete
                  disablePortal
                  options={setting.choices}
                  sx={{ width: 300 }}
                  value={setting.selected}
                  renderInput={(params) => <TextField {...params} label={setting.label} />}
                  onChange={(e, newValue) => updateSettings({settingName:setting.name, newValue: newValue})}
              />
          </>)}

      </Stack>

      </AccordionDetails>
  </Accordion>
  : null
    }

    </Stack>
  );
}