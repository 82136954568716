import Navigation from "./Navigation";
import { Button, Grid, Typography, Box, Chip, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {useState, useEffect, useRef} from 'react';
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import axios from 'axios'

import * as $3Dmol from '3dmol';
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { NameField } from "./NameField";
import { VinaBatch } from "./BatchUpload/VinaBatch";
import {SubmitButton} from './SubmitButton';

registerPlugin(FilePondPluginFileEncode);

const MoleculeVisualizer = ({ receptorFile, boxX, boxY, boxZ, width, height, depth }) => {
  const visualizerRef = useRef();
  const [viewer, setViewer] = useState(null);
  const [receptorData, setReceptorData] = useState('')

  /*
  function removeNonStandardResidues(pdbString) {
        const standardAminoAcids = [
            'ALA', 'ARG', 'ASN', 'ASP', 'CYS',
            'GLN', 'GLU', 'GLY', 'HIS', 'ILE',
            'LEU', 'LYS', 'MET', 'PHE', 'PRO',
            'SER', 'THR', 'TRP', 'TYR', 'VAL'
        ];
    
        return pdbString.split('\n').filter(line => {
            // Check if the line starts with ATOM or HETATM
            if (line.startsWith('ATOM') || line.startsWith('HETATM')) {
                // Extract the residue name (columns 18-20 in PDB format)
                const residueName = line.substring(17, 20).trim();
                // Keep the line if the residue is standard
                return standardAminoAcids.includes(residueName);
            }
            // Keep all other lines
            return true;
        }).join('\n');
    }
    */

  const extractFileContent = async (file) => {
        const read = new FileReader();
        read.readAsBinaryString(file);

        read.onloadend = async function () {
                setReceptorData(read.result);
                console.log(read.result);
        };
   }

  useEffect(() => {
    // Initialize the 3D viewer only once
    if (visualizerRef.current && !viewer) {
      const config = { backgroundColor: 'white' };
      const newViewer = $3Dmol.createViewer(visualizerRef.current, config);
      setViewer(newViewer);
    }
  }, [viewer]);

  useEffect(() => {
    extractFileContent(receptorFile);
  }, [receptorFile]);

  useEffect(() => {
    if (viewer && receptorData) {
      viewer.clear(); // Clear existing models
      viewer.addModel(receptorData, 'sdf'); // Update this line to 'mol' for .mol files
      viewer.setStyle({}, { stick: {} }); // You might want to adjust the style for better visualization of .mol files
  
      viewer.addBox({
        center: {x: Number(boxX), y: Number(boxY), z: Number(boxZ)},
        dimensions: {w: Number(width), h: Number(height), d: Number(depth)},
        color: 'magenta', 
        "opacity": 0.8
      });
  
      viewer.zoomTo();
      viewer.render();
    }
  }, [viewer, receptorData, boxX, boxY, boxZ, width, height, depth]);
  
  return (
    <div style={{ width: '400px', height: '400px', position: 'relative' }} ref={visualizerRef}>
    </div>
  );
};

export const PolymerGeneric = ({ hideNavigation }) => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [receptor, setReceptor] = useState('');
	const [ligand2, setLigand2] = useState('');
        const [smiles1, setSMILES1] = useState('CC(CO)O');
        const [smiles2, setSMILES2] = useState('C(CC(=O)O)C(=O)O');
        const [polymerType, setPolymerType] = useState('alternating');

        const [ligand, setLigand] = useState('C[C@H]1C[C@@H]2[C@H](CC[C@]3([C@H]2CC[C@@]3(C(=O)C)OC(=O)C)C)[C@@]4(C1=CC(=O)CC4)C');

        const [endcap, setEndCap] = useState('CC(=O)O')

        const [duplicateJob, setDuplicateJob] = useState(false);
        const [exceed, setExceed] = useState(false);

        const [boxX, setBoxX] = useState('0');
	const [boxY, setBoxY] = useState('0');
	const [boxZ, setBoxZ] = useState('0');
        const [width, setWidth] = useState('20');
        const [height, setHeight] = useState('20');
        const [depth, setDepth] = useState('20');

        const [files, setFiles] = useState([])
        const [files2, setFiles2] = useState([])
        const [showAll1, setShowAll1] = useState(false);
        const [showAll, setShowAll] = useState(false);

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  

        /*
        useEffect(() => {
                axios.get('/api/getFiles').then(
                        res => {
                                let allFiles = res.data.filter(str => str.endsWith(".pdbqt"))
                                setFiles(allFiles.filter(str => str.includes('A') || str.includes('B')))
                                setFiles2(allFiles.filter(str => !(str.includes('A') || str.includes('B'))))
                        }
                )
        }, [])
        */
      
        useEffect(() => {
                const email = user?.emailAddresses?.[0].emailAddress; // null protection
                if (isLoaded && !validEmails.includes(email) && !email.includes("persist-ai")) {
                  navigate('/app'); // Safely Navigate to the Target State
                }
              }, [navigate, isLoaded, user?.emailAddresses]); // appropriate dependents    

        const handlePolymerTypeChange = (event) => {
                setPolymerType(event.target.value);
            };        

        const [ratio, setRatio] = useState(0.5); // Add this state variable

        const submitFile = () => {
            let config = {
                type: polymerType,
                endcap: endcap,
                ligand: ligand,
            };
        
            if (polymerType === 'homopolymer') {
                config.smiles = smiles1;
            } else if (polymerType === 'alternating') {
                config.smiles1 = smiles1;
                config.smiles2 = smiles2;
                config.ratio = ratio;
            }
        
            addJobToQueue(jobName, 0, user, JSON.stringify(config), "polymer-generic");
            startLambdaForType('polymer-generic');
            navigate("/app/results");
        }
        
        const receptorUploaded = () => {
                return receptor[0] && receptor[0].file.name.includes(".mol")
        }

        const validEmails = ['denizkavi@outlook.com', 'sherryliu987@gmail.com', 'joshua@persist-ai.com', 'daniel@persist-ai.com', 'corina@persist-ai.com', 'kenan@persist-ai.com', 'armon@persist-ai.com']
    
        useEffect(() => {
            const email = user?.emailAddresses?.[0].emailAddress; // null protection
            if (isLoaded && !validEmails.includes(email) && !email.includes("persist-ai")) {
              navigate('/app'); // Safely Navigate to the Target State
            }
          }, [navigate, isLoaded, user?.emailAddresses]); // appropriate dependents

        if (!isLoaded) {
            return <div>Loading...</div>; // e.g., a pre-loader or key aspect, understanding the "index" of the cycle.
        }
          

        if (isLoaded && (validEmails.includes(user.emailAddresses[0].emailAddress) || user.emailAddresses[0].emailAddress.includes("@persist-ai.com"))) {            
                return (
                    <>
                    <Stack spacing={2} style={{padding: '10px' }}>
                            { hideNavigation ? null : 
                            <>
                                    <Typography variant='h5'>Tamarind AutoDock Vina Polymer-Drug Docking Interface</Typography>

                            </> }

                            <Typography>One of the fastest and most widely used open source docking software. Predict how small molecules or drug candidates bind to polymers. Input your receptor and ligand files and receive a prediction of binding affinity and ligand pose. </Typography>

                            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                            
                            <FormControl fullWidth>
                    <InputLabel id="polymer-type-label">Polymer Type</InputLabel>
                    <Select
                        labelId="polymer-type-label"
                        id="polymer-type-select"
                        value={polymerType}
                        label="Polymer Type"
                        onChange={handlePolymerTypeChange}
                    >

                        <MenuItem value="homopolymer">Homopolymer</MenuItem>
                        <MenuItem value="alternating">Alternating</MenuItem>
                    </Select>
                </FormControl>

                {polymerType === 'homopolymer' ? (
                <>
                    <Typography>Please make sure denote a linker in the smiles you are inputting by writing a "*". E.g. "C(*)=CN1CCCC1=O" will be accepted but C=CN1CCCC1=O will not.</Typography>
                    <TextField 
                        label='Monomer Unit SMILES String'
                        value={smiles1}
                        onChange={(e) => setSMILES1(e.target.value)}
                    />
                </>
                ) : (
                    <>
                        <TextField 
                            label='Monomer Unit 1 SMILES String'
                            value={smiles1}
                            onChange={(e) => setSMILES1(e.target.value)}
                        />
                        <TextField 
                            label='Monomer Unit 2 SMILES String'
                            value={smiles2}
                            onChange={(e) => setSMILES2(e.target.value)}
                        />
                        
                        <TextField 
                        label='Ratio of Unit 1 to 2 (number 0-1)'
                        type='number'
                        value={ratio}
                        onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (!isNaN(value) && value >= 0 && value <= 1) {
                                setRatio(value);
                                }
                        }}
                        inputProps={{
                                step: 0.01,
                                min: 0,
                                max: 1
                        }}
                        />


                    </>
                )}

                <TextField 
                    label='End Cap SMILES String'
                    value={endcap}
                    onChange={(e) => setEndCap(e.target.value)}
                />
                            <br></br>
                            <TextField 
                                label='Drug SMILES String'
                                value={ligand}
                                onChange={(e) => setLigand(e.target.value)}
                            />
                        
                        <Box
                                sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                // maxHeight: showAll ? 'none' : 100,
                                gap: 1,
                                }}
                                >
                        {
                                files2.length > 3 ? 
                                <Button sx={{textTransform:"none"}} onClick={() => setShowAll((prev) => !prev)}>
                                {showAll ? 'Show Less' : 'Show More'}
                                </Button> : null
                        }
                        
                        </Box>

                            {/* */}

                            {/*
                            <FilePond
                            files={receptor}
                            allowReorder={true}
                            allowMultiple={false}
                            onupdatefiles={getReceptorFiles}
                            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> .mol file for your Polymer'
                            credits={[]}
                            acceptedFileTypes={["chemical/x-mdl-molfile"]} // Update this line
                            fileValidateTypeDetectType={detectFileTypeGenerator({".mol":"chemical/x-mdl-molfile"})} // Update this line
                            />
                            */}

                            {/*
                            <FilePond
                            files={ligand}
                            allowReorder={true}
                            allowMultiple={false}
                            onupdatefiles={setLigand}
                            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your sdf files for your Ligand(s)'
                            credits={[]}
                            acceptedFileTypes={["sdf"]}
                            fileValidateTypeDetectType= {detectFileTypeGenerator({".sdf":"sdf"})}
                            />
                            */}

                            <Grid>
                            { receptorUploaded() && 
                            <Stack alignItems="center">
                                    <Typography variant='h6'>Set Docking Box</Typography>
                                    <MoleculeVisualizer receptorFile={receptor[0].file} boxX={boxX} boxY={boxY} boxZ={boxZ} width={width} height={height} depth={depth} /> 
                            </Stack>
                            }

                            { receptorUploaded() && 
                                    <Stack alignItems="center" spacing={1}>
                                            <Grid>
                                                    <TextField value={boxX} label='Box Center X' onChange={(e) => setBoxX(e.target.value)} type='number'/>
                                                    <TextField value={boxY} label='Box Center Y' onChange={(e) => setBoxY(e.target.value)} type='number'/>
                                                    <TextField value={boxZ} label='Box Center Z' onChange={(e) => setBoxZ(e.target.value)} type='number'/>
                                            </Grid>

                                            <Grid>
                                                    <TextField value={width} label='Box Width' onChange={(e) => setWidth(e.target.value)} type='number'/>
                                                    <TextField value={height} label='Box Height' onChange={(e) => setHeight(e.target.value)} type='number'/>
                                                    <TextField value={depth} label='Box Depth' onChange={(e) => setDepth(e.target.value)} type='number'/>
                                            </Grid>
                                    </Stack>
                            }

                            </Grid>
                            <Button disabled={duplicateJob} onClick={submitFile}>Submit</Button>
                            {/*<SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>*/}
                    </Stack>
                    </>
            );
    }
}