import React, { useState } from 'react';
import '../index.css'; // Import the CSS file

const ResidueText = ({ fixedCurr, index, char, resIndex }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const isFixed = fixedCurr.has(resIndex);

  // console.log("fixed curr:", fixedCurr)

  return (
    <div style={{ position: 'relative', display: 'inline-block'}}>
		{
			hovered ? 
			<div className="tooltip">
			{char}:{resIndex}
		  </div> : null
		}

      <span
        className={'hover-effect'}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
		style={{backgroundColor:isFixed ? '#c6e4c6' : 'transparent'}}
      >
        {char}
      </span>
    </div>
  );
};

export default ResidueText;
