import Swal from "sweetalert2";
import { OTHER_JOB_BASE, addJobToQueue, startLambdaForType, formatJob, uploadFile } from "../../utils";
import axios from "axios";

export const addBatchJobsToQueue = (jobs) => {
    console.log(jobs);
    axios.get('/api/addBatchJobs', {params: {jobs: jobs}});
}

const returnS3PathsAndUploadFiles = (file, user) => {
    let email = user.emailAddresses[0].emailAddress
    uploadFile(file, user, () => null);

    return email + "/" + file.name;
}

//config is an object containing relevant attributes to the job + "jobName" which is the batch name 
export const submitJobs = (receptorFiles, ligandFiles, user, config, paid) => {
    if (!receptorFiles || !ligandFiles) {
        Swal.fire("Please make sure you've submitted both types of files");
        return;
    }
    
    //add check to see if the pdb contains more than one sequence
    //these won't take into account the removal of all non-standard residues, might be easier to do serverside
    const proteinFilePaths = receptorFiles.map(f => returnS3PathsAndUploadFiles(f.file, user));
    const ligandFilePaths = ligandFiles.map(f => returnS3PathsAndUploadFiles(f.file, user));
    
    const jobs = [
        formatJob(config.jobName, 0, user, 'autodock-vina', "batch", '', 'In Queue', ''),
    ];
    const cost = paid ? OTHER_JOB_BASE : 0
    proteinFilePaths.map((proteinFilePath, i) => {
        ligandFilePaths.map((ligandFilePath, j) => {
            let configObj = {...config, proteinFilePaths: [proteinFilePath], ligandFilePaths: [ligandFilePath], exhaustiveness:"8"};
            const jobName = `${receptorFiles[i].file.name.split('.')[0]}-${ligandFiles[j].file.name.split('.')[0]}`
            jobs.push(formatJob(jobName, cost, user, JSON.stringify(configObj), "autodock-vina", '', 'In Queue', config.jobName));
        });
    })

    if (user.emailAddresses[0].emailAddress != "buddha@keylika.com"){
        startLambdaForType('autodock-vina')
    }

    // console.log(jobs)
    addBatchJobsToQueue(jobs);
}
