import { Stack, Button, MenuItem, InputLabel, Select } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, OTHER_JOB_BASE } from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const EvoDiff = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [len, setLen] = useState(100);
        const [numSeq, setNumSeq] = useState(64);
        const [subsampling, setSubsampling] = useState("random");
        const [model, setModel] = useState('unconditional-oadm');
        const [duplicateJob, setDuplicateJob] = useState(false);
        const navigate = useNavigate();
        const [files, setFiles] = useState([]);

        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));

                let configObj = {name: jobName, msa: filePaths, seq_len: len, model: model, numSeq: numSeq, subsampling: subsampling};
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "evodiff");
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind EvoDiff Online Tool</Typography>
                <Typography variant='body'>
                    EvoDiff generates protein sequences in a sequence space by combining evolutionary data with the conditioning abilities of diffusion models.
                        </Typography>
                        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <InputLabel >Model</InputLabel>
                        <Select
                        value={model}
                        label="Model"
                        onChange={(e) => 
                                setModel(e.target.value)
                        }
                        >
                        <MenuItem value={"unconditional-oadm"}>Unconditional generation - EvoDiff-Seq-OADM</MenuItem>
                        <MenuItem value={"unconditional-d3pm"}>Unconditional generation - EvoDiff-D3PM-Uniform</MenuItem>
                        <MenuItem value={"conditional"}>Conditional generation - EvoDiff-MSA</MenuItem>
                        </Select>
            
                        <>
                          <Typography variant='body'>Sequence Length</Typography>
                        <TextField label='Sequence Length' 
                        onChange={(e) => {setLen(e.target.value)}} 
                        value={len}></TextField>
                        </>

                        {
                            model == "conditional" ? 
                            <>
                            <TextField label='Num Sequences' 
                            onChange={(e) => {setNumSeq(e.target.value)}} 
                            value={numSeq}></TextField>
                            <Select
                            value={subsampling}
                            label="MSA Subsampling Scheme"
                            onChange={(e) => 
                                    setSubsampling(e.target.value)
                            }
                            >
                                <MenuItem value={"random"}>Random</MenuItem>
                                <MenuItem value={"maxhamming"}>Max Hamming</MenuItem>
                            </Select>
                            <FilePond
                            files={files}
                            allowReorder={true}
                            allowMultiple={true}
                            onupdatefiles={setFiles}
                            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your a3m file'
                            credits={[]}
                            acceptedFileTypes={["a3m"]}
                            fileValidateTypeDetectType= {detectFileTypeGenerator({".a3m":"a3m"})}
                            />
                            </>
                            : null
                        }

                <SubmitButton redir="evodiff" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
