import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import {Typography} from '@mui/material'
  
export const EndNode = ({ data }) => {
 
  return (
    <div style={{ width: '200px', background:"white", padding:10}}>
      <Handle type="target" position={Position.Top} style={{right:10}} id="any" />

      <div style={{ textAlign: 'center' }}>
      <Typography><b>End</b></Typography>
      </div>
    </div>
  );
}