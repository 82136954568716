import {useState, useReducer, useEffect, useRef} from 'react';
import { Typography, Accordion, Select, MenuItem, AccordionSummary, AccordionDetails, Autocomplete, Button} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Navigation from "../Navigation";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {useUser} from "@clerk/clerk-react";  
import {useNavigate} from "react-router-dom";
import {NameField} from '../NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from '../SubmitButton';
import axios from 'axios'
import {addJobToQueue, uploadFileCustomName, sleep, submitSequenceToAlphaFold, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE } from '../../utils';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ImmuneBuilderBatch = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
	const navigate = useNavigate()
	const [proteinFiles, setProteinFiles] = useState([]);
	const [sequences, setSequences] = useState([]);	
	const [jobNames, setJobNames] = useState([]);	

	// const models = ["Antibody", "Nanobody", "TCR"];
	const [model2, setModel2] = useState("Antibody")

	const returnS3PathsAndUploadFiles = (file) => { 
		let email = user.emailAddresses[0].emailAddress;

		const new_fn = file.name.replace(/[()]/g, '').replace(/\s/g, '_');
		uploadFileCustomName(file, user, new_fn);

		return email + "/" + new_fn;
	}
	
	const submitBatch = (sequences, jobNames) => {
		const postData = {
			sequences: sequences,
			jobNames: jobNames,
			"batchName": jobName, 
			"settings":"", 
			"type":"immunebuilder"
		  };
		//   console.log(postData)
		axios.post('/api/addJobToQueueBatch', postData)
	}

	const submitFile = async (pay) => {
		if (proteinFiles.length === 0) {
			alert("Make sure you've submitted your files!");
			return false;
		}
		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(f.file));
		const configs = {
				"proteinFile": `${proteinFilePaths.join(",")}`,
		}
		addJobToQueue(jobName, 0, user, "immunebuilder", "batch", JSON.stringify(configs));

		// console.log(sequences, jobNames)

		let batchSize = 350
		let origLength = sequences.length 
		for(let i = 0 ; i<origLength ; i+=batchSize) {
			let batchSeq = sequences.splice(0, batchSize)
			let batchNames = jobNames.splice(0, batchSize)
			submitBatch(batchSeq, batchNames)
		}

		for (let i = 0; i < Math.min(1, origLength); i++) {
			await startLambdaForType("immunebuilder")
		}

		navigate('/app/results')
		return
	}

	const readFileAsync = (file) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.onload = (event) => {
			resolve(event.target.result);
		  };
		  reader.onerror = (error) => {
			reject(error);
		  };
		  reader.readAsText(file);
		});
	  };

	let model3 = model2

	function mergeValuesWithoutGT(arr) {
		if (!arr || arr.length === 0) {
			return [];
		}
	
		let mergedArray = [];
		let buffer = "";
	
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].includes('>')) {
				if (buffer) {
					mergedArray.push(buffer);
					buffer = "";
				}
				mergedArray.push(arr[i]);
			} else {
				buffer += arr[i];
			}
		}
	
		if (buffer) {
			mergedArray.push(buffer);
		}
	
		return mergedArray;
	}

	const handleUpdateFiles = async (fileItems, model) => {
		// console.log(model);
		setProteinFiles(fileItems)
		let seqs = []
		let names = []
		for (const fileItem of fileItems) {
		  const file = fileItem.file;
		  try {
			const text = await readFileAsync(file);
			const lines = mergeValuesWithoutGT(text.split('\n').filter(str => str !== ""));

			// console.log("model here:", model2)
			// console.log("model3:", model3)

			// console.log(model)
			if (model == "Nanobody") {
				// console.log("Processing as Nanobody");
				const line1 = lines[0];
				const line2 = lines[1];
				seqs.push(JSON.stringify({"sequence1":line2, "modelType":model}))
				names.push(jobName + "-" + line1.substring(1) + "-" + Math.random().toString(36).slice(2, 5))
			} else {
				// console.log("model:", model)
				// if (lines.length != 4) {
				// 	alert('Antibody/TCR files must have exactly 4 lines')
				// 	return
				// }
				// console.log("Processing other model types");
				const line1 = lines[0];
				const line2 = lines[1];
				const line3 = lines[2];
				const line4 = lines[3];
				seqs.push(JSON.stringify({"sequence1":line2, "sequence2":line4, "modelType":model}))
				names.push(jobName + "-" + line1.substring(1) + "-" + line3.substring(1) + "-" + Math.random().toString(36).slice(2, 5))
			}
		  } catch (error) {
			console.error('Error reading file:', error);
		  }
		}
		// console.log("setting:",seqs, names)
		setSequences(seqs)
		setJobNames(names)
	  };

	  useEffect(() => {
		console.log("Model2 has changed to:", model2);
	  }, [model2]);	  

	  const model2Ref = useRef(model2);
	  useEffect(() => {
		model2Ref.current = model2;
	}, [model2]);	

	return (
		<>
		{/* {console.log(sequences)} */}
		{/* {console.log(jobNames)} */}
			<Stack spacing={2} style={{padding: '0px' }}>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
				<>
			<Stack spacing={2}>

				{/* <Autocomplete
                disablePortal
                options={models}
                value={model2}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params}  />}
                onChange={(e, val) => setModel2(val)} // prints the selected value
                /> */}

				<Select
                    labelId="model"
                    value={model2}
                    label="Model"
                    onChange={(e) => setModel2(e.target.value)}
                >
                    <MenuItem value={"Antibody"}>Antibody</MenuItem>
                    <MenuItem value={"Nanobody"}>Nanobody</MenuItem>
					<MenuItem value={"TCR"}>TCR</MenuItem>
                </Select>

				<Typography>Input your fasta files below. Each file will be submitted as one ImmuneBuilder job. Antibody fasta files must have a heavy chain followed by a light chain. Nanobody fasta files must contain one chain. </Typography>

				<FilePond
				files={proteinFiles}
				allowReorder={true}
				allowMultiple={true}
				onupdatefiles={(fileItems) => handleUpdateFiles(fileItems, model2Ref.current)}
				labelIdle= 'Drag & Drop or <span class="filepond--label-action">Browse</span> your protein fasta files'
				credits={[]}
				acceptedFileTypes={["fasta"]}
				fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta", ".fa": "fasta"})}
				/>
				
				<SubmitButton numJobs={sequences.length} duplicate={duplicateJob} exceed={exceed} onSubmit={(pay) => {submitFile(false)}}>Submit</SubmitButton>
			</Stack>
			</>
			</Stack>
		</>
		)
	}



