import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useUser, useAuth } from '@clerk/clerk-react';
import { useOrganization, OrganizationSwitcher } from '@clerk/clerk-react';
import Navigation from '../Navigation';
import JobTable from '../JobTable';
import {convertUTCtoLocal} from '../../utils'
import { Grid, Typography, Stack, Box, Button, Card, CardActionArea, CardContent, CardActions, TextField} from '@mui/material';
import axios from 'axios';

export default function OrganizationPage() {
    const { isLoaded, isSignedIn, user } = useUser();
    const [members, setMembers] = useState([]);
    const { organization } = useOrganization();
    // console.log(organization)

    const [jobs, setJobs] = useState([])
    const [jobsLoaded, setJobsLoaded] = useState(false);

    useEffect(() => {  
        jobs.forEach(function(job) {
            if (job.Created) {
                job.Created = convertUTCtoLocal(job.Created);
            }
        });    
      }, [jobs])

        useEffect(() => {
          if (organization == null) return
          axios.get('/api/getOrganizationJobs', {params:{filter: true}}).then(
            res => {
              if (jobs.length != res.data.length || jobs.length == 0){
                setJobs(res.data);
                setJobsLoaded(true);
              }
            }
          )
        }, [organization]);

    return (   
      <div
      style={{
        //backgroundImage: 'linear-gradient(to bottom, #ffffff, #bdcdb8)',
        width: '100%',
        height: '100%',
        minHeight:'100vh'
      }}
      >
        <Stack spacing={2} style={{padding: '10px'}}>
         <Navigation />

        <div style={{ display:'flex', justifyContent:'center' }}>

        <Card sx={{ width: 1200, boxShadow: '0 8px 15px 0 rgba(0,0,0,0.4)'}}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    My Organizations
                </Typography>
                <br></br>
                <OrganizationSwitcher />
                {!jobsLoaded ? <Typography style={{padding: "10px"}}>Loading jobs...</Typography> : 
                <Box  style={{padding: "10px"}}><JobTable jobs={jobs} paid={false} org={true}/></Box> }
            </CardContent>
        </Card>
        </div>
        </Stack>
        </div>
    )
}
