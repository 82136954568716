import Navigation from "./Navigation";
import { Button, Grid, Typography, Box, Chip } from "@mui/material";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {useState, useEffect, useRef} from 'react';
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import axios from 'axios'

import * as $3Dmol from '3dmol';
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { NameField } from "./NameField";
import { VinaBatch } from "./BatchUpload/VinaBatch";
import {SubmitButton} from './SubmitButton';

registerPlugin(FilePondPluginFileEncode);

const MoleculeVisualizer = ({ receptorFile, boxX, boxY, boxZ, width, height, depth }) => {
  const visualizerRef = useRef();
  const [viewer, setViewer] = useState(null);
  const [receptorData, setReceptorData] = useState('')

  /*
  function removeNonStandardResidues(pdbString) {
        const standardAminoAcids = [
            'ALA', 'ARG', 'ASN', 'ASP', 'CYS',
            'GLN', 'GLU', 'GLY', 'HIS', 'ILE',
            'LEU', 'LYS', 'MET', 'PHE', 'PRO',
            'SER', 'THR', 'TRP', 'TYR', 'VAL'
        ];
    
        return pdbString.split('\n').filter(line => {
            // Check if the line starts with ATOM or HETATM
            if (line.startsWith('ATOM') || line.startsWith('HETATM')) {
                // Extract the residue name (columns 18-20 in PDB format)
                const residueName = line.substring(17, 20).trim();
                // Keep the line if the residue is standard
                return standardAminoAcids.includes(residueName);
            }
            // Keep all other lines
            return true;
        }).join('\n');
    }
    */

  const extractFileContent = async (file) => {
        const read = new FileReader();
        read.readAsBinaryString(file);

        read.onloadend = async function () {
                setReceptorData(read.result);
                console.log(read.result);
        };
   }

  useEffect(() => {
    // Initialize the 3D viewer only once
    if (visualizerRef.current && !viewer) {
      const config = { backgroundColor: 'white' };
      const newViewer = $3Dmol.createViewer(visualizerRef.current, config);
      setViewer(newViewer);
    }
  }, [viewer]);

  useEffect(() => {
    extractFileContent(receptorFile);
  }, [receptorFile]);

  useEffect(() => {
    if (viewer && receptorData) {
      viewer.clear(); // Clear existing models
      viewer.addModel(receptorData, 'sdf'); // Update this line to 'mol' for .mol files
      viewer.setStyle({}, { stick: {} }); // You might want to adjust the style for better visualization of .mol files
  
      viewer.addBox({
        center: {x: Number(boxX), y: Number(boxY), z: Number(boxZ)},
        dimensions: {w: Number(width), h: Number(height), d: Number(depth)},
        color: 'magenta', 
        "opacity": 0.8
      });
  
      viewer.zoomTo();
      viewer.render();
    }
  }, [viewer, receptorData, boxX, boxY, boxZ, width, height, depth]);
  
  return (
    <div style={{ width: '400px', height: '400px', position: 'relative' }} ref={visualizerRef}>
    </div>
  );
};

export const Polymer = ({ hideNavigation }) => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [receptor, setReceptor] = useState('');
	const [ligand2, setLigand2] = useState('');
        const [smiles, setSmiles] = useState('CC12CC(=O)C3C(C1CCC2(C(=O)CO)O)CCC4=CC(=O)C=CC34C');

        const [endcap, setEndCap] = useState('CC(=O)O')

        const [duplicateJob, setDuplicateJob] = useState(false);
        const [exceed, setExceed] = useState(false);

        const [boxX, setBoxX] = useState('0');
	const [boxY, setBoxY] = useState('0');
	const [boxZ, setBoxZ] = useState('0');
        const [width, setWidth] = useState('20');
        const [height, setHeight] = useState('20');
        const [depth, setDepth] = useState('20');

        const [files, setFiles] = useState([])
        const [files2, setFiles2] = useState([])
        const [showAll1, setShowAll1] = useState(false);
        const [showAll, setShowAll] = useState(false);

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  

        const [pattern, setPattern] = useState('AAAAABBBBBC');
        const [error, setError] = useState(false);

        useEffect(() => {
                axios.get('/api/getFiles').then(
                        res => {
                                let allFiles = res.data.filter(str => str.endsWith(".pdbqt"))
                                setFiles(allFiles.filter(str => str.includes('A') || str.includes('B')))
                                setFiles2(allFiles.filter(str => !(str.includes('A') || str.includes('B'))))
                        }
                )
        }, [])
      
        const validatePattern = (input) => {
          const isValid = /^[ABC]+$/.test(input) || input == "";
          setError(!isValid);
          if (isValid) {
            setPattern(input);
          }
        };
      
        const handleChange = (event) => {
          validatePattern(event.target.value.toUpperCase());
        };      

        const getReceptorFiles = async (files) => {
                setReceptor(files);
        }

	const submitFile = () => {
		if (!pattern || !smiles) {
			Swal.fire("Please make sure you've submitted both types of files.");
			return;
		}
                
                //add check to see if the pdb contains more than one sequence

                //these won't take into account the removal of all non-standard residues, might be easier to do serverside
                //const proteinFilePaths = receptor.map(f => returnS3PathsAndUploadFiles(f.file));
		        //const ligandFilePaths = ligand.map(f => returnS3PathsAndUploadFiles(f.file));

                let configObj = {
                        name: jobName, 
                        // boxX: boxX, boxY: boxY, boxZ:boxZ, width: width, height: height, depth: depth,
                        additional_molecule: ligand2
                };

                if (files.includes(pattern.replace(/[^AB]/g, '') + endcap + ".pdbqt")) {
                        configObj["plgaFile"] = user.emailAddresses[0].emailAddress + "/" + pattern.replace(/[^AB]/g, '') + endcap + ".pdbqt"
                } else {
                        configObj["pattern"] = pattern
                }

                if (files2.includes(smiles + ".pdbqt")) {
                        configObj["drugFile"] = user.emailAddresses[0].emailAddress + "/" + smiles + ".pdbqt"
                } else {
                        configObj["drug"] = smiles
                }

                if (pattern.includes('C')) {
                        configObj['endcap'] = endcap;
                }

                const cost = 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "polymer-drug-docking");
                startLambdaForType('polymer-drug-docking');
		navigate("/app/results");
	}

        const receptorUploaded = () => {
                return receptor[0] && receptor[0].file.name.includes(".mol")
        }

        const validEmails = ['denizkavi@outlook.com', 'sherryliu987@gmail.com', 'joshua@persist-ai.com', 'daniel@persist-ai.com', 'corina@persist-ai.com', 'kenan@persist-ai.com', 'armon@persist-ai.com']
    
        useEffect(() => {
            const email = user?.emailAddresses?.[0].emailAddress; // null protection
            if (isLoaded && !validEmails.includes(email) && !email.includes("persist-ai")) {
              navigate('/app'); // Safely Navigate to the Target State
            }
          }, [navigate, isLoaded, user?.emailAddresses]); // appropriate dependents

        if (!isLoaded) {
            return <div>Loading...</div>; // e.g., a pre-loader or key aspect, understanding the "index" of the cycle.
        }
          

        if (isLoaded && (validEmails.includes(user.emailAddresses[0].emailAddress) || user.emailAddresses[0].emailAddress.includes("@persist-ai.com"))) {            
                return (
                    <>
                    <Stack spacing={2} style={{padding: '10px' }}>
                            { hideNavigation ? null : 
                            <>
                                    <Typography variant='h5'>Tamarind AutoDock Vina Polymer-Drug Docking Interface</Typography>

                            </> }

                            <Typography>One of the fastest and most widely used open source docking software. Predict how small molecules or drug candidates bind to polymers. Input your receptor and ligand files and receive a prediction of binding affinity and ligand pose. </Typography>

                            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                            <Typography variant=''>A: Lactic Acid, B: Glycolic, C: End Cap</Typography>

                            {/* */}
                            <TextField
                                label="PLGA Pattern"
                                variant="outlined"
                                value={pattern}
                                onChange={handleChange}
                                error={error}
                                helperText={error ? "Pattern can only include 'A', 'B', 'C'" : ' '}
                            />

                           {pattern.includes('C') ? 
                                <TextField 
                                        label='End Cap SMILES String'
                                        value={endcap}
                                        onChange={(e) => setEndCap(e.target.value)}
                                />  : null
                           }
                        <Box
                                sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                                }}
                                >
                                {(showAll1 ? files : files.slice(0, 3)).map((string) => (
                                <Chip
                                key={string}
                                label={string.replace(/[^AB]/g, '') + " + " + string.replace(/[AB]/g, '').replace(/\.pdbqt$/, '')}
                                onClick={(v) => {
                                        if (/[^AB]/.test(string)) {
                                                setPattern(string.replace(/[^AB]/g, '') + "C")
                                                setEndCap(string.replace(/[AB]/g, '').replace(/\.pdbqt$/, ''))
                                        } else {
                                                setPattern(string.replace(/[^AB]/g, '').replace(/\.pdbqt$/, ''))
                                                setEndCap("")
                                        }
                                }}
                                />
                                ))}
                                {
                                        files.length > 3 ? 
                                        <Button sx={{textTransform:"none"}} onClick={() => setShowAll1((prev) => !prev)}>
                                        {showAll1 ? 'Show Less' : 'Show More'}
                                        </Button> : null
                                }
                            </Box>
                            <br></br>
                            <TextField 
                                label='Drug SMILES String'
                                value={smiles}
                                onChange={(e) => setSmiles(e.target.value)}
                            />
                        
                        <Box
                                sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                // maxHeight: showAll ? 'none' : 100,
                                gap: 1,
                                }}
                                >
                        {
                        (showAll ? files2 : files2.slice(0, 3)).map((string) => (
                        <Chip
                        key={string}
                        label={string.replace(/\.pdbqt$/, '')}
                        onClick={(v) => setSmiles(string.replace(/\.pdbqt$/, ''))}
                        />
                        ))}
                        {
                                files2.length > 3 ? 
                                <Button sx={{textTransform:"none"}} onClick={() => setShowAll((prev) => !prev)}>
                                {showAll ? 'Show Less' : 'Show More'}
                                </Button> : null
                        }
                        
                        </Box>

                            <Typography>Optional, will be included in the system if entered.</Typography>
                            <TextField 
                                label='Other SMILES String'
                                value={ligand2}
                                onChange={(e) => setLigand2(e.target.value)}
                            />


                            {/* */}

                            {/*
                            <FilePond
                            files={receptor}
                            allowReorder={true}
                            allowMultiple={false}
                            onupdatefiles={getReceptorFiles}
                            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> .mol file for your Polymer'
                            credits={[]}
                            acceptedFileTypes={["chemical/x-mdl-molfile"]} // Update this line
                            fileValidateTypeDetectType={detectFileTypeGenerator({".mol":"chemical/x-mdl-molfile"})} // Update this line
                            />
                            */}

                            {/*
                            <FilePond
                            files={ligand}
                            allowReorder={true}
                            allowMultiple={false}
                            onupdatefiles={setLigand}
                            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your sdf files for your Ligand(s)'
                            credits={[]}
                            acceptedFileTypes={["sdf"]}
                            fileValidateTypeDetectType= {detectFileTypeGenerator({".sdf":"sdf"})}
                            />
                            */}

                            <Grid>
                            { receptorUploaded() && 
                            <Stack alignItems="center">
                                    <Typography variant='h6'>Set Docking Box</Typography>
                                    <MoleculeVisualizer receptorFile={receptor[0].file} boxX={boxX} boxY={boxY} boxZ={boxZ} width={width} height={height} depth={depth} /> 
                            </Stack>
                            }

                            { receptorUploaded() && 
                                    <Stack alignItems="center" spacing={1}>
                                            <Grid>
                                                    <TextField value={boxX} label='Box Center X' onChange={(e) => setBoxX(e.target.value)} type='number'/>
                                                    <TextField value={boxY} label='Box Center Y' onChange={(e) => setBoxY(e.target.value)} type='number'/>
                                                    <TextField value={boxZ} label='Box Center Z' onChange={(e) => setBoxZ(e.target.value)} type='number'/>
                                            </Grid>

                                            <Grid>
                                                    <TextField value={width} label='Box Width' onChange={(e) => setWidth(e.target.value)} type='number'/>
                                                    <TextField value={height} label='Box Height' onChange={(e) => setHeight(e.target.value)} type='number'/>
                                                    <TextField value={depth} label='Box Depth' onChange={(e) => setDepth(e.target.value)} type='number'/>
                                            </Grid>
                                    </Stack>
                            }

                            </Grid>
                            <Button disabled={duplicateJob} onClick={submitFile}>Submit</Button>
                            {/*<SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>*/}
                    </Stack>
                    </>
            );
    }
}