import * as React from "react";
import {useState} from "react"
import {Button, Paper, TextField, Grid, Stack, Typography, Container, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import Navigation from "./Navigation";
import { ALPHAFOLD_BASE, OTHER_JOB_BASE, ALPHAFOLD_PER_AA, calcPrice} from '../utils'
import { Link } from 'react-router-dom';
import axios from 'axios'
import {useUser} from "@clerk/clerk-react"; 

export default function PricingCalculator() {
  const { isLoaded, isSignedIn, user } = useUser();  
  const [rows, setRows] = useState([{ quantity: "0", type: "alphafold", length:"0" }]);
  const [price, setPrice] = useState(0)
  
  let jobTypes = [
    "alphafold",
    "rfdiffusion",
    "diffdock",
    "autodock-vina",
    "rosettafold2na",
    "rosettafold-aa",
    "combfold",
    "proteinmpnn",
    "ligandmpnn",
    "amber",
    "lightdock",
    "equidock",
    "alphalink",
    "esmfold",
    "protgpt2",
    "progen2",
    "unimol",
    "tdc",
    "diffsbdd",
    "reinvent",
    "umol",
    "afcluster",
    "crispresso2",
    "evodiff",
    "dsressol",
    "admet",
    "afcycdesign"
];
jobTypes = jobTypes.sort()

  const handleAddRow = () => {
    setRows([...rows, { quantity: 0, type: "alphafold", length:0 }]);
  };

  const handleQuantityChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].quantity = value;
    setRows(newRows);
  };

  const handleTypeChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].type = value;
    setRows(newRows);
  };

  const handleLengthChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].length = value;
    setRows(newRows);
  };

  const calculateTotalPrice = () => {
    // console.log(rows)
    let tot = rows.reduce((total, row) => 
    total + calcPrice(row.type, parseInt(row.quantity.replace(",", "")), [parseInt(row.length)])[0], 0).toFixed(2)
    if (isNaN(tot)) {
      setPrice(0)
      return
    }
    let email = isSignedIn ? user.emailAddresses[0].emailAddress : "Anonymous"
    axios.get('/api/notifyUs', {params:{'title': email + " calculated pricing", 'message': "Total: " + tot + "\n" + JSON.stringify(rows)}})
    setPrice(tot)
  };

  // return <>
  //   <Navigation />
  //   <Typography>The calculator is currently being modified, please check back in later or get in touch at info@tamarind.bio</Typography>  
  // </>

  return (
    <>
    <Navigation />
   
      <Container maxWidth="20%" component="main">
    <Paper style={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Pricing Calculator
      </Typography>
      <Typography>
        This is based on pay as you go pricing for Tamarind's <Link to="/pricing">professional plan</Link>. Each job has a base rate of {OTHER_JOB_BASE} per job, and AlphaFold jobs are priced based on their input sequence length and jobs submitted. For larger scale usage or to discuss a subscription based plan, please contact us at <a href="mailto:info@tamarind.bio">info@tamarind.bio</a>. 
      </Typography>
      <br></br>
      {rows.map((row, index) => (
        <Stack>
        <Grid container spacing={2} key={index}>
          <Grid item xs={4}>
            <TextField
              label="Quantity"
              value={row.quantity}
              onChange={(e) => handleQuantityChange(index, e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
                <InputLabel>Job Type</InputLabel>
                <Select
                  label="Job Type"
                  value={row.type}
                  onChange={(e) => handleTypeChange(index, e.target.value)}
                >
                  {jobTypes.map((type, idx) => (
                  <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>
          {
            row.type == "alphafold" || row.type  == "rosettafold-aa" || row.type == "rosettafold2na" || row.type == "combfold" ? 
            <Grid item xs={4}>
            <TextField
              label="Average Length"
              type="number"
              value={row.length}
              onChange={(e) => handleLengthChange(index, e.target.value)}
              fullWidth
            />
          </Grid> : null
          }
        </Grid>
        <br></br>
        </Stack>
      ))}
      <Button variant="outlined" onClick={handleAddRow} style={{ marginTop: '10px',marginRight:'10px' }}>
        Add Row
      </Button>
      <Button variant="contained" onClick={calculateTotalPrice} style={{ marginTop: '10px' }}>
          Calculate
      </Button>
      {
        price != 0 ? 
          <Stack>
          {rows.filter((t) => t.type.includes("dock")).length > 0 ? <Typography variant="h7" style={{ marginTop: '20px'}}>Pricing for docking will typically be much lower(~$0.01-0.00001 per pair), if you have a need for more than 100 docking jobs, please contact us at info@tamarind.bio. We are happy to support in the order of billions of molecules.</Typography> : <></>}
          <Typography variant="h6" style={{ marginTop: '20px' }}>
          Total: ${price}
          </Typography>

          {/*rows.filter((t) => t.length > 5000) ? 
          <Typography variant="h7" style={{ marginTop: '20px', color:"red" }}>We do not currently support AlphaFold jobs of length greater than 5000. Please reach out to info@tamarind.bio to learn how we might support your use case using other methods. </Typography> : 
          <Typography variant="h6" style={{ marginTop: '20px' }}>
          Total: ${price}
      </Typography> */}
        </Stack>: null
      }

    </Paper>
    </Container>
    </>
  );
}

