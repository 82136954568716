import React from 'react';
import { Grid, Typography, Link, Paper, Box } from '@mui/material';
import LigandMPNNImage from './LigandMPNN1.png';
import Navigation from '../Navigation';
import EnzymeActiveSiteImage from './EnzymeActiveSite.png';

export const RFDiffusionForEnzymes = () => {
  return (
    <Paper elevation={3} sx={{ p: 4, margin: 'auto', maxWidth: 900 }}>
      <Navigation />
      <Typography variant="h4" gutterBottom>
        RFdiffusion: Protein Design for Enzyme Engineering 
      </Typography>

      <Box
        component="img"
        src={EnzymeActiveSiteImage}
        alt="Enzyme Active Site Scaffolding Image"
        sx={{ width: '100%', height: 'auto', my: 2 }}
      />

      <Typography variant="h6" gutterBottom>
        Scaffolding Enzyme Active Sites
      </Typography>

      <Typography variant="body1" paragraph>
        RFdiffusion's "motif scaffolding" use case can be used to scaffold minimal descriptions of enzyme active sites made up of single amino acids. Rfdiffusion is able to scaffold active sites made up a diverse set of functional groups (backbone or side chain).
      </Typography>

      <Typography variant="body1" paragraph>
        It is important to note that the current public version of RFdiffusion cannot model small molecule or non-protein components. However, once code for RFdiffusion-all atom is relased by the authors, this will become available. In the meantine, <Link href='/ligand-mpnn-article'>LigandMPNN</Link> may potentially help design non-protein-binding sequences.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Diversification
      </Typography>

      <Typography variant="body1" paragraph>
        RFdiffusion can repeatedly noise and denoise an input structure to generate more diverse folds for it. This can be applied to enzymes to generate large numbers of variants as well! See more on how to use diversification <Link href='https://tamarindbio.notion.site/tamarindbio/Diversify-a-Protein-Structure-with-RFdiffusion-Partial-diffusion-79d19252708c4a43b4b42c5aa48f476d' target='_blank'>here</Link>.
      </Typography>

      <Typography variant="body1" paragraph>
        This is a somewhat computationally intensive process, especially if the number of variants you'd like to explore are significant. The <Link href='/api'>Tamarind API</Link> allows bioinformaticians and programmers to scale up variant generation to tens of GPUs and cut down on diversification time significantly.  
      </Typography>


      {/*
      <Typography variant="h6" gutterBottom>
        Binder Design
      </Typography>

      <Typography variant="body1" paragraph>
        RFdiffusion can produce binders for a given target, with further value provided via specifying a binding site and an expected binder length.
      </Typography>
       */}

    </Paper>
  );
};
