import { Stack, Button, Autocomplete, TextField,  Typography } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE} from '../utils';
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const NetSolP = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const navigate = useNavigate();
    const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
    const [seqs, setSeqs] = useState([]);

    const MAX_NUM_SEQS = 200;

    const handleChange = (event) => {
        if (seqs.length > MAX_NUM_SEQS) {
            return;
        }
        const inputLines = event.target.value.split('\n');
        // Ensure that only up to 10,000 lines are considered
        const seqsArray = inputLines.filter((s) => {return !s.includes(">") && s!=""}).slice(0, MAX_NUM_SEQS);
        setSeqs(seqsArray);
      };

      
	const submit = (pay) => {
		const configs = {
            'seqList': seqs,
        }

		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(configs), "netsolp");
        startLambdaForType("netsolp")
		navigate("/app/results");
	}

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="netsolp"/>
                <Typography variant='body'>Given a list of protein sequences, predict the Solubility and Usability for each of them.</Typography>
                <Typography variant='body'>Currently limited to {MAX_NUM_SEQS} molecules at a time, get in touch at info@tamarind.bio if you'd like to try a larger scale(millions or billions is acceptable!)</Typography>

                
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Paste sequences below, one on each line. Pasting from a spreadsheet or fasta file is okay!</Typography>

                {
                    /*
                    value={smiles.join('\n')}
                    */
                }

                <TextField
                multiline
                minRows={4}
                maxRows={10}
                placeholder={`Enter sequences, each on a new line (max ${MAX_NUM_SEQS})`}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                />

                <Typography>{seqs.length} / {MAX_NUM_SEQS}</Typography>

                <SubmitButton redir="netsolp" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}