import {Autocomplete, Grid, Stack, TextField, Typography, Box, Button, Alert, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Link} from '@mui/material';
import {List, ListItem, Chip } from '@mui/material';
import Navigation from './Navigation';
import { useDropzone } from 'react-dropzone';
import {useCallback, useEffect, useReducer, useState} from 'react';
import { addJobToQueueObject, uploadFile, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE } from '../utils';
import { useUser } from "@clerk/clerk-react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router';
import {NameField} from './NameField';
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode);

function Instructions() {
    return (
        <>
            <Typography>Want to test a large number of potential designs? Get in touch at info@tamarind.bio</Typography>
        </>
    )
}

const MotifScaffolding = ({setMotifScaffoldingState, setContigs}) => {
    const reducer = (state, action) => {
        const { type, field, value } = action;
        switch (type) {
            case 'scaffold_range_lower':
                return {
                    ...state,
                    [field]: [value, state[field][1]],
                };
            case 'scaffold_range_higher':
                return {
                    ...state,
                    [field]: [state[field][0], value],
                };
            case 'n_terminus_length_lower':
                return {
                    ...state,
                    [field]: [value, state[field][1]],
                };
            case 'n_terminus_length_higher':
                return {
                    ...state,
                    [field]: [state[field][0], value],
                };
            case 'c_terminus_length_lower':
                return {
                    ...state,
                    [field]: [value, state[field][1]],
                };
            case 'c_terminus_length_higher':
                return {
                    ...state,
                    [field]: [state[field][0], value],
                };
            default:
                return {
                    ...state,
                    [field]: value,
                };
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        "chain": "A",
        'scaffold_range': ['1', '50'], 
        'n_terminus_length': ['1', '10'], 
        'c_terminus_length': ['1', '10'], 
    });

    const buildContigs = () => {
        const {n_terminus_length, c_terminus_length, chain, scaffold_range} = state;
        return `${n_terminus_length[0]}-${n_terminus_length[1]}/${chain}${scaffold_range[0]}-${scaffold_range[1]}/${c_terminus_length[0]}-${c_terminus_length[1]}`;
    }

    useEffect(() => 
    {
        setContigs(buildContigs());
        setMotifScaffoldingState(state);
    }, [state]);

    return (
        <Stack spacing={2}>
            <Typography>Input Structure Chain</Typography>
            <TextField
                label='Chain'
                onChange={e => dispatch({type: 'chain', field: 'chain', value: e.target.value})}
                value={state.chain}
            />

            <Typography>Range of Residues for Scaffolding</Typography>
            <Box display="flex" alignItems="center" gap={2}>
                <TextField
                    label="Lower"
                    onChange={e => dispatch({type: 'scaffold_range_lower', field: 'scaffold_range', value: e.target.value})}
                    value={state.scaffold_range[0]}
                    type="number"
                />
                <>-</>

                <TextField
                    label="Higher"
                    onChange={e => dispatch({type: 'scaffold_range_higher', field: 'scaffold_range', value: e.target.value})}
                    value={state.scaffold_range[1]}
                    type="number"
                />
            </Box>

            <Typography>N Terminus New Structure Length Range</Typography>
            <Box display="flex" alignItems="center" gap={2}>
                <TextField
                    label="Lower"
                    onChange={e => dispatch({type: 'n_terminus_length_lower', field: 'n_terminus_length', value: e.target.value})}
                    value={state.n_terminus_length[0]}
                    type="number"
                />
                <>-</>

                <TextField
                    label="Higher"
                    onChange={e => dispatch({type: 'n_terminus_length_higher', field: 'n_terminus_length', value: e.target.value})}
                    value={state.n_terminus_length[1]}
                    type="number"
                />
            </Box>

            <Typography>C Terminus New Structure Length Range</Typography>
            <Box display="flex" alignItems="center" gap={2}>
                <TextField
                    label="Lower"
                    onChange={e => dispatch({type: 'c_terminus_length_lower', field: 'c_terminus_length', value: e.target.value})}
                    value={state.c_terminus_length[0]}
                    type="number"
                />
                <>-</>

                <TextField
                    label="Higher"
                    onChange={e => dispatch({type: 'c_terminus_length_higher', field: 'c_terminus_length', value: e.target.value})}
                    value={state.c_terminus_length[1]}
                    type="number"
                />
            </Box>
        </Stack>
    );
};

const PartialDiffusion = ({ setPartialDiffusionState, files, setContigs }) => {
    const reducer = (state, action) => {
        const { type, field, value } = action;
        switch (type) {
            case 'structure_length_lower':
                return {
                    ...state,
                    'structure_length': [value, state['structure_length'][1]],
                };
            case 'structure_length_higher':
                return {
                    ...state,
                    'structure_length': [state['structure_length'][0], value],
                };
            case 'partial_temperature':
                return {
                    ...state,
                    [field]: value,
                };
            default:
                return state; 
        }
    };

    const [pdbContents, setPdbContents] = useState('');
    const [numResidues, setNumResidues] = useState('')

    const initialState = {
        'structure_length': '', 
        'partial_temperature': '20', 
    };

    function readFileContents(file) {
        const reader = new FileReader();
      
        // This event handler will be called once the read operation is complete
        reader.onload = function(e) {
          const text = e.target.result;
          setPdbContents(text);
        };
      
        // If you encounter an error
        reader.onerror = function(e) {
          console.error('Error reading file:', e);
        };
      
        // Read the file as text
        reader.readAsText(file);
    }
    
    function countResidues(pdbContents) {
        // Set to hold unique residue identifiers (chainID+resSeq+insertionCode)
        const uniqueResidues = new Set();
      
        // Split the PDB content by lines and iterate over them
        pdbContents.split('\n').forEach(line => {
          if (line.startsWith('ATOM') || line.startsWith('HETATM')) {
            // Extract chainID, resSeq, and insertionCode from the line
            const chainID = line.substring(21, 22);
            const resSeq = line.substring(22, 26).trim(); // Residue sequence number
            const insertionCode = line.substring(26, 27).trim();
      
            // Create a unique identifier for the residue
            const residueIdentifier = `${chainID}${resSeq}${insertionCode}`;
      
            // Add the identifier to the set
            uniqueResidues.add(residueIdentifier);
          }
        });
      
        // The size of the set gives the number of unique residues
        setNumResidues(uniqueResidues.size);
        return uniqueResidues.size;
    }
      

    const buildContigs = () => {
        if (numResidues)
            return `${numResidues}-${numResidues}`;
    
        return ``;
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        setContigs(buildContigs());
        setPartialDiffusionState(state);
    }, [state]);

    useEffect(() => {
        if (files.length > 0) {
            readFileContents(files[0].file);
            countResidues(pdbContents);
        }
        else {
            setNumResidues('')
        }
    }, [files])

    useEffect(() => setContigs(buildContigs()), [numResidues])

    return (
        <Stack spacing={2}>
            <Typography>For now, we support only diversifying the a structure with a single chain. </Typography>

            <Typography>Structure Length Range</Typography>
            <TextField label={numResidues ? numResidues : 'Upload your pdb file to see the length!'} onChange={() => null} value={numResidues}></TextField>

            <Typography>Partial Temperature</Typography>
            <TextField
                label="Temperature"
                type="number"
                onChange={e => dispatch({type: 'partial_temperature', field: 'partial_temperature', value: e.target.value})}
                value={state.partial_temperature}
            />
        </Stack>
    );
};


export const PDBViewer = ({ PDB_ID }) => {
    // Use a state to hold a unique key that changes whenever PDB_ID changes
    const [key, setKey] = useState(PDB_ID);

    useEffect(() => {
        // Whenever PDB_ID changes, generate a new unique key to force re-render
        setKey(`${PDB_ID}-${new Date().getTime()}`);
    }, [PDB_ID]); // Dependence on PDB_ID ensures this effect runs when it changes

    return (
        <div style={{ width: '80%', height: '500px', position: 'relative', margin: "auto" }}>
            <pdbe-molstar key={key} id="pdb-viewer" custom-data-format="pdb"
                sequence-panel	
                landscape="true"
                bg-color-r="255" bg-color-g="255" bg-color-b="255"
                reactive="true"
                hide-water="true"
                custom-data-url={`https://files.rcsb.org/download/${PDB_ID}.pdb`}>
            </pdbe-molstar>
        </div>
    );
};

export default function RFDiffusionAllAtom() {
    const [numDesigns, setNumDesigns] = useState("1");
    const [contigs, setContigs] = useState("10-120,A84-87,10-120");
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [files, setFiles] = useState([]);
    const [binderDesignState, setBinderDesignState] = useState({
        "chain": "A",
        'binding_site_range': ['1', '50'],
        'binder_length_range': ['1', '20'],
        'numDesigns': '1',
    });
    const [motifScaffoldingState, setMotifScaffoldingState] = useState({
        "chain": "A",
        'scaffold_range': ['1', '50'], 
        'n_terminus_length': ['1', '10'], 
        'c_terminus_length': ['1', '10'], 
    });

    const [partialDiffusionState, setPartialDiffusionState] = useState({
        'structure_length': ['50', '50'], 
        'partial_temperature': '20', 
    });

    const [hotspot, setHotspot] = useState('');
	const [exceed, setExceed] = useState(false);
    const [mode, setMode] = useState(0);

    const { isLoaded, isSignedIn, user } = useUser();  
    const navigate = useNavigate();

    const readUrlAsync = (url) => {
        return new Promise((resolve, reject) => {
            if (typeof url === 'string') {
                fetch(url)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        return response.text();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                reject(new Error('Input must be a URL string'));
            }
        });
    };

    const handleUpdateID = async (id) => {
        setPdb_id(id)
        let url = `https://files.rcsb.org/download/${id}.pdb`
        try {
                const text = await readUrlAsync(url);

                const lines = text.split('\n');
                const ligands = new Set();

                lines.forEach(line => {
                        if (line.startsWith('HETATM')) {
                        const resName = line.substring(17, 20).trim();
                        if (resName !== 'HOH') { // Exclude water
                                ligands.add(resName);
                        }
                        }
                });
                setLigandCodes([...ligands])
                setLigandCode([...ligands][0])
                console.log(ligands)
        }catch (error) {
                console.error('Error reading file:', error);
        }
    };
    
    const submitJob = (pay) => {
        //const fnames = files.map(file => file.filename);

        let email = user.emailAddresses[0].emailAddress
        const cost = pay ? OTHER_JOB_BASE : 0
        let configObj = {contigs: `${contigs}`, pdb_id: pdb_id, ligandCode:ligandCode, numDesigns: numDesigns};

        //configObj['uploaded_file'] = fnames.map(fname => `${email}/${fname}`).join(' ');

        addJobToQueueObject(jobName,cost,user,JSON.stringify(configObj), "rfdiffusion_all_atom", '', 'In Queue');
        //files.map(file => uploadFile(file.file, user, () => {}));
        //startLambdaForType("rfdiffusion");  
        navigate('/app/results');
    }

    const [pdb_id, setPdb_id] = useState('1haz');
    const [ligandCode, setLigandCode] = useState("");
    const [ligandCodes, setLigandCodes] = useState([]);

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://www.ebi.ac.uk/pdbe/pdb-component-library/js/pdbe-molstar-component-3.1.3.js';
    //     document.body.appendChild(script);
    
    //     const script2 = document.createElement('script');
    //     script2.src = "https://cdn.jsdelivr.net/npm/babel-polyfill/dist/polyfill.min.js"
    //     document.body.appendChild(script2);
    
    //     const script3 = document.createElement('script');
    //     script3.src = "https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs/webcomponents-lite.js"
    //     document.body.appendChild(script3);
    
    //     const script4 = document.createElement('script');
    //     script4.src = "https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js"
    //     document.body.appendChild(script4);
    
    //     const link = document.createElement('link');
    //     link.rel = "stylesheet"
    //     link.type = "text/css"
    //     link.href = 'https://www.ebi.ac.uk/pdbe/pdb-component-library/css/pdbe-molstar-light-3.1.3.css';
    //     document.body.appendChild(link);
    
    //     return () => {
    //       document.body.removeChild(script);
    //       document.body.removeChild(script2);
    //       document.body.removeChild(script3);
    //       document.body.removeChild(script4);
    //       document.body.removeChild(link);
    //     };
    //   }, []); 

    return (
        <Stack spacing={2} style={{padding: '10px' }}>

            <Header type="rfdiffusion-all-atom"/>
            <Instructions />

            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

            <Typography>As an example(from the authors) to include protein residues A84-87 in the motif, use '10-120,A84-87,10-120', which tells the model to design a protein containing the 4 residue motif A84-87 with 10-120 residues on either side.</Typography>
            <TextField required label='Contigs' value={contigs} onChange={e => setContigs(e.target.value)}/>

            <TextField required label='Protein PDB ID' value={pdb_id} onChange={e => handleUpdateID(e.target.value)}/>
            {/* <PDBViewer PDB_ID={pdb_id}/> */}
            <Typography>To upload a custom pdb file, try our <Link href='/all-atom-design'>All Atom Design</Link> tool</Typography>
            {/* <Typography>Not sure how to find your ligand? See our <Link href='https://drive.google.com/file/d/1208WSrbIfMLzq3IIsS58cTlMXUBkVx4a/view?usp=sharing' target="_blank">example video.</Link></Typography> */}
            {/* <TextField label='Ligand Name' value={ligandCode} onChange={e => setLigandCode(e.target.value)}/> */}
            <Autocomplete
                    disablePortal
                    freeSolo
                    options={ligandCodes}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField required {...params} label="Ligand Name" />}
                    defaultValue={""}
                    value={ligandCode}
                    onInputChange = {(e, val) => setLigandCode(val)}
                    onChange={(e, val) => setLigandCode(val)}
            />

            <Autocomplete
                disablePortal
                options={["1", "2", "4", "8", "16", "32"]}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Number of Designs" />}
                defaultValue={"1"}
                onChange={(e, val) => setNumDesigns(val)}
            /> 
            
            {/*
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    Advanced Settings
                </AccordionSummary>
                <AccordionDetails>
                    <Stack>
                        <TextField 
                        label="Hotspot" 
                        onChange={e => setHotspot(e.target.value)} 
                        value={hotspot}
                        />
                        
                    </Stack>
                </AccordionDetails>
            </Accordion>
            */}

            {/*
            <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={false}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["chemical/x-pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
            />
            */}

            <SubmitButton redir="rf-diffusion-all-atom" duplicate={duplicateJob} exceed={exceed} onSubmit={submitJob}>Submit</SubmitButton>

        </Stack>
    )
}

/*
export default function RFDiffusion() {
    const [numDesigns, setNumDesigns] = useState("1");
    const [contigs, setContigs] = useState("100");
    const [numIterations, setNumIterations] = useState("1");
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [success, setSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [pdb, setPdb] = useState('');
    const [hotspot, setHotspot] = useState('');
    const [filterChecked, setFilterChecked] = useState(false);
	const [exceed, setExceed] = useState(false);

    const { isLoaded, isSignedIn, user } = useUser();  

    const navigate = useNavigate();

    const submitJob = (pay) => {
        if (files.length === 0) {
            Swal.fire(`Please make sure to upload a file, unconditional generation is currently not supported. \n Your contig is: ${contigs}`)
            return;
        }
        
        const fnames = files.map(file => file.filename);
        //const = `Name:${jobName} Contigs:${contigs} NumDesigns:${numDesigns} pdb:${fnames.join(' ')}${pdb} hotspot: ${hotspot}`;

        let configObj = {name: jobName, contigs: contigs, numDesigns: numDesigns, hotspot: hotspot, filter: filterChecked};
        let email = user.emailAddresses[0].emailAddress
        configObj['uploaded_file'] = fnames.map(fname => `${email}/${fname}`).join(' ');
        configObj['pdb_id'] = pdb;

        const cost = pay ? OTHER_JOB_BASE : 0
        addJobToQueueObject(jobName,cost,user,JSON.stringify(configObj), "rfdiffusion", '', 'In Queue');
        files.map(file => uploadFile(file.file, user, () => {}));
        startLambdaForType("rfdiffusion");  

        navigate('/user');
    }

    return (
        <Stack spacing={2} style={{padding: '10px' }}>
            <Grid container>
                <Navigation />
            </Grid>

        <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind <b>RFdiffusion</b> Protein Design </Typography>
    

        <Instructions />
        
        <Typography>
            Set your settings and steer RFDiffusion to design a protein to your choosing. You can track the status of your job under the "My Workflows" tab! 
        </Typography>

        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
        <Contigs contigs={contigs} setContigs={setContigs}/>
        
        <Autocomplete
            disablePortal
            options={["1", "2", "4", "8", "16", "32"]}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Number of Designs" />}
            defaultValue={"1"}
            onChange={(e, val) => setNumDesigns(val)}
        />

        <TextField onChange={e => setHotspot(e.target.value)} label='Hotspot'></TextField>
    
        <div>
            <Typography variant='body'>Enable filtering with ProteinMPNN and AlphaFold?</Typography>
            <Checkbox onChange={() => setFilterChecked(!filterChecked)}></Checkbox>
        </div>


        <Typography variant='h6'>
            Upload pdb file, or type in the name below.
        </Typography>


        <FilePond
            files={files}
            allowReorder={true}
            allowMultiple={true}
            onupdatefiles={setFiles}
            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
            credits={[]}
            acceptedFileTypes={["chemical/x-pdb"]}
            fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
        />

        <SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submitJob}>Submit</SubmitButton>


        </Stack>
    );
  }
*/