import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import {useState} from 'react';
import { Typography } from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import {SubmitButton} from './SubmitButton';

export const JackHMMER = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
    const [sequence, setSequence] = useState('MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV');
    const [E, setE] = useState('1');
    const [domE, setDomE] = useState('1');
    const [incE, setIncE] = useState('0.5');
    const [incdomE, setIncdomE] = useState('0.5');
    const [mx, setMx] = useState('BLOSUM90');
    const [pextend, setPextend] = useState('0.4');
    const [popen, setPopen] = useState('0.49');
    const [seqdb, setSeqdb] = useState('uniref100');
  
	const [exceed, setExceed] = useState(false);

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay) => {
		const config = {sequence: sequence, E:E, domE: domE, incE: incE, incdomE:incdomE, mx: mx, pextend: pextend, popen: popen, seqdb:seqdb}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), 'jackhmmer');
		startLambdaForType("jackhmmer")
		navigate("/app/results")
	}

	return (
	<>
		<Stack spacing={2} style={{padding: '10px' }}>
		<Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind JackHMMER Online Tool</Typography>

            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

      <TextField
        fullWidth
        multiline
        rows={4}
        label="Sequence"
        value={sequence}
        onChange={(e) => setSequence(e.target.value)}
      />
      
      <TextField
        fullWidth
        label="E-value threshold"
        value={E}
        onChange={(e) => setE(e.target.value)}
      />
      
      <TextField
        fullWidth
        label="Domain E-value threshold"
        value={domE}
        onChange={(e) => setDomE(e.target.value)}
      />
      
      <TextField
        fullWidth
        label="Inclusion E-value threshold"
        value={incE}
        onChange={(e) => setIncE(e.target.value)}
      />
      
      <TextField
        fullWidth
        label="Inclusion domain E-value threshold"
        value={incdomE}
        onChange={(e) => setIncdomE(e.target.value)}
      />
      
      <FormControl fullWidth>
        <InputLabel>Scoring matrix</InputLabel>
        <Select
          value={mx}
          label="Scoring matrix"
          onChange={(e) => setMx(e.target.value)}
        >
          <MenuItem value="BLOSUM90">BLOSUM90</MenuItem>
          <MenuItem value="BLOSUM62">BLOSUM62</MenuItem>
          <MenuItem value="PAM30">PAM30</MenuItem>
        </Select>
      </FormControl>
      
      <TextField
        fullWidth
        label="Gap extend probability"
        value={pextend}
        onChange={(e) => setPextend(e.target.value)}
        type="number"
        inputProps={{ min: 0, max: 1, step: 0.1 }}
      />
      
      <TextField
        fullWidth
        label="Gap open probability"
        value={popen}
        onChange={(e) => setPopen(e.target.value)}
        type="number"
        inputProps={{ min: 0, max: 0.499999, step: 0.01 }}
      />
      
      <FormControl fullWidth>
        <InputLabel>Sequence database</InputLabel>
        <Select
          value={seqdb}
          label="Sequence database"
          onChange={(e) => setSeqdb(e.target.value)}
        >
          <MenuItem value="uniprot">UniProt</MenuItem>
          <MenuItem value="uniref100">UniRef100</MenuItem>
        </Select>
      </FormControl>
    </Stack>

    <SubmitButton redir="abodybuilder" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
	</>
	)
}
