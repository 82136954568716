import { Stack, Button } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const FreeWilson = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [smiFile, setSmiFile] = useState([]);
        const [csvFile, setCsvFile] = useState([]);

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const smiFilePaths = smiFile.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const csvFilePaths = csvFile.map(f => returnS3PathsAndUploadFiles(user, f.file));

                const config = {scaffoldFiles: filePaths, smiFile:smiFilePaths.join(","), activityFile:csvFilePaths.join(",")};
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "free-wilson");
                if (user.emailAddresses[0].emailAddress.endsWith("septerna.com") || user.emailAddresses[0].emailAddress == "sherry.tamarind@gmail.com") {
                        startLambdaForType('septerna-free-wilson')
                } else {
                        startLambdaForType('free-wilson')
                }
                navigate("/app/results");
        }

        let disableReasons = []
        if (files.length == 0) {
            disableReasons.push("No mol file uploaded")
        }
        if (smiFile.length == 0) {
            disableReasons.push("No smi file uploaded")
        }
        if (csvFile.length == 0) {
            disableReasons.push("No csv file uploaded")
        }
        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="free-wilson"/>      
                <Typography>Perform Structure-activity relationship (SAR) analysis given a scaffold with R-groups and a list of smiles and their activities. </Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography><b>Scaffold</b> (with labeled R groups)</Typography>
                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['mol']} defaultFile="scaffold.mol" useMultiple={true}/>
                <br></br>
                <Typography><b>Smiles </b>(list of molecules and their names - ex. line: CN(C)CC(c1ccccc1)Br MOL0001):</Typography>
                <Typography>Any smiles without activity values will be ignored during regression, but considered as synthesized when generating unsynthesized compounds. </Typography>
                <ChooseOrUploadFile files={smiFile} setFiles={setSmiFile} types={['smi']} defaultFile="fw_mols.smi"/>
                <br></br>
                <Typography><b>Activity </b>(csv with columns for name corresponding to smiles file 'Name' and Activity 'Act'):</Typography>
                <Typography>Any non numerical values will be removed</Typography>
                <ChooseOrUploadFile files={csvFile} setFiles={setCsvFile} types={['csv']} defaultFile="fw_act.csv"/>

                <SubmitButton redir="free-wilson" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
