import React from 'react';
import { Grid, Typography, Link, Paper, Box } from '@mui/material';
import LigandMPNNImage from './LigandMPNN1.png';
import Navigation from '../Navigation';
import EnzymeActiveSiteImage from './EnzymeActiveSite.png';

export const AFClusterBlogPost = () => {
  return (
    <Paper elevation={3} sx={{ p: 4, margin: 'auto', maxWidth: 900 }}>
      <Navigation />
      <Typography variant="h4" gutterBottom>
        Computational prediction of multiple protein conformations using AFCluster
      </Typography>

      <Typography variant="h6" gutterBottom>
        AlphaFold2 and its computational peers have become the go-to approach to predict a single for a given protein sequence. However, a protein's function often changes significantly around its conformational substates.  AF-Cluster modifies AlphaFold to get predictions for alternative states for a given protein/multiple sequence alignment. 
      </Typography>      

      <Typography variant="h6" gutterBottom>
        With this, the authors have confirmed one AF-Cluster prediction using magnetic resonance microscopy     
      </Typography>      

      <Typography variant="h6" gutterBottom>
        Additionally, since point mutations can cause changes in these substates 
      </Typography>      

      {/*
      <Typography variant="h6" gutterBottom>
        Binder Design
      </Typography>

      <Typography variant="body1" paragraph>
        RFdiffusion can produce binders for a given target, with further value provided via specifying a binding site and an expected binder length.
      </Typography>
       */}

    </Paper>
  );
};
