import { Autocomplete, TextField } from "@mui/material";
import {useState, useEffect} from 'react';
import { Typography } from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import {SubmitButton} from './SubmitButton';

const solvents = ['Propyl acetate',
'Isophorone',
'Ethanol',
'Dimethyl sulfoxide',
'4-Formylmorpholine',
'Toluene',
'1,2-Dichloroethane',
'Dimethylacetamide',
'1-Propanol',
'Isobutanol',
'Benzyl benzoate',
'2-Ethoxyethanol',
'Dichloromethane',
'Acetic acid',
'4-Methyl-2-pentanone',
'2-Methoxyethanol',
'Cyclohexane',
'1,4-Dioxane',
'2-Propoxyethanol',
'Chloroform',
'Diethylene glycol dimethyl ether',
'Benzyl acetate',
'Formic acid',
'2-Propanol',
'Methyl acetate',
'Heptane',
'Butyl acetate',
'Hexane',
'Acetonitrile',
'2-Butoxyethanol',
'Cyclopentanone',
'Methanol',
'Ethyl acetate',
'Butanone',
'1-Butanol',
'Water',
'N-methyl-2-pyrrolidone',
'Dimethylformamide',
'Propylene glycol',
'1,2-Dimethoxyethane',
'Tetrahydrofuran',
'Ethylene glycol',
'Benzyl alcohol',
'Acetone'];

export const BinarySolubility = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [exceed, setExceed] = useState(false);

	const [drugSmiles, setDrugSmiles] = useState('O=C(CO)[C@@]3(O)CC[C@H]2[C@@H]4CC\C1=C\C(=O)\C=C/[C@]1(C)[C@H]4C(=O)C[C@@]23C')
	const [solvent1, setSolvent1] = useState('Water');
	const [solvent2, setSolvent2] = useState('Ethanol');
	const [temperature, setTemperature] = useState('300');
	const [solvent_mass_fraction, set_solvent_mass_fraction] = useState(0.5);

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay) => {
		const configStr = {drug: drugSmiles, solvent1: solvent1, solvent2: solvent2, temperature:temperature, solvent_mass_fraction: solvent_mass_fraction}

		addJobToQueue(jobName, 0, user, JSON.stringify(configStr), 'binary-solubility');
		startLambdaForType('binary-solubility');
		navigate("/app/results");
	}

	const validEmails = ['denizkavi@outlook.com', 'sherryliu987@gmail.com', 'kraman@persist-ai.com', 'joshua@persist-ai.com', 'daniel@persist-ai.com', 'corina@persist-ai.com', 'kenan@persist-ai.com', 'armon@persist-ai.com']
    
	useEffect(() => {
		const email = user?.emailAddresses?.[0].emailAddress; // null protection
		if (isLoaded && !validEmails.includes(email) && !email.includes("persist-ai")) {
		  navigate('/app'); // Safely Navigate to the Target State
		}
	  }, [navigate, isLoaded, user?.emailAddresses]); // appropriate dependents

	if (!isLoaded) {
		return <div>Loading...</div>; // e.g., a pre-loader or key aspect, understanding the "index" of the cycle.
	}
	  

	if (isLoaded && validEmails.includes(user.emailAddresses[0].emailAddress)) {   
		return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
			<Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind Solubility Prediction Online Tool</Typography>
				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

				<TextField label='Drug SMILES String' value={drugSmiles} onChange={(e) => setDrugSmiles(e.target.value)}></TextField>
				<TextField label='Temperature (K)' value={temperature} onChange={(e) => setTemperature(e.target.value)}></TextField>
				<TextField label='Solvent Mass Fraction (0-1)' value={solvent_mass_fraction} onChange={(e) => set_solvent_mass_fraction(e.target.value)}></TextField>

				<Autocomplete
				disablePortal
				options={solvents}
				sx={{ width: 300 }}
				value={solvent1}
				onChange={(e, s) => setSolvent1(s)}
				renderInput={(params) => <TextField {...params} label="Solvent 1" />}
				/>

				<Autocomplete
				disablePortal
				options={solvents}
				sx={{ width: 300 }}
				value={solvent2}
				onChange={(e, s) => setSolvent2(s)}
				renderInput={(params) => <TextField {...params} label="Solvent 2" />}
				/>

				<SubmitButton redir="binary-solubility" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
			</Stack>
		</>
		)
	} else {
		<></>
	}
}
