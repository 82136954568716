import { Button, TextField } from "@mui/material";
import { Stack } from "@mui/material";
import Navigation from "./Navigation";
import {useState} from 'react';
import { Typography } from "@mui/material";
import { NameField } from './NameField';
import { useUser } from "@clerk/clerk-react";
import { addJobToQueue, OTHER_JOB_BASE } from "../utils";
import { useNavigate } from "react-router";
import {SubmitButton} from './SubmitButton';
import Swal from "sweetalert2";

export const Unimol = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [pdbId, setPdbId] = useState('4ty7');
	const [smilesString, setSmilesString] = useState('');
	const [pocket, setPocket] = useState("D175, D55, A41, A157, A229, D76, A203, A204, A234, A149, D94, A233, A227, D96, D64, A61, A156, A235, D30, A155, A236, A42, D131, D43, D107, A226, A200, A202, A223, A198, A222, A201, D226, A44, A43, A224, A228, A199, A60, A59, A225, D167");

	const [duplicateJob, setDuplicateJob] = useState(false);
	const { isLoaded, isSignedIn, user } = useUser();  
	const navigate = useNavigate()
	const [exceed, setExceed] = useState(false);

	const submit = (pay) => {
		if (pocket.trim() == "") {
			Swal.fire("Please define a binding pocket")
			return
		}
		const configObj = {protein: pdbId, ligand: smilesString, pocket: pocket}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(configObj), 'unimol', '');
		
		navigate('/app/results');
	}

	return (
		<>
	
			<Stack spacing={2} style={{padding: '10px' }}>
				<Navigation />

                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind UniMol Online Tool</Typography>
				<Typography>Method for docking small molecule to protein.</Typography>
				<Typography>Feel free to import multiple SMILES string by seperating them using a ",".</Typography>
				<Typography>If the SMILES box is left empty, the default ligand in the PDB will be used.</Typography>

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

				<TextField value={pdbId} label='PDB ID' onChange={(e) => setPdbId(e.target.value)}/>
				<TextField value={smilesString} label='SMILES' onChange={(e) => setSmilesString(e.target.value)}/>
				<TextField value={[pocket]} label='Binding Pocket' required onChange={(e) => setPocket(e.target.value)}/>
				<SubmitButton redir="unimol" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
			</Stack>
		</>
	)
}
