import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';

function parseSettings(inputString) {
    const pattern = /--(\S+)\s+(\S+)/g;
    const settings = {};
    let match;

    while ((match = pattern.exec(inputString.replace("msas", "").replace("amber", "amber true"))) !== null) {
      const [, key, value] = match; // Using destructuring to ignore the full match
      settings[key] = value;
    }
    // console.log(settings)
    return Object.entries(settings);
}

export default function SettingsTable({ infoString }) {
    return(
      infoString.length == 0 ? null : 
        <TableContainer component={Paper} sx={{maxWidth:"350px"}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: '90px' }}>Setting</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parseSettings(infoString).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
}