import { useState, useEffect, useRef } from 'react';
import Navigation from '../Navigation';
import {Button, Tabs, Tab, Grid, Card, useMediaQuery, Container, CardContent, Chip, List, ListItem, ListItemText} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import FeaturesGrid from './FeaturesGrid';
import UsedBy from './UsedBy';
import * as $3Dmol from '3dmol';
import Features from '../Features';
import diffdockImage from './diffdock.png';
import alphafoldImage from './alphafold.png';
import rfdiffusionImage from './rfdiffusion.png';
import ButtonGroup from '@mui/material/ButtonGroup';
import axios from 'axios'
import apiImage from './api.png'
import screeningImage from './virtual-screening.png'
import zinc from './zinc.png'
import pipelinesImage from './pipelines.png'
import pdb from './6w70.pdb'
import ligand from './6w70_ligand.sdf'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {theme} from '../../utils'
import '../../fonts.css'
import { useInView } from 'react-intersection-observer';
import PdbViewer from '../PdbViewer';
import { styled } from '@mui/system';
import AminoAcidAnimation from './AminoAcidAnimation'; // Adjust the path as necessary
import Footer from '../Footer';
import FeaturesSection from './FeaturesSection';
import { HighlightedText } from '../UIComponents';

const PDBViewer = ({ pdbUrl, w, h, ext='pdb' }) => {
  const viewerRef = useRef();
  const [pdbData, setPdbData] = useState(null);

  useEffect(() => {
      // Function to fetch PDB data
      const fetchPdbData = async () => {
          try {
              const response = await fetch(pdbUrl);
              const data = await response.text();
              setPdbData(data);
          } catch (error) {
              console.error("Error fetching PDB data:", error);
          }
      };

      fetchPdbData();
  }, [pdbUrl]);

  useEffect(() => {
      if (viewerRef.current && pdbData) {
          const config = { backgroundColor: '#f5f5f5' };
          const viewer = $3Dmol.createViewer(viewerRef.current, config);

          let viewerElement = viewer.container;
          viewerElement.addEventListener('wheel', function(event) {
              viewer.zoomTo();
              window.scrollBy(0, event.deltaY);
          }, { passive: false }); 

          viewer.addModel(pdbData, ext);
          viewer.setStyle({}, { cartoon: { color: 'spectrum' } });
          viewer.spin(true);
          viewer.zoomTo();
          viewer.render();
      }
      
  }, [pdbData]); // Rerun when pdbData changes



  return <div style={{ width: `${w}`, height: `${h}`, position: 'relative', 
  // opacity: 0,
  // animation: 'fadeInAnimation 1s ease-in forwards',
}} ref={viewerRef} 
  />;
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="tamarind.bio">
        Tamarind Bio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// function TabPanel(props) {
//   const { children, value, index, imageSrc, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3, minHeight: '500px'  }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={6}>
//               <Typography>{children}</Typography>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <img src={imageSrc} alt={`Tab ${index}: Demo of Tamarind Bio Website`} style={{ width: '100%', height: 'auto' }} />
//             </Grid>
//           </Grid>
//         </Box>
//       )}
//     </div>
//   );
// }

const CustomComponent = ({ text, boxContent, imageUrl, toolUrl }) => {
  const navigate = useNavigate();
  return (
    <div style={{ marginBottom: 20, backgroundColor:"#f5f5f5" }}>
      <Grid container spacing={2} >
        <Grid item xs={12} md={6}>
          <Typography variant="body1" style={{ marginBottom: 10 }}>{text}</Typography>
          <Box
            style={{height: 200 }}
          >
            {boxContent}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
          sx={{
            transition: 'transform 0.3s ease', // Smooth transition for the transform effect
            '&:hover': {
              transform: 'scale(1.03)', // Scale the card by 10% on hover
              cursor: "pointer"
            },
            margin:"7px",padding:"5px",
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Example shadow effect

          }}
          >
          <img onClick={() => 
            {navigate(toolUrl)
              window.scrollTo({
                top: 0,
                behavior: 'auto' // Optional: Smooth scrolling animation
              });
            }} src={imageUrl} alt="Image" style={{ maxWidth: '100%', height: 'auto' }} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};


function UseCases() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState("")
  const params = {
    "jobName": "my-job",
    "type": "alphafold",
    "settings": {
      "sequence": "MALKSLVLLSLLVLVLLLVRVQ",
    }
  };
  const codeSnippet = `
  params = ${JSON.stringify(params, null, 2)}
  response = requests.post('https://www.tamarind.bio/api/submit-job', headers=headers, json=params)
`;

  const slides = [
    {
      title: 'Structure Prediction',
      content: <CustomComponent
        text="Protein structure prediction for monomers, multimers, and protein/nucleic acid complexes from an amino acid sequence."
        imageUrl={alphafoldImage}
        toolUrl={'/alphafold'}
        boxContent={<PDBViewer w="100%" h="100%" pdbUrl="https://files.rcsb.org/download/3KHH.pdb"/>}
      />
    }, {
      title: 'Protein Design',
      content: <CustomComponent
        text="Quickly design binders for your target. Filter from large scale designs to find the best ones. "
        imageUrl={rfdiffusionImage}
        toolUrl={'/rf-diffusion'}
        boxContent={<PDBViewer w="100%" h="100%" pdbUrl="https://files.rcsb.org/download/8GJG.pdb"/>}
      />
    }, {
      title: 'Docking',
      content: <CustomComponent
        text="Small molecule and protein-protein docking at massive scale using both ML and physics based methods. "
        imageUrl={diffdockImage}
        toolUrl={'/diffdock'}
        boxContent={
          <div style={{ width: '100%', height: '100%', position: 'relative', margin:"auto" }}>
          {/* <pdbe-molstar custom-data-url={pdb} 
          custom-data-format="pdb" 
          bg-color-r="255" bg-color-g="255" bg-color-b="255" 
          hide-water="true"
          ></pdbe-molstar> */}
          <br></br>
        </div> 
        }

      />
    }, {
      title: 'API',
      content: <CustomComponent
        text="Programmatic access to all of our existing tools at scale, or deploy your custom models on our cloud."
        imageUrl={apiImage}
        toolUrl={'/api-docs'}
        boxContent={
          <SyntaxHighlighter 
          lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
          language="python" style={oneLight} customStyle={{fontSize:12}} wrapLines={true}>
            {codeSnippet}
        </SyntaxHighlighter>
        }
      />
    }, {
      title: 'Virtual Screening',
      content: <CustomComponent
        text="Screen up to billions of ligands from libraries like ZINC against receptor targets, with custom scoring."
        imageUrl={screeningImage}
        toolUrl={'/virtualscreening'}
        boxContent={
          <img src={zinc} alt="Image" style={{ maxWidth: '100%', height: '100%' }} />     }
      />
    }, {
      title: 'Pipelines',
      content: <CustomComponent
        text="Connect tools together to seamlessly string outputs to inputs and streamline workflows."
        imageUrl={pipelinesImage}
        toolUrl={'/pipelines'}
      />
    },
  ];

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 10000)
    setIntervalId(interval)

    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    clearInterval(intervalId)
    let interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 10000)
    setIntervalId(interval)
  };

  return (
    <Grid container spacing={2} sx={{backgroundColor:"#f5f5f5"}}>
      <Grid item xs={2.5}>
        <div style={{ height: '100%', textAlign: 'left'}}>
          {slides.map((slide, index) => (
            <>
            <Button key={index} onClick={() => handleDotClick(index)} style={{ textAlign: 'left' }}>
              <Typography variant="body1" sx={{textTransform: 'none', fontWeight:"bold"}}> {index === currentIndex ? '●' : '○'} {slide.title}</Typography>
            </Button> <br></br></>
          ))}
        </div>
      </Grid>
      <Grid item xs={9.5}>
        <Card style={{ padding: '20px', boxShadow: 'none', backgroundColor:"#f5f5f5"  }}>
          <Typography variant="body1">{slides[currentIndex].content}</Typography>
        </Card>
      </Grid>
    </Grid>
  );
}

// function UseCases2() {
//   const [value, setValue] = useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const navigate = useNavigate();

//   return (
//     <Box >
//       <ButtonGroup variant="contained"  sx={{ boxShadow: 'none'}}>
//         <Button color={value === 0 ? "primary" : "secondary"}  sx={{marginRight:"20px"}}onClick={() => setValue(0)}>Protein Structure Prediction</Button>
//         <Button color={value === 1 ? "primary" : "secondary"} sx={{marginRight:"20px"}} onClick={() => setValue(1)}>Docking</Button>
//         <Button color={value === 2 ? "primary" : "secondary"} sx={{marginRight:"20px"}} onClick={() => setValue(2)}>Protein Design</Button>
//         <Button color={value === 3 ? "primary" : "secondary"} sx={{marginRight:"20px"}} onClick={() => setValue(3)}>API</Button>
//         <Button color={value === 4 ? "primary" : "secondary"} onClick={() => setValue(4)}>Virtual Screening</Button>
//       </ButtonGroup>

//     <Box sx={{'& *': {fontFamily:"WantedSans"}}}>
//       <TabPanel value={value} index={0} imageSrc={alphafoldImage}>
//         <Typography variant='h6'> Protein structure prediction for long monomers and complexes. </Typography>
//         <Typography variant='h6'> Structure prediction at massive scale. </Typography>
//         <Button onClick={() => navigate('/alphafold')}>alphafold</Button>
//         <Typography variant='h6'>Protein/Nucleic Acid Complex Structure Prediction</Typography>
//         <Button onClick={() => navigate('/rosettafold2na')}>rosettafold2na</Button>
//       </TabPanel>
//       <TabPanel value={value} index={1} imageSrc={diffdockImage}>
//         <Typography variant='h6'> Protein-small molecule and protein-protein docking at scale, no need to think about servers!</Typography>
//         <Typography variant='h6'> Access both AI-based approaches such as DiffDock along with knowledge based ones like AutoDock.</Typography>
//         <Button onClick={() => navigate('/diffdock')}>diffdock</Button>
//         <Button onClick={() => navigate('/autodock-vina')}>autodock</Button>

//         <Typography variant='h6'>Protein-Protein Docking</Typography>
//         <Stack justifyContent='center'>
//           <Button onClick={() => navigate('/lightdock')}>lightdock</Button>
//         </Stack>
//       </TabPanel>

//       <TabPanel value={value} index={2} imageSrc={rfdiffusionImage} >
//         <Typography variant='h6' > Quickly design binders for your target. Filter from large scale designs to find the best designs. </Typography>
//         <Typography variant='h6'> Generate sequences or structures with no code needed. </Typography>
//         <Button onClick={() => navigate('/rf-diffusion')}>rfdiffusion</Button>
//       </TabPanel>

//       <TabPanel value={value} index={3} imageSrc={apiImage} >
//         <Typography variant='h6' > Use our existing tools at scale, our deploy your custom models on our cloud. </Typography>
//         <Typography variant='h6' > No need to worry about GPUs or scaling up efficiently. </Typography>

//         <Button onClick={() => navigate('/api-docs')}>Learn more</Button>
//       </TabPanel>

//       <TabPanel value={value} index={4} imageSrc={screeningImage} >
//         <Typography variant='h6' > Screen up to billions of ligands from libraries like ZINC against receptor targets. </Typography>
//         <Typography variant='h6' > Flexible pricing fit to your use case. </Typography>

//         <Button onClick={() => navigate('/virtualscreening')}>Virtual Screening</Button>
//       </TabPanel>

//       </Box>
//     </Box>
//   );
// }

const Section = styled(Box)(({ theme }) => ({
  opacity: 0,
  transform: 'translateY(50px)',
  transition: 'opacity 0.8s ease-out, transform 0.6s ease-out',
  '&.visible': {
    opacity: 1,
    transform: 'translateY(0)',
  },
  marginBottom: '20px',
}));

export default function Landing() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const features = [
    [{name: 'AlphaFold Structure Prediction', 
    summary: 'Accurate and fast protein structure prediction for multimers and monomers', 
    description:'Input amino acid sequences for monomers or multimers and receive a predicted 3D structure from AlphaFold.', 
    url:'/alphafold'},
    {name: 'AlphaFold Batch',
    summary: 'Run large number of AlphaFold jobs, both for monomers and running sets of sequences against others.',
    description: 'Batch submissions for AlphaFold, both for monomers and running sets of sequences against others.',
    url:'/batch'},
    {name: 'ESMFold Structure Prediction',
    summary: 'Accurate and very fast protein structure prediction for small monomers',
    description: 'Within seconds, retrieve the 3D structure for sequences of up to 390 amino acids',
    url:'/esmfold'},
    {name: 'RosettaFold Structure Prediction',
    summary: 'Accurate and fast protein structure prediction for monomers',
    description: 'Within seconds, retrieve the 3D structure for sequences of up to 1000 amino acids',
    url:'/rosettafold'}],
    [{name: 'RFDiffusion', 
    summary: 'A generative model for protein design', 
    description:'Method for structure generation, with or without conditional information (a motif, target etc)', 
    url:'/rf-diffusion'},
    {name: "ProteinMPNN",
    summary: 'Given a structure, return plausible designed sequences for that structure.',
    description:'',
    url:'/proteinmpnn'},
    {name: "ProtGPT2",
    summary: 'Generate de novo protein sequences from user input',
    description:'Given the first few amino acids of a sequence, ProtGPT generates a plausible sequence.',
    url:'/protgpt2'},
    {name: "UMol",
    summary: 'Structure prediction of protein-ligand complexes from sequence information',
    description: 'At a high accuracy threshold, unseen protein-ligand complexes can be predicted more accurately than for RoseTTAFold-AA, and at medium accuracy even classical docking methods that use known protein structures as input are surpassed.',
    url: "/umol"}
  ],
    [
      {
        name: "DiffDock",
        summary: "State of the art protein-ligand docking",
        description:"",
        url:"/diffdock"
      },
      {
        name: "LightDock",
        summary: "protein-protein docking",
        description:"",
        url:"/lightdock"
      }
    ],
    [{name: "Have any workflow ideas?",
    summary: 'Get in touch!',
    description:'If you have a need/idea to implement a computational tool to support your research, get in touch below.',
    url:'/app'},
    // {name: "SignalP 6",
    // summary: 'Signal Peptide Prediction',
    // description:'For sequences or xgmml files, predict signal peptides and their cleavage sites in all domains',
    // url:'/signalp'},
    {name: "Molstar Viewer",
    summary: 'Visualization and analysis of macromolecular structures',
    description:'Modern web app for 3D visualization and analysis of large biomolecular structures (visualize pdbs and more!)',
    url:'/visualizer'}]
  ]

  const pdbUrl = 'https://alphafold.ebi.ac.uk/files/AF-Q5VSL9-F1-model_v4.pdb';

  const submitRef = () => {
    // axios.post('/api/submit-rfdiffusion', {"pdbFile":"myFile.pdb", "jobName": Math.random().toString(36).slice(2, 7), "contigs": "100", "numDesigns":"1"}, {headers:{'x-api-key':"88CzFcDZBi8dgZ9Qozxy11fFNRQmnyER39Hs8LXU"}}).then(
    //   function(response) {
    //     console.log(response)
    //   }
    // )
    // axios.post('/api/delete-job', {"jobName":"test6_0"}, {headers:{'x-api-key':"88CzFcDZBi8dgZ9Qozxy11fFNRQmnyER39Hs8LXU"}}).then(
    //   function(response) {
    //     console.log(response)
    //   }
    // )
    axios.post('/api/result', {"jobName":"test_2"}, {headers:{'x-api-key':"b6d8b975-6476-4d21-9779-bf54ae6a1ed5"}}).then(
      function(response) {
        // console.log(response)
        // console.log(response.data)
      }
    )
    // axios.get('/api/jobs', {headers:{'x-api-key':"b6d8b975-6476-4d21-9779-bf54ae6a1ed5"}}).then(
    //   function(response) {
    //     console.log(response)
    //   }
    // )
  }

  const [selectedTab, setSelectedTab] = useState(0);

  const features3 = [
    {
      title: 'Simple Interface',
      blurb: 'Access all tools through a simple web interface. Select your inputs, submit, and analyze your results',
      image: '/interface.png',  // Replace with your image URL
    },
    {
      title: 'Scalable Workflows',
      blurb: 'Run thousands of designs, predictions, and simulations in parallel using our batch interface or API',
      image: '/3.png',  // Replace with your image URL
    },
    {
      title: 'Uncompromised Security',
      blurb: "Your data never leaves our private cloud. You retain all IP ownership of your data and derivatives",
      image: '/security.png',  // Replace with your image URL
    },
  ];

  const capabilities = [
    {
      label: 'Enzyme Design',
      blurb: 'Design, test, and iterate on your target sequence, optimizing for multiple properties',
      points: [
        {
          text: 'Test tens of thousands of variants in parallel through multiple rounds of evolution',
          italic: ['tens of thousands of variants']
        },
        {
          text: 'Optimize enzymes for thermostability, activity, and solubility',
          italic: ['thermostability', 'activity', 'solubility']
        },
        {
          text: 'Scaffold an existing motif or diversify the sequence/structure of an existing binder',
          italic: []
        },
        {
          text: 'Generate de novo protein structures targeted to your substrate',
          italic: ['de novo protein structures']
        },
        {
          text: 'Apply physics-based methods for free energy calculations',
          italic: []
        }
      ],
      url: 'enzyme-design',
      tasks:["Structure prediction", 'De novo design', 'Property prediction', 'Point mutation generation']
    },
    {
      label: 'Antibody Engineering',
      blurb: 'Design and optimize your nanobodies and antibodies',
      points: [
        {
          text: 'Predict the structures of immune proteins at massive scale and speed',
          italic: ['massive scale and speed']
        },
        {
          text: 'Redesign and optimize CDRs for an antigen',
          italic: []
        },
        {
          text: 'Predict binding poses of antibody-antigen complexes',
          italic: []
        },
        {
          text: 'Optimize and predict sequences for thermostability, solubility, and other developability properties',
          italic: ['thermostability', 'solubility', 'developability']
        },
        {
          text: 'Design de novo nanobody binders for an antigen',
          italic: []
        }
      ],
      url: 'antibody-engineering',
      tasks:["CDR redesign", "Structure prediction", "De novo design", "Antibody-antigen docking"]
    },
    {
      label: 'Small Molecule',
      blurb: 'Generate, screen, and predict properties of your small molecules',
      points: [
        {
          text: 'Run structure and ligand based virtual screening for billions of compounds within 2 days',
          italic: [ 'billions of compounds']
        },
        {
          text: 'Compute binding affinities using state of the art AI and physics-based approaches',
          italic: ['AI', 'physics-based']
        },
        {
          text: 'Use simple interface to protein-ligand dynamics with free energy calculations',
          italic: []
        },
        {
          text: 'Decorate scaffolds for in-silico library creation',
          italic: []
        }
      ],
        url: 'small-molecule',
        tasks:["Virtual screening", "Molecular dynamics", "Protein-ligand docking"]
    },
    {
      label: 'Peptide discovery',
      blurb: 'Identify and optimize protein therapeutics',
      points: [
        {
          text: 'Screen large-scale protein-peptide interactions',
          italic: ['protein-peptide interactions']
        },
        {
          text: 'Predict structures and design state of the art cyclic peptides',
          italic: ['cyclic peptides']
        },
        {
          text: 'Simulate protein-peptide systems to find optimal binding modes',
          italic: ['Simulate']
        },
        {
          text: 'Predict solubility, membrane permeability, and physicochemical characteristics',
          italic: []
        },
        {
          text: 'Design de novo peptide binder based on your receptor',
          italic: ['de novo peptide binder']
        }
      ],
      url: 'peptide-discovery',
      tasks:["Peptide structure prediction", "Protein-peptide docking", "De novo binder design"]
    },
  ];

  const CapabilityTab = styled(Tab)({
    transform: 'skew(-20deg)',
    minWidth: '180px',
    height: '20px', // Adjust the height to make the parallelogram shorter
    backgroundColor: '#F4E3DB',
    margin: '0 5px',
    overflow: 'visible',
    padding: '0 10px',
    '&.Mui-selected': {
      backgroundColor: '#8B2B00',
      color: '#fff',
    },
    borderRadius: '0px', // Remove the border-radius to make the edges sharp
    '& span': {
      display: 'inline-block',
      transform: 'skew(20deg)', // Counteract the skew to keep the text straight
      fontSize: '0.97rem',
    },
  });
  
  const CapabilityContent = styled(Box)({
    padding: '20px',
    backgroundColor: '#f5f5f5',
    marginTop: '0px',
  });

  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const [ref5, inView5] = useInView({ triggerOnce: true });

  const UnderlinedText = styled(Typography)(({ theme }) => ({
    position: 'relative',
    display: 'inline-block',
    paddingBottom: '10px',
    fontFamily: 'LTHoop',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: "2%",
      bottom: 0,
      width: '96%',
      height: '8px', // Thickness of the line
      // backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, rgba(245, 245, 245, 0.5))`, // Transition to 50% opacity
      // backgroundImage: `linear-gradient(to right, #255a15, rgba(245, 245, 245, 0.5))`, // Transition to 50% opacity
      backgroundColor: "#a8bda1", // "#255a15",
      opacity: 1, // Full opacity for the gradient itself
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
          <Box sx={{position: 'absolute', top: 0, left: 0, width: "100%", backgroundColor:"#f5f5f5"}}>
            <Navigation />
          </Box>

      <main style={{marginTop:"60px"}}>

      <Box sx={{ bgcolor: '#f5f5f5' }} >


      <Section ref={ref1} className={inView1 ? 'visible' : ''}>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

        <Stack
        style={{
          alignItems:"center",
          justifyContent:"center",
          height:"70vh"
        }}
        >
          <PDBViewer w="300px" h="300px" pdbUrl={pdbUrl}/>
          {/* <style>
            {`
              @keyframes fadeInAnimation {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            `}
          </style> */}
          <Typography variant="h1" style={{
              fontSize: isMobile ? "35px" : '6vh',
              fontWeight: 'bold',
              fontFamily: 'LTHoop, Helvetica',
              // opacity: 0,
              // animation: 'fadeInAnimation 0.5s ease-in forwards',
            }} align="center" gutterBottom>
              State of the art <span style={{color: "#8B2B00"}}>computational tools for biology. </span>
          </Typography>
          <Typography 
          style={{
            // opacity: 0,
            // animation: 'fadeInAnimation 0.5s ease-in forwards',
          }}
          sx={{maxWidth:1000, fontFamily: 'WantedSans', fontSize:isMobile ? "20px" : "3vh"}} variant="h5" align="center" gutterBottom>
            Protein design, molecular docking, and structure prediction, no setup required.
            The simplest way to access <u>in silico methods for drug discovery</u>.
          </Typography> 

          <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="outlined" onClick={() =>navigate("/sign-in")} sx={{ textTransform: 'none', fontWeight: 'bold',borderRadius: '15px',borderWidth: '2px'}}>Sign in</Button>
              <Button variant="contained" endIcon={<ArrowForwardIcon />} onClick={() =>navigate("/app")} sx={{ textTransform: 'none', fontWeight: 'bold',borderRadius: '15px'}}>Get Started</Button>
            </Stack>     
        </Stack>
        </div>

        </Section>

        <style>
            {`.shapedividers_com-4098{
            overflow:hidden;
            position:relative;
            }
            .shapedividers_com-4098::before{ 
            content:'';
            font-family:'shape divider from ShapeDividers.com';
            position: absolute;
            z-index: 3;
            pointer-events: none;
            background-repeat: no-repeat;
            bottom: -0.1vw;
            left: -0.1vw;
            right: -0.1vw;
            top: -0.1vw; 
            background-size: 100% 90px;
            background-position: 50% 0%;  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.278 3.62" preserveAspectRatio="none"><path d="M35.278.092S8.238.267 0 3.62V.092z" opacity=".2" fill="%23fbd8c2"/><path d="M35.278.092S8.238.246 0 3.194V.092z" opacity=".2" fill="%23fbd8c2"/><path d="M35.278.092S8.238.223 0 2.738V.092zM35.278.092H0V0h35.278z" fill="%23fbd8c2"/></svg>'); 
            }

            @media (min-width:2100px){
            .shapedividers_com-4098::before{
            background-size: 100% calc(2vw + 90px);
            }`}
 
        </style>


            <Box 
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            paddingTop={4}
            style={{
              overflow: "hidden",
              width:"100%"
            }}
            >



            {/* <PdbViewer /> */}

        <Section ref={ref2} className={inView2 ? 'visible' : ''} >
        <Box sx={{marginBottom:0, paddingBottom:0, spacing:0}}>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => {setSelectedTab(newValue);}}
            sx={{
              '& .MuiTabs-flexContainer': {
              paddingBottom: '0px', // Reduce margin below the tabs container
                paddingTop:'0px'
              },
            }}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
            variant="fullWidth"
          >
            {capabilities.map((capability, index) => (
              <CapabilityTab
                key={index}
                label={<span>{capability.label}</span>}
                wrapped
                disableRipple
                sx={{textTransform:"none",}}
              />
            ))}
          </Tabs>
          <CapabilityContent>
            {/* <Typography variant="h6" gutterBottom>
              <b>{capabilities[selectedTab].blurb}</b>
            </Typography> */}
            <UnderlinedText variant="h6">{capabilities[selectedTab].blurb}</UnderlinedText>

            {/* <Box
              sx={{
                height: '8px', // Thickness of the line
                backgroundColor: '#8B2B00', // Color of the line (adjust based on your theme)
                width: '100%', // Make sure it spans the full width of the text
              }}
            /> */}
          <Stack direction="row" spacing={1} sx={{justifyContent:"center", display:"flex", alignItems:"center", marginTop:2}}>
            {capabilities[selectedTab].tasks.map((item, index) => (
              <Chip key={index} label={item} />
            ))}
          </Stack>
            <Box component="ul" sx={{ paddingLeft: '20px', margin: 0 }}>
            {capabilities[selectedTab].points.map((point, index) => (
              <Box
                component="li"
                key={index}
                sx={{
                  textAlign: 'left',
                  listStyleType: 'disc',
                  listStylePosition: 'outside',
                  paddingLeft: '1em',
                  marginBottom: '8px',
                  marginTop: 2,
                  fontFamily: 'WantedSans',
                }}
              >
                <HighlightedText text={point.text} italicParts={point.italic} />
              </Box>
            ))}
        </Box>
          {/* <Button variant="outlined" onClick={() =>navigate('/app/' + capabilities[selectedTab].url)} sx={{ textTransform: 'none', fontWeight: 'bold',borderRadius: '15px',borderWidth: '2px'}}>View tools</Button> */}

          {/* <Button onClick={() => navigate('/' + capabilities[selectedTab].url )} sx={{textTransform:"none"}}>Use cases</Button> */}
          </CapabilityContent>
        </Box>
        </Section>

        {/* <Section ref={ref5} className={inView5 ? 'visible' : ''} >
        <FeaturesSection />
        </Section> */}

        <Section ref={ref3} className={inView3 ? 'visible' : ''} >
            <UsedBy/>
          </Section>

        <Section ref={ref4} className={inView4 ? 'visible' : ''}>
            <Container sx={{ py: 0, backgroundColor: '#f5f5f5' }}>
            <Grid container spacing={4}>
              {features3.map((feature, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card sx={{ boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', borderRadius: 2, textAlign: 'center',borderBottom: '8px solid #a8bda1', }}>
                    <Box sx={{justifyContent:"center", width:"100%", alignItems:"center", display: 'flex',}}>
                    <CardMedia
                      component="img"
                      height="220"
                      image={feature.image}
                      alt={feature.title}
                      sx={{width:feature.title == "Scalable Workflows" ? "90%" : "75%"}}
                    />
                    </Box>
                    <CardContent>
                      <Box 
                      // sx={{backgroundColor:"#f5f5f5"}}
                      >
                      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, fontFamily: 'Geist',}}>
                        {feature.title}
                      </Typography>
                      {/* <UnderlinedText variant="h6">{feature.title}</UnderlinedText> */}
                      <Typography variant="body1" sx={{ color: 'text.secondary', fontFamily: 'WantedSans' }}>
                        {feature.blurb}
                      </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
          </Section>

            {/* <Box style={{width:"90%"}}>
              <UseCases />
            </Box> */}

            <br></br>
            <Button variant="contained" onClick={() =>
              {navigate("/app")
              window.scrollTo({
                top: 0,
                behavior: 'auto' // Optional: Smooth scrolling animation
              });
              }} sx={{textTransform: 'none', fontWeight: 'bold',borderRadius: '15px' }}>Check out our tools!</Button>
              </Box>
      
      </Box>
      </main>
        
      {/* Footer */}
      {/* <Box sx={{ bgcolor: '#f5f5f5', p: 3,}} component="footer">
          <Stack
                sx={{ pt: 1, width:"100%" }}
                direction="row"
                justifyContent="center"
              >
          <AminoAcidAnimation />   
          </Stack> 
          <Typography variant="h6" align="center" gutterBottom
          style={{
            fontSize: '3vh',
            fontWeight: 'normal',
            fontFamily: 'LTHoop'
          }}
          > Computational drug discovery, simplified. </Typography>
          <Copyright />
      </Box> */}
      <Footer/>

    </ThemeProvider>
  );
}