import {useState} from 'react';
import Navigation from "./Navigation";
import { Stack, Grid, Button, Typography, TextField } from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'
export const CombFold = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [exceed, setExceed] = useState(false);

	const [data, setData] = useState([{ sequence: '', units: '' }]);

	const handleAddField = () => {
		const newData = [...data, { sequence: '', units: '' }];
		setData(newData);
	  };
	
	  const handleRemoveField = (index) => {
		const newData = [...data];
		newData.splice(index, 1);
		setData(newData);
	  };
	
	  const handleChange = (index, field, value) => {
		const newData = [...data];
		newData[index][field] = value;
		setData(newData);
	  };

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay, cost_) => {
		const configStr = `name: ${jobName}, data: ${JSON.stringify(data)}`
		const cost = pay ? cost_ : 0
		addJobToQueue(jobName, cost, user, configStr, 'combfold');
		navigate("/app/results")
	}

	return (
	<>
		<Stack spacing={2} style={{padding: '10px' }}>
			<Header type="combfold"/>
			<Typography variant='body'>The CombFold pipeline predicts the structure of large protein complexes starting from the sequences of chains in the complex (up to at least 18,000 amino acids and 32 subunits). The pipeline uses AlphaFold-Multimer to predict structures of "possible subcomplexes" which are combinations of subunits from the target complex. </Typography>
            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

			
			<div>
			<Button variant="contained" color="primary" style={{marginBottom:20}} onClick={handleAddField}>+</Button>
			{data.map((item, index) => (
				<Grid container spacing={2} alignItems="center" key={index} style={{marginBottom:20}}>
				<Grid item xs={8}>
					<TextField
					label={`Sequence`}
					value={item.sequence}
					onChange={(event) => handleChange(index, 'sequence', event.target.value)}
					fullWidth
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
					label={`# Subunits`}
					value={item.units}
					onChange={(event) => handleChange(index, 'units', event.target.value)}
					fullWidth
					/>
				</Grid>
				<Grid item xs={2}>
					<Button variant="contained" style={{ backgroundColor: '#255a15' }}onClick={() => handleRemoveField(index)}>-</Button>
				</Grid>
				</Grid>
			))}
			</div>

            <SubmitButton redir="combfold" duplicate={duplicateJob} exceed={exceed} onSubmit={submit} seqLength={[data.reduce((acc, item) => acc + item.sequence.length * parseInt(item.units), 0)]}>Submit</SubmitButton>
		</Stack>
	</>
	)
}
