import Navigation from "./Navigation";
import { Stack, Button, Link, Accordion, Switch, Tooltip } from "@mui/material";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
import {useState} from 'react';
import { addJobToQueue, OTHER_JOB_BASE  } from "../utils";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router";
import {NameField} from './NameField';
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'
const Freeda = () => {	
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
	const [geneName, setGeneName] = useState('MX1');
	const [species, setSpecies] = useState('Human');
	const [codonFreq, setCodonFreq] = useState('F3X4');  
	const [exceed, setExceed] = useState(false);

    const [first, setFirst] = useState(""); 
    const [second, setSecond] = useState("");
    const [third, setThird] = useState("");  

    const [startOne, setStartOne] = useState('');
    const [endOne, setEndOne] = useState('');
  
    const [startTwo, setStartTwo] = useState('');
    const [endTwo, setEndTwo] = useState('');
  
    const [startThree, setStartThree] = useState('');
    const [endThree, setEndThree] = useState('');
  
    const [exclude, setExclude] = useState('')

    const [expected, setExpected] = useState('None');
    const [subgroup, setSubgroup] = useState('None')
    const [commonDomain, setCommonDomain] = useState('No'); 
    //labelling check 
    const [checked, setChecked] = useState(false);

    const { isLoaded, isSignedIn, user } = useUser();  
    const navigate = useNavigate();

    const buildConfig = () => {
        let codonFreqMap = new Map(
            [
                ['F3X4','1'],
                ['F61', '2'],
                ['F3X4 and F61', '3']
            ]
        );

        const isAdvanced = expected !== "None" || commonDomain !== "No" || checked;
        let config = {species: species, subgroup:subgroup,  exclude:exclude, codonFreq:codonFreqMap.get(codonFreq), gene:geneName, isAdvanced:isAdvanced ? 'yes' : 'no' }

        if (isAdvanced) {
            config["expectation"] = expected
            config["commonDomain"] = commonDomain
            config["first"] = first
            config["startOne"] = startOne
            config["endOne"] = endOne
            config["second"] = second
            config["startTwo"] = startTwo
            config["endTwo"] = endTwo
            config["third"] = third
            config["startThree"] = startThree
            config["endThree"] = endThree
        }

        return config;
    }

    const submit = (pay) => {
        if (!geneName.match(/^[A-Z]{1}([A-Za-z0-9]+$)/)) {
            //Swal.fire(`Gene name "${geneName}" contains invalid characters, please re-write starting with one capital letter followed only by letters or numbers`);
            alert(`Gene name ${geneName} contains invalid characters, please re-write starting with one capital letter followed only by letters or numbers`);
			return false;
        }

        if (!first.match(/^[A-Za-z0-9]+$/) && first) {
            alert(`First region label "${first}" contains invalid characters, please re-write only using letters and numbers`);
			return false;
        }

        if (!second.match(/^[A-Za-z0-9]+$/) && second) {
            alert(`Second region label "${second}" contains invalid characters, please re-write only using letters and numbers`);
			return false;
        }

        if (!third.match(/^[A-Za-z0-9]+$/) && third) {
            alert(`Third region label "${third}" contains invalid characters, please re-write only using letters and numbers`);
			return false;
        }

        const config = buildConfig();
        const cost = pay ? OTHER_JOB_BASE : 0
        addJobToQueue(jobName, cost, user, JSON.stringify(config), 'freeda');
        navigate('/app/results');
    }

    return (
    <>
        <Stack spacing={2} style={{padding: '10px' }}>
            <Header type="freeda"/>
            <Typography variant='body'>A computational pipeline to guide experimental testing of protein innovation by detecting positive selection.</Typography>
            <Typography variant='body'>For more information on FREEDA, see <Link href="https://ddudka9.github.io/freeda/docs/html/index.html">here</Link> for the authors' documentation.</Typography>
            <Typography variant='body'>Input your gene name and track the status of your analysis in the "My Results" tab of the main page.</Typography>
            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
            <Typography variant='body'>(Consider using <Link href="https://useast.ensembl.org/index.html" target="_blank" rel="noopener noreferrer">ensembl</Link> to find your gene name.)</Typography>
            
            {species === "Fly" ? <Typography>For flies please input a FlyBase ID instead of a gene name</Typography> : null}
            <Tooltip title='Please make sure that your gene name fits the ensembl version for your species. For example, the same gene may be Mouse: Cenpa (Always only first letter capital), Human: CENPA (Always all letters capital)
'>
                <TextField value={geneName} label='Gene Name' onChange={(e) => setGeneName(e.target.value)}/>
            </Tooltip>
            <Autocomplete
                disablePortal
                options={['Human', 'Mouse', 'Dog', 'Chicken', 'Fly']}
                sx={{width: 300 }}
                renderInput={(params) => <TextField {...params} label="Species" />}
                onChange={(e, value) => setSpecies(value)}
                defaultValue="Human"
                value={species}
            />

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Advanced Settings (optional) </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2} style={{padding: '10px' }}>


            <Autocomplete
                disablePortal
                options={['F3X4', 'F61', 'F3X4 and F61']}
                sx={{width: 300 }}
                renderInput={(params) => <TextField {...params} label="Codon Frequency" />}
                onChange={(e, value) => setCodonFreq(value)}
                defaultValue="F3X4"
                value={codonFreq}
            />

            <Autocomplete
                disablePortal
                options={['None', 'hominoidea', 'catarrhini', 'caniformia', 'melanogaster']}
                sx={{width: 300 }}
                renderInput={(params) => <TextField {...params} label="Subgroup" />}
                onChange={(e, value) => setSubgroup(value)}
                value={subgroup}
                />

                <TextField value={exclude} onChange={e => setExclude(e.target.value)} label='Species to exclude (e.g. Ha Gd)'/>


                    <Autocomplete
                    disablePortal
                    options={['Duplication expected', 'Tandem duplication expected', 'Long introns expected (>50kb)']}
                    sx={{width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Expectation" />}
                    onChange={(e, value) => setExpected(value)}
                    value={expected}
                    />
                    
                    <Autocomplete
                    disablePortal
                    options={['No', 'Yes']}
                    sx={{width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Do you expect common domains?" />}
                    onChange={(e, value) => setCommonDomain(value)}
                    value={commonDomain}
                    />

                    <Typography>Label up to 3 regions in gene {geneName}?</Typography>
                    <Switch
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {checked ? 
                    <>
                        <Typography>(Leave empty if you want to label less than 3 or 2)</Typography>

                        <div>
                        <TextField label='Label for first region' onChange={e => setFirst(e.target.value)}></TextField>
                        <TextField
                        value={startOne}
                        label='First residue number'
                        onChange={(e) => setStartOne(e.target.value)}
                        type='number'
                        />
                        <TextField
                        value={endOne}
                        label='Last residue number'
                        onChange={(e) => setEndOne(e.target.value)}
                        type='number'
                        />
                        </div>

                        <div>
                        <TextField label='Label for second region' onChange={e => setSecond(e.target.value)}></TextField>
                        <TextField
                        value={startTwo}
                        label='First residue number'
                        onChange={(e) => setStartTwo(e.target.value)}
                        type='number'
                        />
                        <TextField
                        value={endTwo}
                        label='Last residue number'
                        onChange={(e) => setEndTwo(e.target.value)}
                        type='number'
                        />
                        </div>

                        <div>
                        <TextField label='Label for third region' onChange={e => setThird(e.target.value)}></TextField>
                        <TextField
                        value={startThree}
                        label='First residue number'
                        onChange={(e) => setStartThree(e.target.value)}
                        type='number'
                        />
                        <TextField
                        value={endThree}
                        label='Last residue number'
                        onChange={(e) => setEndThree(e.target.value)}
                        type='number'
                        />
                        </div>
                    </>
                    : null}

                </Stack> 

                </AccordionDetails>
            </Accordion>


            <SubmitButton redir="freeda" duplicate={duplicateJob} exceed={exceed} onSubmit={submit} toolName="freeda">Submit</SubmitButton>
        </Stack>
    </>
)
}

export default Freeda;
