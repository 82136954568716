import { Stack, Slider, Grid, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile} from './UIComponents'
import { 
        Checkbox, 
        FormGroup, 
        FormControlLabel, 
        Button, 
        Box 
      } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ZymCTRL = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [prompt, setPrompt] = useState("");
        const [numSequences, setNumSequences] = useState(100);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
        const [fineTune, setFineTune] = useState();
        const [selectedCategories, setSelectedCategories] = useState([]);

        const submit = (pay) => {
                /*
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                */
               
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const config = {fastaFile: filePaths.join(","), numSequences:numSequences, selectedCategories: selectedCategories.map(cat => cat.split(" ")[0])};
                const cost = pay ? OTHER_JOB_BASE : 0;
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "zymctrl");
                navigate("/app/results");
        }


        const ecTree = {
                "1 Oxidoreductases": [
                  "1.1 Acting on the CH-OH group of donors",
                  "1.2 Acting on the aldehyde or oxo group of donors",
                  "1.3 Acting on the CH-CH group of donors",
                  "1.4 Acting on the CH-NH2 group of donors",
                  "1.5 Acting on the CH-NH group of donors",
                  "1.6 Acting on NADH or NADPH",
                  "1.7 Acting on other nitrogenous compounds as donors",
                  "1.8 Acting on a sulfur group of donors",
                  "1.9 Acting on a heme group of donors",
                  "1.10 Acting on diphenols and related substances as donors",
                  "1.11 Acting on a peroxide as acceptor",
                  "1.12 Acting on hydrogen as donor",
                  "1.13 Acting on single donors with incorporation of molecular oxygen (oxygenases)",
                  "1.14 Acting on paired donors, with incorporation or reduction of molecular oxygen",
                  "1.15 Acting on superoxide as acceptor",
                  "1.16 Oxidizing metal ions",
                  "1.17 Acting on CH or CH2 groups",
                  "1.18 Acting on iron-sulfur proteins as donors",
                  "1.19 Acting on reduced flavodoxin as donor",
                  "1.20 Acting on phosphorus or arsenic in donors",
                  "1.21 Catalysing the reaction X-H + Y-H = X-Y",
                  "1.22 Acting on halogen in donors",
                  "1.23 Reducing C-O-C group as acceptor",
                  "1.97 Other oxidoreductases",
                  "1.98 Enzymes using H2 as reductant (deleted subclass)",
                  "1.99 Other enzymes using O2 as oxidant (deleted subclass)"
                ],
                "2 Transferases": [
                  "2.1 Transferring one-carbon groups",
                  "2.2 Transferring aldehyde or ketonic groups",
                  "2.3 Acyltransferases",
                  "2.4 Glycosyltransferases",
                  "2.5 Transferring alkyl or aryl groups, other than methyl groups",
                  "2.6 Transferring nitrogenous groups",
                  "2.7 Transferring phosphorus-containing groups",
                  "2.8 Transferring sulfur-containing groups",
                  "2.9 Transferring selenium-containing groups",
                  "2.10 Transferring molybdenum- or tungsten-containing groups"
                ],
                "3 Hydrolases": [
                  "3.1 Acting on ester bonds",
                  "3.2 Glycosylases",
                  "3.3 Acting on ether bonds",
                  "3.4 Acting on peptide bonds (peptidases)",
                  "3.5 Acting on carbon-nitrogen bonds, other than peptide bonds",
                  "3.6 Acting on acid anhydrides",
                  "3.7 Acting on carbon-carbon bonds",
                  "3.8 Acting on halide bonds",
                  "3.9 Acting on phosphorus-nitrogen bonds",
                  "3.10 Acting on sulfur-nitrogen bonds",
                  "3.11 Acting on carbon-phosphorus bonds",
                  "3.12 Acting on sulfur-sulfur bonds",
                  "3.13 Acting on carbon-sulfur bonds"
                ],
                "4 Lyases": [
                  "4.1 Carbon-carbon lyases",
                  "4.2 Carbon-oxygen lyases",
                  "4.3 Carbon-nitrogen lyases",
                  "4.4 Carbon-sulfur lyases",
                  "4.5 Carbon-halide lyases",
                  "4.6 Phosphorus-oxygen lyases",
                  "4.7 carbon-phosphorus lyases",
                  "4.8 Nitrogen-oxygen lyases",
                  "4.98 ATP-independent chelatases",
                  "4.99 Other lyases"
                ],
                "5 Isomerases": [
                  "5.1 Racemases and epimerases",
                  "5.2 cis-trans-Isomerases",
                  "5.3 Intramolecular oxidoreductases",
                  "5.4 Intramolecular transferases",
                  "5.5 Intramolecular lyases",
                  "5.6 Isomerases altering macromolecular conformation",
                  "5.99 Other isomerases"
                ],
                "6 Ligases": [
                  "6.1 Forming carbon-oxygen bonds",
                  "6.2 Forming carbon-sulfur bonds",
                  "6.3 Forming carbon-nitrogen bonds",
                  "6.4 Forming carbon-carbon bonds",
                  "6.5 Forming phosphoric-ester bonds",
                  "6.6 Forming nitrogen—metal bonds",
                  "6.7 Forming nitrogen-nitrogen bonds"
                ],
                "7 Translocases": [
                  "7.1 Catalysing the translocation of hydrons",
                  "7.2 Catalysing the translocation of inorganic cations",
                  "7.3 Catalysing the translocation of inorganic anions and their chelates",
                  "7.4 Catalysing the translocation of amino acids and peptides",
                  "7.5 Catalysing the translocation of carbohydrates and their derivatives",
                  "7.6 Catalysing the translocation of other compounds"
                ]
              };

        const handleCategoryChange = (category) => {
                setSelectedCategories(prev => 
                  prev.includes(category)
                    ? prev.filter(cat => cat !== category)
                    : [...prev, category]
                );
              };
            
              const handleSave = () => {
                console.log("Selected categories:", selectedCategories);
              };  
        
        let disableReasons = []
        if (files.length == 0) {
                disableReasons.push("No fasta file uploaded")
        }

        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                
                {console.log(selectedCategories)}

                <Header type="zymctrl"/>
                <Typography variant='body'> </Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                <Accordion>
                        <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        >
                        <Typography>Pick Enzyme Commission annotations for your enzyme (Mandatory)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        {Object.entries(ecTree).map(([mainCategory, subCategories]) => (
                                        <Box key={mainCategory} sx={{ marginBottom: 2 }}>
                                        <FormControlLabel
                                        control={
                                                <Checkbox
                                                checked={selectedCategories.includes(mainCategory)}
                                                onChange={() => handleCategoryChange(mainCategory)}
                                                />
                                        }
                                        label={<Typography>{mainCategory}</Typography>}
                                        />
                                        <Box sx={{ marginLeft: 3 }}>
                                        {subCategories.map(subCategory => (
                                                <FormControlLabel
                                                key={subCategory}
                                                control={
                                                <Checkbox
                                                checked={selectedCategories.includes(subCategory)}
                                                onChange={() => handleCategoryChange(subCategory)}
                                                />
                                                }
                                                label={subCategory}
                                                />
                                        ))}
                                        </Box>
                                        </Box>
                                        ))}
                        </AccordionDetails>
                </Accordion>


                <Typography>Fasta File to Fine-tune model on (Optional):</Typography>
                <Typography variant='body'>If you have a set of sequences to steer the sequence generation process, upload it below.</Typography>
                <Typography variant='body'>The authors find that this process works best for 200 sequences and above, but smaller samples have been shown to be useful as well.</Typography>
                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['fasta', 'fa']}/>
                <TextField 
                        type="number"
                        label="# of Sequences to generate"              
                        value={numSequences}
                        onChange={(e) => setNumSequences(e.target.value)}
                        error={numSequences > 1000}
                        helperText={numSequences > 1000 ? 'Max # sequences is 1000' : ''}
                />

                <SubmitButton redir="zymctrl" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
