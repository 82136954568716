import { useUser } from "@clerk/clerk-react";
import Navigation from "./Navigation";
import { Button, Chip, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import {useState} from 'react';
import { NameField } from "./NameField";
import { addJobToQueue, OTHER_JOB_BASE } from "../utils";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom/dist";
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

export const RoseTTAFold2NA = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [proteinSequence, setProteinSequence] = useState('TRPNHTIYINNLNEKIKKDELKKSLHAIFSRFGQILDILVSRSLKMRGQAFVIFKEVSSATNALRSMQGFPFYDKPMRIQYAKTDSDIIAKM');
	const [DNASequence, setDNASequence] = useState('GGGCTCTGATCCTTTCTCCTTTTTATCTCGCTACAGGCACATGCATCACCTGGGAAATGGGGAGACATACAGTGCTCTAGGCCTAGAGATGGAGAACTGCGAGGGCTACAACAACTTTGGGGCCGCCGGAACCGGTGGTGGGTACTACGAGGCGGGTCAGCAACCTCCTATTCCGGCCACCCACGGCGATGGACACCATCCCCACCACGTGCAGGTGCCGGCTCAGGCGCATGCCCACATCCACGCCCACCACAATTCTGCCGCGATTCCCGGAGGAGTGGGAGTTGGACCGCCCCCCTCGCATATCCATGGATTCGCCATCAATGGGGGACCGGCTGTTCAGGGCCAGGCATTCGGAAACAACGGAAGCACTGCAGCTGGGACCGCTGCGATCAGTGGTCTGGAGAACTCCAATAGCTCGGACTTCAACTTTCTAGCAACTTGGCCAACGACTTTGCGCCGGAATACTACCAACTCAGTTAGTTCATGTCGGAAGTACGCCAGAGGCCAGTGTAAATATTTGTTAGCCTAATTCGCCGCGTGGGCGTGAGTCTTGTATTTCTATTCAGTGTCGTAGCGTCGTAAGCACATTGTCCTTAGCACATATCTGTACATAATAAGCTATTCCTCTTTTTAGCCTAATCAAATCGAAACTGTTATGATTTTCTATTGGCTTCAAGTCAGATGATGCTCTATCTTAAAATTCCAATTAGAACTACAACAGACAACAAAATTGTAAATAGATAGAAAAATTAAACGACTTTCGCACAAGTCCACATCAATGATTATAAATTACTGAATTATCTTTGATAAACTCGGTTTTTGTATATATCTGAAGTAGCTTAAATCAAAAACGTAAACAAACTGTCAATAAATCTTATGTAAAAAAATAAATAAAAGTTTGGATCGCTCTGGATCCAAGTGCAATTTCTCAATGAAAAAAATTTAATTTAATATCTTAAGGCACTCTCAGTTATTTTGATGTTTGTCATGTTCGTATGTTGATCTAAATTTTTCCATAATAGTAAACAAATATATATTATAAATATGACTTAAAATAATGGAATAGATATCTTTGTAAGGAAAAAGAATAAAAAATAAGTAAGACAAAAAACTTTCACAATAAAAAAATCTTACAGAATACTAAAGACGATTTGGGGTTGTATGACGGTTGACATTAACTTAAGTTGCTAACATTTTCTTGGCTACAGTCGGTGCTCAAAAATGTATTTTTTTTTTTTTTTTTTTTTTTTTTTTGAGAAAATGGCATTGGCAACAATATTACGGTTGGTATTTGCAATCTAATTTGCACTTCAAGGAAATCAACAAGCCACCAACCACAACCGAGATAAGAGCACCGAGTAGGCGTTACTAAAATCTATTATAATTGCCCGACTCGAATTC');
	const [RNASequence, setRNASequence] = useState('GAGAGAGAAGTCAACCAGAGAAACACACCAACCCATTGCACTCCGGGTTGGTGGTATATTACCTGGTACGGGGGAAACTTCGTGGTGGCCGGCCACCTGACA');
	const [proteinRNAComplex, setProteinRNAComplex] = useState({protein: 'TRPNHTIYINNLNEKIKKDELKKSLHAIFSRFGQILDILVSRSLKMRGQAFVIFKEVSSATNALRSMQGFPFYDKPMRIQYAKTDSDIIAKM', rna: 'GAGAGAGAAGTCAACCAGAGAAACACACCAACCCATTGCACTCCGGGTTGGTGGTATATTACCTGGTACGGGGGAAACTTCGTGGTGGCCGGCCACCTGACA'})
	const [type, setType] = useState('RNA');
	const [proteinType, setProteinType] = useState('protein')
	const [exceed, setExceed] = useState(false);

	const { isLoaded, isSignedIn, user } = useUser();  
	const navigate = useNavigate()

	const submit = (pay, cost_) => {
		const config = {}
		if (proteinType == "protein") {
			if (!proteinSequence) {
				Swal.fire("Please make sure to input a protein sequence");
				return;
			}

			config["proteinSequence"] = proteinSequence.replace(" ", "");
		}
		// else if (proteinType == "protein-rna") {
		// 	if (!proteinRNAComplex['protein'] || !proteinRNAComplex['rna']) {
		// 		Swal.fire("Please make sure to input a protein/rna complex sequence");
		// 		return;
		// 	}

		// 	config["protein-rna"] = JSON.stringify(proteinRNAComplex);
		// }

		if (type === "Double DNA" ) {
			if (!DNASequence) {
				Swal.fire("Please make sure to input a DNA sequence");
				return;
			}

			config["doubleDnaSequence"] = DNASequence.replace(" ", "");
		}
		else if (type === "Single DNA") {
			if (!DNASequence) {
				Swal.fire("Please make sure to input a DNA sequence");
				return;
			}

			config["singleDnaSequence"] = DNASequence.replace(" ", "");
		}
		else if (type === "RNA") {
			if (!RNASequence) {
				Swal.fire("Please make sure to input an RNA sequence");
				return;
			}

			config["rnaSequence"] = RNASequence.replace(" ", "");
		}

		const cost = pay ? cost_ : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), 'rosettafold2na', '');
		navigate('/app/results');
	}

	let disableReasons = []
	if (proteinSequence == "") {
		disableReasons.push("Empty protein sequence")
	}

	return (
		<>
			<Stack spacing={2} style={{paddingLeft: '20px',paddingRight: '20px' }}>

			<Header type="rosettafold2na"/>

				<ul>
					<li>
				<Typography >Predict complex structure given protein and nucleic acid sequences</Typography></li>
				<li>
				<Typography >For protein complexes / multiple nucleic acids, separate each chain with a colon (:) to indicate a chain break (ex. ATGCCA:ATTCG) </Typography></li>
				</ul>

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

				{/* <ToggleButtonGroup color="primary" value={proteinType} exclusive onChange={(e, newType) => newType ? setProteinType(newType) : null}> 
					<ToggleButton value='protein'>Protein</ToggleButton>
					<ToggleButton value='protein-rna'>Protein/RNA Complex</ToggleButton>
				</ToggleButtonGroup> */}

				{/* <Typography>For protein complexes, insert your chains with a ":" in between, where the ":" indicates a chain break. Such as TRPNHTIYINNLNEKIKKDELKKSLHAIFSRFGQILDILVSRSL<b>:</b>SATNALRSMQGFPFYDKPMRIQYAKTDSDIIAKM</Typography> */}
				<>
					<TextField multiline
					minRows={2}
					maxRows={10} value={proteinSequence} label='Protein Sequence' onChange={(e) => setProteinSequence(e.target.value)}/> 
				</>
				<br></br>
				<ToggleButtonGroup color="primary" value={type} exclusive onChange={(e, newType) => newType ? setType(newType) : null}> 
					<ToggleButton sx={{textTransform:"none"}} value='RNA'>RNA</ToggleButton>
					<ToggleButton sx={{textTransform:"none"}} value='Double DNA'>Double Stranded DNA</ToggleButton>
					<ToggleButton sx={{textTransform:"none"}} value='Single DNA'>Single Stranded DNA</ToggleButton>
				</ToggleButtonGroup>

				{
					type === "RNA" ? <TextField multiline
					minRows={2}
					maxRows={10}  value={RNASequence} label='RNA Sequence' onChange={(e) => setRNASequence(e.target.value)}/>
					: type == "Double DNA" ? <TextField multiline
					minRows={2}
					maxRows={10}  value={DNASequence} label='Double Stranded DNA Sequence' onChange={(e) => setDNASequence(e.target.value)}/> 
					: <TextField multiline
					minRows={2}
					maxRows={10}  value={DNASequence} label='Single Stranded Sequence' onChange={(e) => setDNASequence(e.target.value)}/> 
				}

			<SubmitButton redir="rosettafold2na" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit} seqLength={[proteinSequence.length]}>Submit</SubmitButton>

			</Stack>
		</>
	)
}
