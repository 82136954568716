import { Grid, Typography } from "@mui/material";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {useState, useEffect, useRef} from 'react';
import {uploadFile, detectFileTypeGenerator} from '../../utils';

import * as $3Dmol from '3dmol';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { NameField } from "../NameField";
import { submitJobs } from "./batchUtils";
import {SubmitButton} from '../SubmitButton';

registerPlugin(FilePondPluginFileEncode);

const MoleculeVisualizer = ({ receptorFile, boxX, boxY, boxZ, width, height, depth }) => {
  const visualizerRef = useRef();
  const [viewer, setViewer] = useState(null);
  const [receptorData, setReceptorData] = useState('')

  /*
  function removeNonStandardResidues(pdbString) {
        const standardAminoAcids = [
            'ALA', 'ARG', 'ASN', 'ASP', 'CYS',
            'GLN', 'GLU', 'GLY', 'HIS', 'ILE',
            'LEU', 'LYS', 'MET', 'PHE', 'PRO',
            'SER', 'THR', 'TRP', 'TYR', 'VAL'
        ];
    
        return pdbString.split('\n').filter(line => {
            // Check if the line starts with ATOM or HETATM
            if (line.startsWith('ATOM') || line.startsWith('HETATM')) {
                // Extract the residue name (columns 18-20 in PDB format)
                const residueName = line.substring(17, 20).trim();
                // Keep the line if the residue is standard
                return standardAminoAcids.includes(residueName);
            }
            // Keep all other lines
            return true;
        }).join('\n');
    }
    */

  const extractFileContent = async (file) => {
        const read = new FileReader();
        read.readAsBinaryString(file);

        read.onloadend = async function () {
                setReceptorData(read.result);
        };
   }

  useEffect(() => {
    // Initialize the 3D viewer only once
    if (visualizerRef.current && !viewer) {
      const config = { backgroundColor: 'white' };
      const newViewer = $3Dmol.createViewer(visualizerRef.current, config);
      setViewer(newViewer);
    }
  }, [viewer]);

  useEffect(() => {
    extractFileContent(receptorFile);
  }, [receptorFile]);

  useEffect(() => {
    if (viewer && receptorData) {
      viewer.clear(); // Clear existing models
      viewer.addModel(receptorData, 'pdb'); // Assuming PDB format
      viewer.setStyle({}, { cartoon: {} });

      viewer.addBox({center:{x: Number(boxX), y: Number(boxY), z: Number(boxZ)},
        dimensions: {w: Number(width), h: Number(height), d:Number(depth)},
        color:'magenta', 
        "opacity": 0.8});

      viewer.zoomTo();
      viewer.render();
    }
  }, [viewer, receptorData, boxX, boxY, boxZ, width, height, depth]);

  return (
    <div style={{ width: '400px', height: '400px', position: 'relative' }} ref={visualizerRef}>
    </div>
  );
};

export const VinaBatch = ({ hideNavigation }) => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [receptor, setReceptor] = useState('');
	const [ligand, setLigand] = useState('');
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [exceed, setExceed] = useState(false);

    const [boxX, setBoxX] = useState('35');
	const [boxY, setBoxY] = useState('27');
	const [boxZ, setBoxZ] = useState('35');
    const [width, setWidth] = useState('20');
    const [height, setHeight] = useState('20');
    const [depth, setDepth] = useState('20');

    const navigate = useNavigate();
    const {user} = useUser(); 
    const email = user.emailAddresses[0].emailAddress;

    const getReceptorFiles = async (files) => {
            setReceptor(files);
    }

	const returnS3PathsAndUploadFiles = (file) => {
		let email = user.emailAddresses[0].emailAddress
		uploadFile(file, user, () => null);

		return email + "/" + file.name;
	}
    
    const receptorUploaded = () => {
            return receptor[0] && receptor[0].file.name.includes(".pdb")
    }

        return (
                <>
                <Stack spacing={2} style={{padding: '0px' }}>
                        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName} numJobs={Math.max(receptor.length, 1) * Math.max(ligand.length, 1)}></NameField>
                        <Typography>Receptor Preparation reminders:</Typography>
                        <Typography>Remove all chains from the file other than the one where the docking will occur, this can be done in software such as ChimeraX or PyMol.</Typography>
                        <Typography>By default we remove all non-standard residues.</Typography>
                        
                        <FilePond
                        files={receptor}
                        allowReorder={true}
                        allowMultiple={false}
                        onupdatefiles={getReceptorFiles}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> pdb file for your Receptor'
                        credits={[]}
                        acceptedFileTypes={["chemical/x-pdb"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
                        />
                        
                        <FilePond
                        files={ligand}
                        allowReorder={true}
                        allowMultiple={true}
                        onupdatefiles={setLigand}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> mol2 or sdf files for your Ligand(s)'
                        credits={[]}
                        acceptedFileTypes={["chemical/x-mol2", "sdf"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".mol2":"chemical/x-mol2", ".sdf":"sdf"})}
                        />

                        <Grid>
                        { receptorUploaded() && 
                        <Stack alignItems="center">
                                <Typography variant='h6'>Set Docking Box</Typography>
                                <MoleculeVisualizer receptorFile={receptor[0].file} boxX={boxX} boxY={boxY} boxZ={boxZ} width={width} height={height} depth={depth} /> 
                        </Stack>
                        }

                        { receptorUploaded() && 
                                <Stack alignItems="center" spacing={1}>
                                        <Grid>
                                                <TextField value={boxX} label='Box Center X' onChange={(e) => setBoxX(e.target.value)} type='number'/>
                                                <TextField value={boxY} label='Box Center Y' onChange={(e) => setBoxY(e.target.value)} type='number'/>
                                                <TextField value={boxZ} label='Box Center Z' onChange={(e) => setBoxZ(e.target.value)} type='number'/>
                                        </Grid>

                                        <Grid>
                                                <TextField value={width} label='Box Width' onChange={(e) => setWidth(e.target.value)} type='number'/>
                                                <TextField value={height} label='Box Height' onChange={(e) => setHeight(e.target.value)} type='number'/>
                                                <TextField value={depth} label='Box Depth' onChange={(e) => setDepth(e.target.value)} type='number'/>
                                        </Grid>
                                </Stack>
                        }

                        </Grid>

                        <SubmitButton numJobs={Math.max(receptor.length, 1) * Math.max(ligand.length, 1)} duplicate={duplicateJob} exceed={exceed} onSubmit={(pay) => {submitJobs(receptor, ligand, user, {jobName:jobName, boxX: boxX, boxY: boxY, boxZ:boxZ, width: width, height: height, depth: depth}, pay); navigate('/app/results')}}>Submit</SubmitButton>

                </Stack>
                </>
        );
}