import React, {useEffect} from 'react';
import { Card, CardContent, Typography, Grid, Box, Container, Button, CardActions, Stack } from '@mui/material';
import { Route, Routes } from 'react-router';
import Navigation from '../Navigation';

const blogPosts = [
  {
    title: 'State of the Art Motif Scaffolding with RFdiffusion',
    date: 'February 4, 2024',
    content: 'Given a target motif whose structure provides a function, build scaffolds to support it. This is especially useful for scaffolding the active site of an enzyme for example.',
    link: 'https://tamarindbio.notion.site/State-of-the-Art-Motif-Scaffolding-with-RFdiffusion-d64c349828934991b38f90e5b300f31a?pvs=4'
    },
  {
    title: 'Diversify a Protein Structure with RFdiffusion (Partial diffusion)',
    date: 'February 4, 2024',
    content: 'Given an input structure, generate variants to it to produce more diverse folds.',
    link: 'https://tamarindbio.notion.site/Diversify-a-Protein-Structure-with-RFdiffusion-Partial-diffusion-79d19252708c4a43b4b42c5aa48f476d?pvs=4'  
  },  
  {
    title: "LigandMPNN: Nucleotide, Small Molecule, and Metal Binding Protein Sequence Design",
    date: "February 6, 2024",
    content: 'State of the art protein sequence design for contexts including nucleotide, small molecule and metal-protein interactions.',
    link: '/ligand-mpnn-article'
  },
  {
    title: "RoseTTAFold2NA: Protein-Nucleic Acid Complex Structure Prediction",
    date: "February 6, 2024",
    content: 'RoseTTAFold2NA applies new approaches in protein structure prediction to the task of predicting protein-DNA and protein-RNA complexes.',
    link: '/rosettafold2na-protein-nucleic-acid-complex-structure-prediction'
  }
];

const SubstackFeedEmbed = ({ url = "tamarindbio.substack.com", posts = 12, filter = "top" }) => {
  useEffect(() => {
    // Set up Substack Feed Widget configuration on the window object
    window.SubstackFeedWidget = {
      substackUrl: url,
      posts,
      filter
    };

    // Function to load the Substack script
    const loadScript = () => {
      const script = document.createElement('script');
      script.src = "https://substackapi.com/embeds/feed.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        // Cleanup the script when the component unmounts
        document.body.removeChild(script);
      };
    };

    // Load the script
    const cleanupFunc = loadScript();

    // Cleanup function to remove the script
    return cleanupFunc;
  }, [url, posts, filter]);  // Dependencies array to reload the script if these props change

  return <div id="substack-feed-embed"></div>;
}

export const BlogComponent = () => {
  return (
    <>
    <Box sx={{top: 0, left: 0, width: '100vw', backgroundColor:"#f5f5f5"}}>
      <Navigation />
    </Box>

    <Stack>
    <SubstackFeedEmbed /> 
    </Stack>    
    </>
  );
};