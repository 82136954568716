import axios from 'axios'
import { useState, useEffect, useRef} from 'react';
import * as $3Dmol from '3dmol/build/3Dmol.js';

export const PolymerViewer = ({job, email}) => {

    const viewerRef = useRef(null);

    useEffect(
        () => {
          // console.log(job, email)

          if (viewerRef.current) {
              if (job.JobName.includes("insulin")) {

              axios.get('/api/getSignedUrl', {params:{"filename": job.JobName + "/plga.pdb", "email":email, "jobName":job.JobName, "jobEmail":job.Shared == "true" || job.User == email ? job.User : ""}}).then(
                res => {        
                  const link = res.data
                  let config = { backgroundColor: '#E0E0E0' };
                  fetch(link)
                  .then((response) => response.text())
                  .then((plga) => {
                    axios.get('/api/getSignedUrl', {params:{"filename": job.JobName + "/insulin.pdb", "email":email, "jobName":job.JobName, "jobEmail":job.Shared == "true"  || job.User == email ? job.User : ""}}).then(
                      res => {
                        const link = res.data
                        let config = { backgroundColor: '#E0E0E0' };
                        fetch(link)
                        .then((response) => response.text())
                        .then((ligand) => {
                                if (viewerRef.current) {
                                  const viewer = $3Dmol.createViewer(viewerRef.current, config);
                                  // console.log("PLGA:", plga)
                                  viewer.addModel(plga, 'pdb');
                                  viewer.setStyle({model: 0}, {stick: {}});  /* style all atoms */
                                  viewer.addModel(ligand, 'pdb');
                                  viewer.setStyle({model:1}, {cartoon: {color: 'spectrum'}});  /* style all atoms */
                                  viewer.zoomTo();
                                  viewer.render();
                                }
                          })}
                    )
                  })
                  .catch((error) => {
                    console.error('Error fetching PDB file:', error);
                  });
                }
              );
              } else {
              axios.get('/api/getSignedUrl', {params:{"filename": job.JobName + "/plga.pdb", "email":email, "jobName":job.JobName, "jobEmail":job.Shared == "true" || job.User == email ? job.User : ""}}).then(
                res => {        
                  const link = res.data
                  let config = { backgroundColor: '#E0E0E0' };
                  fetch(link)
                  .then((response) => response.text())
                  .then((plga) => {
                    axios.get('/api/getSignedUrl', {params:{"filename": job.JobName + "/ligand_out.pdbqt", "email":email, "jobName":job.JobName, "jobEmail":job.Shared == "true"  || job.User == email ? job.User : ""}}).then(
                      res => {
                        const link = res.data
                        let config = { backgroundColor: '#E0E0E0' };
                        fetch(link)
                        .then((response) => response.text())
                        .then((ligand) => {
                                if (viewerRef.current) {
                                  const viewer = $3Dmol.createViewer(viewerRef.current, config);
                                  // console.log("PLGA:", plga)
                                  viewer.addModel(plga, 'pdb');
                                  viewer.setStyle({model: 0}, {stick: {}});  /* style all atoms */
                                  viewer.addModel(ligand, 'pdb');
                                  viewer.setStyle({model:1}, {stick: {color:"#8B2B00"}});  /* style all atoms */
                                  viewer.zoomTo();
                                  viewer.render();
                                }
                          })}
                    )
                  })
                  .catch((error) => {
                    console.error('Error fetching PDB file:', error);
                  });
                }
              );
              }

          }
        }
      )

      return (
        <div ref={viewerRef} style={{ width: '75%', height: '400px', position: 'relative', margin:"auto" }}></div>
      )
}