import { TextField } from "@mui/material";
import {useState} from 'react';
import { Typography } from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import {SubmitButton} from './SubmitButton';

export const DSResSol = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [seq, setSeq] = useState('AKLAGQKVRIGGWVKTGRQQGKGTFAFLEVNDGSCPANLQVMVDSSLYDLSRLVATGTCVTVDGVLKIPPEGKGLKQSIELSVETVIAVGTVDP');
	const [exceed, setExceed] = useState(false);

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay) => {
		const configStr = `name: ${jobName}, seq: ${seq}`
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, configStr, 'dsressol');
		navigate("/app/results")
	}

	let error = seq.length > 5 && /^[ATUGC]+$/.test(seq)

	return (
	<>
		<Stack spacing={2} style={{padding: '10px' }}>
		<Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind DSResSol Online Tool</Typography>
			<Typography variant='body'>Predict the expression and solubility given a protein sequence using DSResSol's Dilated Squeeze Excitation Residual Networks</Typography>
            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

			<TextField value={seq} label='Seq' onChange={(e) => setSeq(e.target.value)}
				error={error}
				helperText={error ? "This sequence looks like a nucleic acid, but this tool is for proteins only." :''}
				/>

            <SubmitButton redir="dsressol" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
		</Stack>
	</>
	)
}
