import Navigation from "./Navigation";
import { Button, TextField, Typography, Autocomplete, Box, Accordion, AccordionDetails, AccordionSummary,} from "@mui/material";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import {useState } from 'react';
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';

import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { NameField } from "./NameField";
import {SubmitButton} from './SubmitButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode);

export const ThompsonSampling = ({ hideNavigation }) => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [reagentFiles, setReagentFiles] = useState([]);
	const [pdbFiles, setPdbFiles] = useState([]);

        const [duplicateJob, setDuplicateJob] = useState(false);
        const [exceed, setExceed] = useState(false);

        let formParameters = [
        {
                type: 'text',
                description: '',
                variableName: 'reaction_smarts',
                displayName: 'Reaction Smarts',
                defaultValue: '[NH2:2][#6:1].[#6:4][C:3]([OH])=O>>[NH:2]([#6:1])[C:3]([#6:4])=O'
        },
        {
                type: 'text',
                description: '',
                variableName: 'num_warmup_trials',
                displayName: 'Num Warmup Trials',
                defaultValue: '3'
        },
        {
                type: 'text',
                description: '',
                variableName: 'num_ts_iterations',
                displayName: 'Thompson Sampling',
                defaultValue: '2000'
        },
        {
                type: 'dropdown',
                description: '',
                variableName: 'evaluator_class_name',
                displayName: 'Evaluator',
                options: ['FPEvaluator', 'AutoDockEvaluator', 'MWEvaluator', 'MLClassifierEvaluator'],
                defaultValue: 'FPEvaluator'
        },
        {
                type: 'dropdown',
                description: '',
                variableName: 'ts_mode',
                displayName: 'Thompson Sampling Mode',
                options: ['maximize', 'minimize'],
                defaultValue: 'maximize'
        }, 
        {
                type: 'text',
                description: '',
                variableName: 'query_smiles',
                displayName: 'Query SMILES',
                defaultValue: 'COC(=O)[C@@H](O)CC(=O)Nc1nncc2ccccc12'
        }
        ];

        const [formValues, setFormValues] = useState(
                formParameters.reduce((acc, param) => ({
                  ...acc,
                  [param.variableName]: param.defaultValue || ''
                }), {})
              );
        
        if (formValues["evaluator_class_name"] != "FPEvaluator") {
                formParameters = formParameters.filter(x => x.variableName != "query_smiles")
        }
        
        const handleChange = (event, value, variableName) => {
        setFormValues({ ...formValues, [variableName]: value });
        };

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  

	const submitFile = (pay) => {

                const uploadReagents = reagentFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const reagentFilePaths = reagentFiles.map(f => "inputs/" + f.file.name);
                let configObj = {
                        "inputFiles": uploadReagents,
                        "reactionSmarts": formValues["reaction_smarts"],
                        "numWarmup": parseInt(formValues["num_warmup_trials"]),
                        "numIterations": parseInt(formValues["num_ts_iterations"]),
                        "evaluatorClass":formValues["evaluator_class_name"],
                        "tsMode":formValues["ts_mode"],
                        "jsonSettings" : {
                                "reagent_file_list": reagentFilePaths,
                                "reaction_smarts": formValues["reaction_smarts"],
                                "num_warmup_trials": formValues["num_warmup_trials"],
                                "num_ts_iterations": formValues["num_ts_iterations"],
                                "evaluator_class_name":formValues["evaluator_class_name"],
                                "evaluator_arg":{},
                                "ts_mode":formValues["ts_mode"],
                                "log_filename":"out/log.txt",
                                "results_filename":"out/results.csv"
                        }
                }
                if (formValues["evaluator_class_name"] == "MLClassifierEvaluator") {
                        configObj["modelFilename"] = "TS/mapk1_modl.pkl"
                        configObj["jsonSettings"]["evaluator_arg"]["model_filename"] = "TS/mapk1_modl.pkl"
                } else if (formValues["evaluator_class_name"] == "FPEvaluator") {
                        configObj["querySmiles"] = formValues["query_smiles"]
                        configObj["jsonSettings"]["evaluator_arg"]["query_smiles"] = formValues["query_smiles"]
                } else if (formValues["evaluator_class_name"] == "AutoDockEvaluator") {
                        const pdbFile = pdbFiles.map(f => returnS3PathsAndUploadFiles(user, f.file))[0];
                        configObj["pdbFile"] = pdbFile
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "thompson-sampling");
                if (user.emailAddresses[0].emailAddress.endsWith("septerna.com") || user.emailAddresses[0].emailAddress == "sherry.tamarind@gmail.com") {
                        startLambdaForType('septerna-thompson-sampling')
                } else {
                        startLambdaForType('thompson-sampling')
                }
		navigate("/app/results");
	}

        return (
                <>
                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="thompson-sampling"/>


                        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                        <FilePond
                        files={reagentFiles}
                        allowReorder={true}
                        allowMultiple={true}
                        onupdatefiles={setReagentFiles}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your smi files for your reagents'
                        credits={[]}
                        acceptedFileTypes={["smi"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".smi":"smi"})}
                        />

                        <Box>
                        {formParameters.map((param, index) => (
                        <Box key={index} marginBottom={2}>
                        <Typography variant="subtitle1">{param.displayName}</Typography>
                        <Typography variant="body2">{param.description}</Typography>
                        {param.type === 'dropdown' ? (
                        <Autocomplete
                        options={param.options}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        onChange={(event, value) => handleChange(event, value, param.variableName)}
                        value={formValues[param.variableName]}
                        sx={{width:"20%", minWidth: '250px'}}
                        defaultValue={param.defaultValue} 
                        />
                        ) : (
                        <TextField
                        variant="outlined"
                        onChange={(event) => handleChange(event, event.target.value, param.variableName)}
                        value={formValues[param.variableName]}
                        defaultValue={param.defaultValue} 
                        sx={{width:param.variableName == "reaction_smarts" || param.variableName == "query_smiles" ? "70%"  :"20%", minWidth: '250px'}}
                        />
                        )}
                        </Box>
                        ))}
                        </Box>

                        {
                                formValues["evaluator_class_name"] == "AutoDockEvaluator" ?
                                <>
                                <Typography><b>Choose a protein to dock your ligand with during evaluation for AutoDockEvaluator:</b> </Typography>
                                <FilePond
                                files={pdbFiles}
                                allowReorder={true}
                                allowMultiple={true}
                                onupdatefiles={setPdbFiles}
                                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb files'
                                credits={[]}
                                acceptedFileTypes={["pdb"]}
                                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"pdb"})}
                                /></>
                                : null
                        }

                        <SubmitButton redir="gromacs" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
                </Stack>
                </>
        );
}