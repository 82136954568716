import {Typography, Select, Box, TextField, Stack, Button, Autocomplete} from "@mui/material"
import React, { useState, useEffect } from 'react';
import CodeBlock from '../components/CodeBlock';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios'
import {
  useNavigate,
} from "react-router-dom"

export default function PipelinesAPIDocs({jobSettings, userInfo, isSignedIn, isLoaded}) {
  
  const [stages, setStages] = useState([]);
  const [options, setOptions] = useState(Object.keys(jobSettings));
  const [autoCompleteValue, setAutoCompleteValue] = useState("");

  let jobOutputs = {
    "alphafold": ["pdb"],
    "rfdiffusion-all-atom": ["pdb"],
    "autodock-vina": [], // can do pdbqt -> sdf in future
    "rfdiffusion": ["pdb"],
    "diffdock": ["sdf"],
    "proteinmpnn":["sequence"],
    "equidock":["pdb"],
    "temstapro": [],
    "netsolp":[],
    "ligandmpnn":["sequence"],
    "smina":[],
    "gnina":[]
  }

  const handleSelect = (event, newValue) => {
    if (newValue) {
      setStages([...stages, newValue]);
      if (!jobOutputs.hasOwnProperty(newValue)) {
        console.log("invalid")
        setOptions([]) // error message
      } else {
        let options = Object.keys(jobSettings).sort().filter((key) => {
          const settings = jobSettings[key];
          const outputTypes = jobOutputs[newValue] || [];
          // console.log(outputTypes)
          console.log(key)
          let allowed = settings.some(setting => setting.hasOwnProperty("type") && outputTypes.includes(setting.type));
          console.log(allowed)
          return allowed
        });
        console.log(options)
        setOptions(options)
        setAutoCompleteValue("");  // Clear the Autocomplete
      }
    }
  };

  let setup = `import requests 
  api_key = "${!isLoaded || (isSignedIn && userInfo == '')? '' : isSignedIn && userInfo.ApiKey != undefined && userInfo.ApiKey != '' ? userInfo.ApiKey : 'insert api key here'}"
  base_url = \"https://tamarind.bio/api/\"`

  let stagesSettingsString = stages.map((stage, idx1) => 
    "{\n\t\t" + (idx1 == 0 ? "\t" : "") +  "\"type\":" + stage + 
    jobSettings[stage].map((endpoint, idx) => (
      "\n\t\t"  + "\"" +  endpoint["name"] + "\"" + ":" + ("type" in endpoint && endpoint["type"] == "list" ? "" : "\"") + ("default" in endpoint ? endpoint["default"] : "") + ("type" in endpoint && endpoint["type"] == "list" ? "" : "\"")
    )) + "\n\t  }"
  )

  let example = `params = {
  "jobName": "myPipeline",
  "stages": [
      {
          "type":"rfdiffusion",
          "contigs":"10-40/A163-181/10-40",
          "pdbFile":"5TPN.pdb"
      },{
          "type":"proteinmpnn",
          "pdbFile":"pipe", # to indicate input will be retrieved from rfdiffusion job
          "numSequences":4,
      },{
          "type":"alphafold",
          "sequence":"pipe"
      }
  ]
}`
  
  
  let submit = setup + "\n\n" + 
  `params = {
    "jobName": "myJobName",
    "stages": [
      ${stagesSettingsString.join(", ")}
    ]
  }
    
response = requests.post(base_url + "submit-pipeline", headers={'x-api-key': api_key}, json=params)
print(response.text)`

  return (
      <Box>
        <div className="page-body">
          <p id="a423606e-ae1b-47a4-be3d-760b1949cb25" className="">
            The Tamarind pipelines tools allows you to configure a custom list of individual tools to run in sequential order. 
            To start, select a tool type for your first job. All valid tool types for each additional job will be shown, based on each tool's input and output types. 
            Copy the generated request below and fill in your desired settings to submit. 
          </p>
          <p>To specify that the field of a tool should be "piped" in from the previous tool's output, use the string value <b>"pipe"</b>. An example can be found below. </p>
          <p><i>We currently only support passing one job's outputs into one job's inputs (no many to one or one to many connections). </i></p>
        </div>
        <Stack spacing={2} sx={{marginTop:2}}>
          {stages.map((stage, index) => (
            <TextField
              key={index}
              value={stage}
              label={`Stage ${index + 1}`}
              variant="outlined"
              disabled
              sx={{width:"50%"}}
            />
          ))}
          <Autocomplete
            value={autoCompleteValue}
            options={options}
            onChange={handleSelect}
            renderInput={(params) => <TextField {...params} label="Select Stage" variant="outlined" />}
            disableClearable
            blurOnSelect
            sx={{width:"50%"}}
          />
        </Stack>

        <Button sx={{textTransform:"none"}} onClick={() => {setStages([]); setOptions(Object.keys(jobSettings))}}>Clear</Button>
        <CodeBlock code={submit}/>
        <h2 id="13932a1d-b623-43ad-8e3b-8f1a094192d8" className="">
          Example params
        </h2>
        <div className="page-body">
        <p>The below example shows an example of a pipeline which uses rfdiffusion to design a protein backbone, proteinmpnn to design a sequence for the generated backbone, and alphafold verify the generated sequence. 3 jobs will be submitted, and they will automatically run sequentially.</p>
        </div>
        <CodeBlock code={example}/>

      </Box>
  )
}