import { TextField } from "@mui/material";
import {useState, useEffect} from 'react';
import { Typography } from "@mui/material";
import Navigation from "./Navigation";
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Button } from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

export const TCRModel2 = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);

	const [type, setType] = useState("class1")
	const [A, setA] = useState('AQEVTQIPAALSVPEGENLVLNCSFTDSAIYNLQWFRQDPGKGLTSLLLIQSSQREQTSGRLNASLDKSSGRSTLYIAASQPGDSATYLCAVTNQAGTALIFGKGTTLSVSS');
	const [B, setB] = useState('NAGVTQTPKFQVLKTGQSMTLQCSQDMNHEYMSWYRQDPGMGLRLIHYSVGAGITDQGEVPNGYNVSRSTTEDFPLRLLSAAPSQTSVYFCASSYSIRGSRGEQFFGPGTRLTVL');
	const [peptide, setPeptide] = useState('RLPAKAPLL');
	const class1Def = 'SHSLKYFHTSVSRPGRGEPRFISVGYVDDTQFVRFDNDAASPRMVPRAPWMEQEGSEYWDRETRSARDTAQIFRVNLRTLRGYYNQSEAGSHTLQWMHGCELGPDGRFLRGYEQFAYDGKDYLTLNEDLRSWTAVDTAAQISEQKSNDASEAEHQRAYLEDTCVEWLHKYLEKGKETLLH'
	const class2Def = 'IKADHVSTYAAFVQTHRPTGEFMFEFDEDEMFYVDLDKKETVWHLEEFGQAFSFEAQGGLANIAILNNNLNTLIQRSNHTQAT'
	const [MHCA, setMHCA] = useState('SHSLKYFHTSVSRPGRGEPRFISVGYVDDTQFVRFDNDAASPRMVPRAPWMEQEGSEYWDRETRSARDTAQIFRVNLRTLRGYYNQSEAGSHTLQWMHGCELGPDGRFLRGYEQFAYDGKDYLTLNEDLRSWTAVDTAAQISEQKSNDASEAEHQRAYLEDTCVEWLHKYLEKGKETLLH');
	const [MHCB, setMHCB] = useState('PENYLFQGRQECYAFNGTQRFLERYIYNREEFARFDSDVGEFRAVTELGRPAAEYWNSQKDILEEKRAVPDRMCRHNYELGGPMTLQR');

	const [exceed, setExceed] = useState(false);

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay) => {
		let config = {name: jobName, type:type, A: A, B:B}
		if (type == "class1" || type == "class2") {
			config["peptide"] = peptide;
			config["MHCA"] = MHCA
		}
		if (type == "class2") {
			config["MHCB"] = MHCB
		}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), 'tcrmodel2');
		startLambdaForType('tcrmodel2');
		navigate("/app/results")
	}

	useEffect(() => {
		// Reset fields when type changes
		if (type === "class1") {
			setA('AQEVTQIPAALSVPEGENLVLNCSFTDSAIYNLQWFRQDPGKGLTSLLLIQSSQREQTSGRLNASLDKSSGRSTLYIAASQPGDSATYLCAVTNQAGTALIFGKGTTLSVSS');
			setB('NAGVTQTPKFQVLKTGQSMTLQCSQDMNHEYMSWYRQDPGMGLRLIHYSVGAGITDQGEVPNGYNVSRSTTEDFPLRLLSAAPSQTSVYFCASSYSIRGSRGEQFFGPGTRLTVL');
			setPeptide('RLPAKAPLL');
			setMHCA('SHSLKYFHTSVSRPGRGEPRFISVGYVDDTQFVRFDNDAASPRMVPRAPWMEQEGSEYWDRETRSARDTAQIFRVNLRTLRGYYNQSEAGSHTLQWMHGCELGPDGRFLRGYEQFAYDGKDYLTLNEDLRSWTAVDTAAQISEQKSNDASEAEHQRAYLEDTCVEWLHKYLEKGKETLLH');
			setMHCB('');
		} else if (type === "class2") {
			setA('LAKTTQPISMDSYEGQEVNITCSHNNIATNDYITWYQQFPSQGPRFIIQGYKTKVTNEVASLFIPADRKSSTLSLPRVSLSDTAVYYCLVGDTGFQKLVFGTGTRLLVSP');
			setB('GAVVSQHPSWVICKSGTSVKIECRSLDFQATTMFWYRQFPKQSLMLMATSNEGSKATYEQGVEKDKFLINHASLTLSTLTVTSAHPEDSSFYICSARDPGGGGSSYEQYFGPGTRLTVT');
			setPeptide('LAWEWWRTV');
			setMHCA('IKADHVSTYAAFVQTHRPTGEFMFEFDEDEMFYVDLDKKETVWHLEEFGQAFSFEAQGGLANIAILNNNLNTLIQRSNHTQAT');
			setMHCB('PENYLFQGRQECYAFNGTQRFLERYIYNREEFARFDSDVGEFRAVTELGRPAAEYWNSQKDILEEKRAVPDRMCRHNYELGGPMTLQR');
		}
	}, [type]);

	return (
	<>
		<Stack spacing={2} style={{padding: '10px' }}>
			<Header type="tcrmodel2"/>
			<Typography variant='body'>Model T Cell receptors with high resolution</Typography>
            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

			<ToggleButtonGroup color="primary" value={type} exclusive onChange={(e, newType) => 
				{if (!newType) return; setType(newType) 
				if (newType == "class2") {
					setMHCA(class2Def)
				} else setMHCA(class1Def)}}> 
				<ToggleButton value='class1'>Class I TCR-pMHC Complex</ToggleButton>
				<ToggleButton value='class2'>Class II TCR-pMHC Complex</ToggleButton>
				<ToggleButton value='unbound'>Unbound TCR</ToggleButton>
			</ToggleButtonGroup>
			
			<TextField value={A} label='TCR A' onChange={(e) => setA(e.target.value)}/>
			<TextField value={B} label='TCR B' onChange={(e) => setB(e.target.value)}/>
			{
				type == "class2" || type == "class1" ? 
				<>
					<TextField value={peptide} label='Peptide' onChange={(e) => setPeptide(e.target.value)}/>
					<TextField value={MHCA} label={type == "class2" ? 'MHCA' : 'MHC'} onChange={(e) => setMHCA(e.target.value)}/>
				</>
				 : null
			}
			{
				type == "class2" ? 
				<TextField value={MHCB} label='MHC B' onChange={(e) => setMHCB(e.target.value)}/> : null
			}

            <SubmitButton redir="tcrmodel2" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
		</Stack>
	</>
	)
}
