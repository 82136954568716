import {Autocomplete, Grid, Stack, TextField, Typography, Box, Button, Alert, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Link} from '@mui/material';
import {List, ListItem, Chip } from '@mui/material';
import Navigation from './Navigation';
import { useDropzone } from 'react-dropzone';
import {useCallback, useEffect, useReducer, useState} from 'react';
import { addJobToQueueObject, uploadFile, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE } from '../utils';
import { useUser } from "@clerk/clerk-react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router';
import {NameField} from './NameField';
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import {SubmitButton} from './SubmitButton';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode);

function Instructions() {
    return (
        <>
            <Typography> Learn more on how to best use RFdiffusion <Link href='https://www.tamarind.bio/blog' target="_blank">here</Link>.</Typography>
            <Typography>Want to test a large number of potential designs? Try our <Link href='https://www.tamarind.bio/batch' target="_blank">Batch Workflows</Link>.</Typography>
        </>
    )
}

const MotifScaffolding = ({setMotifScaffoldingState, setContigs}) => {
    const reducer = (state, action) => {
        const { type, field, value } = action;
        switch (type) {
            case 'scaffold_range_lower':
                return {
                    ...state,
                    [field]: [value, state[field][1]],
                };
            case 'scaffold_range_higher':
                return {
                    ...state,
                    [field]: [state[field][0], value],
                };
            case 'n_terminus_length_lower':
                return {
                    ...state,
                    [field]: [value, state[field][1]],
                };
            case 'n_terminus_length_higher':
                return {
                    ...state,
                    [field]: [state[field][0], value],
                };
            case 'c_terminus_length_lower':
                return {
                    ...state,
                    [field]: [value, state[field][1]],
                };
            case 'c_terminus_length_higher':
                return {
                    ...state,
                    [field]: [state[field][0], value],
                };
            default:
                return {
                    ...state,
                    [field]: value,
                };
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        "chain": "A",
        'scaffold_range': ['1', '50'], 
        'n_terminus_length': ['1', '10'], 
        'c_terminus_length': ['1', '10'], 
    });

    const buildContigs = () => {
        const {n_terminus_length, c_terminus_length, chain, scaffold_range} = state;
        return `${n_terminus_length[0]}-${n_terminus_length[1]}/${chain}${scaffold_range[0]}-${scaffold_range[1]}/${c_terminus_length[0]}-${c_terminus_length[1]}`;
    }

    useEffect(() => 
    {
        setContigs(buildContigs());
        setMotifScaffoldingState(state);
    }, [state]);

    return (
        <Stack spacing={2}>
            <Typography>Input Structure Chain</Typography>
            <TextField
                label='Chain'
                onChange={e => dispatch({type: 'chain', field: 'chain', value: e.target.value})}
                value={state.chain}
            />

            <Typography>Range of Residues for Scaffolding</Typography>
            <Box display="flex" alignItems="center" gap={2}>
                <TextField
                    label="Lower"
                    onChange={e => dispatch({type: 'scaffold_range_lower', field: 'scaffold_range', value: e.target.value})}
                    value={state.scaffold_range[0]}
                    type="number"
                />
                <>-</>

                <TextField
                    label="Higher"
                    onChange={e => dispatch({type: 'scaffold_range_higher', field: 'scaffold_range', value: e.target.value})}
                    value={state.scaffold_range[1]}
                    type="number"
                />
            </Box>

            <Typography>N Terminus New Structure Length Range</Typography>
            <Box display="flex" alignItems="center" gap={2}>
                <TextField
                    label="Lower"
                    onChange={e => dispatch({type: 'n_terminus_length_lower', field: 'n_terminus_length', value: e.target.value})}
                    value={state.n_terminus_length[0]}
                    type="number"
                />
                <>-</>

                <TextField
                    label="Higher"
                    onChange={e => dispatch({type: 'n_terminus_length_higher', field: 'n_terminus_length', value: e.target.value})}
                    value={state.n_terminus_length[1]}
                    type="number"
                />
            </Box>

            <Typography>C Terminus New Structure Length Range</Typography>
            <Box display="flex" alignItems="center" gap={2}>
                <TextField
                    label="Lower"
                    onChange={e => dispatch({type: 'c_terminus_length_lower', field: 'c_terminus_length', value: e.target.value})}
                    value={state.c_terminus_length[0]}
                    type="number"
                />
                <>-</>

                <TextField
                    label="Higher"
                    onChange={e => dispatch({type: 'c_terminus_length_higher', field: 'c_terminus_length', value: e.target.value})}
                    value={state.c_terminus_length[1]}
                    type="number"
                />
            </Box>
        </Stack>
    );
};

const PartialDiffusion = ({ setPartialDiffusionState, files, setContigs }) => {
    const reducer = (state, action) => {
        const { type, field, value } = action;
        switch (type) {
            case 'structure_length_lower':
                return {
                    ...state,
                    'structure_length': [value, state['structure_length'][1]],
                };
            case 'structure_length_higher':
                return {
                    ...state,
                    'structure_length': [state['structure_length'][0], value],
                };
            case 'partial_temperature':
                return {
                    ...state,
                    [field]: value,
                };
            default:
                return state; 
        }
    };

    const [pdbContents, setPdbContents] = useState('');
    const [numResidues, setNumResidues] = useState('')

    const initialState = {
        'structure_length': '', 
        'partial_temperature': '20', 
    };

    function readFileContents(file) {
        const reader = new FileReader();
      
        // This event handler will be called once the read operation is complete
        reader.onload = function(e) {
          const text = e.target.result;
          setPdbContents(text);
        };
      
        // If you encounter an error
        reader.onerror = function(e) {
          console.error('Error reading file:', e);
        };
      
        // Read the file as text
        reader.readAsText(file);
    }
    
    function countResidues(pdbContents) {
        // Set to hold unique residue identifiers (chainID+resSeq+insertionCode)
        const uniqueResidues = new Set();
      
        // Split the PDB content by lines and iterate over them
        pdbContents.split('\n').forEach(line => {
          if (line.startsWith('ATOM') || line.startsWith('HETATM')) {
            // Extract chainID, resSeq, and insertionCode from the line
            const chainID = line.substring(21, 22);
            const resSeq = line.substring(22, 26).trim(); // Residue sequence number
            const insertionCode = line.substring(26, 27).trim();
      
            // Create a unique identifier for the residue
            const residueIdentifier = `${chainID}${resSeq}${insertionCode}`;
      
            // Add the identifier to the set
            uniqueResidues.add(residueIdentifier);
          }
        });
      
        // The size of the set gives the number of unique residues
        setNumResidues(uniqueResidues.size);
        return uniqueResidues.size;
    }
      

    const buildContigs = () => {
        if (numResidues)
            return `${numResidues}-${numResidues}`;
    
        return ``;
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        setContigs(buildContigs());
        setPartialDiffusionState(state);
    }, [state]);

    useEffect(() => {
        if (files.length > 0) {
            readFileContents(files[0].file);
            countResidues(pdbContents);
        }
        else {
            setNumResidues('')
        }
    }, [files])

    useEffect(() => setContigs(buildContigs()), [numResidues])

    return (
        <Stack spacing={2}>
            <Typography>For now, we support only diversifying the a structure with a single chain. </Typography>

            <Typography>Structure Length Range</Typography>
            <TextField label={numResidues ? numResidues : 'Upload your pdb file to see the length!'} onChange={() => null} value={numResidues}></TextField>

            <Typography>Partial Temperature</Typography>
            <TextField
                label="Temperature"
                type="number"
                onChange={e => dispatch({type: 'partial_temperature', field: 'partial_temperature', value: e.target.value})}
                value={state.partial_temperature}
            />
        </Stack>
    );
};

const BinderDesign = ({setBinderDesignState, setContigs}) => {
    const reducer = (state, action) => {
        const { type, field, value } = action;
        switch (type) {
            case 'binding_site_range_lower':
                return {
                    ...state,
                    [field]: [value, state[field][1]],
                };
            case 'binding_site_range_higher':
                return {
                    ...state,
                    [field]: [state[field][0], value],
                };
            case 'binder_length_range_lower':
                return {
                    ...state,
                    [field]: [value, state[field][1]],
                }
            case 'binder_length_range_higher':
                return {
                    ...state,
                    [field]: [state[field][0], value],
                }
            default:
                return {
                    ...state,
                    [field]: value
                }
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        "chain": "A",
        'binding_site_range': ['1', '50'],
        'binder_length_range': ['1', '20'],
        'numDesigns': '1',
    });

    const buildContigs = () => {
        const {chain, binding_site_range, binder_length_range} = state;
        return `${chain}${binding_site_range[0]}-${binding_site_range[1]}/0 ${binder_length_range[0]}-${binder_length_range[1]}`
    }

    useEffect(() => {
        setContigs(buildContigs)
        setBinderDesignState(state)
    }
    , [state])

    return (
        <Stack spacing={2}>            
                <Typography>Input Structure Chain</Typography>
                <TextField 
                    label='Chain'
                    onChange={e => dispatch({type: 'chain', field: 'chain', value: e.target.value})} 
                    value={state.chain}
                >

                </TextField>

                <Typography>Residue Range in Input Where Binding Will Occur (To have one length, input same number for both) - make sure all residues in this range exist in your structure</Typography>
                <Box display="flex" alignItems="center" gap={2}>
                    <TextField 
                        label="Lower" 
                        onChange={e => dispatch({type: 'binding_site_range_lower', field: 'binding_site_range', value: e.target.value})} 
                        value={state.binding_site_range[0]}
                        type="number"
                    />
                    <>-</>

                    <TextField 
                        label="Higher" 
                        onChange={e => dispatch({type: 'binding_site_range_higher', field: 'binding_site_range', value: e.target.value})} 
                        value={state.binding_site_range[1]}
                        type="number"
                    />    
                </Box>     

                <Typography>Binder Length</Typography>   
                    <Box display="flex" alignItems="center" gap={2}>
                            <TextField 
                                label="Lower" 
                                onChange={e => dispatch({type: 'binder_length_range_lower', field: 'binder_length_range', value: e.target.value})} 
                                value={state.binder_length_range[0]}
                                type="number"
                            />
                            <>-</>

                            <TextField 
                                label="Higher" 
                                onChange={e => dispatch({type: 'binder_length_range_higher', field: 'binder_length_range', value: e.target.value})} 
                                value={state.binder_length_range[1]}
                                type="number"
                            />    
                    </Box>   
        </Stack>
    );
};

const CustomContigs = ({ contigs, setContigs}) => {

    return (
        <Stack>
            <TextField 
                value={contigs} 
                onChange={e => setContigs(e.target.value)}
                label='Contigs'
            />
        </Stack>
    )
}

function TabPanel(props) {
    const { children, mode, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={mode !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {mode === index && (
          <Box sx={{ marginTop:2, marginBottom:2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

export default function RFDiffusion() {
    const [numDesigns, setNumDesigns] = useState("1");
    const [contigs, setContigs] = useState("100");
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [files, setFiles] = useState([]);
    const [binderDesignState, setBinderDesignState] = useState({
        "chain": "A",
        'binding_site_range': ['1', '50'],
        'binder_length_range': ['1', '20'],
        'numDesigns': '1',
    });
    const [motifScaffoldingState, setMotifScaffoldingState] = useState({
        "chain": "A",
        'scaffold_range': ['1', '50'], 
        'n_terminus_length': ['1', '10'], 
        'c_terminus_length': ['1', '10'], 
    });

    const [partialDiffusionState, setPartialDiffusionState] = useState({
        'structure_length': ['50', '50'], 
        'partial_temperature': '20', 
    });

    const [temp, setTemp] = useState('');
    const [filterChecked, setFilterChecked] = useState(true);
    const [hotspot, setHotspot] = useState('');
	const [exceed, setExceed] = useState(false);
    const [mode, setMode] = useState(0);

    const { isLoaded, isSignedIn, user } = useUser();  
    const navigate = useNavigate();
    
    const submitJob = (pay) => {
        if (files.length === 0) {
            Swal.fire(`Please make sure to upload a file, unconditional generation is currently not supported.`)
            return false;
        }

    
        const fnames = files.map(file => file.filename);

        const mode2type = {0: 'binderDesign', 1: 'motifScaffolding', 2: 'partialDiffusion', 3: 'custom'};
        let email = user.emailAddresses[0].emailAddress
        const cost = pay ? OTHER_JOB_BASE : 0

        let submit_contigs = contigs.trim().replace(/\s*-\s*/g, "-")
        console.log(submit_contigs)
        let configObj = {contigs: `[${submit_contigs}]`, numDesigns: numDesigns, hotspot: hotspot};

        if (mode2type[mode] === 'partialDiffusion') {
            configObj['partial_T'] = partialDiffusionState.partial_temperature;
        } else if (temp != "") {
            configObj['partial_T'] = temp
        }

        configObj['uploaded_file'] = fnames.map(fname => `${email}/${fname}`).join(' ');
        files.map(file => uploadFile(file.file, user, () => {}));

        if (filterChecked) {
            configObj['rfdiffusion'] = "waiting"
        }
        // }
        addJobToQueueObject(jobName,cost,user,JSON.stringify(configObj), "rfdiffusion", '', 'In Queue');

        startLambdaForType("rfdiffusion"); 
        navigate('/app/results');
    }

    const handleChange = (e, newValue) => {
        setMode(newValue)
    }

    return (
        <Stack spacing={2} style={{padding: '10px' }}>

            <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind <b>RFdiffusion</b> Protein Design </Typography>

            <Instructions />

            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={mode} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Binder Design" />
                    <Tab label="Motif Scaffolding" />
                    <Tab label="Partial Diffusion/Variant Generation" />
                    <Tab label="Custom" />
                    </Tabs>
                </Box>
                
                <TabPanel mode={mode} index={0}>
                    <BinderDesign setBinderDesignState={setBinderDesignState} setContigs={setContigs}/>
                </TabPanel>
                <TabPanel mode={mode} index={1}>
                    <MotifScaffolding setMotifScaffoldingState={setMotifScaffoldingState} setContigs={setContigs}/>
                </TabPanel>
                <TabPanel mode={mode} index={2}>
                    <PartialDiffusion setPartialDiffusionState={setPartialDiffusionState} files={files} setContigs={setContigs}/>
                </TabPanel>
                <TabPanel mode={mode} index={3}>
                    <CustomContigs contigs={contigs} setContigs={setContigs}/>
                </TabPanel>                
            </Box>
            
            <Autocomplete
                freeSolo
                disablePortal
                options={["1", "2", "4", "8", "16", "32"]}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Number of Designs" />}
                defaultValue={"1"}
                onInputChange = {(e, val) => setNumDesigns(val)}
                onChange={(e, val) => setNumDesigns(val)}
            /> 

            <TextField onChange={(e) => setHotspot(e.target.value)} style={{width:"300px"}} label="Hotspots"></TextField>
            
            {mode != 2 ? <TextField
                style={{width:"300px"}} 
                label="Partial Temperature (Noise Level)"
                type="number"
                onChange={e => setTemp(e.target.value)}
                value={temp}
            /> : null}

        <div>
            <Typography variant='body'>Enable filtering with ProteinMPNN and AlphaFold?</Typography>
            <Checkbox onChange={() => setFilterChecked(!filterChecked)} checked={filterChecked}></Checkbox>
        </div>

            <Typography>Contig selected: <b>{contigs}</b></Typography>
            
            {/*
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    Advanced Settings
                </AccordionSummary>
                <AccordionDetails>
                    <Stack>
                        <TextField 
                        label="Hotspot" 
                        onChange={e => setHotspot(e.target.value)} 
                        value={hotspot}
                        />
                        
                    </Stack>
                </AccordionDetails>
            </Accordion>
            */}

            <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={false}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["chemical/x-pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
            />

            <SubmitButton redir="rf-diffusion" duplicate={duplicateJob} exceed={exceed} onSubmit={submitJob}>Submit</SubmitButton>

        </Stack>
    )
}

/*
export default function RFDiffusion() {
    const [numDesigns, setNumDesigns] = useState("1");
    const [contigs, setContigs] = useState("100");
    const [numIterations, setNumIterations] = useState("1");
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [success, setSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [pdb, setPdb] = useState('');
    const [hotspot, setHotspot] = useState('');
    const [filterChecked, setFilterChecked] = useState(false);
	const [exceed, setExceed] = useState(false);

    const { isLoaded, isSignedIn, user } = useUser();  

    const navigate = useNavigate();

    const submitJob = (pay) => {
        if (files.length === 0) {
            Swal.fire(`Please make sure to upload a file, unconditional generation is currently not supported. \n Your contig is: ${contigs}`)
            return;
        }
        
        const fnames = files.map(file => file.filename);
        //const = `Name:${jobName} Contigs:${contigs} NumDesigns:${numDesigns} pdb:${fnames.join(' ')}${pdb} hotspot: ${hotspot}`;

        let configObj = {name: jobName, contigs: contigs, numDesigns: numDesigns, hotspot: hotspot, filter: filterChecked};
        let email = user.emailAddresses[0].emailAddress
        configObj['uploaded_file'] = fnames.map(fname => `${email}/${fname}`).join(' ');
        configObj['pdb_id'] = pdb;

        const cost = pay ? OTHER_JOB_BASE : 0
        addJobToQueueObject(jobName,cost,user,JSON.stringify(configObj), "rfdiffusion", '', 'In Queue');
        files.map(file => uploadFile(file.file, user, () => {}));
        startLambdaForType("rfdiffusion");  

        navigate('/user');
    }

    return (
        <Stack spacing={2} style={{padding: '10px' }}>
            <Grid container>
                <Navigation />
            </Grid>

        <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind <b>RFdiffusion</b> Protein Design </Typography>
    

        <Instructions />
        
        <Typography>
            Set your settings and steer RFDiffusion to design a protein to your choosing. You can track the status of your job under the "My Workflows" tab! 
        </Typography>

        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
        <Contigs contigs={contigs} setContigs={setContigs}/>
        
        <Autocomplete
            disablePortal
            options={["1", "2", "4", "8", "16", "32"]}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Number of Designs" />}
            defaultValue={"1"}
            onChange={(e, val) => setNumDesigns(val)}
        />

        <TextField onChange={e => setHotspot(e.target.value)} label='Hotspot'></TextField>
    
        <div>
            <Typography variant='body'>Enable filtering with ProteinMPNN and AlphaFold?</Typography>
            <Checkbox onChange={() => setFilterChecked(!filterChecked)}></Checkbox>
        </div>


        <Typography variant='h6'>
            Upload pdb file, or type in the name below.
        </Typography>


        <FilePond
            files={files}
            allowReorder={true}
            allowMultiple={true}
            onupdatefiles={setFiles}
            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
            credits={[]}
            acceptedFileTypes={["chemical/x-pdb"]}
            fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
        />

        <SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submitJob}>Submit</SubmitButton>


        </Stack>
    );
  }
*/