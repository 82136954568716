import {useState} from 'react';
import { Autocomplete, FormControl, InputLabel, Link, Select, Tab, Tabs, TextField, Typography, Accordion, AccordionSummary, AccordionDetails} from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {useUser} from "@clerk/clerk-react";  
import {useNavigate} from "react-router-dom";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {detectFileTypeGenerator, addJobToQueue, returnS3PathsAndUploadFiles, startLambdaForType, OTHER_JOB_BASE } from '../utils';
import { Header } from './UIComponents';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const SPACE2 = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
				<Header type="space2"/>

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
				<FileUpload user={user} isSignedIn={isSignedIn} jobName={jobName} duplicateJob={duplicateJob} exceed={exceed}/>
			</Stack>
		</>
		)
	}

const FileUpload = ({user, isSignedIn, jobName, duplicateJob, exceed}) => {
	const navigate = useNavigate();
	const [proteinFiles, setProteinFiles] = useState([]);
	const [chainAResid, setChainAResid] = useState('');
	const [chainBResid, setChainBResid] = useState([]);
	const parsePdb = require('parse-pdb');
	const [sequences, setSequences] = useState([])
    const [chain, setChain] = useState("")
    const d = {'CYS': 'C', 'ASP': 'D', 'SER': 'S', 'GLN': 'Q', 'LYS': 'K',
        'ILE': 'I', 'PRO': 'P', 'THR': 'T', 'PHE': 'F', 'ASN': 'N', 
        'GLY': 'G', 'HIS': 'H', 'LEU': 'L', 'ARG': 'R', 'TRP': 'W', 
        'ALA': 'A', 'VAL':'V', 'GLU': 'E', 'TYR': 'Y', 'MET': 'M'}

	/*
	const submit = () => {
		const config = `pdb:${proteinString} smiles:${ligandString} numInference:${numInference} numSamples:${numSamples}`;
		addJobToQueue(jobName, 0, user, config, "diff-dock");
	}
	*/

	const submitFile = (pay) => {
		/*
		if (proteinFiles.length === 0) {
			alert("Make sure you've submitted your protein files!");
			return false;
		}
		*/

		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

		const config = {
			proteinFiles: proteinFilePaths,
		}

		for (let i = 0; i < settings.length; i++) {
			config[settings[i].name] = settings[i].selected;
		}

		console.log(config)
		
		const cost = pay ? OTHER_JOB_BASE : 0
		
		addJobToQueue(jobName, cost, user, JSON.stringify(config), "space2");
		startLambdaForType('space2');
		navigate("/app/results");
	}


    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsText(file);
        });
      };
    
    function filterUniqueField(arr, field) {
    const uniqueValues = new Set();
    return arr.filter(obj => {
        const value = obj[field];
        if (uniqueValues.has(value)) {
        return false;
        } else {
        uniqueValues.add(value);
        return true;
        }
    });
    }

	const handleUpdateFiles = async (fileItems) => {
		setProteinFiles(fileItems);
	};
	
	const [activeTab, setActiveTab] = useState('structure');

	const [settings, setSettings] = useState([
		{
		  name: 'cutoff',
		  label: 'Cutoff',
		  selected: 1.25,
		  type: 'number',
		},
		{
		  name: 'clusteringAlgorithm',
		  label: 'Clustering Algorithm',
		  selected: 'Agglomerative clustering',
		  choices: ['Agglomerative clustering', 'Greedy clustering'],
		},
		{
		  name: 'CDRs',
		  label: 'CDRs',
		  selected: [],
		  choices: ["CDRH1", "CDRH2", "CDRH3", "CDRL1", "CDRL2", "CDRL3"],
		  multiple: true,
		},
		{
		  name: 'fw',
		  label: 'Framework Regions (FW)',
		  selected: [],
		  choices: ["fwH", "fwL"],
		  multiple: true,
		},
	  ]);
	
	
	  const updateSettings = ({ settingName, newValue }) => {
		setSettings((prevSettings) =>
		  prevSettings.map((setting) =>
			setting.name === settingName ? { ...setting, selected: newValue } : setting
		  )
		);
	  };
	

	return (
		<Stack spacing={2}>
			<Tabs 
			value={activeTab} 
			onChange={(e, newValue) => setActiveTab(newValue)} 
			centered
			>
			<Tab label='Structure' value='structure' />
			<Tab label='Sequence' value='sequence' />
			</Tabs>


			{activeTab === 'structure' ? 
			<>
				<Typography variant='h6'>Antibodies (Must be IMGT numbered)</Typography>
				<Typography variant='body'>Upload your Antibody pdb files of interest, get clustering results.</Typography>			
				<Typography variant='body'>To run the clustering you will need antibody models which are IMGT numbered and with chain identifier 'H' for the heavy chain and 'L' for the light chain. Models with IMGT numbering and correct chain identifiers can be obtained from running <Link href='/immunebuilder' target='_blank'>ImmuneBuilder</Link>.</Typography>
			</> :
			<>
				<Typography variant='h6'>Antibody Sequences</Typography>
				<Typography variant='body'>Upload your Antibody fasta files of interest, each file should contain one heavy and one light chain.</Typography>			
				<Typography variant='body'>Each antibody structure will be predicted using <Link href='/immunebuilder' target='_blank'>ImmuneBuilder</Link>, then inputted to SPACE2. </Typography>
			</>
			}

			<FilePond
				files={proteinFiles}
				allowReorder={true}
				allowMultiple={true}
				onupdatefiles={handleUpdateFiles}
				labelIdle={`Drag & Drop or <span class="filepond--label-action">Browse</span> your ${activeTab === 'structure' ? 'protein pdb' : 'fasta'} files`}
				credits={[]}
				acceptedFileTypes={activeTab === 'structure' ? ["chemical/x-pdb"] : ['fasta']}
				fileValidateTypeDetectType= {activeTab === 'structure' ? detectFileTypeGenerator({".pdb":"chemical/x-pdb"}) : detectFileTypeGenerator({".fasta":"fasta"})}
			/>


<Accordion sx={{ justifyContent: 'center' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Optional Settings
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          More detailed control of how each prediction is made
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          {settings.map((setting) => (
            <>
              {setting.name === 'cutoff' ? (
                <TextField
                  key={setting.name}
                  label={setting.label}
                  type="number"
                  sx={{ width: 300 }}
                  value={setting.selected}
                  onChange={(e) => updateSettings({ settingName: setting.name, newValue: parseFloat(e.target.value) })}
                  inputProps={{ step: "0.01" }}
                  required
                />
              ) : setting.multiple ? (
                <Autocomplete
                  key={setting.name}
                  multiple
                  options={setting.choices}
                  sx={{ width: 300 }}
                  value={setting.selected}
                  onChange={(e, newValue) => updateSettings({ settingName: setting.name, newValue })}
                  renderInput={(params) => <TextField {...params} label={setting.label} />}
                />
              ) : (
                <Autocomplete
                  key={setting.name}
                  disablePortal
                  options={setting.choices}
                  sx={{ width: 300 }}
                  value={setting.selected}
                  onChange={(e, newValue) => updateSettings({ settingName: setting.name, newValue })}
                  renderInput={(params) => <TextField required {...params} label={setting.label} />}
                />
              )}
            </>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>


			<SubmitButton redir="space2" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
			
					
		</Stack>
	)
}