import React from 'react';
import { Container, Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Box, Button } from '@mui/material';
import Navigation from './Navigation';
import CircleIcon from '@mui/icons-material/Circle';

const useCases = [
  {
    title: 'Optimize',
    overview: 'enzymes for increased thermostability, activity, and solubility',
    bulletPoints: [
      'Test tens of thousands of variants in parallel through multiple rounds of evolution',
      'Integrate experimental results into each round of machine learning, fine-tuning our AI for higher scoring in your property of interest',
    ],
    image: '/path-to-image-industry-a.jpg', // Replace with actual image path
  },
  {
    title: 'Design',
    overview: 'enzymes targeted to your substrate',
    bulletPoints: [
      'Scaffold an existing motif, or diversify the sequence or structure of an existing binder',
      'Generate de novo protein structures targeted to your substrate',
      'Optimize for kcat/km/binding affinity to a substrate',
      'Generate sequences given Enzyme Commission annotations or starting sequences',
    ],
    image: '/path-to-image-industry-b.jpg', // Replace with actual image path
  },
  {
    title: 'Molecular Dynamics',
    overview: 'Apply physics-based methods for free energy calculations',
    bulletPoints: [
      'Run molecular dynamics simulations to evaluate protein-ligand complex dynamic trajectories',
      'Perform large scale protein-ligand docking',
      'Predict system stability over time',
        ],
    image: '/path-to-image-industry-b.jpg', // Replace with actual image path
  },
  {
    title: 'Structural modeling',
    overview: 'Large scale structure prediction of proteins and protein-ligand complexes',
    bulletPoints: [
      'Build a structure for your enzyme, incorporating templates',
      'Model protein-ligand complexes given a sequence and ligands/cofactors',
        ],
    image: '/path-to-image-industry-b.jpg', // Replace with actual image path
  },
  // Add more sections as needed
];

const UseCasesPage = () => {
  return (
    <>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', backgroundColor: '#f5f5f5' }}>
        <Navigation />
      </Box>
      <Box 
        sx={{ 
          background: 'linear-gradient(to bottom, #ffffff, #f5f5f5)', // Gradient background
          minHeight: '100vh', 
          marginTop: '60px', 
          paddingTop: '2rem' // Padding to ensure the content is not hidden behind the navigation
        }}
      >
        <Container>
          {/* Large Section at the Top */}
          <Box sx={{ 
            backgroundColor: '#8B2B00', 
            padding: 6, 
            borderRadius: 2, 
            marginBottom: 3,
            textAlign: 'center'
          }}>
            <Typography 
              variant="h3" 
              sx={{ 
                color: '#FFFFFF', 
                fontWeight: 'bold', 
                fontFamily: 'Geist, sans-serif'  // Custom font for the header
              }} 
              gutterBottom
            >
              Enzyme Design
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                color: '#FFD8CC', 
                fontFamily: 'LTHoop, sans-serif'  // Custom font for the subheader
              }} 
              paragraph
            >
             Design, test, and iterate on your target sequence, optimizing for multiple properties
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => alert("Learn more clicked")}
              sx={{ 
                marginTop: 2, 
                backgroundColor: '#F0F0F0', 
                color: '#8B2B00', 
                '&:hover': { backgroundColor: '#E0E0E0' } 
              }}
            >
              Schedule a demo
            </Button>
          </Box>

          {/* Use Cases Sections */}
          {useCases.map((useCase, index) => (
            <Paper 
              key={index} 
              sx={{ 
                padding: 4, 
                marginBottom: 0, 
                backgroundColor: index % 2 === 1 ? '#E0E0E0' : '#F0F0F0', // Alternating background colors
                borderRadius: 2, 
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container spacing={4} direction={index % 2 === 1 ? 'row' : 'row-reverse'}>
                {/* Image */}
                <Grid item xs={12} md={6}>
                  <img 
                    src={useCase.image} 
                    alt={useCase.title} 
                    style={{ 
                      width: '100%', 
                      borderRadius: '8px', 
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                    }} 
                  />
                </Grid>
                {/* Text Section */}
                <Grid item xs={12} md={6}>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      color: '#8B2B00', 
                      fontWeight: 'bold', 
                      fontFamily: 'Geist, sans-serif'  // Custom font for the header
                    }} 
                    gutterBottom
                  >
                    {useCase.title}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: '#4A1C00', 
                      marginBottom: 2, 
                      fontFamily: 'LTHoop, sans-serif'  // Custom font for the body text
                    }}
                  >
                    {useCase.overview}
                  </Typography>
                  <List>
                    {useCase.bulletPoints.map((point, idx) => (
                      <ListItem key={idx}>
                        <ListItemIcon>
                          <CircleIcon sx={{fontSize:15}}/>
                        </ListItemIcon>
                        <ListItemText 
                          primary={point} 
                          primaryTypographyProps={{ 
                            sx: { 
                              fontFamily: 'sans-serif'  // Custom font for bullet points
                            } 
                          }} 
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Container>
        <br></br>
        <br></br>
      </Box>
    </>
  );
};

export default UseCasesPage;
