import React, { useState } from 'react';
import { Box, Button, Tooltip, Snackbar, Alert } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };


  const handleClose = () => {
    setCopied(false);
  };

  return (
    <Box sx={{ position: 'relative', mb: 2, paddingTop:2}}>
      <SyntaxHighlighter 
        language="javascript" 
        style={solarizedlight} 
        showLineNumbers 
        customStyle={{
          backgroundColor: '#fdf6e3', // Solarized Light background color
          borderRadius: '4px',
          padding: '16px',
          margin: 0,
          overflow: 'auto',
          fontSize: '14px', // Ensure consistent font size
        }}
        lineNumberStyle={{ color: '#586e75', minWidth: '2em', textAlign: 'right', paddingRight: '1em' }} // Ensure alignment and padding
      >
        {code.replace(/api_key\s*=\s*".*?"/, (match, p1) => {
          if (match === 'api_key = "insert api key here"') {
            return match; 
          }
          return `api_key = "***************"`;
        })}
      </SyntaxHighlighter>
      <Tooltip title="Copy to clipboard">
        <Button
          variant="contained"
          size="small"
          sx={{ position: 'absolute', top: 25, right: 8, padding: '4px 8px' }}
          startIcon={<ContentCopyIcon />}
          onClick={handleCopy}
        >
          Copy
        </Button>
      </Tooltip>
      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Code copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CodeBlock;