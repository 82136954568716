import { Stack, Button, Autocomplete, TextField,  Typography, Chip } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import "filepond/dist/filepond.min.css";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

export const ImmuneBuilder = () => {
        const navigate = useNavigate();

	    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [model, setModel] = useState("Antibody")
        const { isLoaded, isSignedIn, user } = useUser();  
	    const [exceed, setExceed] = useState(false);

        const models = ["Antibody", "Nanobody", "TCR"];
        const [heavy, setHeavy] = useState('EVQLVESGGGVVQPGGSLRLSCAASGFTFNSYGMHWVRQAPGKGLEWVAFIRYDGGNKYYADSVKGRFTISRDNSKNTLYLQMKSLRAEDTAVYYCANLKDSRYSGSYYDYWGQGTLVTVS');
        const [light, setLight] = useState('VIWMTQSPSSLSASVGDRVTITCQASQDIRFYLNWYQQKPGKAPKLLISDASNMETGVPSRFSGSGSGTDFTFTISSLQPEDIATYYCQQYDNLPFTFGPGTKVDFK');  

        const submit = (pay) => {
                let configs = {}

                if (model === "Antibody" || model === "TCR") {
                    configs = {
                        'sequence1': heavy,
                        'sequence2': light,
                        'modelType': model,
                    }
    
                }
                else if (model == 'Nanobody') {
                    configs = {
                        'sequence1': heavy,
                        'modelType': model,
                    }
                }

                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configs), "immunebuilder");
                startLambdaForType('immunebuilder');
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="immunebuilder"/>
                <Typography variant='body'>Deep learning model to predict the structure of immune proteins including Antibodies, Nanobodies, and TCRs.</Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
                <Autocomplete
                disablePortal
                options={models}
                value={model}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Model Type" />}
                onChange={(event, value) => setModel(value)} // prints the selected value
                />

                <TextField 
                    label={model !== 'TCR' ? 'H' : 'A'}
                    value={heavy}
                    onChange={e => setHeavy(e.target.value)}
                />

                {model !== 'Nanobody' &&
                <TextField 
                    label={model !== 'TCR' ? 'L' : 'B'}
                    value={light}
                    onChange={e => setLight(e.target.value)}
                />
                }

                <SubmitButton redir="immunebuilder" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
