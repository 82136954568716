import Navigation from "./Navigation";
import { Button, Chip, Box, Typography } from "@mui/material";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {useState, useEffect, useRef} from 'react';
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import axios from 'axios'

import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { NameField } from "./NameField";

registerPlugin(FilePondPluginFileEncode);

export const SmilesDocking = ({ hideNavigation }) => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [smiles, setSmiles] = useState('CC(=O)OCC(C1=CC=CC=C1)O');
        const [smiles2, setSmiles2] = useState('CC12CC(=O)C3C(C1CCC2(C(=O)CO)O)CCC4=CC(=O)C=CC34C');
        const [files, setFiles] = useState([])
        const [files2, setFiles2] = useState([])

        const [duplicateJob, setDuplicateJob] = useState(false);
        const [exceed, setExceed] = useState(false);
        const [showAll1, setShowAll1] = useState(false);
        const [showAll, setShowAll] = useState(false);

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  

        useEffect(() => {
                axios.get('/api/getFiles').then(
                        res => {
                                let allFiles = res.data
                                setFiles(allFiles.filter(str => str.endsWith(".pdbqt") && !str.includes("A")))
                                setFiles2(allFiles.filter(str => str.endsWith(".sdf")))
                        }
                )
        }, [])

	const submitFile = () => {
		if (smiles2 == "" || smiles == "") {
			Swal.fire("Please make sure you've inputted both molecules");
			return;
		}

                let configObj = {}
                if (files.includes(smiles + ".pdbqt")) {
                        configObj["receptorFile"] = user.emailAddresses[0].emailAddress + "/" + smiles + ".pdbqt"
                } else {
                        configObj["stringA"] = smiles
                }

                if (files2.includes(smiles2 + ".sdf")) {
                        configObj["ligandFile"] = user.emailAddresses[0].emailAddress + "/" + smiles2 + ".sdf"
                } else {
                        configObj["stringB"] = smiles2
                }

                const cost = 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "smiles-docking");
                startLambdaForType("smiles-docking")
		navigate("/app/results");
	}

        const validEmails = ['denizkavi@outlook.com', 'sherryliu987@gmail.com', 'kraman@persist-ai.com', 'joshua@persist-ai.com', 'daniel@persist-ai.com', 'corina@persist-ai.com', 'kenan@persist-ai.com', 'armon@persist-ai.com']
    
        useEffect(() => {
            const email = user?.emailAddresses?.[0].emailAddress; // null protection
            if (isLoaded && !validEmails.includes(email) && !email.includes("persist-ai")) {
              navigate('/app'); // Safely Navigate to the Target State
            }
          }, [navigate, isLoaded, user?.emailAddresses]); // appropriate dependents

        if (!isLoaded) {
            return <div>Loading...</div>; // e.g., a pre-loader or key aspect, understanding the "index" of the cycle.
        }

        if (isLoaded && (validEmails.includes(user.emailAddresses[0].emailAddress) || user.emailAddresses[0].emailAddress.includes("@persist-ai.com"))) {            
                return (
                    <>
                    <Stack spacing={2} style={{padding: '10px' }}>
                            { hideNavigation ? null : 
                            <>
                                    <Typography variant='h5'>Tamarind Smiles Docking Interface</Typography>

                            </> }

                            <Typography>Use Smina molecular docking tool to dock two smiles molecules. </Typography>

                            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                            <TextField 
                                label='Receptor SMILES String'
                                value={smiles}
                                onChange={(e) => setSmiles(e.target.value)}
                            />

                            <div>
                            {/* <Stack direction="row" spacing={1}> */}
                            <Box
                                sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                                }}
                                >
                                {(showAll1 ? files : files.slice(0, 3)).map((string) => (
                                <Chip
                                key={string}
                                label={string.replace(/\.pdbqt$/, '')}
                                onClick={(v) => setSmiles(string.replace(/\.pdbqt$/, ''))}
                                />
                                ))}
                                {
                                        files.length > 3 ? 
                                        <Button sx={{textTransform:"none"}} onClick={() => setShowAll1((prev) => !prev)}>
                                        {showAll1 ? 'Show Less' : 'Show More'}
                                        </Button> : null
                                }
                            </Box>
                            </div>
                            <br></br>

                        <div>
                        
                        <TextField 
                        fullWidth
                        label='Ligand SMILES String'
                        value={smiles2}
                        onChange={(e) => setSmiles2(e.target.value)}
                        />
                            <br></br><br></br>
                            <Box
                                sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                // maxHeight: showAll ? 'none' : 100,
                                gap: 1,
                                }}
                                >
                        {
                        (showAll ? files2 : files2.slice(0, 3)).map((string) => (
                        <Chip
                        key={string}
                        label={string.replace(/\.sdf$/, '')}
                        onClick={(v) => setSmiles2(string.replace(/\.sdf$/, ''))}
                        />
                        ))}
                        {
                                files2.length > 3 ? 
                                <Button sx={{textTransform:"none"}} onClick={() => setShowAll((prev) => !prev)}>
                                {showAll ? 'Show Less' : 'Show More'}
                                </Button> : null
                        }
                        
                        </Box>
                        
                        </div>

                            <Button disabled={duplicateJob} onClick={submitFile}>Submit</Button>
                            {/*<SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>*/}
                    </Stack>
                    </>
            );
    }
}