import React, {useState, useEffect, useRef} from 'react'
import {TextField, Typography} from '@mui/material';
import axios from 'axios';
import { useUser } from "@clerk/clerk-react";
import { isJobThisMonth } from '../utils';
import { NUM_FREE_JOBS } from '../utils';

function NameField(props) {
    const [jobNames, setJobNames] = useState([]);
    const { isLoaded, isSignedIn, user } = useUser();  

    const initialCheckDone = useRef(false); // To track if initial check is done

    useEffect(() => {
        if (!isSignedIn) {
            return
        }
        const email = user.emailAddresses[0].emailAddress
        initialCheckDone.current = false

        const fetchJobs = async () => {
            let allJobs = []
            let startKey = null
            while (startKey != -1) {
                await axios.get('/api/getJobsForUser', {params:{ "filter": "false", "limit":2500, "startKey":JSON.stringify(startKey)}}).then(
                    res => {
                    startKey = res.data.startKey
                    allJobs = [...allJobs, ...res.data.jobs]
                    })
            }
            return allJobs
        }

        fetchJobs().then(
            jobs => {
                let nonDeleted = jobs.filter(job => {
                    return job.JobStatus != "Deleted";
                });
        
                let jobnames = []
                nonDeleted.map(job => jobnames.push(job.JobName))
                setJobNames(jobnames);
                // console.log(jobnames)
        
                let thisMonthJobs = jobs.filter(job => {
                    return !(job.Type == "batch") && isJobThisMonth(job) && !(job.JobStatus.includes("Stopped"));
                });
        
                let numJobs = props.numJobs ? props.numJobs : 1;
                axios.get('/api/getJobLimit').then(
                    res => {
                        props.setExceed(thisMonthJobs.length + numJobs > res.data)
                    }
                )
                props.setDuplicate(jobnames.includes(props.jobName));
            }
        ).then(res => {
            initialCheckDone.current = true
        })
        
    }, [props.numJobs, isLoaded])

    return(
        isSignedIn ? 
        <>
        <TextField label='Job Name' 
        sx={{width:"25%", minWidth:"220px"}}
        onChange={
        (e) => {
            // console.log(e.target.value, initialCheckDone.current)
            let new_char = e.target.value.charAt(e.target.value.length - 1)
            if ( new_char == '/' || new_char == '\'' || new_char == ' '){
                return
            }
            if (initialCheckDone.current) {
                let new_name = e.target.value.split(/[ \/\'+]/).join("_")
                props.setJobName(new_name)
                props.setDuplicate(jobNames.includes(new_name))
            }
        }} 
        error={props.duplicate}
        helperText={props.duplicate ? `Job with name "${props.jobName}" already exists, please choose a new name.` :""}
        value={props.jobName}></TextField>
        {/* {props.duplicate ? <Typography color="#FF0000">Job with name "{props.jobName}" already exists, please choose a new name.</Typography> : <></>} */}
        </> : 
        <TextField label='Job Name' onChange = {(e) => props.setJobName(e.target.value)} value={props.jobName}></TextField>
    )
}

export { NameField }