import {useNavigate, Link} from "react-router-dom";
import {useUser} from "@clerk/clerk-react";  
import {useState, useEffect} from 'react';
import { Button, Typography, Dialog, DialogTitle, DialogContent, Box, Tooltip, Stack, Chip } from '@mui/material'
import PaymentUI from './Payment/PaymentUI';
import axios from 'axios'
import Swal from "sweetalert2"
import CircularProgress from '@mui/material/CircularProgress';
import { ALPHAFOLD_BASE, OTHER_JOB_BASE, ALPHAFOLD_FREE_LIMIT, ALPHAFOLD_HARD_LIMIT, ALPHAFOLD_PER_AA, calcPrice } from '../utils'

function SubmitButton(props) {
    // {duplicate, exceed, onSubmit, length}

    const [tier, setTier] = useState(false)
    const [paymentTab, setPaymentTab] = useState(false)
    const [processing, setProcessing] = useState(false)
    const { isLoaded, isSignedIn, user } = useUser();  
    const navigate = useNavigate();

    const checkPaying = () => {
        axios.get('/api/getCustomerTier').then(
            res => {
                setTier(res.data)
            }
        )
    }

    useEffect(() => {
        checkPaying()
    }, [])

    function handleFinalSubmit(pay) {
        if (props.onSubmit(pay, getCost())){
            return
        }
        // navigate("/user");
    }

    function handleSubmit() {
		if (!isSignedIn) {
            // navigate(props.redir ? `/sign-in/${props.redir}` : "/sign-in/app")
            navigate("/sign-in")
			return
		}
        if (props.duplicate) {
            return
        }
        if (tier == "Premium") {
            handleFinalSubmit(false) // paid or not
        }
        // if (props.seqLength > ALPHAFOLD_HARD_LIMIT) {
        //     let email = user.emailAddresses[0].emailAddress
        //     Swal("We currently only support AlphaFold sequences of length up to " + ALPHAFOLD_HARD_LIMIT)
        //     axios.get('/api/notifyUs', {params:{'title': email + " above 5000", 'message': 'Tried to submit above 5000'}})
        //     return
        // }
        if (tier == "Free" && (props.exceed || (props.seqLength && props.seqLength > ALPHAFOLD_FREE_LIMIT))) {
            let email = user.emailAddresses[0].emailAddress
            const reason = props.exceed ? " Monthly limit exceeded" : " Length too large: " + props.seqLength
            axios.get('/api/notifyUs', {params:{'title': email + reason, 'message': 'Reached payment tab.'}})
            setPaymentTab(true)
            return
        }
        const paid = tier == "Professional" ? (props.exceed || (props.seqLength && props.seqLength > ALPHAFOLD_FREE_LIMIT)) : false
        handleFinalSubmit(paid) // paid or not
    }

    function getCost() {
        if (props.toolName == "freeda") {
            return (0).toFixed(2)
        }
        let cost = props.numJobs ? OTHER_JOB_BASE * props.numJobs : OTHER_JOB_BASE
        // console.log("num jobs:", props.numJobs)
        // console.log("test cost:", cost)
        if (props.toolName == "AlphaFold" || props.redir == "alphafold" || props.redir == "combfold" || props.redir == "rosettafold-aa" || props.redir == "rosettafold2na") {
            // console.log("made it to here")
            
            let seqArr = []
            if (props.seq2Length && props.seq2Length.length > 0) {
                for (const i of props.seqLength) {
                    for(const j of props.seq2Length) {
                        seqArr.push(i + j)
                    }
                }
            } else {
                seqArr = props.seqLength
            }

            cost = calcPrice("alphafold", props.numJobs ? props.numJobs : 1, seqArr)[0]
            // let sumSeqLength = props.seqLength.reduce((accumulator, currentValue) => {
            //     return accumulator + currentValue;
            //   }, 0);
            // if (props.seqLength.length == 1 && !props.seq2Length){ // ones
            //     cost = ALPHAFOLD_PER_AA * props.seqLength[0] + ALPHAFOLD_BASE
            // } else if(props.seq2Length == 0) { // af single
            //     cost = sumSeqLength * ALPHAFOLD_PER_AA + ALPHAFOLD_BASE * props.seqLength.length
            // } else { // af pair
            //     let sumSeq2Length = props.seq2Length.reduce((accumulator, currentValue) => {
            //         return accumulator + currentValue;
            //       }, 0);
            //     cost = sumSeqLength * props.seq2Length.length * ALPHAFOLD_PER_AA + sumSeq2Length * props.seqLength.length * ALPHAFOLD_PER_AA + props.seqLength.length * props.seq2Length.length * ALPHAFOLD_BASE
            // }
        }
        // console.log(cost)
        return cost.toFixed(2)
    }

    const features = ["Pay as you go", "First 10 jobs of each month remain free", "Premium support", "Billed monthly", "Unlimited data storage"]

    return <>
    <Stack
          display="flex"
          alignItems="center"
          justifyContent="center"
    >
        {
            isSignedIn && tier == "Premium" ?  
            <>
            <Tooltip title={!props.disableReasons ? "" : props.disableReasons.map((reason, index) => (
                <Typography key={index} variant="body2">
                {reason}
                </Typography>
            ))} disableHoverListener={!props.disableReasons || props.disableReasons.length == 0}>
            <Button disabled={props.duplicate || props.disable || (props.disableReasons && props.disableReasons.length > 0)} onClick={() => handleFinalSubmit(false)}>Submit</Button>
            </Tooltip>
            {
                props.disabledReasons && props.disabledReasons.length > 0 ? 
                <Typography variant="body2">{props.disabledReasons[0]}</Typography> : null
            }
            </>
            :
            <>
            <Tooltip title={!props.disableReasons ? "" : props.disableReasons.map((reason, index) => (
                    <Typography key={index} variant="body2">
                    {reason}
                    </Typography>
                ))} arrow 
                disableHoverListener={!props.disableReasons || props.disableReasons.length == 0}>
                <span> 
            <Button disabled={props.duplicate || !isLoaded || props.disable || (props.disableReasons && props.disableReasons.length > 0)} onClick={handleSubmit}>Submit</Button>
                </span> 
            </Tooltip>
            {
                props.disableReasons && props.disableReasons.length > 0 ? 
                <Typography variant="body2" sx={{marginBottom:1}} color="textSecondary"><i>{props.disableReasons[0]}</i></Typography> : null
            }
            {
                tier == "Professional" && (props.exceed || (props.seqLength && props.seqLength > ALPHAFOLD_FREE_LIMIT)) && getCost() != 0 ? 
                <Tooltip title={props.seqLength ? ALPHAFOLD_PER_AA + " * (# amino acids) + " + ALPHAFOLD_BASE : "$" + OTHER_JOB_BASE + " per job"}>
                    <Typography variant="body2" color="textSecondary">Cost = <b>${getCost()}</b></Typography>
                </Tooltip>
                : <>{tier == "Professional" ? 
                    <Typography variant="body2" color="textSecondary">First ten jobs of each month are free - including this one!</Typography>
                : null}</>
            }
        </>
        }

    </Stack>
    <Dialog open={paymentTab} onClose={() => {setPaymentTab(false)}}>
        <DialogTitle>
            <Typography variant="h5" color="primary">Free Tier Limit Exceeded</Typography>
        </DialogTitle>
        <DialogContent>
            {
                props.seqLength && Math.max(...props.seqLength) > ALPHAFOLD_FREE_LIMIT ? 
            <Typography >Tamarind's free tier is limited to sequences of length {ALPHAFOLD_FREE_LIMIT} for AlphaFold. Sign up for our <u>professional tier</u> to submit jobs of up to {ALPHAFOLD_HARD_LIMIT}</Typography>
                :  <Typography>Tamarind's free tier is limited to 10 jobs per month. Sign up for our <u>professional tier</u> to continue using our tools at scale: </Typography>
            }
            <ul>
            {features.map((feature, index) => (
                <li key={index}>{feature}</li>
            ))}
            </ul>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
            <Chip label="View additional pricing options" onClick={() => {navigate("/pricing")}}></Chip>
            </Box>
            <br></br>
            <Typography>Your current job costs: <b>${getCost()}</b></Typography>

            {props.seqLength ? 
                <Typography>*Each AlphaFold job cost is determined by the length and quantity submitted. See <Link to='/pricing-calculator'>pricing calculator</Link></Typography> :
                <Typography variant="body2" color="textSecondary">*Each {props.toolName} job costs $1</Typography>
            }
            <br></br>
            <Box>
                {
                    tier == "Professional" ? 
                    <Typography style={{ color: '#4CAF50' }}>Payment Info Saved. Thanks for using Tamarind!</Typography> : 
                    <>
                       <PaymentUI user={user} checkPaying={checkPaying} setProcessing={setProcessing}></PaymentUI>
                       <br></br>
                        <Box
                        margin="auto"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        >
                        {processing ? <CircularProgress /> : null}
                    </Box>
                    </>
                }
               
            </Box>

        </DialogContent>

        <Button disabled={tier == "Free"} onClick={() => {handleFinalSubmit(true)}} color="primary">
            Submit
        </Button>

          <Button onClick={() => {setPaymentTab(false)}} color="primary">
            Cancel
          </Button>

          <Typography sx={{ margin: '20px' }}>Please email <a href={`mailto:info@tamarind.bio`}>info@tamarind.bio</a> with any questions about payment or to discuss alternative payment plans e.g. subscription, license. </Typography>

      </Dialog>
    </>
}

export {SubmitButton}