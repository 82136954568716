import { Button, Stack } from "@mui/material";
import Navigation from "./Navigation";
import {useState} from 'react';
import { Typography } from "@mui/material";
import { TextField } from "@mui/material";
import {useUser} from "@clerk/clerk-react";  
import axios from "axios";
import {useNavigate} from "react-router-dom";
import { addJobToQueue, OTHER_JOB_BASE  } from "../utils";
import {NameField} from './NameField';
import {SubmitButton} from './SubmitButton';
  
export const Umol = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [exceed, setExceed] = useState(false);
    const [protein, setProtein] = useState('SEDELYRQSLEIISRYLREQATGAKDTKPMGRSGATSRKALETLRRVGDGVQRNHETAFQGMLRKLDIKNEDDVKSLSRVMIHVFSDGVTNWGRIVTLISFGAFVAKHLKTINQESCIEPLAESITDVLVRTKRDWLVKQRGWDGFVEFFH');
	const [ligand, setLigand] = useState('CCc1sc2ncnc(N[C@H](Cc3ccccc3)C(=O)O)c2c1-c1cccc(Cl)c1C');
    const [positions, setPositions] = useState('51,52,54,55,56,57,58,59,60,61,62,63,65,66,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,93,94,95,96,97,98,99,100,101,102,104,105,125,128,129');
	const { isLoaded, isSignedIn, user } = useUser();  
	const navigate = useNavigate();

	const submit = async (pay) => {
        const data = {
            "protein": protein,
            "ligand": ligand,
        "positions": positions}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(data), 'umol', '', 'In Queue', "");
		navigate("/app/results");
	}

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
            <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind UMol Online Tool</Typography>
				<Typography variant=''>Structure prediction of protein-ligand complexes from sequence information</Typography>
				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
				<TextField value={protein} label='Protein' onChange={(e) => setProtein(e.target.value)}/>
				<TextField value={ligand} label='Ligand' onChange={(e) => setLigand(e.target.value)}/>
                <TextField value={positions} label='Positions' onChange={(e) => setPositions(e.target.value)}/>
				<SubmitButton redir="umol" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
			</Stack>
		</>
	)
}
