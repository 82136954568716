import {useState} from 'react';
import Navigation from "./Navigation";
import { Stack, Grid, Button, Typography, TextField, Autocomplete, Chip } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, OTHER_JOB_BASE, returnS3PathsAndUploadFiles, startLambdaForType } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import {SubmitButton} from './SubmitButton';
import axios from 'axios'
import Select from '@mui/material/Select'; // Import Select
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem for options
import FormControl from '@mui/material/FormControl'; // Import FormControl for better styling
import InputLabel from '@mui/material/InputLabel'; // Import InputLabel for select label
import { FilePond} from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {Header} from './UIComponents'

export const RosettaFoldAA = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [exceed, setExceed] = useState(false);

	const [data, setData] = useState([{ sequence: '', type: 'protein'}]);

	const handleAddField = () => {
		const newData = [...data, { sequence: '', type: 'protein'}];
		setData(newData);
	  };
	
	  const handleRemoveField = (index) => {
		const newData = [...data];
		newData.splice(index, 1);
		setData(newData);
	  };
	
	  const handleChange = (index, field, value, subfield="") => {
		const newData = [...data];
		if (field == "type" && (value == "small-molecule" || value == "cofactor")) {
			newData[index]["sequence"] = []
			newData[index][field] = value;
		} else if (field == "type" && value == "covalent-bond") {
			newData[index][field] = value;
			newData[index]["sequence"] = {"protein-chain":"", "residue-number":"", "atom-name":"", "small-molecule-chain":"", "atom-index":"", "protein-chirality":"", "small-molecule-chirality":""}
		} else if (data[index].type == "covalent-bond") {
			newData[index]["sequence"][subfield] = value
		} else {
			newData[index][field] = value;
		}
		setData(newData);
	  };

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay, cost_) => {
		const newData = [...data];

		for (let i = 0 ; i<data.length ; i++){
			let item = data[i]
			if (item.type == "small-molecule" || item.type == "cofactor") {
				const filePaths = item.sequence.map(f => returnS3PathsAndUploadFiles(user, f.file));
				newData[i]["sequence"] = filePaths
			} else if (item.type == "covalent-bond") {
				newData[i]["sequence"] = `(${item.sequence["protein-chain"]}, ${item.sequence["residue-number"]}, ${item.sequence["atom-name"]}) (${item.sequence["small-molecule-chain"]}, ${item.sequence["atom-index"]}) (${item.sequence["protein-chirality"]}, ${item.sequence["small-molecule-chirality"]})`
			}else {
				newData[i]["sequence"] = item.sequence.toUpperCase().replace(" ", "")
			}
		}
		
		const configStr = JSON.stringify(newData)
		const cost = pay ? cost_ : 0
		addJobToQueue(jobName, cost, user, configStr, 'rosettafold-aa');
		// startLambdaForType("rosettafold-aa")
		navigate("/app/results")
	}

	let disableReasons = []
	if (data.some(x => x.sequence == "")) {
		disableReasons.push("Empty sequence")
	}
	if (!data.some(x => x.type == "protein")) {
		disableReasons.push("Must contain at least one protein sequence")
	}

	// const typeOptions = ['protein', 'rna', 'dna', 'small-molecule']; 

	return (
	<>
		{/* {console.log(data)} */}
		<Stack spacing={2} style={{padding: '10px' }}>
        <Header type="rosettafold-all-atom"/>

            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

			<Typography>RosettafoldAA does not support protein/RNA complexes currently. Try out our <Link to="/rosettafold2na">RosettaFold2NA</Link> tool instead. We currently only support protein sequences of up to 700 AAs. </Typography>

			<Typography>Click + to add a new protein/nucleic acid/small molecule to your complex. </Typography>
			
			<div>
			<Button variant="contained" color="primary" style={{marginBottom:20}} onClick={handleAddField}>+</Button>
			{data.map((item, index) => (
				<Grid container spacing={2} alignItems="center" key={index} style={{marginBottom:20}}>
				<Grid item xs={8}>		
					<FormControl fullWidth>
						<InputLabel id={`type-label-${index}`}>Type</InputLabel>
						<Select
							labelId={`type-label-${index}`}
							value={item.type}
							onChange={(event) => handleChange(index, 'type', event.target.value)}
							label="Type"
							fullWidth
						>
							<MenuItem value="protein">Protein</MenuItem>
							{/* <MenuItem value="rna">RNA</MenuItem> */}
							<MenuItem value="dna-single">Single Stranded DNA</MenuItem>
							<MenuItem value="dna-double">Double Stranded DNA</MenuItem>
							<MenuItem value="small-molecule">Small Molecule</MenuItem>
							<MenuItem value="cofactor">Cofactor</MenuItem>
							<MenuItem value="covalent-bond">Covalent Bond</MenuItem>
						</Select>
						<br></br>
					</FormControl>

					{
						item.type == "small-molecule" || item.type == "cofactor" ? 
						<FilePond
						files={item.sequence}
						allowReorder={true}
						allowMultiple={false}
						onupdatefiles={(files) => handleChange(index, 'sequence', files)}
						labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your sdf files'
						credits={[]}
						acceptedFileTypes={["sdf"]}
						fileValidateTypeDetectType= {detectFileTypeGenerator({".sdf":"sdf"})}
						/> : item.type == "covalent-bond" ? 
							<>
							<TextField
							label={`Protein Chain`}
							value={item.sequence["protein-chain"]}
							onChange={(event) => handleChange(index, 'sequence', event.target.value, "protein-chain")}
							placeholder="A"
							sx={{margin:"10px"}}
							/>
							<TextField
							label={`Protein Residue Number`}
							value={item.sequence["residue-number"]}
							onChange={(event) => handleChange(index, 'sequence', event.target.value, "residue-number")}
							placeholder="74"
							sx={{margin:"10px"}}
							/>
							<TextField
							label={`Protein Atom Name`}
							value={item.sequence["atom-name"]}
							onChange={(event) => handleChange(index, 'sequence', event.target.value, "atom-name")}
							placeholder="ND2"
							sx={{margin:"10px"}}
							/>
							<TextField
							label={`Small Molecule Chain`}
							value={item.sequence["small-molecule-chain"]}
							onChange={(event) => handleChange(index, 'sequence', event.target.value, "small-molecule-chain")}
							placeholder="B"
							sx={{margin:"10px"}}
							/>
							<TextField
							label={`Small Molecule Atom Index`}
							value={item.sequence["atom-index"]}
							onChange={(event) => handleChange(index, 'sequence', event.target.value, "atom-index")}
							placeholder="1"
							sx={{margin:"10px"}}
							/>
							<TextField
							label={`Protein Chirality`}
							value={item.sequence["protein-chirality"]}
							onChange={(event) => handleChange(index, 'sequence', event.target.value, "protein-chirality")}
							placeholder="CW"
							sx={{margin:"10px"}}
							/>
							<TextField
							label={`Small Molecule Chirality`}
							value={item.sequence["small-molecule-chirality"]}
							onChange={(event) => handleChange(index, 'sequence', event.target.value, "small-molecule-chirality")}
							placeholder="CCW"
							sx={{margin:"10px"}}
							/>
							</>
						:
						<TextField
						label={`Sequence`}
						value={item.sequence}
						onChange={(event) => handleChange(index, 'sequence', event.target.value)}
						fullWidth
						/>
					}



				</Grid>
				<Grid item xs={2}>
					<Button variant="contained" style={{ backgroundColor: '#255a15' }}onClick={() => handleRemoveField(index)}>-</Button>
				</Grid>
				</Grid>
			))}
			</div>

            <SubmitButton redir="rosettafold-aa" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit} seqLength={[data.reduce((acc, item) => acc + item.sequence.length, 0)]}>Submit</SubmitButton>
		</Stack>
	</>
	)
}
