import {EndNode} from './Pipelines/EndNode.jsx';
import {GenericNode} from './Pipelines/GenericNode.jsx';
import {FileUploadNode} from './Pipelines/FileUploadNode.jsx';
import ReactFlow from 'reactflow';
import {useEffect, useState} from 'react'

export const PipelineJobViewer = ({nodes, edges}) => {

    const [myNodes, setMyNodes] = useState([])

    useEffect(() => {
        for (let i = 0 ; i<nodes.length ; i++) {
            nodes[i]["data"]["view"] = true
            nodes[i]["data"]["id"] = nodes[i].id
        }
        setMyNodes(nodes)
    }, [])

    const nodeTypes = { 
        generic: GenericNode, 
        fileupload: FileUploadNode, 
        end:EndNode, 
      };

    return (
        <div style={{ width: '100%', height: '1000px', background: "#D3D3D3"}}>
              {
                myNodes.length > 0 ? 
                <ReactFlow
                nodes={myNodes}
                edges={edges}
                nodeTypes={nodeTypes}/>  : null
              }
            
        </div>
    )
}