import {Autocomplete, Grid, Stack, TextField, Typography, Tabs, Tab, Link} from '@mui/material';
import {useState} from 'react';
import { addJobToQueue, detectFileTypeGenerator, OTHER_JOB_BASE} from '../../utils'; // Updated
import { useUser } from "@clerk/clerk-react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from 'react-router';
import { NameField } from '../NameField'; 
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import {SubmitButton} from '../SubmitButton';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode);

function Instructions() {
    return (
        <>
            <Typography>To learn more about how to use RFdiffusion for your needs, consider reading our guide <Link href='https://blog.tamarind.bio/posts/rfdiffusion' target="_blank">here.</Link></Typography>
            <Typography>Different binders will be generated and you can select your preferred one using the ProteinMPNN+AlphaFold metric.</Typography>
        </>
    )
}

export default function RFdiffusionBatch() {
    const [numDesigns, setNumDesigns] = useState(8);
    const [contigs, setContigs] = useState("100");
    const [numIterations, setNumIterations] = useState("1");
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [success, setSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [pdb, setPdb] = useState('');
    const [hotspot, setHotspot] = useState('');
    const [filterChecked, setFilterChecked] = useState(false);
    const [exceed, setExceed] = useState(false);

    const {user} = useUser();
    let email = user.emailAddresses[0].emailAddress

    const navigate = useNavigate();

    const submitJob = (pay) => {
        if (files.length === 0) {
            Swal.fire(`Please make sure to upload a file, unconditional generation is currently not supported. \n Your contig is: ${contigs}`)
            return;
        }
        
        const fnames = files.map(file => file.filename);
        //const = `Name:${jobName} Contigs:${contigs} NumDesigns:${numDesigns} pdb:${fnames.join(' ')}${pdb} hotspot: ${hotspot}`;
        const cost = 0;        

        let configObj = {name: jobName, contigs: contigs, numDesigns: 1, hotspot: hotspot, filter: filterChecked};
        
        configObj['uploaded_file'] = fnames.map(fname => `${email}/${fname}`).join(' ');
        configObj['pdb_id'] = pdb;

        addJobToQueue(jobName,cost,user,JSON.stringify(configObj), "batch", '', 'In Queue');

        for (let i = 0; i < parseInt(numDesigns) ; i++) {
            addJobToQueue(`${jobName}_generated_${i}`,cost,user,JSON.stringify(configObj), "rfdiffusion", '', 'In Queue', jobName);
        }

        //addJobToQueueObject(jobName,cost,user,JSON.stringify(configObj), "rfdiffusion", '', 'In Queue');
        //files.map(file => uploadFile(file.file, user, () => {}));
        //startLambdaForType("rfdiffusion");  

        //navigate('/user');
    }

    return (
        <Stack spacing={2} style={{padding: '10px' }}>

        <Instructions />

        <Typography>
            Set your settings and steer RFDiffusion to design a protein to your choosing. numDesigns jobs will be created for you within the batch, each with one designed proteined. You can track the status of your job under the "My Workflows" tab! 
        </Typography>

        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName} numJobs={Math.max(files.length, 1)}></NameField>
        <Contigs contigs={contigs} setContigs={setContigs}/>

        {/* <Autocomplete
            disablePortal
            options={["50", "100", "200", "1000"]}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Number of designs"/>}
            defaultValue={"100"}
        /> */}

        <TextField
        value={numDesigns}            
        onChange={(e) => setNumDesigns(parseInt(e.target.value))}
        label="Number of designs"
        sx={{ width: 300 }}/>

        <TextField onChange={e => setHotspot(e.target.value)} label='Hotspot'></TextField>
    
        <div>
            <Typography variant='body'>Enable filtering with ProteinMPNN and AlphaFold?</Typography>
            <Checkbox onChange={() => setFilterChecked(!filterChecked)}></Checkbox>
        </div>


        <Typography variant='h6'>
            Upload pdb file, or type in the name below.
        </Typography>

        {/* <TextField onChange={e => setPdb(e.target.value)} label='PDB'></TextField> */}

        <FilePond
            files={files}
            allowReorder={true}
            allowMultiple={true}
            onupdatefiles={setFiles}
            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
            credits={[]}
            acceptedFileTypes={["chemical/x-pdb"]}
            fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
        />

        <SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submitJob} numJobs={Math.max(numDesigns, 1)}>Submit</SubmitButton>

        </Stack>
    );
  }


  function Contigs({contigs, setContigs}) {
    const [value, setValue] = useState(0);

    const [targetChain, setTargetChain] = useState("A");
    const [targetResidues, setTargetResidues] = useState("1-50");
    const [binderLength, setBinderLength] = useState("10-20");
    
    const handleBinderTargetChange = (newVal) => {
        setContigs(`${newVal}${targetResidues}:${binderLength}`);
        setTargetChain(newVal);
    }

    const handleResidChange = (newVal) => {
        setContigs(`${targetChain}${newVal}:${binderLength}`);
        setTargetResidues(newVal);
    }

    const handleBinderChange = (newVal) => {
        setContigs(`${targetChain}${targetResidues}:${newVal}`);
        setBinderLength(newVal);
    }

    const [motifTargetChain, setMotifTargetChain] = useState("A");
    const [motifTargetResidues, setMotifTargetResidues] = useState("10-25");
    const [NTerminus, setNTerminus] = useState("5-15");
    const [CTerminus, setCTerminus] = useState("30-40");

    //5-15/A10-25/30-40

    const handleMotifTargetChange = (newVal) => {
        setContigs(`${motifTargetResidues}/${newVal}${NTerminus}/${CTerminus}`);
        setMotifTargetChain(newVal);
    }

    const handleMotifResidChange = (newVal) => {
        setContigs(`${motifTargetResidues}/${motifTargetChain}${NTerminus}/${CTerminus}`);
        setMotifTargetResidues(newVal);
    }

    const handleNTerminusChange = (newVal) => {
        setContigs(`${motifTargetResidues}/${motifTargetChain}${newVal}/${CTerminus}`);
        setNTerminus(newVal);
    }

    const handleCTerminusChange = (newVal) => {
        setContigs(`${motifTargetResidues}/${motifTargetChain}${NTerminus}/${newVal}`);
        setCTerminus(newVal);
    }

    const handleTabChange = (newValue) => {
        //0 stays the same as contigs
        if (newValue === 1) {
            setContigs(`${targetChain}${targetResidues}:${binderLength}`);
        }

        if (newValue === 2) {
            setContigs(`${motifTargetResidues}/${motifTargetChain}${NTerminus}/${CTerminus}`);
        }

        setValue(newValue)
    }

    return (
        <>
            <Tabs value={value} onChange={(e, newValue) => handleTabChange(newValue)} aria-label="basic tabs example">
                <Tab label="Custom" />
                <Tab label="Binder Design" />
                <Tab label="Motif Scaffolding" />
            </Tabs>


            {value === 0 ?
            <TextField label={`Contigs`} value={contigs} onChange={e => setContigs(e.target.value)}/>
            : null}

            {value === 1 ?
                <>
                    <TextField label={`Chain In Input Where Binding Will Happen`} value={targetChain} onChange={e => handleBinderTargetChange(e.target.value)}/>
                    <TextField label={`Residue (range) In Input For Binding`} value={targetResidues} onChange={e => handleResidChange(e.target.value)}/>
                    <TextField label={`Binder Length (Range)`} value={binderLength} onChange={e => handleBinderChange(e.target.value)}/>                    
                </>
            : null}

            {value === 2 ?
                <>
                    <TextField label={`Chain In Input`} value={motifTargetChain} onChange={e => handleMotifTargetChange(e.target.value)}/>
                    <TextField label={`Number of Residues (range) That Will Be Scaffolded`} value={motifTargetResidues} onChange={e => handleMotifResidChange(e.target.value)}/>
                    
                    <TextField label={`Length to be generated in the N Terminus`} value={NTerminus} onChange={e => handleNTerminusChange(e.target.value)}/>                    
                    <TextField label={`Length C Terminus`} value={CTerminus} onChange={e => handleCTerminusChange(e.target.value)}/>                    
                </>
            : null}
        </>
    )     
}