import {useState} from 'react';
import { Autocomplete, FormControl, InputLabel, Select, TextField, Typography} from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {useUser} from "@clerk/clerk-react";  
import {useNavigate} from "react-router-dom";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

import {detectFileTypeGenerator, addJobToQueue, returnS3PathsAndUploadFiles, startLambdaForType, OTHER_JOB_BASE } from '../utils';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const StructuralEvolution = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
				<Header type="structural-evolution"/>
                <Typography>Upload your protein structure to receive mutation recommendations for one chain of interest. To produce mutations for antibody variable domains, run the workflow individually for the heavy and light chains.</Typography>

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
				<FileUpload user={user} isSignedIn={isSignedIn} jobName={jobName} duplicateJob={duplicateJob} exceed={exceed}/>
			</Stack>
		</>
		)
	}

const FileUpload = ({user, isSignedIn, jobName, duplicateJob, exceed}) => {
	const navigate = useNavigate();
	const [proteinFiles, setProteinFiles] = useState([]);
	const [chain, setChain] = useState('');
	const [upperLimit, setUpperLimit] = useState('')
	const parsePdb = require('parse-pdb');
	const [sequences, setSequences] = useState([])
    const d = {'CYS': 'C', 'ASP': 'D', 'SER': 'S', 'GLN': 'Q', 'LYS': 'K',
        'ILE': 'I', 'PRO': 'P', 'THR': 'T', 'PHE': 'F', 'ASN': 'N', 
        'GLY': 'G', 'HIS': 'H', 'LEU': 'L', 'ARG': 'R', 'TRP': 'W', 
        'ALA': 'A', 'VAL':'V', 'GLU': 'E', 'TYR': 'Y', 'MET': 'M'}


	const submitFile = (pay) => {
		if (proteinFiles.length === 0 || !chain) {
			alert("Please make sure you've submitted your protein file and picked a chain");
			return false;
		}
//test
		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

		const config = {
			pdbFile: proteinFilePaths[0],
			chain: chain
		}

		if (upperLimit)
			config['upperLimit'] = upperLimit

		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), "structural-evolution");
		startLambdaForType('structural-evolution');
		navigate("/app/results");
	}


    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsText(file);
        });
      };
    
    function filterUniqueField(arr, field) {
    const uniqueValues = new Set();
    return arr.filter(obj => {
        const value = obj[field];
        if (uniqueValues.has(value)) {
        return false;
        } else {
        uniqueValues.add(value);
        return true;
        }
    });
    }

	const handleUpdateFiles = async (fileItems) => {
		setProteinFiles(fileItems);
		if (fileItems.length === 0) {
			setSequences([]);
			return;
		}
	
		let allChainsList = [];
	
		for (const fileItem of fileItems) {
			const file = fileItem.file;
			try {
				const text = await readFileAsync(file);
				const structure = await parsePdb(text);
				const uniqueChains = new Set();
				structure.atoms.forEach(item => uniqueChains.add(item.chainID));
				let myChains = Array.from(uniqueChains);
	
				for (const c of myChains) {
					let atoms = structure.atoms.filter(s => s.chainID === c && s.name === "N");
					// make sure unique by resSeq
					atoms = filterUniqueField(atoms, 'resSeq');
					let seq = atoms.map(obj => d[obj.resName]).join("");
					let idxs = atoms.map(obj => obj.resSeq);
					allChainsList.push({
						file: file.name,
						chain: c,
						sequence: seq,
						idxs: idxs,
						checked: true,
						fixed: ""
					});
				}
			} catch (error) {
				console.error('Error processing file:', file.name, error);
			}
		}
	
		// console.log("seqs:", allChainsList);
		setSequences(allChainsList);
	};
	
	return (
		<Stack spacing={2}>
			<Typography variant='h6'>Protein Structure</Typography>
			<Typography variant='body'>Please make sure that the residues of your chain of interest are numbered 1-length (i.e. the first residue that appears in the pdb is index 1).</Typography>

			<FilePond
			files={proteinFiles}
			allowReorder={true}
			allowMultiple={false}
			onupdatefiles={handleUpdateFiles}
			labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your protein pdb files'
			credits={[]}
			acceptedFileTypes={["chemical/x-pdb"]}
			fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
			/>

			{sequences.map((chain, index) => (
				<>
					<Typography >{`Input chain name: ${chain.chain}, seq: ${chain.sequence.slice(0, 10)}...`}</Typography>
				</>
			))}

			<Autocomplete
			id="tags-standard"
			options={sequences ? sequences.map(seq => seq.chain) : []}
			getOptionLabel={(option) => option}
			onChange={(e, value) => setChain(value)}
			renderInput={(params) => (
			<TextField
				{...params}
				variant="standard"
				label="Chain"
				placeholder={sequences ? "Chain you would like to evolve": "Please upload a structure before picking a chain"}
			/>
			)}
			/>
						
			<Autocomplete
			id="tags-standard"
			options={sequences && sequences.filter((seq) => seq.chain == chain).length > 0 ? sequences.filter((seq) => seq.chain == chain)[0].sequence.split('').map((ele, index) => `${index+1}`) : []}
			getOptionLabel={(option) => option}
			onChange={(e, value) => setUpperLimit(value)}
			renderInput={(params) => (
			<TextField
				{...params}
				variant="standard"
				label="(Optional) Upper Limit"
				placeholder={""}
			/>
			)}
			/>

			<Typography variant='body'>Number of residues to consider for mutation, e.g. mutations will be recommended in the residue indices in the range 1 to selected upper limit. </Typography>

			<Typography variant='body'>Please make sure that the residues of your chain of interest are numbered 1-length (i.e. the first residue that appears in the pdb is index 1).</Typography>
			
			<SubmitButton redir="structural-evolution" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
			
					
		</Stack>
	)
}