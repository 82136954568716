import { Stack, Button } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const AntiFold = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [H, setH] = useState("H");
        const [L, setL] = useState("L");

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const config = {pdb: filePaths.join(","), heavy: H, light:L, region: regions.join(" ")};
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "antifold");
                startLambdaForType("antifold")
                navigate("/app/results");
        }




        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
        };
        
        const names = [                  
        "all",
        "allH",
        "allL",
        "FWH",
        "FWL",
        "CDRH",
        "CDRL",
        "FW1",
        "FWH1",
        "FWL1",
        "CDR1",
        "CDRH1",
        "CDRL1",
        "FW2",
        "FWH2",
        "FWL2",
        "CDR2",
        "CDRH2",
        "CDRL2",
        "FW3",
        "FWH3",
        "FWL3",
        "CDR3",
        "CDRH3",
        "CDRL3",
        "FW4",
        "FWH4",
        "FWL4",
        ];
        
        
          const [regions, setRegions] = useState(['CDR1', 'CDR2', 'CDR3']);
        
          const handleChange = (event) => {
            const {
              target: { value },
            } = event;
            setRegions(
              // On autofill we get a stringified value.
              typeof value === 'string' ? value.split(',') : value,
            );
          };

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="antifold"/>
                <Typography variant='body'>Given an input antibody structure, AntiFold returns a set of sequences for that backbone.</Typography>
                <Typography variant='body'>Upload your structure to get more detail on which residues to design.</Typography>
                <Typography variant='body'>Next steps may include inputting the resulting sequence into ImmuneBuilder or AlphaFold to verify the result.</Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Antibody:</Typography>
                <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"pdb"})}
                />

                <div style={{ display: 'flex' }}>
                        <TextField 
                        label='H chain'
                        value={H}
                        onChange={e => setH(e.target.value)}
                        style={{ width: '100px', marginRight:"20px" }}
                        />
                        <TextField 
                        label='L chain'
                        value={L}
                        onChange={e => setL(e.target.value)}
                        style={{ width: '100px' }}
                        />
                </div>

                <div>
                        
                <Typography>
                Select region in your input as described in IMGT. We will handle renumbering for you if your input isn't up to this specification. 
                </Typography>

                        <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="demo-multiple-chip-label">Region</InputLabel>
                                <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={regions}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Region" />}
                                renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                ))}
                                </Box>
                                )}
                                MenuProps={MenuProps}
                                >
                                {names.map((name) => (
                                <MenuItem
                                key={name}
                                value={name}
                                >
                                {name}
                                </MenuItem>
                                ))}
                                </Select>
                        </FormControl>
                </div>

                <SubmitButton redir="antifold" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}