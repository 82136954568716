import { Stack, Button, Autocomplete, TextField,  Typography } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ADMET = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const navigate = useNavigate();
    const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
    const [smiles, setSmiles] = useState([]);

    const MAX_NUM_SMILES = 200;

    const handleChange = (event) => {
        if (smiles.length > MAX_NUM_SMILES) {
            return;
        }
        const inputLines = event.target.value.split('\n');
        // Ensure that only up to 10,000 lines are considered
        const smilesArray = inputLines.slice(0, MAX_NUM_SMILES);
        setSmiles(smilesArray);
      };

      
	const submit = (pay) => {
		const configs = {
            'smilesStrings': smiles,
        }

		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(configs), "admet");
        let email = user.emailAddresses[0].emailAddress;
        
        if (email.includes("@septerna.com") || email == "sherry.tamarind@gmail.com") {
            startLambdaForType('septerna-admet');
        } else {
            startLambdaForType('admet');   
        }

        navigate("/app/results");
	}

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="admet"/>
                <Typography variant='body'>Given a list of molecules as SMILES strings, predict the Absorption, Distribution, Metabolism, Excretion and Toxicity Properties(ADMET) for each of them.</Typography>
                <Typography variant='body'>Currently limited to {MAX_NUM_SMILES} molecules at a time, get in touch at info@tamarind.bio if you'd like to try a larger scale(millions or billions is acceptable!)</Typography>

                
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Paste SMILES strings below, one on each line. Pasting from a spreadsheet is okay!</Typography>

                {
                    /*
                    value={smiles.join('\n')}
                    */
                }

                <TextField
                multiline
                minRows={4}
                maxRows={10}
                placeholder={`Enter SMILES strings, each on a new line (max ${MAX_NUM_SMILES})`}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                />

                <Typography>{smiles.length} / {MAX_NUM_SMILES}</Typography>

                <SubmitButton redir="admet" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}