import React, {useState} from 'react';
import {Button, TextField, Stack, Typography, Snackbar, Alert, Dialog} from '@mui/material'
import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import {
    useUser,
} from "@clerk/clerk-react";  
import Navigation from "./Navigation";

function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function Main() {

    const onSubmitCreditsJob = (numCredits, jobName) => {
        while(!isLoaded){

        }

        alert("Submitted! The download of your pdb file will begin in a few seconds.")

        try {
          let date = new Date().toJSON().slice(0, 10);
          console.log(date)
          if(user.unsafeMetadata.jobs){
            (user.unsafeMetadata.jobs).push({"name":jobName, "type": "monomer", "sequence": sequence, "status":"complete", "created":date, "cost":numCredits})
          }
          user.update({
            unsafeMetadata: {
              credits: user.unsafeMetadata.credits ? parseInt(user.unsafeMetadata.credits) - parseInt(numCredits): parseInt(numCredits),
              jobs: user.unsafeMetadata.jobs ?  user.unsafeMetadata.jobs : 
                    [{"name":jobName, "type": "monomer", "status":"complete","sequence": sequence,"created":date, "cost":numCredits}]
            },
          });          
    
          navigate('/app/results');
        } catch (error) {
          console.log(error);
        }
    };    

    const submitSequence = async (sequence, filename) => {
        const length_limit = 390;

        if (!filename) {
            filename = "Tamarind Protein Prediction"
        }

        if (sequence.length > length_limit) {
            alert("This sequence seems to be too long! Please limit sequence lengths to 390 amino acids.");
        }

        sequence = sequence.replace(':', 'X');
        
        //predicted pdb 
        let data = ""
        try{
            data = await axios.post('https://api.esmatlas.com/foldSequence/v1/pdb/', sequence);
        }catch (error){
            // alert("Invalid job, please try again.")
            return
        }

        // charge credits
        onSubmitCreditsJob(0, jobName)

        //add error message
        setOpenSuccess(true);

        download(`${filename}.pdb`, data.data);
    }
    
    const getCost = (sequence) => {
        const length = sequence.length;
        const cost = (length / 100) / 2; //will be more computations based on ec2 instance being used
        // console.log("cost calc: ", (cost + 0.5).toFixed())
        return [length, (cost + 0.5).toFixed()]; // round up integer
    }

    const [sequence, setSequence] = useState("MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV");
    const [jobName, setJobName] = useState("sp_P61823");
    const [openSuccess, setOpenSuccess] = useState(false);

    const [pricingDialogue, setPricingDialogue] = useState(false);
    const [confirmDialogue, setConfirmDialogue] = useState(false);
    const [sequenceType, setSequenceType] = useState("Monomer")
    const { isLoaded, isSignedIn, user } = useUser();
    const navigate = useNavigate();

    return (
            <Stack spacing={2} style={{padding: '10px' }}>
                {/*(!user.unsafeMetadata.credits && user.unsafeMetadata.credits != 0)? setBaseCredits() : <></>*/}
                <>

                    <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind ESMFold Online Tool</Typography>
                    <Typography variant="body1">Please input your sequence into the Sequence box. Press submit to recieve a prediction for its structure! </Typography>
                    <Typography>
                        Currently limited to sequence lengths of approximately 390 amino acids, try out our <Link to='/alphafold'>AlphaFold</Link> workflow for longer sequences!
                    </Typography>
                    {/*<Typography variant="body">Input your sequence into the Sequence box, place ":" in between the sequences for multimers. </Typography>*/}

                    <TextField id="standard-basic" label="Sequence Name" onChange={(e) => setJobName(e.target.value)} value={jobName} defaultValue={"j"} key="esm"/>
                    <TextField id="standard-basic" label="Sequence" onChange={(e) => setSequence(e.target.value)} value={sequence} defaultValue={"j"}/>

                    {/*<Button onClick={() => setPricingDialogue(true)}>See Pricing</Button>*/}
                    
                    <Button onClick={() => submitSequence(sequence, jobName)}>
                        Submit Sequence
                    </Button>
                    

                    <Dialog onClose={() => setPricingDialogue(false)} open={pricingDialogue}>
                        <Typography variant="h4">Pricing for your sequence (ESMFold)</Typography>
                        {getCost(sequence)[0] ? 
                        <>
                            <Typography>
                                Sequence Length: {getCost(sequence)[0]}
                            </Typography> 
                            <Typography>
                                Cost: ${getCost(sequence)[1]}
                            </Typography> 

                            <Typography>
                                This will be the number of credits charged to your account.
                            </Typography>

                            {isSignedIn ? 
                            <Typography>
                            Current number of credits: ${user.unsafeMetadata.credits}
                            </Typography> : <></>
                            }

                        </> 
                        : "Please make sure you've entered a sequence!"}
                    </Dialog>

                    <Dialog onClose={() => {setConfirmDialogue(false)}} open={confirmDialogue}>
                    <Typography variant="h4">Confirmation for sequence {jobName} (AlphaFold)</Typography>
                        {getCost(sequence)[0] ? 
                        <>
                            <Typography>
                                Sequence Length: {getCost(sequence)[0].toFixed()}
                            </Typography> 
                            <Typography>
                                Cost: ${getCost(sequence)[1]}
                            </Typography> 

                            {
                                isSignedIn ? user.unsafeMetadata.credits >= (getCost(sequence)[1]) ?
                                <>
                                    <Typography>
                                        Remaining credits: ${(user.unsafeMetadata.credits - getCost(sequence)[1])}
                                    </Typography>
                                    <Button onClick={() => {submitSequence(sequence, jobName); setOpenSuccess(true); setConfirmDialogue(false);}}>Submit</Button>
                                </>
                                :
                                <>
                                    <Typography>
                                        You do not have enough credits for this job. Please purchase ${(getCost(sequence)[1] - user.unsafeMetadata.credits)} credits below:
                                    </Typography>
                                    <Button onClick={() => navigate("/purchase-credits")}>Purchase</Button>
                                </> : <></>
                            }

                        </> 
                        : "Please make sure you've entered a sequence!"}
                    </Dialog>

                    {/* Notifications for (un)sucessful sequence submissions*/}
                    <Snackbar
                        open={openSuccess}
                        autoHideDuration={6000}
                        onClose={() => setOpenSuccess(false)}
                    >
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Job successfully submitted, please wait a few seconds until the pdb file is downloaded.
                        </Alert>

                    </Snackbar>
                </>

            </Stack>

        );
    }

    export default Main;