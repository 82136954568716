import {Autocomplete, Grid, Stack, TextField, Box, Typography, FormControl, InputLabel, IconButton, Select, MenuItem} from '@mui/material';
import {List, ListItem, Chip } from '@mui/material';
import Navigation from './Navigation';
import { useDropzone } from 'react-dropzone';
import {useCallback, useEffect, useReducer, useState} from 'react';
import { addJobToQueueObject, uploadFile, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE, submitBatch, addJobToQueue } from '../utils';
import { useUser } from "@clerk/clerk-react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router';
import {NameField} from './NameField';
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import {SubmitButton} from './SubmitButton';
import ResidueText from './ResidueText';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode);

function Instructions() {
    return (
        <>
            <Typography>Given a pdb containing a protein and ligand, design a new protein containing parts of the original protein and new designed structures. LigandMPNN will be used to generate sequences for each designed backbone. </Typography>
            <Typography>Want to test a large number of potential designs? Get in touch at info@tamarind.bio</Typography>
        </>
    )
}

export const PDBViewer = ({ PDB_ID }) => {
    // Use a state to hold a unique key that changes whenever PDB_ID changes
    const [key, setKey] = useState(PDB_ID);

    useEffect(() => {
        // Whenever PDB_ID changes, generate a new unique key to force re-render
        setKey(`${PDB_ID}-${new Date().getTime()}`);
    }, [PDB_ID]); // Dependence on PDB_ID ensures this effect runs when it changes

    return (
        <div style={{ width: '80%', height: '500px', position: 'relative', margin: "auto" }}>
            <pdbe-molstar key={key} id="pdb-viewer" custom-data-format="pdb"
                sequence-panel	
                landscape="true"
                bg-color-r="255" bg-color-g="255" bg-color-b="255"
                reactive="true"
                hide-water="true"
                custom-data-url={`https://files.rcsb.org/download/${PDB_ID}.pdb`}>
            </pdbe-molstar>
        </div>
    );
};

export default function AllAtomDesign() {
    const [numDesigns, setNumDesigns] = useState("1");
    const [contigs, setContigs] = useState("20-20,A84-87,100-100");
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [files, setFiles] = useState([]);
	const [exceed, setExceed] = useState(false);

    const [diffusion, setDiffusion] = useState(false)
    const [chain, setChain] = useState("")
    const [ranges, setRanges] = useState([])
    const [sequences, setSequences] = useState([])
    const [fixed, setFixed] = useState("")

    const [ligandCodes, setLigandCodes] = useState([])

    const { isLoaded, isSignedIn, user } = useUser();  
    const navigate = useNavigate();
    const parsePdb = require('parse-pdb');

    const d = {'CYS': 'C', 'ASP': 'D', 'SER': 'S', 'GLN': 'Q', 'LYS': 'K',
        'ILE': 'I', 'PRO': 'P', 'THR': 'T', 'PHE': 'F', 'ASN': 'N', 
        'GLY': 'G', 'HIS': 'H', 'LEU': 'L', 'ARG': 'R', 'TRP': 'W', 
        'ALA': 'A', 'VAL':'V', 'GLU': 'E', 'TYR': 'Y', 'MET': 'M'}
    
    const submitJob = (pay) => {
        const fnames = files.map(file => file.filename);

        let email = user.emailAddresses[0].emailAddress
        const cost = pay ? OTHER_JOB_BASE : 0
        let configObj = {contigs: `${calcFinalContigs(true)}`, ligandCode:ligandCode, numDesigns: numDesigns, diffusion: diffusion, chain:chain};

        let oneDesignContigs = JSON.stringify({
                contigs: `${calcFinalContigs(true)}`, 
                ligandCode:ligandCode, 
                numDesigns: 1, 
                diffusion: diffusion,
                pdbFile: fnames.map(fname => `${email}/${fname}`).join(' '),
        });

        // configObj['uploaded_file'] = fnames.map(fname => `${email}/${fname}`).join(' ');
        files.map(file => uploadFile(file.file, user, () => {}));

        let submitNames = []
        let submitConfigs = []
        for (let i = 0 ; i<numDesigns ; i++) {
                submitNames.push(jobName + "-" + i + "-all_atom_design")
                submitConfigs.push(oneDesignContigs)
        }
        submitBatch(submitConfigs, submitNames, "all_atom_design", jobName)
        addJobToQueue(jobName,cost,user,JSON.stringify(configObj), "All Atom Design Batch", '', 'In Queue');
        for (let i = 0 ; i<Math.min(numDesigns, 5) ; i++) {
                startLambdaForType("all_atom_design");
        }
        navigate('/app/results');
    }

    const [ligandCode, setLigandCode] = useState("");

    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsText(file);
        });
      };
    
    function filterUniqueField(arr, field) {
    const uniqueValues = new Set();
    return arr.filter(obj => {
        const value = obj[field];
        if (uniqueValues.has(value)) {
        return false;
        } else {
        uniqueValues.add(value);
        return true;
        }
    });
    }

    const handleUpdateFiles = async (fileItems) => {
        setFiles(fileItems)
        if (fileItems.length == 0 ) {
                setLigandCodes([])
                setSequences([])
                return
        }
        const fileItem = fileItems[0]
        const file = fileItem.file;
        try {
                const text = await readFileAsync(file);

                const lines = text.split('\n');
                const ligands = new Set();

                lines.forEach(line => {
                        if (line.startsWith('HETATM')) {
                        const resName = line.substring(17, 20).trim();
                        if (resName !== 'HOH') { // Exclude water
                                ligands.add(resName);
                        }
                        }
                });
                setLigandCodes([...ligands])
                setLigandCode([...ligands][0])

                let chains_list = []
                try {
                        const text = await readFileAsync(file);
                        const structure = await parsePdb(text);
                        const uniqueChains = new Set();
                        structure.atoms.forEach(item => uniqueChains.add(item["chainID"]));
                        let myChains = Array.from(uniqueChains);
                        for (const c of myChains) {
                                let atoms = structure.atoms.filter(s => s.chainID == c && s.name == "N")
                                // make sure unique by resSeq
                                atoms = filterUniqueField(atoms, 'resSeq');
                                let seq = atoms.map(obj => d[obj["resName"]]).join("")
                                let idxs = atoms.map(obj => obj["resSeq"])
                                chains_list.push({chain: c, sequence: seq, idxs:idxs, checked:true, fixed:""});
                        }
                } catch (error) {
                        console.error('Error reading file:', error);
                }
                // console.log("seqs:", chains_list)
                setSequences(chains_list)
                setChain(chains_list[0].chain)
        }catch (error) {
                console.error('Error reading file:', error);
        }
    };

    const getFixedResidues = (fixed) => {
        //     console.log("in fixed res:",fixed)
            let fixedSet = new Set([])
            fixed = fixed.replace(/,/g, " ").trim()
            let fixed_arr = fixed.split(/\s+/)

            if (fixed_arr.length > 0 && fixed_arr[0] != "") {
                    for (const f of fixed_arr) {
                            if (f.includes("-")) {
                                    let begin = parseInt(f.split("-")[0])
                                    let end = parseInt(f.split("-")[1])
                                    for (let i = begin ; i<=end ; i++) {
                                            fixedSet.add(i)
                                    }
                            } else {
                                    fixedSet.add(parseInt(f))
                            }
                    }
            }
            return fixedSet
    }

    let chosenSeq = sequences.filter((seq) => seq.chain == chain)
    chosenSeq = chosenSeq.length == 0 ? "" : chosenSeq[0]
    let fixedCurr = chosenSeq.fixed.length == 0 ? new Set() : getFixedResidues(chosenSeq.fixed)
//     console.log("fixedCurr:", fixedCurr)
    const chosenIdx = sequences.findIndex(seq => seq.chain == chain);
    // console.log("chosenIdx:", chosenIdx)
    // console.log(chosenSeq)

    useEffect(() => {
        if (chosenSeq == "") return
        // console.log("chosenSeq:", chosenSeq)
        if (chosenSeq.fixed == "") {
                handleSeqChange(chosenIdx, "fixed", "")
        }
    }, [chosenSeq])

    let bad = []
    for (const r of fixedCurr) {
            if (!chosenSeq.idxs.includes(r)) {
                    bad.push(r)
            }
    }
    
    let displaySeq = []
    if (chosenSeq != "") {
        for (let i = 0; i < chosenSeq.sequence.length; i++) {
            if (i != 0) {
                    for (let j = chosenSeq.idxs[i-1] ; j<chosenSeq.idxs[i] - 1; j++) {
                            displaySeq.push(<span>-</span>)
                    }
            }
            if (i != 0 && (i + 1) % 20 === 1) {
                    displaySeq.push(<sup style={{fontSize: "x-small"}}>({chosenSeq.idxs[i-1]})</sup>);
                    displaySeq.push("\t");
            }
            displaySeq.push(<ResidueText fixedCurr={fixedCurr} index={i} char={chosenSeq.sequence[i]} resIndex={chosenSeq.idxs[i]}/>);
            if (i == chosenSeq.sequence.length - 1) {
                    displaySeq.push(<sup style={{fontSize: "x-small"}}>({chosenSeq.idxs[i]})</sup>);
            }
        }
    }
//     console.log(displaySeq)

    const handleSeqChange = async (index, field, value) => {
        // console.log("calling handle change with:", index, field, value)
        // if (field == "sequence" && sequences.length != 0) {
        //         console.log("seqs not empty")
        //         return
        // }
        const newData = [...sequences];
        newData[index][field] = value;
        let ranges_ = []

        // console.log(index, field, value)
        
        if (field == "fixed" && newData[index].chain == chain) {
                let fixed_arr = value.replace(/,/g, " ").trim().split(/\s+/)
                // console.log("FIXED:", fixed_arr)
                if (fixed_arr.length > 0 && fixed_arr[0] != "") {
                        for (const f of fixed_arr) {
                                if (f == "") continue
                                // console.log(f)
                                let begin = f.includes("-") ? parseInt(f.split("-")[0]) : parseInt(f)
                                let end = f.includes("-") ? parseInt(f.split("-")[1]) : parseInt(f)
                                let prevLength = ranges_.length == 0 ? 0 : ranges_[ranges_.length - 1].end
                                let numBetween = 0
                                for (let i = prevLength + 1 ; i<begin ; i++) {
                                        if (newData[index].idxs.includes(i)){
                                                numBetween++
                                                // console.log(i)
                                        }
                                }
                                ranges_.push({start:numBetween, end:numBetween, show:diffusion})
                                ranges_.push({start:begin, end:end, show:true})
                        }
                }
                let prevLength = ranges_.length == 0 ? 0 : ranges_[ranges_.length - 1].end
                // let startingLength = Math.max(0, newData[index].sequence.length - prevLength)
                let numBetween = 0
                for (let i = prevLength + 1 ; i<=newData[index].idxs.at(-1) ; i++) {
                        if (newData[index].idxs.includes(i)){
                                numBetween++
                                // console.log(i)
                        }
                }
                ranges_.push({start:numBetween, end:numBetween, show:true})
                let oddRanges = ranges_.filter((_, index) => index % 2 !== 0);
                oddRanges.sort((a, b) => parseInt(a.start) - parseInt(b.start));
                for (let i = 1; i < ranges_.length; i += 2) {
                        ranges_[i] = oddRanges[Math.floor(i/2)];
                }
                setRanges(ranges_)
        }

        setSequences(newData);
};

    // const handleFixedChange = (fixed) => {
    //     setFixed(fixed)
    //     if (fixed == "") return
    //     let contigs = ""
    //     let ranges = fixed.replace(/,/g, " ").trim().split(/\s+/)
    //     let lastIncluded = chosenSeq.idxs[0] - 1
    //     for (const r of ranges){
    //         let [first, second] = r.split("-");
    //         let between = 0
    //         for (let i = lastIncluded + 1; i<parseInt(first) ; i++) {
    //             if (chosenSeq.idxs.includes(i)) {
    //                 between++
    //             }
    //         }
    //         console.log(between)
    //         if (between != 0) {
    //             contigs += between + "-" + between + ","
    //         }
    //         contigs += chain + r + ","
    //         lastIncluded = parseInt(second)
    //     }
    //     let between = 0
    //     for (let i = lastIncluded + 1; i<=parseInt(chosenSeq.idxs[chosenSeq.idxs.length - 1]) ; i++) {
    //         if (chosenSeq.idxs.includes(i)) {
    //             between++
    //         }
    //     }
    //     if (between != 0)
    //         contigs += between + "-" + between
    //     console.log(contigs)
    // }

    function getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    let calcFinalContigs = (sample) => {
            let finalContigs = ""
            for (let i = 0 ; i<ranges.length ; i++){
                    let range = ranges[i]
                    if (range.show && (range.end != 0)) {
                            if (i % 2 == 1) {
                                    finalContigs += chain
                                    finalContigs += range.start + "-" + range.end + ","
                            } else if (sample){
                                    let sampledLength = getRandomIntInclusive(range.start, range.end)
                                    finalContigs += sampledLength + "-" + sampledLength + ","
                            } else {
                                    finalContigs += range.start + "-" + range.end + ","
                            }
                    }
                    
            }
            finalContigs = finalContigs.slice(0, -1)
            return finalContigs
    }

    let finalContigs = calcFinalContigs(false)

    const handleRangeChange = (index, field, value) => {
            if (diffusion) return;
            const updatedRanges = [...ranges];
            updatedRanges[index][field] = value;
            setRanges(updatedRanges);
    };

    useEffect(() => {
        if (chosenSeq == "") return
        handleSeqChange(chosenIdx, 'fixed', chosenSeq.fixed)
    }, [diffusion])

    let disableReasons = []
    if (files.length == 0) {
        disableReasons.push("No pdb file uploaded")
    }
    if (ligandCode == "") {
        disableReasons.push("No ligand code specified")
    }
    if (chain == "") {
        disableReasons.push("No chain specified")
    }
    if (calcFinalContigs(false) == "") {
        disableReasons.push("Empty contigs - adjust fixed residues and ranges")
    }

    return (
        <Stack spacing={2} style={{padding: '10px' }}>
        <Header type="all_atom_design"/>
            <Instructions />

            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

            <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={false}
                onupdatefiles={handleUpdateFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["chemical/x-pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
            />

            <Autocomplete
                    disablePortal
                    freeSolo
                    options={ligandCodes}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Ligand Name" />}
                    defaultValue={""}
                    value={ligandCode}
                    onInputChange = {(e, val) => setLigandCode(val)}
                    onChange={(e, val) => setLigandCode(val)}
            />

            {/* <Typography>As an example(from the authors) to include protein residues A84-87 in the motif, use '20-20,A84-87,100-100', which tells the model to design a protein containing the 4 residue motif A84-87 with 20, 100 residues on either side.</Typography> */}
            <FormControl sx={{width:300}}>
                    <InputLabel>Chain</InputLabel>
                    <Select
                            value={chain}
                            onChange={(event) => setChain(event.target.value)}
                            label="Chain"
                            fullWidth
                    >
                            {sequences.map((item, i) => <MenuItem value={item.chain}>{item.chain}</MenuItem>)}
                    </Select>
            </FormControl>

            {
                !chain ? null : 
                <>
                <div>
            <Typography variant='body'>Diversify residues from existing structure (if unchecked, residues will be kept fixed)</Typography>
            <Checkbox onChange={() => setDiffusion(!diffusion)} checked={diffusion}></Checkbox>
            </div>

            {/* <TextField label='Contigs' value={contigs} onChange={e => setContigs(e.target.value)}/> */}
            <Typography fontWeight="bold">Specify residues to be {!diffusion ? "kept" : "fixed (not diversified)"} from the original protein below. Highlighted residues indicate those which are selected. Individual residues (ex. 5) and ranges (ex. 5-10) are accepted, along with multiple segments. Hover to view residue indices.</Typography>

            <p>{displaySeq}</p>
            <br></br>

            <TextField
            label={`Fixed Residues`}
            value={chosenSeq.fixed}
            sx={{ width:"50%", marginRight: '10px' }}
            // onChange={(event) => handleFixedChange(event.target.value)}
            onChange={(event) => handleSeqChange(chosenIdx, 'fixed', event.target.value)}
            placeholder="2, 12-13"
            />
            {
            bad.length > 0 ? 
            <Typography sx={{color:"red"}}>Error: Residues {bad.join(",")} are not present in your pdb.</Typography>
            : null
            }

            {!diffusion ? <Typography fontWeight="bold">Add additional structures to scaffold your fixed residues (by default, a new protein the length of the original will be designed). </Typography> : null}

            <Grid sx={{display:"flex",overflowX: 'auto', flexDirection:"row"}}>
            {ranges.map((range, index) => (
                    <Grid item sx={{marginRight:7, justifyContent:"center", alignItems:"center"}} key={index}>
                            {
                            index % 2 == 1 ? 
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <Typography noWrap sx={{backgroundColor:'#c6e4c6'}}>{chosenSeq.chain}{range.start}-{range.end}</Typography></Box>:
                            range.show ? 
                            <>
                            <TextField
                            label="Start"
                            value={range.start}
                            onChange={(e) => handleRangeChange(index, 'start', e.target.value)}
                            variant="standard"
                            sx={{marginRight:"5px", width:"70px"}}
                            disabled={diffusion}
                            style={{
                                    color: 'black', // Set text color to black
                                    backgroundColor: 'inherit', // Set background color to transparent
                                    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, // Set border color to black
                                    }}
                            />
                            <TextField
                            label="End"
                            value={range.end}
                            onChange={(e) => handleRangeChange(index, 'end', e.target.value)}
                            variant="standard"
                            sx={{width:"70px"}}
                            disabled={diffusion}
                            />
                            {
                                    diffusion ? <></> : 
                                    <IconButton onClick={(e) => handleRangeChange(index, 'show', false)}>
                                    <CloseIcon />
                                    </IconButton>
                            }
                            </> :
                            <>
                            <IconButton onClick={(e) => handleRangeChange(index, 'show', true)}>
                            <AddIcon />
                            </IconButton>
                            </>
                            }
                    </Grid>
            ))}
            </Grid>

            <Typography>Your RFdiffusion Contigs: <i>{finalContigs}</i></Typography>
           

            {/* <TextField label='Ligand Name' value={ligandCode} onChange={e => setLigandCode(e.target.value)}/> */}

            <Autocomplete
                disablePortal
                options={["1", "2", "4", "8", "16", "32",]} //'100', '500']}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Number of Designs" />}
                defaultValue={"1"}
                onChange={(e, val) => setNumDesigns(val)}
            /> 
                </>
            }

            

            <SubmitButton redir="all-atom-design" duplicate={duplicateJob} disable={disableReasons.length > 0} disableReasons={disableReasons} exceed={exceed} onSubmit={submitJob}>Submit</SubmitButton>

        </Stack>
    )
}

/*
export default function RFDiffusion() {
    const [numDesigns, setNumDesigns] = useState("1");
    const [contigs, setContigs] = useState("100");
    const [numIterations, setNumIterations] = useState("1");
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [success, setSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [pdb, setPdb] = useState('');
    const [hotspot, setHotspot] = useState('');
    const [filterChecked, setFilterChecked] = useState(false);
	const [exceed, setExceed] = useState(false);

    const { isLoaded, isSignedIn, user } = useUser();  

    const navigate = useNavigate();

    const submitJob = (pay) => {
        if (files.length === 0) {
            Swal.fire(`Please make sure to upload a file, unconditional generation is currently not supported. \n Your contig is: ${contigs}`)
            return;
        }
        
        const fnames = files.map(file => file.filename);
        //const = `Name:${jobName} Contigs:${contigs} NumDesigns:${numDesigns} pdb:${fnames.join(' ')}${pdb} hotspot: ${hotspot}`;

        let configObj = {name: jobName, contigs: contigs, numDesigns: numDesigns, hotspot: hotspot, filter: filterChecked};
        let email = user.emailAddresses[0].emailAddress
        configObj['uploaded_file'] = fnames.map(fname => `${email}/${fname}`).join(' ');
        configObj['pdb_id'] = pdb;

        const cost = pay ? OTHER_JOB_BASE : 0
        addJobToQueueObject(jobName,cost,user,JSON.stringify(configObj), "rfdiffusion", '', 'In Queue');
        files.map(file => uploadFile(file.file, user, () => {}));
        startLambdaForType("rfdiffusion");  

        navigate('/user');
    }

    return (
        <Stack spacing={2} style={{padding: '10px' }}>
            <Grid container>
                <Navigation />
            </Grid>

        <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind <b>RFdiffusion</b> Protein Design </Typography>
    

        <Instructions />
        
        <Typography>
            Set your settings and steer RFDiffusion to design a protein to your choosing. You can track the status of your job under the "My Workflows" tab! 
        </Typography>

        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
        <Contigs contigs={contigs} setContigs={setContigs}/>
        
        <Autocomplete
            disablePortal
            options={["1", "2", "4", "8", "16", "32"]}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Number of Designs" />}
            defaultValue={"1"}
            onChange={(e, val) => setNumDesigns(val)}
        />

        <TextField onChange={e => setHotspot(e.target.value)} label='Hotspot'></TextField>
    
        <div>
            <Typography variant='body'>Enable filtering with ProteinMPNN and AlphaFold?</Typography>
            <Checkbox onChange={() => setFilterChecked(!filterChecked)}></Checkbox>
        </div>


        <Typography variant='h6'>
            Upload pdb file, or type in the name below.
        </Typography>


        <FilePond
            files={files}
            allowReorder={true}
            allowMultiple={true}
            onupdatefiles={setFiles}
            labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
            credits={[]}
            acceptedFileTypes={["chemical/x-pdb"]}
            fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
        />

        <SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submitJob}>Submit</SubmitButton>


        </Stack>
    );
  }
*/