import { Stack, Button, MenuItem, InputLabel, Select } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE } from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const REINVENT = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [numSmiles, setNumSmiles] = useState(100);
        const [sampleStrat, setSampleStrat] = useState("beamsearch");
        const [temp, setTemp] = useState(1);
        const [model, setModel] = useState('reinvent');
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const returnS3PathsAndUploadFiles = (file) => {
                let email = user.emailAddresses[0].emailAddress
		uploadFile(file, user, () => null);

		return email + "/" + file.name;
	}

        const submit = (pay) => {
                if (model != "reinvent" && files.length === 0) {
                        alert("Please make sure you've inputted your file.");
			return false;
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(f.file));

                let configObj = {name: jobName, smi: filePaths.join(","), num_smiles: numSmiles, model: model, temp: temp, sample: sampleStrat};
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "reinvent");
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}><Navigation />
                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind REINVENT4 Online Tool</Typography>
                <Typography variant='body'>
                        REINVENT generates small molecules. We currently offer REINVENT for sampling without a scoring system. Select a model and receive a csv of generated SMILES.
                        </Typography>
                        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <InputLabel >Model</InputLabel>
                        <Select
                        value={model}
                        label="Model"
                        onChange={(e) => 
                                setModel(e.target.value)
                        }
                        >
                        <MenuItem value={"reinvent"}>De novo (Reinvent)</MenuItem>
                        <MenuItem value={"libinvent"}>R-groups for scaffolds (LibInvent)</MenuItem>
                        <MenuItem value={"linkinvent"}>Find linker for two fragments (LinkInvent)</MenuItem>
                        <MenuItem value={"mol2mol"}> Molecule Optimization (Mol2Mol)</MenuItem>
                        </Select>
                {
                        model == "reinvent" ?
                        <>
                          <Typography variant='body'>Specify the number of smiles molecules you would like to generate </Typography>
                        <TextField label='Number Smiles' 
                        onChange={
                        (e) => {
                        setNumSmiles(e.target.value)
                        }} 
                        value={numSmiles}></TextField>
                        </>
                        :   
                        <>
                        <Typography variant='body'>SMILE file:</Typography>
                        <FilePond
                        files={files}
                        allowReorder={true}
                        allowMultiple={true}
                        onupdatefiles={setFiles}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your SMILES file'
                        credits={[]}
                        acceptedFileTypes={["smi"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".smi":"smi"})}
                        />
                        </>
                }
                {
                        model == "mol2mol" ?     
                        <>
                      <TextField label='Temperature' 
                      onChange={
                      (e) => {
                      setTemp(e.target.value)
                      }} 
                      value={temp}></TextField>

                        <Select
                        value={sampleStrat}
                        label="Sampling Strategy"
                        onChange={(e) => 
                                setSampleStrat(e.target.value)
                        }
                        >
                        <MenuItem value={"beamsearch"}>Beam Search (deterministic)</MenuItem>
                        <MenuItem value={"multinomial"}>Multinomial (non-deterministic)</MenuItem>
                        </Select>
                      </> : null
                }
              
              
   

                <SubmitButton redir="reinvent" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
