import React, { useEffect, useRef, useState } from 'react';
import { Stack, Chip, Typography } from "@mui/material";

const ResidueSelector = ({ epitopes, setEpitopes, antigen }) => {
  const stageRef = useRef(null);
  const stageInstance = useRef(null); // Ref to store the stage instance

  const [atom, setAtom] = useState("None selected")

  const extractAndFormat = (text) => {
    // Regular expression to capture the atom type and the number
    const atomTypeRegex = /:(\w)/;
    const numberRegex = /\[(\w+)\](\d+)/;
  
    // Extract atom type
    const atomTypeMatch = text.match(atomTypeRegex);
    if (!atomTypeMatch) {
      console.error('Atom type not found in text:', text);
      return null;
    }
    const atomType = atomTypeMatch[1];
  
    // Extract number
    const numberMatch = text.match(numberRegex);
    if (!numberMatch) {
      console.error('Number not found in text:', text);
      return null;
    }
    const number = numberMatch[2];
  
    // Combine them into the desired format
    return `${atomType}${number}`;
  };

  useEffect(() => {
    if (antigen == "") {
      console.log("no antigen")
      return
    }
    // console.log(antigen)
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/ngl@latest/dist/ngl.js'; // Updated script URL
    script.async = true;
    script.onload = () => {
      const stage = new window.NGL.Stage(stageRef.current);
      stageInstance.current = stage;

      var binaryBlob = new Blob( [ antigen.file ? antigen.file : antigen ], { type: 'application/octet-binary'} );
      const objectURL = URL.createObjectURL(binaryBlob);
      stage.loadFile(objectURL, { ext: "pdb" }).then( component => {
        // stage.loadFile('rcsb://1crn').then(component => {
          component.addRepresentation('cartoon');
          // var selection = new window.NGL.Selection(":468 and /N");
          // console.log(selection)
          component.autoView();
          // component.autoView(selection);
          // component.addRepresentation("ball+stick", {sele: selection});
        });
      stage.setParameters({'backgroundColor':'lightgrey'});
      // }

      const handleClick = (event) => {
        if (stageRef.current) {
          let residue = extractAndFormat(stageRef.current.querySelector('div:nth-of-type(2)').textContent.trim())
          if (!epitopes.includes(residue)) {
            if (residue != "" && residue) {
              setEpitopes((prevItems) => {
                const newItems = new Set(prevItems); // Create a new Set from previous items
                newItems.add(residue); // Add the new residue
                return Array.from(newItems); // Return the updated Set
              })
            }
          }
        }
      };

      stageRef.current.addEventListener('click', handleClick);

      return () => {
        stageRef.current.removeEventListener('click', handleClick);
      };
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [antigen]);

  const handleDelete = (chipToDelete) => () => {
    setEpitopes((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  return (
  <>
  {
    antigen == "" ? null : 
    <>
    {/* <Typography>{epitopes}</Typography> */}
    <Stack direction="row" spacing={1}>
      {epitopes.map((chip) => (
        <>
        <Chip
          key={chip}
          label={chip}
          onDelete={handleDelete(chip)}
        />
        </>
      ))}
    </Stack>
    <br></br>
    <div id="viewport" ref={stageRef} style={{ width: '100%', height: '80vh' }} />
	</>
  }
  </>
 
  );
};

export default ResidueSelector;
