import { Stack, Button, Link } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import Swal from "sweetalert2";
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const AfCluster = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [seq, setSeq] = useState('');
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {
                if (files.length === 0) {
                        Swal.fire("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const config = JSON.stringify({a3m: filePaths[0]});
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, config, "afcluster");
                startLambdaForType('afcluster')
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="afcluster"/>
                <Typography variant='body'>Given a multiple sequence alignment (MSA), generate clusters for conformation prediction</Typography>
                <Typography variant='body'>If you only have an amino acid sequence, you can get an ".a3m" file using the result of our <Link href='/alphafold'>AlphaFold</Link> tool. This will also produce a structure prediction for this sequence alongside producing the MSA.</Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>MSA:</Typography>
                <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your a3m file'
                credits={[]}
                acceptedFileTypes={["a3m"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".a3m":"a3m"})}
                />
                
                {/*
                <Typography variant='body'>If you do not have an MSA, input your protein sequence below.</Typography>
                <TextField label='Sequence' 
                onChange={
                (e) => {
                setSeq(e.target.value)
                }} 
                value={seq}></TextField>
                */}
   

                <SubmitButton redir="afcluster" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
