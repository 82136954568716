import { Stack, Button, ToggleButtonGroup, Checkbox, ToggleButton } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile} from './UIComponents'
import { all } from "axios";

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const DeepMainMast = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [disable, setDisable] = useState(false);

        const [files, setFiles] = useState([]);
        const [fastaFiles, setFastaFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
        const [sequence, setSequence] = useState('SPRTLNAWVKVVEEKAFSPEVIPMFSALSEGATPQDLNTMLNTVGGHQAAMQMLKETINEEAAEWDRLHPVHAGPIAPGQMREPRGSDIAGTTSTLQEQIGWMTHNPPIPVGEIYKRWIILGLNKIVRMYSPTSILDIRQGPKEPFRDYVDRFYKTLRAEQASQEVKNWMTETLLVQNANPDCKTILKALGPGATLEEMMTACQGVGGPGHKARVLAEAMSQVT')
        const [file, setFile] = useState(false)

        const [useAlpha, setUseAlpha] = useState(false)
        const [pdbFiles, setPdbFiles] = useState(false)
        const [allAtom, setAllAtom] = useState(false)

        const [contour, setContour] = useState(0.01)

        const submit = (pay) => {
                if (!isSignedIn) return
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                let config = {map: filePaths[0], contour:contour};
                if (file) {
                        config["fastaFile"] = returnS3PathsAndUploadFiles(user, fastaFiles[0].file);
                } else {
                        config["sequence"] = sequence.replace(/\*/g, "")
                }
                if (useAlpha) {
                        config["pdbFile"] = returnS3PathsAndUploadFiles(user, pdbFiles[0].file);
                }
                if (allAtom) {
                        config["allAtom"] = true
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "deepmainmast");
                let email = user.emailAddresses[0].emailAddress
                if (email.includes("septerna") || email == "sherry.tamarind@gmail.com") {
                        startLambdaForType('septerna-deepmainmast');
                } else {
                        startLambdaForType('deepmainmast');
                }
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind DeepMainMast Automated Atomic Model Building Tool</Typography>
                <Typography variant='body'>Given a density map and a protein sequence, automatically produce an atomic model for the input. </Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Map:</Typography>
                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['mrc']} setDisable={setDisable}/>
                {/* <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your mrc file'
                credits={[]}
                acceptedFileTypes={["mrc"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".mrc":"mrc"})}
                /> */}
                
                <ToggleButtonGroup color="primary" value={file} exclusive onChange={(e, newType) => setFile(newType)}> 
                        <ToggleButton value={false}>Sequence</ToggleButton>
                        <ToggleButton value={true}>File</ToggleButton>
                </ToggleButtonGroup>
              
                {
                        file ? 
                        <FilePond
                        files={fastaFiles}
                        allowReorder={true}
                        allowMultiple={false}
                        onupdatefiles={setFastaFiles}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your fasta file'
                        credits={[]}
                        acceptedFileTypes={["fasta", "fa"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta", ".fa":"fa"})}
                        /> :                 
                        <>
                        <Typography>Complexes can be inputted with each chain seperated with a <b>:</b>, where <b>:</b> denotes a chain break.</Typography>
                        <Typography>E.g. <b>chainA:chainA:chainA</b> would be a trimer and a <b>chainA:chainB</b> would be pair of chains.</Typography>  
                        <TextField onChange={e => setSequence(e.target.value)} label='Protein Sequence' value={sequence}></TextField>
                        </>
                }

            <div>
            <Typography variant='body'>Use Alphafold Structure</Typography>
            <Checkbox onChange={() => setUseAlpha(!useAlpha)} checked={useAlpha}></Checkbox>
            </div>

            {
                useAlpha ? 
                <FilePond
                files={pdbFiles}
                allowReorder={true}
                allowMultiple={false}
                onupdatefiles={setPdbFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"pdb"})}
                /> : null
            }
                {
                        isSignedIn && (user.emailAddresses[0].emailAddress.includes("septerna.com")  || user.emailAddresses[0].emailAddress == "sherry.tamarind@gmail.com")? 
            <div>
            <Typography variant='body'>All Atom Structure with Rosetta</Typography>
            <Checkbox onChange={() => setAllAtom(!allAtom)} checked={allAtom}></Checkbox>
            </div> : null
                }
            
            <TextField onChange={e => setContour(e.target.value)} label='Contour' value={contour}></TextField>

                <SubmitButton redir="deepmainmast" disable={disable} duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
                </Stack>
                </>
        )
}
