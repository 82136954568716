import Navigation from "./Navigation";
import { Button, TextField, Typography, Autocomplete, Box, Accordion, AccordionDetails, AccordionSummary, Link, FormControlLabel, Checkbox} from "@mui/material";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import {useState } from 'react';
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, OTHER_JOB_BASE } from '../utils';

import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { NameField } from "./NameField";
import {SubmitButton} from './SubmitButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { mdParameters } from "../constants";

registerPlugin(FilePondPluginFileEncode);

export const ProteinLigandMD = ({ hideNavigation }) => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [receptor, setReceptor] = useState([]);
	const [ligand, setLigand] = useState([]);
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [exceed, setExceed] = useState(false);
        const [ligandCodes, setLigandCodes] = useState([]);

        const [formValues, setFormValues] = useState(
                mdParameters.filter(x => x.variableName != "pdbFile" && x.variableName != "ligandFile").reduce((acc, param) => ({
                  ...acc,
                  [param.variableName]: param.defaultValue || ''
                }), {})
              );
        
        const handleChange = (event, value, variableName) => {
        setFormValues({ ...formValues, [variableName]: value });
        };

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  

        const getReceptorFiles = async (files) => {
                setReceptor(files);
        }

        const [isFileUpload, setIsFileUpload] = useState(true);

	const submitFile = (pay) => {
		if (!receptor && !pdbID) {
			Swal.fire("Please make sure you've a protein");
			return;
		}
                
                const proteinFilePaths = receptor.map(f => returnS3PathsAndUploadFiles(user, f.file));
		const ligandFilePaths = ligand.map(f => returnS3PathsAndUploadFiles(user, f.file));

                let configObj = formValues;
                if (isFileUpload) {
                        configObj["pdbFile"] = proteinFilePaths[0]
                        configObj["ligandFile"] = ligandFilePaths[0]
                } else {
                        configObj["pdbID"] = pdbID
                        configObj["ligandCode"] = ligandCode
                }
                configObj["name"] = jobName
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "protein-ligand-md");
		navigate("/app/results");
	}

        const [pdbID, setPdbId] = useState('2h4g');
        const [ligandCode, setLigandCode] = useState('694');

        const readUrlAsync = (url) => {
                return new Promise((resolve, reject) => {
                    if (typeof url === 'string') {
                        fetch(url)
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error(`HTTP error! status: ${response.status}`);
                                }
                                return response.text();
                            })
                            .then(data => {
                                resolve(data);
                            })
                            .catch(error => {
                                reject(error);
                            });
                    } else {
                        reject(new Error('Input must be a URL string'));
                    }
                });
            };

        const handleUpdateID = async (id) => {
                setPdbId(id)
                let url = `https://files.rcsb.org/download/${id}.pdb`
                try {
                        const text = await readUrlAsync(url);
        
                        const lines = text.split('\n');
                        const ligands = new Set();
        
                        lines.forEach(line => {
                                if (line.startsWith('HETATM')) {
                                const resName = line.substring(17, 20).trim();
                                if (resName !== 'HOH') { // Exclude water
                                        ligands.add(resName);
                                }
                                }
                        });
                        setLigandCodes([...ligands])
                        setLigandCode([...ligands][0])
                        console.log(ligands)
                }catch (error) {
                        console.error('Error reading file:', error);
                }
            };

        return (
                <>
                <Stack spacing={2} style={{padding: '10px' }}>
                        { hideNavigation ? null : 
                        <>
                                <Typography variant='h5'>Tamarind Protein Ligand Molecular Dynamics Interface</Typography>

                        </> }

                        <Typography> Simulate energy minimization between a protein and ligand in aqueous solution, using AMBER and GAFF2 force fields. </Typography>
                        <Typography> Trial simulations are limited to 5ns. If you'd like to run longer simulations or those at larger scales, please get in touch at info@tamarind.bio</Typography>
                        <Typography> For protein only simulation, check out our <Link href="/gromacs" target="_blank" rel="noopener">Gromacs</Link> tool</Typography>

                        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                        <Button sx={{textTransform:"none"}} onClick={() => setIsFileUpload(!isFileUpload)}>{isFileUpload ? 'Switch to PDB ID Input' : 'Switch to File Upload'}</Button>

                        {isFileUpload ? <>
                                <FilePond
                                files={receptor}
                                allowReorder={true}
                                allowMultiple={false}
                                onupdatefiles={getReceptorFiles}
                                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> pdb file for your Protein'
                                credits={[]}
                                acceptedFileTypes={["chemical/x-pdb"]}
                                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
                                />
                                
                                <FilePond
                                files={ligand}
                                allowReorder={true}
                                allowMultiple={false}
                                onupdatefiles={setLigand}
                                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb files for your Ligand(s)'
                                credits={[]}
                                acceptedFileTypes={["pdb"]}
                                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"pdb"})}
                                />
                        </>
                        : <>
                        
                                <TextField label='PDB ID' value={pdbID} onChange={(e) => handleUpdateID(e.target.value)}></TextField>
                                <Typography>The ligand below must be found in the pdb file above</Typography>
                                <Autocomplete
                                        disablePortal
                                        freeSolo
                                        options={ligandCodes}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Ligand Name" />}
                                        defaultValue={""}
                                        value={ligandCode}
                                        onInputChange = {(e, val) => setLigandCode(val)}
                                        onChange={(e, val) => setLigandCode(val)}
                                />
                                {/* <TextField label='Ligand' value={ligandCode} onChange={(e) => setLigandCode(e.target.value)}></TextField> */}

                        </>}

                        <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{backgroundColor:"lightgrey"}}
                        >
                        <Typography >Additional Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                        <Box>
                        {mdParameters.filter(x => x.variableName != "pdbFile" && x.variableName != "ligandFile").map((param, index) => (
                        <Box key={index} marginBottom={2}>
                        <Typography variant="subtitle1">{param.displayName}</Typography>
                        <Typography variant="body2">{param.description}</Typography>
                        {param.type === 'dropdown' ? (
                        <Autocomplete
                        options={param.options}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        onChange={(event, value) => handleChange(event, value, param.variableName)}
                        value={formValues[param.variableName]}
                        sx={{width:"20%", minWidth: '250px'}}
                        defaultValue={param.defaultValue} 
                        />
                        ) : 
                        param.type === 'boolean' ?
                        (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                control={
                                        <Checkbox
                                        checked={formValues[param.variableName] || false}
                                        onChange={(event) =>
                                        handleChange(event, event.target.checked, param.variableName)
                                        }
                                        sx={{ width: "20%", minWidth: '250px' }}
                                        />
                                }
                                />
                                </Box>
                        )
                        :
                        (
                        <TextField
                        variant="outlined"
                        onChange={(event) => handleChange(event, event.target.value, param.variableName)}
                        value={formValues[param.variableName]}
                        defaultValue={param.defaultValue} 
                        sx={{width:"20%", minWidth: '250px'}}
                        />
                        )}
                        </Box>
                        ))}
                        </Box>

                        </AccordionDetails>
                        </Accordion>

                        <SubmitButton redir="gromacs" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
                </Stack>
                </>
        );
}