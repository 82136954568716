import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button, Stack, Box, Link } from '@mui/material';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';

const CheckoutForm = ({user, checkPaying, setProcessing}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async () => {

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    setProcessing(true)
    
    const res = await fetch('/api/setupStripeCustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: user.fullName
      })      
    });
      
    const clientSecret = await res.json();

    const {error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
        },
        redirect: "if_required"
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      // console.log("Checking paying now")
      checkPaying()
      axios.get('/api/notifyUs', {params:{'title': user.emailAddresses[0].emailAddress + " Signed Up as Customer", 'message': 'Wahooo'}})
    }
  };

  return (
    <Stack>
        
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <br></br>
            <div>
                <p>By proceeding, you agree to our <Link href="/terms-and-conditions">Terms of Service</Link>.</p>
            </div>

            <Box
              margin="auto"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
            <Button variant="contained" onClick={handleSubmit} disabled={!stripe || !elements || !user}>
                Save Card
            </Button>
            </Box>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    </Stack>
  );
};

export default function PaymentUI({checkPaying, setProcessing}) {
    const { isLoaded, isSignedIn, user } = useUser();

    return (
      isSignedIn ? 
        <CheckoutForm user={user} checkPaying={checkPaying} setProcessing={setProcessing}/> : <></>
    )
};
