import { useState } from 'react';
import { Typography, Box, Tab, Link, Button } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Navigation from "./Navigation";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { NameField } from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { SubmitButton } from './SubmitButton';

import { addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE } from '../utils';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const DiffDockPP = () => {
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [tab, setTab] = useState("2");
    const { isLoaded, isSignedIn, user } = useUser();
    const [exceed, setExceed] = useState(false);

    return (
        <>
            <Stack spacing={2} style={{ padding: '10px' }}>
                <Typography variant='h1' style={{ fontSize: '1.55em', fontWeight: 'normal' }}>Tamarind DiffDock-PP Online Tool</Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <FileUpload user={user} jobName={jobName} duplicateJob={duplicateJob} exceed={exceed} />
            </Stack>
        </>
    )
}

const FileUpload = ({ user, jobName, duplicateJob, exceed }) => {
    const navigate = useNavigate();
    const [proteinFiles, setProteinFiles] = useState([]);
    const [ligandFiles, setLigandFiles] = useState([]);

    const submitFile = (pay) => {
        if (proteinFiles.length === 0 || ligandFiles === 0) {
            alert("Make sure you've submitted both files!");
            return false;
        }

        const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
        const ligandFilePaths = ligandFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));


        const configs = {
            "proteinFile": `${proteinFilePaths.join(",")}`,
            "ligandFile": `${ligandFilePaths.join(",")}`,
        }
        const cost = pay ? OTHER_JOB_BASE : 0
        addJobToQueue(jobName, cost, user, JSON.stringify(configs), "diffdock-pp");
        startLambdaForType("diffdock-pp")
        navigate("/app/results");
    }

    const setDefault = () => {
        const filePath = process.env.PUBLIC_URL + "/6w70.pdb";
        fetch(filePath)
            .then(response => response.blob())
            .then(blob => {
                const protFile = new File([blob], '6w70.pdb');
                setProteinFiles([protFile])
            })
            .catch(error => console.error('Error fetching file:', error));

        const ligFilePath = process.env.PUBLIC_URL + "/6w70_ligand.sdf";
        fetch(ligFilePath)
            .then(response => response.blob())
            .then(blob => {
                const ligFile = new File([blob], '6w70_ligand.sdf');
                setLigandFiles([ligFile])
            })
            .catch(error => console.error('Error fetching file:', error));
    }

    return (
        <>
            <Stack spacing={1} style={{padding: '0px' }}>
            <Typography>DiffDock-PP is a diffusion model applied to rigid protein-protein docking problem. It achieves state of the art performance on the DIPS dataset. </Typography>

                <Typography variant='h6'>Protein</Typography>

                <FilePond
                    files={proteinFiles}
                    allowReorder={true}
                    allowMultiple={false}
                    onupdatefiles={setProteinFiles}
                    labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your protein pdb files'
                    credits={[]}
                    acceptedFileTypes={["chemical/x-pdb"]}
                    fileValidateTypeDetectType={detectFileTypeGenerator({ ".pdb": "chemical/x-pdb" })}
                />

                <Typography variant='h6'>Protein 2</Typography>

                <FilePond
                    files={ligandFiles}
                    allowReorder={true}
                    allowMultiple={false}
                    onupdatefiles={setLigandFiles}
                    labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your ligand pdb file'
                    credits={[]}
                    acceptedFileTypes={["chemical/x-pdb"]}
                    fileValidateTypeDetectType={detectFileTypeGenerator({ ".pdb": "chemical/x-pdb" })}
                />
                {/*<Button sx={{ width: '180px', textAlign: 'left' }} onClick={setDefault}>Load example inputs</Button> */}
                <SubmitButton redir="DiffDockPP" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>


            </Stack>
        </>
    )
}
