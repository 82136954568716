import {useState} from 'react';
import { Autocomplete, FormControl, InputLabel, Select, TextField, Typography} from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {useUser} from "@clerk/clerk-react";  
import {useNavigate} from "react-router-dom";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'
import {detectFileTypeGenerator, addJobToQueue, returnS3PathsAndUploadFiles, startLambdaForType, OTHER_JOB_BASE } from '../utils';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ColabDock = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
				<Header type="colabdock"/>
				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
				<FileUpload user={user} isSignedIn={isSignedIn} jobName={jobName} duplicateJob={duplicateJob} exceed={exceed}/>
			</Stack>
		</>
		)
	}

const FileUpload = ({user, isSignedIn, jobName, duplicateJob, exceed}) => {
	const navigate = useNavigate();
	const [proteinFiles, setProteinFiles] = useState([]);
	const [chainAResid, setChainAResid] = useState('');
	const [chainBResid, setChainBResid] = useState([]);
	const parsePdb = require('parse-pdb');
	const [sequences, setSequences] = useState([])
    const [chain, setChain] = useState("")
    const d = {'CYS': 'C', 'ASP': 'D', 'SER': 'S', 'GLN': 'Q', 'LYS': 'K',
        'ILE': 'I', 'PRO': 'P', 'THR': 'T', 'PHE': 'F', 'ASN': 'N', 
        'GLY': 'G', 'HIS': 'H', 'LEU': 'L', 'ARG': 'R', 'TRP': 'W', 
        'ALA': 'A', 'VAL':'V', 'GLU': 'E', 'TYR': 'Y', 'MET': 'M'}

	/*
	const submit = () => {
		const config = `pdb:${proteinString} smiles:${ligandString} numInference:${numInference} numSamples:${numSamples}`;
		addJobToQueue(jobName, 0, user, config, "diff-dock");
	}
	*/

	const submitFile = (pay) => {
		if (proteinFiles.length === 0) {
			alert("Make sure you've submitted your protein files!");
			return false;
		}
//test
		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

		const config = {
			proteinFiles: proteinFilePaths,
		}

		if  (chainAResid && chainBResid) {
			config['rest_1v1'] = [chainAResid, chainBResid]
		}

		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), "colabdock");
		startLambdaForType('colabdock');
		navigate("/app/results");
	}


    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsText(file);
        });
      };
    
    function filterUniqueField(arr, field) {
    const uniqueValues = new Set();
    return arr.filter(obj => {
        const value = obj[field];
        if (uniqueValues.has(value)) {
        return false;
        } else {
        uniqueValues.add(value);
        return true;
        }
    });
    }

	const handleUpdateFiles = async (fileItems) => {
		setProteinFiles(fileItems);
		if (fileItems.length === 0) {
			setSequences([]);
			return;
		}
	
		let allChainsList = [];
	
		for (const fileItem of fileItems) {
			const file = fileItem.file;
			try {
				const text = await readFileAsync(file);
				const structure = await parsePdb(text);
				const uniqueChains = new Set();
				structure.atoms.forEach(item => uniqueChains.add(item.chainID));
				let myChains = Array.from(uniqueChains);
	
				for (const c of myChains) {
					let atoms = structure.atoms.filter(s => s.chainID === c && s.name === "N");
					// make sure unique by resSeq
					atoms = filterUniqueField(atoms, 'resSeq');
					let seq = atoms.map(obj => d[obj.resName]).join("");
					let idxs = atoms.map(obj => obj.resSeq);
					allChainsList.push({
						file: file.name,
						chain: c,
						sequence: seq,
						idxs: idxs,
						checked: true,
						fixed: ""
					});
				}
			} catch (error) {
				console.error('Error processing file:', file.name, error);
			}
		}
	
		// console.log("seqs:", allChainsList);
		setSequences(allChainsList);
		// If you want to set a default chain, you can uncomment and modify this line:
		// setChain(allChainsList[0].file + '_' + allChainsList[0].chain);
	};
	
	return (
		<Stack spacing={2}>
			<Typography variant='h6'>Proteins (Docking is performed between two chains at a time)</Typography>
			<FilePond
			files={proteinFiles}
			allowReorder={true}
			allowMultiple={true}
			onupdatefiles={handleUpdateFiles}
			labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your protein pdb files'
			credits={[]}
			acceptedFileTypes={["chemical/x-pdb"]}
			fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
			/>

			{sequences.map((chain, index) => (
				<>
					{index < 2 ? <Typography>Chain {'AB'[index]}</Typography> : null}
					<Typography color={index < 2 ? 'black' : 'red'}>{`File: ${chain.file}, input chain name: ${chain.chain}, seq: ${chain.sequence.slice(0, 10)}...`}</Typography>
					{console.log(chain)}
				</>
			))}

			{sequences.length > 2 ? <Typography color={'red'}>Your input pdb files contain more than two chains, chains marked above in red will not be used in the docking prediction. </Typography>: <></>}

			<Typography variant='h6'>Restraints</Typography>
			<Typography>(Optional) Pick a residue in each input chain that will be within 8 Ås from each other in the binding pose.</Typography>

			<Autocomplete
			id="tags-standard"
			options={sequences && sequences.length > 0 ? sequences[0].idxs.map(num => num.toString()) : []}
			getOptionLabel={(option) => option}
			onChange={(e, value) => setChainAResid(value)}
			renderInput={(params) => (
			<TextField
				{...params}
				variant="standard"
				label="Chain A Residues"
				placeholder="Residue Numbers"
			/>
			)}
			/>

			<Autocomplete
			id="tags-standard"
			options={sequences && sequences.length > 1 ? sequences[1].idxs.map(num => num.toString()) : []}
			getOptionLabel={(option) => option}
			onChange={(e, value) => setChainBResid(value)}
			renderInput={(params) => (
			<TextField
				{...params}
				variant="standard"
				label="Chain B Residues"
				placeholder="Residue Numbers"
			/>
			)}
			/>

			<SubmitButton redir="colabdock" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
			
					
		</Stack>
	)
}