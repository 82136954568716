export const toolInfo = {
  "alphafold" : {
    "citations":[
      "Mirdita, M., Schütze, K., Moriwaki, Y. et al. ColabFold: making protein folding accessible to all. Nat Methods 19, 679–682 (2022). https://doi.org/10.1038/s41592-022-01488-1",
      "Jumper, J., Evans, R., Pritzel, A. et al. Highly accurate protein structure prediction with AlphaFold. Nature 596, 583–589 (2021). https://doi.org/10.1038/s41586-021-03819-2"
    ], "github":"https://github.com/sokrypton/ColabFold",
    "paper":"https://www.nature.com/articles/s41592-022-01488-1",
    "displayName":"AlphaFold",
    "functions":[
      "Predict the structure of a protein monomer or multimer"
    ]
  }, "rosettafold-all-atom": {
    "citations":[
      "Krishna R, et al. Generalized Biomolecular Modeling and Design with RoseTTAFold All-Atom. Science. March 2024."
    ], "github": "https://github.com/baker-laboratory/RoseTTAFold-All-Atom",
    "paper":"https://www.science.org/doi/10.1126/science.adl2528",
    "displayName":"RosettaFold All-Atom"
  }, "rfdiffusion-all-atom": {
    "citations":[
      'Krishna, R et al., Generalized biomolecular modeling and design with RoseTTAFold All-Atom. Science 0, eadl2528 DOI:10.1126/science.adl2528'
    ], "github": "https://github.com/baker-laboratory/rf_diffusion_all_atom",
    "paper":"https://www.science.org/doi/10.1126/science.adl2528",
    "displayName":"RFdiffusion All Atom"
  }, "autodock-vina": {
    "citations":[
      'J. Eberhardt, D. Santos-Martins, A. F. Tillack, and S. Forli. (2021). AutoDock Vina 1.2.0: New Docking Methods, Expanded Force Field, and Python Bindings. Journal of Chemical Information and Modeling. O. Trott, A. J. Olson, AutoDock Vina: improving the speed and accuracy of docking with a new scoring function, efficient optimization and multithreading, Journal of Computational Chemistry 31 (2010) 455-461',
      'O. Trott, A. J. Olson, AutoDock Vina: improving the speed and accuracy of docking with a new scoring function, efficient optimization and multithreading, Journal of Computational Chemistry 31 (2010) 455-461'
    ], "github":"https://github.com/ccsb-scripps/AutoDock-Vina",
    "paper":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3041641/",
    "displayName":"Autodock Vina"
  }, "rfdiffusion": {
    "citations":[
      "Watson, J., Juergens, D., Bennett, N., et al. Broadly applicable and accurate protein design by integrating structure prediction networks and diffusion generative models. (2022). https://doi.org/10.1101/2022.12.09.519842"
    ], "github": "https://github.com/RosettaCommons/RFdiffusion",
    "paper":"https://www.nature.com/articles/s41586-023-06415-8",
    "displayName":"RFdiffusion"
  }, "frameflow": {
    "citations":[
      'Yim, J., Campbell, A., Matthiew, E. et al. "Improved motif-scaffolding with {SE}(3) flow matching", 2024, https://openreview.net/forum?id=fa1ne8xDGn'
    ], "github": "https://github.com/microsoft/protein-frame-flow",
    "paper":"https://arxiv.org/pdf/2310.05297",
    "displayName":"FrameFlow"
  }, "unimol2": {
    "citations":[
      'Alcaide, Eric, et al. "Uni-Mol Docking V2: Towards Realistic and Accurate Binding Pose Prediction." arXiv preprint arXiv:2405.11769 (2024).'
    ], 
    "gitub":"",
    // "github": "https://github.com/deepmodeling/Uni-Mol",
    "paper":"https://chemrxiv.org/engage/chemrxiv/article-details/628e5b4d5d948517f5ce6d72",
    "displayName":"Unimol DockingV2"
  }, "zymctrl": {
    "citations":[
      'Conditional language models enable the efficient design of proficient enzymes Geraldene Munsamy, Ramiro Illanes-Vicioso, Silvia Funcillo, Ioanna T. Nakou, Sebastian Lindner, Gavin Ayres, Lesley S. Sheehan, Steven Moss, Ulrich Eckhard, Philipp Lorenz, Noelia Ferruz bioRxiv 2024.05.03.592223; doi: https://doi.org/10.1101/2024.05.03.592223'
    ], "github": "",
    "paper":"https://www.mlsb.io/papers_2022/ZymCTRL_a_conditional_language_model_for_the_controllable_generation_of_artificial_enzymes.pdf",
    "displayName":"ZymCTRL"
  }, "diffab": {
    "citations":[
      'Luo, S., Su, Y., Peng, X., et al. "Antigen-Specific Antibody Design and Optimization with Diffusion-Based Generative Models for Protein Structures". bioRxiv, doi: https://doi.org/10.1101/2022.07.10.499510'
    ], "github": "https://github.com/luost26/diffab",
    "paper":"https://www.biorxiv.org/content/10.1101/2022.07.10.499510v5.full.pdf",
    "displayName":"DiffAb"
  }, "diffdock": {
    "citations":[
      "Corso, G., Stark, H., Jing, B., et al. DiffDock: Diffusion Steps, Twists, and Turns for Molecular Docking. (2023). https://doi.org/10.48550/arXiv.2210.01776"
    ], "github": "https://github.com/gcorso/DiffDock",
    "paper":"https://arxiv.org/abs/2210.01776",
    "displayName":"DiffDock"
  }, "antibody-evolution": {
    "citations":[
    'Hie, B.L., Shanker, V.R., Xu, D. et al. Efficient evolution of human antibodies from general protein language models. Nat Biotechnol 42, 275–283 (2024). https://doi.org/10.1038/s41587-023-01763-2'
    ], "github": "https://github.com/brianhie/efficient-evolution",
    "paper":"https://www.nature.com/articles/s41587-023-01763-2",
    "displayName":"Antibody Affinity Maturation"
  }, "rosettafold2na": {
    "citations":[
    "Baek, M., McHugh, R., Anishchenko, I. et al. Accurate prediction of protein-nucleic acid complexes using RoseTTAFoldNA. Nat Methods (2023). https://doi.org/10.1038/s41592-023-02086-5"
    ], "github": "https://github.com/uw-ipd/RoseTTAFold2NA",
    "paper":"https://www.nature.com/articles/s41592-023-02086-5",
    "displayName":"RoseTTAFold2NA"
  }, "gcdm": {
    "citations":[
    'Morehead, A., Cheng, J. Geometry-complete diffusion for 3D molecule generation and optimization. Commun Chem 7, 150 (2024). https://doi.org/10.1038/s42004-024-01233-z'
    ], "github": "https://github.com/BioinfoMachineLearning/bio-diffusion",
    "paper":"https://arxiv.org/pdf/2302.04313v6",
    "displayName":"3D Molecule Generation (GCDM)"
  }, "thompson-sampling": {
    "citations":[
    'Kathryn Klarich, Brian Goldman, Trevor Kramer, Patrick Riley, and W. Patrick Walters, Journal of Chemical Information and Modeling 2024 64 (4), 1158-1171, DOI: 10.1021/acs.jcim.3c01790'
    ], "github": "https://github.com/PatWalters/TS",
    "paper":"https://pubs.acs.org/doi/10.1021/acs.jcim.3c01790",
    "displayName":"Thompson Sampling"
  }, "free-wilson": {
    "citations":[
    'Spencer M. Free and James W. Wilson, A mathematical contribution to structure-activity studies, Journal of Medicinal Chemistry 7.4 (1964): 395-399.'
    ], "github": "https://github.com/PatWalters/Free-Wilson",
    "paper":"https://pubs.acs.org/doi/10.1021/jm00334a001",
    "displayName":"Free Wilson SAR"
  }, "proteinmpnn": {
    "citations":[
    "Dauparas, J., Anishchenko, I., Bennet, N. et al., Robust deep learning–based protein sequence design using ProteinMPNN. Science 378, (2022). https://doi.org/10.1101/2022.06.03.494563"
    ], "github": "https://github.com/dauparas/ProteinMPNN",
    "paper":"https://www.biorxiv.org/content/10.1101/2022.06.03.494563v1",
    "displayName":"ProteinMPNN"
  }, "proteinmpnn-ddg": {
    "citations":[
    "Dutton, O., Bottaro, S., Invernizzi, M. et al., Improving Inverse Folding models at Protein Stability Prediction without additional Training or Data. Cold Spring Harbor Laboratory, (2024). https://doi.org/10.1101/2024.06.15.599145"
    ], "github": "https://github.com/PeptoneLtd/proteinmpnn_ddg",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.06.15.599145v3",
    "displayName":"ProteinMPNN-ddG"
  }, "ligandmpnn": {
    "citations":[
    "Dauparas, J., Lee, G., Pecoraro, R. et al., Atomic context-conditioned protein sequence design using LigandMPNN. (2023), doi:10.1101/2023.12.22.573103"
    ], "github": "https://github.com/dauparas/LigandMPNN",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.12.22.573103v1",
    "displayName":"LigandMPNN"
  }, "cryodrgn": {
    "citations":[
      'Zhong, E.D., Bepler, T., Berger, B. et al. CryoDRGN: reconstruction of heterogeneous cryo-EM structures using neural networks. Nat Methods 18, 176–185 (2021). https://doi.org/10.1038/s41592-020-01049-4'
    ], "github": "https://github.com/ml-struct-bio/cryodrgn",
    "paper":"https://www.nature.com/articles/s41592-020-01049-4",
    "displayName":"CryoDRGN"
  }, "deepemhancer": {
    "citations":[
      'Sanchez-Garcia, R., Gomez-Blanco, J., Cuervo, A. et al. DeepEMhancer: a deep learning solution for cryo-EM volume post-processing. Commun Biol 4, 874 (2021). https://doi.org/10.1038/s42003-021-02399-1'
    ], "github": "https://github.com/rsanchezgarc/deepEMhancer",
    "paper":"https://www.nature.com/articles/s42003-021-02399-1",
    "displayName":"DeepEMhancer"
  }, "modelangelo": {
    "citations":[
      'Jamali, K., Käll, L., Zhang, R. et al. Automated model building and protein identification in cryo-EM maps. Nature 628, 450–457 (2024). https://doi.org/10.1038/s41586-024-07215-4'
    ], "github": "https://github.com/3dem/model-angelo",
    "paper":"https://www.nature.com/articles/s41586-024-07215-4",
    "displayName":"Model Angelo"
  }, "pulchra": {
    "citations":[
      'Rotkiewicz P., Skolnick, J., "Fast method for reconstruction of full-atom protein models from reduced representations," J. Comp. Chem., Jul 15, 29(9), 1460-5 (2008).'
    ], "github": "https://github.com/euplotes/pulchra",
    "paper":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2692024/",
    "displayName":"PULCHRA"
  }, "afsample": {
    "citations":[
      'Predicting multiple conformations and ensembles with AlphaFold2 Yogesh Kalakoti, Björn Wallner bioRxiv 2024.05.28.596195; doi: https://doi.org/10.1101/2024.05.28.596195'
    ], "github": "https://github.com/iamysk/AFsample2/",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.05.28.596195v1",
    "displayName":"AFSample2"
  }, "amber": {
    "citations":[
      "Mirdita, M., Schütze, K., Moriwaki, Y. et al. ColabFold: making protein folding accessible to all. Nat Methods 19, 679–682 (2022). https://doi.org/10.1038/s41592-022-01488-1"
    ], "github": "",
    "paper":"https://www.nature.com/articles/s41592-022-01488-1",
    "displayName":"Amber Relaxation"
  }, "immunebuilder": {
    "citations":[
      'Abanades, B., Wong, W.K., Boyles, F. et al. ImmuneBuilder: Deep-Learning models for predicting the structures of immune proteins. Commun Biol 6, 575 (2023). https://doi.org/10.1038/s42003-023-04927-7'
    ], "github": "https://github.com/oxpig/ImmuneBuilder",
    "paper":"https://www.nature.com/articles/s42003-023-04927-7",
    "displayName":"ImmuneBuilder"
  }, "dymean": {
    "citations":[
      'Kong, X., Huang, W., Liu, Y. "End-to-End Full-Atom Antibody Design." ACM International Conference on Bioinformatics, 2023, https://doi.org/10.48550/arXiv.2302.00203'
    ], "github": "https://github.com/THUNLP-MT/dyMEAN",
    "paper":"https://arxiv.org/pdf/2302.00203",
    "displayName":"dyMEAN"
  }, "abodybuilder": {
    "citations":[
      'Kenlay, H., Dreyer, F., Cutting, D., et al. "ABodyBuilder3: Improved and scalable antibody structure predictions", https://doi.org/10.48550/arXiv.2405.20863 (2024)'
    ], "github": "https://github.com/Exscientia/abodybuilder3",
    "paper":"https://arxiv.org/abs/2405.20863",
    "displayName":"ABodyBuilder3"
  }, "unifold": {
    "citations":[
      'Li, Z., Liu, X., Chen, W. et al, "Uni-Fold: An Open-Source Platform for Developing Protein Folding Models beyond AlphaFold," BiorXiv, 2022, https://doi.org/10.1101/2022.08.04.502811. '
    ], "github": "https://github.com/dptech-corp/Uni-Fold",
    "paper":"https://www.biorxiv.org/content/10.1101/2022.08.04.502811v1",
    "displayName":"UniFold"
  }, "cyclic-peptide": {
    "citations":[
      "Rettie, S., Campbell, K., Bera, A., et al. Cyclic peptide structure prediction and design using AlphaFold. (2023), doi: https://doi.org/10.1101/2023.02.25.529956"
    ], "github": "",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.02.25.529956v1.full",
    "displayName":"Cyclic Peptide Prediction"
  }, "SPACE2": {
    "citations":[
      'Spoendlin FC, Abanades B, Raybould MIJ, Wong WK, Georges G, Deane CM. Improved computational epitope profiling using structural models identifies a broader diversity of antibodies that bind to the same epitope. Front Mol Biosci. 2023 Sep 18;10:1237621. doi: 10.3389/fmolb.2023.1237621. PMID: 37790877; PMCID: PMC10544996.'
    ], "github": "https://github.com/oxpig/SPACE2",
    "paper":"https://www.frontiersin.org/journals/molecular-biosciences/articles/10.3389/fmolb.2023.1237621/full",
    "displayName":"Space2"
  }, "antifold": {
    "citations":[
      "Hummer, A., Haraldson Hoie, M, Olsen, T, et al. AntiFold: Improved antibody structure design using inverse folding. Nature Methods, Machine Learning in Structural Biology, (2023)."
    ], "github": "https://github.com/oxpig/AntiFold",
    "paper":"https://arxiv.org/abs/2405.03370",
    "displayName":"AntiFold"
  }, "smina": {
    "citations":[
      'Koes, D et al., Lessons Learned in Empirical Scoring with smina from the CSAR 2011 Benchmarking Exercise. ACS, https://doi.org/10.1021/ci300604z'
    ], "github": "https://github.com/mwojcikowski/smina/tree/master",
    "paper":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3726561/",
    "displayName":"Smina"
  }, "gnina": {
    "citations":[
    'McNutt, A.T., Francoeur, P., Aggarwal, R. et al. GNINA 1.0: molecular docking with deep learning. J Cheminform 13, 43 (2021). https://doi.org/10.1186/s13321-021-00522-2'
    ], "github": "https://github.com/gnina/gnina",
    "paper":"https://jcheminf.biomedcentral.com/articles/10.1186/s13321-021-00522-2",
    "displayName":"GNINA"
  }, "gromacs": {
    "citations":[
      'H.J.C. Berendsen, D. van der Spoel and R. van Drunen. "GROMACS: A message-passing parallel molecular dynamics implementation", Comp. Phys. Comm. 91, 43-56 (1995), DOI: https://doi.org/10.1016/0010-4655(95)00042-E'
    ], "github": "https://github.com/gromacs/gromacs",
    "paper":"https://www.sciencedirect.com/science/article/pii/S2352711015000059",
    "displayName":"GROMACS"
  }, "alphaflow": {
    "citations":[
      "Jing, B., Berger, B., Jaakkola, T. AlphaFold Meets Flow Matching for Generating Protein Ensembles. (2024). https://doi.org/10.48550/arXiv.2402.04845"
    ], "github": "https://github.com/bjing2016/alphaflow",
    "paper":"https://arxiv.org/abs/2402.04845",
    "displayName":"AlphaFlow"
  }, "lightdock": {
    "citations":[
      "Jiménez-García, B., Roel-Touris, J., Barradas-Bautista, D. The LightDock Server: Artificial Intelligence-powered modeling of macromolecular interactions. Nucleic Acids Research (2023). https://doi.org/10.1093/nar/gkad327",
      "Roel-Touris, J., Jiménez-García, B., Bonvin, A. Integrative Modeling of Membrane-associated Protein Assemblies. Nat Commun 11, (2020). https://doi.org/10.1038/s41467-020-20076-5",
      "Roel-Touris, J., Bonvin, A., Jiménez-García, B. LightDock goes information-driven. Bioinformatics, (2020). https://doi.org/10.1093/bioinformatics/btz642 ",
      "Jiménez-García, B., Roel-Touris, J., Romero-Durana, M., et. al. LightDock: a new multi-scale approach to protein–protein docking. Bioinformatics, (2018). https://doi.org/10.1093/bioinformatics/btx555"
    ], "github": "https://github.com/lightdock/lightdock",
    "paper":"https://academic.oup.com/bioinformatics/article/34/1/49/4103399",
    "displayName":"LightDock"
  }, "equidock": {
    "citations":[
      "Ganea, O., Huang, X., Bunne, C. Independent SE(3)-Equivariant Models for End-to-End Rigid Protein Docking. (2021). https://doi.org/10.48550/arXiv.2111.07786"
    ], "github": "https://github.com/octavian-ganea/equidock_public",
    "paper":"https://arxiv.org/abs/2111.07786",
    "displayName":"EquiDock"
  }, "esm-finetune": {
    "citations":[
      "Lin, Z., Akin, H., Rao, R. Evolutionary-scale prediction of atomic-level protein structure with a language model. Science 379, (2023). https://doi.org/10.1126/science.ade2574"
    ], "github": "https://github.com/facebookresearch/esm",
    "paper":"https://www.science.org/doi/10.1126/science.ade2574",
    "displayName":"ESM2 Property Finetuning"
  }, "reinvent-finetune": {
    "citations":[
    "Loeffler, H., He, J., Tibo, A. et al. 'REINVENT4: Modern AI-Driven Generative Molecule Design', ChemRxiv, 2023, https://chemrxiv.org/engage/chemrxiv/article-details/65463cafc573f893f1cae33a"
    ], "github": "https://github.com/MolecularAI/REINVENT4",
    "paper":"https://link.springer.com/article/10.1186/s13321-024-00812-5",
    "displayName":"Reinvent Finetune"
  }, "combfold": {
    "citations":[
    "Shor, B., Shneidman-Duhovny, D. CombFold: predicting structures of large protein assemblies using a combinatorial assembly algorithm and AlphaFold2. Nature Methods, 477-487 (2024). https://doi.org/10.1038/s41592-024-02174-0"
    ], "github": "https://github.com/dina-lab3D/CombFold",
    "paper":"https://www.nature.com/articles/s41592-024-02174-0",
    "displayName":"CombFold"
  }, "gbsa": {
    "citations":[
    ], "github": "",
    "paper":"",
    "displayName":"GBSA"
  }, "libinvent": {
    "citations":[
      'Fialkova V, Zhao J, Papadopoulos K, Engkvist O, Bjerrum EJ, Kogej T, et al. Lib-INVENT: Reaction Based Generative Scaffold Decoration for in silico Library Design. ChemRxiv. 2021; doi:10.26434/chemrxiv.14473980.v1'
    ], "github": "https://github.com/MolecularAI/Lib-INVENT",
    "paper":"https://pubs.acs.org/doi/10.1021/acs.jcim.1c00469",
    "displayName":"LibInvent"
  }, "tcrmodel2": {
    "citations":[
      "Yin R, Ribeiro-Filho HV, Lin V, Gowthaman R, Cheung M, Pierce BG. (2023) TCRmodel2: high-resolution modeling of T cell receptor recognition using deep learning. Nucleic Acids Research, 51(W1):W569-W576. https://doi.org/10.1093/nar/gkad356"
    ], "github": "https://github.com/piercelab/tcrmodel2/tree/main",
    "paper":"https://academic.oup.com/nar/article/51/W1/W569/7151345?login=false",
    "displayName":"TCRModel2"
  }, "dlkcat": {
    "citations":[
      'Li, F., Yuan, L., Lu, H. et al. Deep learning-based kcat prediction enables improved enzyme-constrained model reconstruction. Nat Catal 5, 662–672 (2022). https://doi.org/10.1038/s41929-022-00798-z',
    ], "github": "https://github.com/SysBioChalmers/DLKcat",
    "paper":"https://www.nature.com/articles/s41929-022-00798-z",
    "displayName":"DLKcat"
  }, "catpred": {
    "citations":[
      'Boorla, V., Maranas, C., et al. "CatPred: A comprehensive framework for deep learning in vitro enzyme kinetic parameters kcat, Km and Ki", https://doi.org/10.1101/2024.03.10.584340 (2024)'
    ], "github": "https://github.com/maranasgroup/CatPred",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.03.10.584340v2",
    "displayName":"CatPred"
  }, "binding-ddg": {
    "citations":[
      'Shan, S., Luo, S., Yang, Z., et al. "Deep learning guided optimization of human antibody against SARS-CoV-2 variants with broad neutralization." PNAS, doi:10.1073/pnas.2122954119, Mar 2022'
    ], "github": "https://github.com/HeliXonProtein/binding-ddg-predictor",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.02.28.530137",
    "displayName":"Binding ddG"
  }, "progen2": {
    "citations":[
      "Nijkamp, E., Ruffolo, J., Weinstein, E. et al, ProGen2: Exploring the Boundaries of Protein Language Models. arxiV, 2022, https://arxiv.org/abs/2206.13517."
    ], "github": "https://github.com/enijkamp/progen2",
    "paper":"https://arxiv.org/abs/2206.13517",
    "displayName":"Progen2"
  }, "progen2-finetune": {
    "citations":[
      "Nijkamp, E., Ruffolo, J., Weinstein, E. et al, ProGen2: Exploring the Boundaries of Protein Language Models. arxiV, 2022, https://arxiv.org/abs/2206.13517."
    ], "github": "https://github.com/enijkamp/progen2",
    "paper":"https://arxiv.org/abs/2206.13517",
    "displayName":"Progen2 Finetune"
  }, "protein-metrics": {
    "citations":[
      'Johnson, S.R., Fu, X., Viknander, S. et al. Computational scoring and experimental evaluation of enzymes generated by neural networks. Nat Biotechnol (2024). https://doi.org/10.1038/s41587-024-02214-2'
    ], "github": "",
    "paper":"https://www.nature.com/articles/s41587-024-02214-2",
    "displayName":"COMPSS Protein Metrics"
  }, "saprot": {
    "citations":[
      'Su, J. et al. "SaProt: Protein Language Modeling with Structure-aware Vocabulary." biorXiv preprint doi:10.1101/2023.10.01.560349 (2023).'
    ], "github": "https://github.com/westlake-repl/SaProt",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.10.01.560349v1",
    "displayName":"SaProt"
  }, "freeda": {
    "citations":[
      "Dudka, D., Akins, B., Lampson, M., FREEDA: An automated computational pipeline guides experimental testing of protein innovation (2023). https://doi.org/10.1083/jcb.202212084"
    ], "github": "https://github.com/DDudka9/freeda",
    "paper":"https://pubmed.ncbi.nlm.nih.gov/36909479/",
    "displayName":"FREEDA"
  }, "peppatch": {
    "citations":[
      'Valentin J. Hoerschinger, Franz Waibl, Nancy D. Pomarici, et al. "PEP-Patch: Electrostatics in Protein–Protein Recognition, Specificity, and Antibody Developability," Journal of Chemical Information and Modeling, 2023, DOI: 10.1021/acs.jcim.3c01490'
    ], "github": "https://github.com/liedllab/surface_analyses",
    "paper":"https://pubs.acs.org/doi/10.1021/acs.jcim.3c01490",
    "displayName":"PEP-Patch"
  }, "moflow": {
    "citations":[
      "Zang, Chengxi, and Fei Wang. 'MoFlow: an invertible flow model for generating molecular graphs.' In Proceedings of the 26th ACM SIGKDD International Conference on Knowledge Discovery & Data Mining, pp. 617-626. 2020."
    ], "github": "https://github.com/calvin-zcx/moflow",
    "paper":"https://arxiv.org/abs/2006.10137",
    "displayName":"MoFlow"
  }, "afcluster": {
    "citations":[
      "Wayment-Steele, H., Ojoawo, A., Otten, R. et al, Predicting multiple conformations via sequence clustering and AlphaFold2, Nature, 2023, https://www.nature.com/articles/s41586-023-06832-9."
    ], "github": "https://github.com/HWaymentSteele/AF_Cluster",
    "paper":"https://www.nature.com/articles/s41586-023-06832-9",
    "displayName":"AF Cluster"
  }, "deepimmuno": {
    "citations":[
      'Guangyuan Li, Balaji Iyer, V B Surya Prasath, Yizhao Ni, Nathan Salomonis, DeepImmuno: deep learning-empowered prediction and generation of immunogenic peptides for T-cell immunity, Briefings in Bioinformatics, Volume 22, Issue 6, November 2021, bbab160, https://doi.org/10.1093/bib/bbab160'
    ], "github": "https://github.com/frankligy/DeepImmuno",
    "paper":"https://academic.oup.com/bib/article/22/6/bbab160/6261914",
    "displayName":"DeepImmuno"
  }, "colabdock": {
    "citations":[
      'Feng, S., Chen, Z., Zhang, C. et al. Integrated structure prediction of protein-protein docking with experimental restraints using ColabDock. Nat Mach Intell (2024). https://doi.org/10.1038/s42256-024-00873-z'
    ], "github": "https://github.com/JeffSHF/ColabDock",
    "paper":"https://www.nature.com/articles/s42256-024-00873-z",
    "displayName":"ColabDock"
  }, "tlimmuno": {
    "citations":[
      'Guangshuai Wang, Tao Wu, Wei Ning, Kaixuan Diao, Xiaoqin Sun, Jinyu Wang, Chenxu Wu, Jing Chen, Dongliang Xu, Xue-Song Liu, TLimmuno2: predicting MHC class II antigen immunogenicity through transfer learning, Briefings in Bioinformatics, Volume 24, Issue 3, May 2023, bbad116, https://doi.org/10.1093/bib/bbad116'
    ], "github": "https://github.com/XSLiuLab/TLimmuno2",
    "paper":"https://academic.oup.com/bib/article/24/3/bbad116/7084794?login=false",
    "displayName":"TLimmuno"
  }, "netsolp": {
    "citations":[
      "Thumuluri V, Martiny HM, Almagro Armenteros JJ, Salomon J, Nielsen H, Johansen AR. NetSolP: predicting protein solubility in Escherichia coli using language models. Bioinformatics. 2022 Jan 27;38(4):941-946. doi: 10.1093/bioinformatics/btab801. PMID: 35088833."
    ], "github": "https://github.com/TviNet/NetSolP-1.0",
    "paper":"https://academic.oup.com/bioinformatics/article/38/4/941/6444984",
    "displayName":"NetSolP"
  }, "temstapro": {
    "citations":[
      'Pudžiuvelytė, I., Olechnovič, K., Godliauskaite, E., "TemStaPro: protein thermostability prediction using sequence representations from protein language models," Bioinformatics, 2024, doi:10.1093/bioinformatics/btae157'
    ], "github": "https://github.com/ievapudz/TemStaPro",
    "paper":"https://academic.oup.com/bioinformatics/article/40/4/btae157/7632735",
    "displayName":"TemStaPro"
  }, "thermompnn": {
    "citations":[
      'Dieckhaus, H., Brocidiacono, M., Randolph, N, et al. "Transfer learning to leverage larger datasets for improved prediction of protein stability changes," Proceedings of the National Academy of Sciences, 2024, doi:10.1073/pnas.2314853121'
    ], "github": "https://github.com/Kuhlman-Lab/ThermoMPNN",
    "paper":"https://www.pnas.org/doi/10.1073/pnas.2314853121",
    "displayName":"ThermoMPNN"
  }, "admet": {
    "citations":[
      "Swanson K, Walther P, Leitz J, Mukherjee S, Wu JC, Shivnaraine RV, Zou J. ADMET-AI: A machine learning ADMET platform for evaluation of large-scale chemical libraries. bioRxiv [Preprint]. 2023 Dec 28:2023.12.28.573531. doi: 10.1101/2023.12.28.573531. PMID: 38234753; PMCID: PMC10793392."
    ], "github": "https://github.com/swansonk14/admet_ai",
    "paper":"https://academic.oup.com/bioinformatics/article/40/7/btae416/7698030",
    "displayName":"ADMET"
  }, "af2bind": {
    "citations":[
      "Gazizov, A., Lian, A., Goverde, C., et al. AF2BIND: Predicting ligand-binding sites using the pair representation of AlphaFold2, (2023), doi: https://doi.org/10.1101/2023.10.15.562410"
    ], "github": "https://github.com/sokrypton/af2bind",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.10.15.562410v1.full.pdf",
    "displayName":"AF2Bind"
  }, "afcycdesign": {
    "citations":[
      "Rettie, S., Campbell, K., Bera, A., et al. Cyclic peptide structure prediction and design using AlphaFold. (2023), doi: https://doi.org/10.1101/2023.02.25.529956"
    ], "github": "https://github.com/sokrypton/ColabDesign/blob/main/af/examples/af_cyc_design.ipynb",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.02.25.529956v1.full",
    "displayName":"AfCycDesign"
  }, "structural-evolution": {
    "citations":[
      'Varun R. Shanker et al. ,Unsupervised evolution of protein and antibody complexes with a structure-informed language model.Science385,46-53(2024).DOI:10.1126/science.adk8946'
    ], "github": "https://github.com/varun-shanker/structural-evolution",
    "paper":"https://www.science.org/doi/10.1126/science.adk8946",
    "displayName":"Structural Evolution"
  }, "prodigy": {
    "citations":[
      'Xue L, Rodrigues J, Kastritis P, Bonvin A.M.J.J, Vangone A.: PRODIGY: a web server for predicting the binding affinity of protein-protein complexes. Bioinformatics (2016) (10.1093/bioinformatics/btw514)', 'Anna Vangone and Alexandre M.J.J. Bonvin: Contacts-based prediction of binding affinity in protein-protein complexes. eLife, e07454 (2015) (10.7554/eLife.07454)', 'Panagiotis L. Kastritis , João P.G.L.M. Rodrigues, Gert E. Folkers, Rolf Boelens, Alexandre M.J.J. Bonvin: Proteins Feel More Than They See: Fine-Tuning of Binding Affinity by Properties of the Non-Interacting Surface. Journal of Molecular Biology, 14, 2632–2652 (2014). (10.1016/j.jmb.2014.04.017)'
    ], "github": "https://github.com/haddocking/prodigy",
    "paper":"https://academic.oup.com/bioinformatics/article/32/23/3676/2525629",
    "displayName":"PRODIGY"
  }, "protein-redesign": {
    "citations":[
      'Nguyen, V., Nguyen, N., Hy, T. "Complex-based Ligand-Binding Proteins Redesign by Equivariant Diffusion-based Generative Models," Cold Spring Harbor Laboratory, 2024, doi:10.1101/2024.04.17.589997'
    ], "github": "https://github.com/HySonLab/Protein_Redesign",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.04.17.589997v3",
    "displayName":"Protein Redesign"
  }, "deepmainmast":{
    "citations":[
       "Genki Terashi, Xiao Wang, Devashish Prasad, Tsukasa Nakamura & Daisuke Kihara. DeepMainmast: integrated protocol of protein structure modeling for cryo-EM with deep learning and structure prediction. Nature Methods, 21: 122-131 (2024)"
    ], "github": "https://github.com/kiharalab/DeepMainMast",
    "paper":"https://www.nature.com/articles/s41592-023-02099-0",
    "displayName":"DeepMainMast"
  }
}
// indexed by type (which should = url after conversion)

// exceptions only
export const typeToUrl = {
  "monomer":"alphafold",
  "multimer":"alphafold",
  "rosettafold-aa":"rosettafold-all-atom",
  "all_atom_design":"rfdiffusion-all-atom",
  "protein-design":"rfdiffusion",
  "unifold-multimer":"unifold",
  "unifold-monomer":"unifold",
  "protrediff":"protein-redesign",
  "compss-structure":"protein-metrics",
  "compss-sequence":"protein-metrics",
  "progen":"progen2",
  "afcycpeptide":"cyclic-peptide"
}

export const jobSettings = {
    "alphafold" : [
      { "name": "sequence", "required": true, "type":"sequence", "descr":"Protein amino acid sequence, use : to separate chains for multimers", prefillDefault:"false", "default": "MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV"},
      { 'name': 'numModels', "type": 'number', 'default': 5, displayName:"Number of Models", descr:"Number of models to be used, each generating a different prediction"},
      { "name": 'msaMode', "type": 'dropdown', displayName:"MSA Mode", default:"mmseqs2_uniref_env", options:["mmseqs2_uniref_env", "mmseqs2_uniref", "single_sequence"], descr:"Select which database to use for MSA, or choose 'single_sequence' to skip MSA search"},
      { "name": 'numRecycles', "type": 'number', 'default': 3, displayName:"Number of recycles", descr:"Number of times recycle outputs back through structure prediction process for refined results"},
      { "name": 'numRelax', "type": 'boolean', 'default': 0, displayName:"Amber relaxation", descr:"Perform amber relaxation for more accurate side chain predictions"},
      { "name": 'pairMode', 'type': 'dropdown', displayName:"Pair Mode", options:["paired", "unpaired", "unpaired_paired"], default:"unpaired_paired", descr:'"unpaired_paired" = pair sequences from same species + unpaired MSA, "unpaired" = seperate MSA for each chain, "paired" - only use paired sequences.'},
      { 'name': 'templateFiles', "type":"list", "extension": ["cif"], displayName:"Template Files"},
    ],"rfdiffusion-all-atom": [
      {"name": "contigs", "required": true, "type":"string", "descr":"String describing fixed and variable regions of protein, see https://www.tamarind.bio/all-atom-design to generate"},
      {"name": "pdbFile", "required": true, "type":"pdb", "descr":"Contains protein and ligand"},
      {"name": "ligandCode", "required": true, "type":"string", "descr":"Ligand must be found in pdbFile"},
      {"name": "numDesigns", "type":"number", "descr":"Number of designs to generate (1-16)"},
    ], "autodock-vina": [
      { "name": "receptorFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", displayName:"Ligand File"},
      { "name": "boxX", "required": true, "type":"number"},
      { "name": "boxY", "required": true, "type":"number"},
      { "name": "boxZ", "required": true, "type":"number"},
      { "name": "width", "required": true, "type":"number"},
      { "name": "height", "required": true, "type":"number"},
      { "name": "depth", "required": true, "type":"number"},
      { "name": "exhaustiveness", "type":"number"},
    ],"rfdiffusion": [
      { "name": "contigs", "required": true, "type":"string", "default": "20-20/0 B17-209", displayName:"Contigs"},
      {"name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", "default": "3di3.pdb", "submitName":"uploaded_file", displayName:"PDB File"},
      { "name": "numDesigns", "type":"number", "default": "1", displayName:"Number of Designs"},
      { "name": "hotspots", "type":"string", displayName:"Hotspots (for binder design)", descr:"Residues to focus on, in list format {Chain ID}{Residue}, ex. A15 A17"},
      { "name": "partial_T", "type":"number", displayName:"Partial Diffusion Temperature", descr:"Diffuse your protein, if selected - contigs must be same length as input pdb sequence"},
      // { "name": "numMPNNSequences", "type":"number", "default": "2"}
    ], "diffdock": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", "default": "6w70.pdb", displayName:"Protein File"},
      { "name": "ligandFile", "required": true, "extension": ["sdf", "mol2"], "type":"sdf", "default": "6w70_ligand.sdf", displayName:"Ligand File"}
    ], "proteinmpnn": [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", "default": "3HTN.pdb"},
      {"name": "numSequences","type":"number", "default":2, "submitName":"seqs", displayName:"Number of Sequences"},
      {"name": "chain", "type":"string", "default":"A", displayName:"Chain"},
      {"name": "temperature", "type":"number", "default":0.1, "submitName":"temp", displayName:"Temperature"},
      {"name": "modelName", "type":"dropdown", "default":"v_48_020", options:["v_48_002", "v_48_010", "v_48_020", "v_48_030", "abmpnn"], "submitName":"model_name", displayName:"Model Noise Level", descr:"Select from models of various noise levels, or AbMPNN for antibodies"},
      {"name": "omitAAs", "type":"number", "default":"C","submitName":"omit_AAs", displayName:"Amino Acids to Omit"},
    ], "equidock": [
      { "name": "receptorFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File"},
      { "name": "ligandFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Ligand File"}
    ], "temstapro" : [
      { "name": "sequence", "required": true, "type":"sequence"}
    ], "netsolp" : [
      { "name": "sequence", "required": true, "type":"sequence"}
    ], "ligandmpnn": [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb"},
      {"name": "fixedResidues", "type":"string", displayName:"Fixed Residues", descr:"Residues to be kept same as original pdb, in list format {ChainID}{Residue}, ex A15 A17"},
      {"name": "temperature", "type":"number", displayName:"Temperature", descr:"Higher temperature results in more even distribution of amino acids"},
    ], "smina": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", displayName:"Ligand File"}, 
      { "name": "boxX", "required": true, "type":"number"},
      { "name": "boxY", "required": true, "type":"number"},
      { "name": "boxZ", "required": true, "type":"number"},
      { "name": "width", "required": true, "type":"number"},
      { "name": "height", "required": true, "type":"number"},
      { "name": "depth", "required": true, "type":"number"},
    ],"gnina": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", displayName:"Ligand Name"}, 
      { "name": "boxX", "required": true},
      { "name": "boxY", "required": true},
      { "name": "boxZ", "required": true},
      { "name": "width", "required": true},
      { "name": "height", "required": true},
      { "name": "depth", "required": true},
      { "name": "cnnScoring", type:"dropdown", default:"rescore", options:["none", "rescore", "refinement", "metrosrescore", "metrorefine"], displayName:"CNN Scoring Mode"},
      { "name": "wholeProtein", type:"boolean", displayName:"Whole protein docking"},
      { "name": "exhaustiveness"},
    ]
    ,"rosettafold-aa": [
      {"name": "units", "required": true, "type":"list", "descr":"List of proteins, small molecules, and nucleic acids for your complex"},
    ], "frameflow":[
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb"},
      { "name": "contigs", "required": true, "type":"string"},
      { "name": "numDesigns", "required": true, "type":"number", "type":"Number of designs to generate (1-16)", default:4},
      { "name": "chain", "required": true, "type":"string", default:"A"},
    ], 
    "rosettafold2na": [
      { "name": "proteinSequence", "required": true, "default": "TRPNHTIYINNLNEKIKKDELKKSLHAIFSRFGQILDILVSRSLKMRGQAFVIFKEVSSATNALRSMQGFPFYDKPMRIQYAKTDSDIIAKM", "type":"sequence", displayName:"Protein Sequence"},
      { "name": "rnaSequence", "default": "GAGAGAGAAGTCAACCAGAGAAACACACCAACCCATTGCACTCCGGGTTGGTGGTATATTACCTGGTACGGGGGAAACTTCGTGGTGGCCGGCCACCTGACA", "type":"sequence", displayName:"RNA Sequence"},
      { "name": "singleDnaSequence", "type":"sequence", displayName:"Single DNA Sequence"},
      { "name": "doubleDnaSequence", "type":"sequence", displayName:"Double DNA Sequence"}
    ], "amber": [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb"},
      {"name": "maxIterations", default:2000},
      {"name": "tolerance"},
      {"name": "stiffness"},
    ], "lightdock": [
      { "name": "receptorFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File"},
      { "name": "ligandFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Ligand File"}
    ], "protgpt2": [
      { "name": "sequence", "required": true, type:'sequence', displayName:"Seed sequence"},
      {"name": "minLength", 'type': 'number', displayName:"Minimum length to generate"},
      {"name": "maxLength", 'type': 'number', displayName:"Maximum length to generate"}
    ], "progen2": [
      {"name": "sequence", "required": true, "type":'sequence', displayName:"Seed sequence"},
      {"name": "numSequences", 'type': 'number', default:10, displayName:"Number of sequences"},
      {"name": "maxLength", 'type':'number', displayName:"Maximum length to generate"}
    ], "reinvent": [
      {"name": "model", "required": true,},
      {"name": "numSequences", 'type':'number'},
      {"name": "smiles", 'type':'string'},
    ], "afcluster": [
      {"name": "a3mFile", "required": true, "extension": ["a3m"], 'type': 'a3m', displayName:"MSA File"},
    ], "admet": [
      {"name": "smiles", "required": true, 'type': 'list', default: []},
    ], "afcycdesign": [
      {"name": "model", "required": true,},
      {"name": "pdbFile", "required": true, "type":"pdb", "extension": ["pdb"]},
      {"name": "task", required: true, },
      {"name": "chain", required: true, default: "A"},
    ], "dlkcat": [
      {"name": "proteinSequences", "required": true, "type":"sequence", displayName:"Sequence"},
      {"name": "smiles", "required": true, displayName:"Substrate SMILES String"},
    ], "catpred": [
      {"name": "sequence", "required": true, "type":"sequence"},
      {"name": "smiles", "required": true, displayName:"Substrate SMILES"},
    ], "af2bind" : [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb"}
    ], "immunebuilder" : [
      { "name": "modelType", "required": true, "default":"Antibody", "type":"dropdown", "options":["Antibody", "Nanobody", "TCR"], displayName:"Model Type"},
      { "name": "sequence1", "required": true, "default":"EVQLVESGGGVVQPGGSLRLSCAASGFTFNSYGMHWVRQAPGKGLEWVAFIRYDGGNKYYADSVKGRFTISRDNSKNTLYLQMKSLRAEDTAVYYCANLKDSRYSGSYYDYWGQGTLVTVS", "type":"sequence", displayName:"Heavy/Alpha Chain"},
      { "name": "sequence2", "default":"VIWMTQSPSSLSASVGDRVTITCQASQDIRFYLNWYQQKPGKAPKLLISDASNMETGVPSRFSGSGSGTDFTFTISSLQPEDIATYYCQQYDNLPFTFGPGTKVDFK", "type":"sequence", displayName:"Light/Beta Chain"}
    ], "antifold" : [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb"},
      { "name": "heavyChain", "required": true, "default":"H", "type":"chain", displayName:"Heavy Chain ID"},
      { "name": "lightChain", "required": true, "default":"L", "type":"chain", displayName:"Light Chain ID"},
      { "name": "region", type:"dropdown", options:[ "all","allH","allL","FWH","FWL","CDRH","CDRL","FW1","FWH1","FWL1","CDR1","CDRH1","CDRL1","FW2","FWH2","FWL2","CDR2","CDRH2","CDRL2","FW3","FWH3","FWL3","CDR3", "CDRH3","CDRL3","FW4","FWH4","FWL4",], displayName:"Region to mutate"}
    ], "afsample" : [
      { "name": "sequence", "required": true, "type":"sequence"}
    ], "unifold" : [
      { "name": "sequence", "required": true, "type":"sequence"}
    ], "unimol2": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File"}, 
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", displayName:"Ligand File"}, 
      { "name": "centerX", "required": true, submitName:"center_x"},
      { "name": "centerY", "required": true, submitName:"center_y"},
      { "name": "centerZ", "required": true, submitName:"center_z"},
      { "name": "width", "required": true, submitName:"size_x"},
      { "name": "height", "required": true, submitName:"size_y"},
      { "name": "depth", "required": true, submitName:"size_z"},
    ], "compss-structure" : [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], submitName:"filename"}
    ], "compss-sequence" : [
      { "name": "fastaFile", "required": true, "extension": ["fasta"], submitName:"filename"}
    ], "peppatch" : [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb"}
    ], "protrediff": [
      { "name": "sequence", "required": true, "type":"sequence", submitName:"protein_sequence"},
      { "name": "ligandString", "required": true, submitName:"ligand_string"}
    ], "saprot": [
      { "name": "pdbFiles", "required": true, "type":"list"},
    ], 'jackhmmer': [
      {'name': 'sequence', required: true, "type":"sequence"},
      {'name' : 'E'},
      {'name' : 'domE'},
      {'name' : 'incE'},
      {'name' : 'incdomE'},
      {'name' : 'mx'},        
      {'name' : 'pextend'},
      {'name' : 'popen'},     
      {'name' : 'seqdb', 'required': true},
    ], "alphaflow": [
      {'name': 'sequence', required: true,  "submitName":"seq", "type":"sequence"}
    ], 'combfold': [
      {'name': 'sequence', required: true, "type":"sequence"},
    ], 'tcrmodel2': [
      {'name': 'type', 'type': 'string', required: true},
      {'name': 'peptide', 'type': 'sequence',},
      {'name': 'MHCA', 'type': 'sequence',}, 
      {'name': 'MHCB', 'type': 'sequence'},
    ], 'abodybuilder': [
      {'name': 'heavy', 'type': 'sequence', required: true, "displayName":"Heavy Chain"},
      {'name': 'light', 'type': 'sequence', required: true, "displayName":"Light Chain"},
    ], 'diffab': [
      {'name': 'pdbFile', extension: ['pdb'], required: true, "type":"pdb", displayName:"Antibody-Antigen Complex File"},
      {'name': 'task', default:"antibody-antigen", prefillDefault:true, type:"dropdown", options:["antibody-antigen", "design", "optimize", "structure-prediction"], displayName:"Task"},
      {'name': 'numDesigns', required: true, displayName:"Number of Designs", default:10},
      {"name": 'verify', "type":"bool", displayName:"Verify with Alphafold", default: false},
      {"name": 'scoring', "type":"list", displayName:"", default: ['binding-ddg']},
      {"name": 'sampleCDRTogether', "type": "bool", default: false},
    ], 'dymean': [
      {'name': 'pdbFile', extension: ['pdb'], required: true},
      {'name': 'heavy', 'type': 'sequence', required: true, displayName:"Heavy Chain"},
      {'name': 'light', 'type': 'sequence', required: true, displayName:"Light Chain"},
      {'name': 'epitope', required: true, displayName:"Epitope", descr:"Antigen epitope, in list format {Chain ID}{Residue}, ex. A15 A17"},
      {'name': 'task', required: true, type:"dropdown", options:["structure-prediction", "optimize"], default:"optimize", displayName:"Task"},
    ], 'antibody-evolution': [
      {'name': 'sequence', 'type': 'sequence', required: true},
    ], 'modelangelo': [
      {'name': 'map', extension: ['mrc'], required: true},
      {'name': 'sequence', 'type': 'sequence', required: true}
    ], 'pulchra': [
      {'name': 'pdbFile', extension: ['pdb'], required: true, "type":"pdb"},
    ], 'prodigy': [
      {'name': 'proteinFile', extension: ['pdb'], required: true, "type":"pdb", displayName:"Protein Complex File"},
    ], 'colabdock': [
      {'name': 'proteinComplexFile', extension: ['pdb'], required: true, "type":"pdb", submitName:"proteinFiles"},
    ], 'tlimmuno': [
      {'name': 'sequence', required: true, "type":"sequence", displayName:"Epitope"},
      {'name': 'hla', required: true},
    ], 'deepimmuno': [
      {'name': 'sequence', required: true, "type":"sequence", displayName:"Epitope"},
      {'name': 'hla', required: true},
    ], 'structure-evolution': [
      {'name': 'pdbFile', required: true, 'type': 'pdb', extension: ['pdb']},
      {'name': 'chain', required: true, 'type': 'string', },
      {'name': 'upperLimit', required: false, 'type': 'string', },
    ], 'gbsa': [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf"}
    ], 'space2': [
      {name:"proteinFiles", required: true, "type":"pdb", extension: ['pdb']},
    ]
  }
  
  export const jobOutputs = {
    "alphafold": ["pdb"],
    "rfdiffusion-all-atom": ["pdb"],
    "autodock-vina": ['score'], // can do pdbqt -> sdf in future
    "rfdiffusion": ["pdb"],
    "diffdock": ["sdf", 'score'],
    "gbsa": [],
    "proteinmpnn":["sequence-list", "sequence"],
    "equidock":["pdb"],
    "temstapro": ["score"],
    "netsolp":["score"],
    "ligandmpnn":["sequence"],
    "smina":['score'],
    "gnina":['score'],
    "frameflow": ['pdb'],
    "abodybuilder": ['pdb'],
    'afcluster': ['pdb'],
    'afsample': ['pdb'],
    "antibody-evolution": [], //['sequence'], //this is a csv of seqs
    'diffab': ['sequence-list', 'pdb-list'],
    "lightdock": ['pdb'],
    'immunebuilder': ['pdb'],
    "jackhmmer": [],
    "esm-finetune": [],
    'dlkcat': ['score'],
    "deepimmuno": ['score'],
    'antifold': ['sequence'],
    'protrediff': ['pdb'],
    'alphaflow': ['pdb'],
    'combfold': ['pdb'],
    "dymean": ['pdb'],
    "peppatch": [],
    'pocketflow': [],
    "proteinmpnn-ddg": [],     
    "pulchra": [],     
    "reinvent": [],   
    'rosettafold2na': ['pdb'],
    'rosettafold-aa': ['pdb'],
    'tcrmodel2': ['pdb'],
    'unifold': ['pdb'],
    "zymctrl": ['sequence'],
    'gromacs': [],
    'amber': ['pdb'],
    "afcycdesign": ['pdb'],    
    'protein-scoring': [],
    'thompson-sampling': [],
    'free-wilson': [],
    'moflow': [],
    'admet': [],
    'structure-evolution': [],
    'tlimmuno': ['score'],
    'space2': [],
  }

  export const mdParameters = [
    {
      type: 'pdb',
      group: 'setup',
      variableName: 'pdbFile',
      displayName: 'PDB File with Protein',
    }, {
      type: 'sdf',
      group: 'setup',
      variableName: 'ligandFile',
      displayName: 'PDB File with Ligand',
    },{
      type: 'boolean',
      group: 'setup',
      variableName: 'removeWatersSetup',
      displayName: 'Remove Waters',
      defaultValue: true
    }, {
      type: 'boolean',
      group: 'setup',
      variableName: 'liquidHydrogens',
      displayName: 'Add Liquid Hydrogens',
      defaultValue: true
    }, {
      type: 'number',
      group: 'setup',
      variableName: 'charge',
      displayName: 'Charge',
      minVal: -10,
      maxVal:10,
      defaultValue: 0
    }, {
      type: 'dropdown',
      variableName: 'forceField',
      displayName: 'Force Field',
      options: ['ff19SB', 'ff14SB'],
      defaultValue: 'ff19SB'
    }, {
      type: 'dropdown',
      variableName: 'waterType',
      displayName: 'Water Type',
      options: ['TIP3P', 'OPC'],
      defaultValue: 'TIP3P'
    }, {
      type: 'number',
      group: 'setup',
      variableName: 'boxSize',
      displayName: 'Box Size',
      minVal: 10,
      maxVal: 20,
      defaultValue: 10
    }, {
      type: 'dropdown',
      variableName: 'ions',
      displayName: 'Ions',
      options: ['NaCl', 'KCl'],
      defaultValue: 'NaCl'
    }, {
      type: 'number',
      group: 'setup',
      variableName: 'concentration',
      displayName: 'Ion Concentration',
      minVal: 0,
      maxVal: 1,
      defaultValue: 0.15
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'minimizationSteps',
      displayName: 'Minimization Steps',
      minVal: 100,
      maxVal: 100000,
      defaultValue: 20000
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'timeEquilibrate',
      displayName: 'Equilibration Time (ns)',
      minVal: 1,
      maxVal: 1000, // do they want more ??
      defaultValue: 5,
      required:true
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'integrationTimeEquilibrate',
      displayName: 'Integration Time (fs)',
      minVal: 1,
      maxVal: 5,
      defaultValue: 2,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'temperatureEquilibrate',
      displayName: 'Temperature (k)',
      minVal: 200,
      maxVal: 400, 
      defaultValue: 300,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'pressureEquilibrate',
      displayName: 'Pressure (bar)',
      minVal: 0,
      maxVal: 1000, 
      defaultValue: 1,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'forceConstant',
      displayName: 'Position restraints force constant (kJ/mol)',
      minVal: 0,
      maxVal: 2000, 
      defaultValue: 700,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'logIntervalEquilibrate',
      displayName: 'Frequency of writing to log (ps)',
      minVal: 10,
      maxVal: 1000, 
      defaultValue: 10,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'trajIntervalEquilibrate',
      displayName: 'Frequency of writing to trajectory (ps)',
      minVal: 10,
      maxVal: 1000, 
      defaultValue: 10,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'timeSimulate',
      displayName: 'Simulation Time (ns)',
      minVal: 1,
      maxVal: 1000, // do they want more ??
      defaultValue: 5,
      required:true
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'integrationTimeSimulate',
      displayName: 'Integration Time (fs)',
      minVal: 1,
      maxVal: 5,
      defaultValue: 2,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'temperatureSimulate',
      displayName: 'Temperature (k)',
      minVal: 200,
      maxVal: 400, 
      defaultValue: 300,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'pressureSimulate',
      displayName: 'Pressure (bar)',
      minVal: 0,
      maxVal: 1000, 
      defaultValue: 1,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'logIntervalSimulate',
      displayName: 'Frequency of writing to log (ps)',
      minVal: 10,
      maxVal: 1000, 
      defaultValue: 10,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'trajIntervalSimulate',
      displayName: 'Frequency of writing to trajectory (ps)',
      minVal: 10,
      maxVal: 1000, 
      defaultValue: 10,
    }, {
      type: 'boolean',
      group: 'align',
      variableName: 'removeWatersAlign',
      displayName: 'Remove Waters',
      defaultValue: true
    }
  ]