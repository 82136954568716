import { useUser } from "@clerk/clerk-react";
import Navigation from "./Navigation";
import { Button, Chip, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import {useState} from 'react';
import { NameField } from "./NameField";
import { addJobToQueue, OTHER_JOB_BASE } from "../utils";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom/dist";
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'
export const UniFold = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [sequence, setSequence] = useState('TRPNHTIYINNLNEKIKKDELKKSLHAIFSRFGQILDILVSRSLKMRGQAFVIFKEVSSATNALRSMQGFPFYDKPMRIQYAKTDSDIIAKM');
	const [exceed, setExceed] = useState(false);

	const { isLoaded, isSignedIn, user } = useUser();  
	const navigate = useNavigate()

	const submit = (pay) => {
		const config = {}
		config['sequence'] = sequence

		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), config['sequence'].includes(':') ? 'unifold-multimer' : 'unifold-monomer', '');
		navigate('/app/results');
	}

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>

			<Header type="unifold" title="Unifold Structure Prediction"/>
				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>


				<Typography>For protein complexes, insert your chains with a ":" in between, where the ":" indicates a chain break. Such as TRPNHTIYINNLNEKIKKDELKKSLHAIFSRFGQILDILVSRSL<b>:</b>SATNALRSMQGFPFYDKPMRIQYAKTDSDIIAKM</Typography>
				<TextField value={sequence} label='Protein Sequence' onChange={(e) => setSequence(e.target.value)} multiline rows={7}/> 

				<SubmitButton redir="unifold" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

			</Stack>
		</>
	)
}
