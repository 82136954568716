import React, { useState } from 'react';
import Navigation from './Navigation';
import { Stack, CircularProgress, Grid, Typography } from '@mui/material';

function Visualizer() {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <Stack>
      <Grid container>
        <Navigation />
      </Grid>

      <Typography variant="h5">
        Molstar Viewer
      </Typography>

      {isLoading ? (
        <>
          <Typography>Please wait up to a minute for the viewer to load.</Typography>
          <CircularProgress /> 
        </>
      ) : null}
      <iframe
        height={1000}
        width={1400}
        src="https://molstar.org/viewer/"
        onLoad={handleIframeLoad}
        style={{ display: isLoading ? 'none' : 'block' }} // Hide the iframe while it's loading
      ></iframe>
    

    </Stack>
  );
}

export default Visualizer;
