import axios from "axios";
import { upload } from '@vercel/blob/client';
import { v4 as uuidv4 } from "uuid";
import { createTheme } from '@mui/material/styles';
import {toolInfo, typeToUrl} from './constants'

export const NUM_FREE_JOBS = 10
export const ALPHAFOLD_PER_AA = 0.002
export const ALPHAFOLD_BASE = 0.25
export const OTHER_JOB_BASE = 0.25
export const ALPHAFOLD_FREE_LIMIT = 2500
export const ALPHAFOLD_HARD_LIMIT = 5000
export const ALPHAFOLD_COMPUTE_PER_AA = 0.001
export const ALPHAFOLD_PROFIT_PER_AA = 0.001

export const getTypeInfo = (type) => {
  let typeReturn = type
  let typeConvert = {
    "protein-design":"Protein Design",
    "binder-design-filtering":"Binder Design Filtering",
    "binderdesignfiltering":"Binder Design Filtering",
    "monomer":"AlphaFold Monomer",
    "multimer":"AlphaFold Multimer",
    "protein-scoring":"Protein Scoring",
    "pipeline":"Pipeline",
    "protein-ligand-md":"Protein Ligand MD",
    "point-mutation-generation":"Point Mutation Generation",
    "all_atom_design":"De Novo All-Atom Protein Design",
    "scoring-batch":"Pipelines Scoring"
  }
  if (type in typeConvert) {
    typeReturn = typeConvert[type]
  } else if (type.length > 30) { // if json for batch
    typeReturn  = ""
  } 
  let canonicalType = type
  if (type in typeToUrl) {
    canonicalType = typeToUrl[type]
  }
  if (canonicalType in toolInfo) {
    let returnInfo = JSON.parse(JSON.stringify(toolInfo[canonicalType]))
    if (typeReturn != type) {
      returnInfo["displayName"] = typeReturn
    }
    return returnInfo
  }
  return {citations:[], github:"", paper:"", displayName:typeReturn, type:canonicalType}
}

  //missing esm-fine-tune

export const ourMSAServer = async () => {
  let userInfo = await axios.get("/api/getUser")
  let orgs = ["adimab", "persist-ai", "maverickbiometals", "alidabio", "septerna", "receptive", "mabqi"]
  if (userInfo.data.hasOwnProperty("MsaServer") && !userInfo.data.MsaServer) {
    return false
  }
  return userInfo.data != -1 && (userInfo.data.Tier == "Premium" || userInfo.data.MsaServer || orgs.includes(userInfo.data.email))
}

export const cleanName = (name) => {
  return name.replace(/[^\w\s.]/g, '').replace(/\s/g, '_');
}

export const asyncReturnS3PathsAndUploadFiles = async (user, file) => {
  let email = user.emailAddresses[0].emailAddress
  let fn = file.name
  if (file.type != "text/html") {
    // fn = file.name.replace(/[()]/g, '').replace(/\s/g, '_');
    fn = cleanName(file.name)
    await uploadFileCustomName(file, user, fn);
  }
  return email + "/" + fn;
}

export const returnS3PathsAndUploadFiles = (user, file) => {
  let email = user.emailAddresses[0].emailAddress
  let fn = file.name
  if (file.type != "text/html") {
    // fn = file.name.replace(/[()]/g, '').replace(/\s/g, '_');
    fn = cleanName(file.name)
    uploadFileCustomName(file, user, fn);
  }
  return email + "/" + fn;
}

export const submitBatch = (sequences, jobNames, type, batchName, batchID = "") => {

  let origLength = sequences.length 

  let types = type
  if (!Array.isArray(types)) {
    types = Array(origLength).fill(type);
  }
  let batchFieldNames = batchName
  if (!Array.isArray(batchName)) {
    batchFieldNames = Array(origLength).fill(batchName);
  }
  let BATCH_SIZE = 350
  for(let i = 0 ; i<origLength ; i+=BATCH_SIZE) {
    let batchSeq = sequences.splice(0, BATCH_SIZE)
    let batchNames = jobNames.splice(0, BATCH_SIZE)
    let batchTypes = types.splice(0, BATCH_SIZE)
    let batchBatchFieldNames = batchFieldNames.splice(0, BATCH_SIZE)
    const postData = {
      sequences: batchSeq,
      jobNames: batchNames,
      "batchName": batchBatchFieldNames, 
      "settings":"", 
      "type":batchTypes,
      "batchID":batchID
      };
      console.log(postData)
    axios.post('/api/addJobToQueueBatch', postData)
    }
  }
  export const theme = createTheme({
    palette: {
      primary: {
        main: "#8B2B00",
      },
      // secondary: {
      //   main: "#255a15"
      // }
    },
    typography: {
      fontFamily: 'Alliance No 2, Helvetica',
      fontSize: 16,
      textTransform: 'none',
      h1: {
        fontFamily: [
          'Alliance No 2, Helvetica',
        ].join(',')
      },
      h5: {
        fontSize: 25,
        fontFamily: [
          'Alliance No 2, Helvetica',
        ].join(',')
      },
      // h6: {
      //   fontFamily: [
      //     'Helvetica',
      //   ].join(',')
      // },
      body: {
        fontSize: 15,
        fontFamily: [
          'Alliance No 2', 'Helvetica'].join(',')
      },
      /*
      button: {
        textTransform: 'none',
        fontSize: 17,
        fontFamily: [
          'Alliance No 2', 
          'Helvetica'
        ].join(',')
      }
      */
    }
  });
  

function calcPriceAvgLength(quant, le){
  let base = le > 2000 ? 5e-7 * Math.pow(le,2) * quant : 0.001 * le * quant
  let profit = Math.min((0.0015 * le + 0.25) * quant, 500)
  return base + profit
}

// calcPrice("alphafold", 3, [1000, 2000, 1500])
export const calcPrice = (jobType, quantity, lengths) => {
  // console.log("calcprice:", jobType, quantity, lengths)
  if (jobType == "freeda") {
    return [0, []]
  }

  let lengthTypes = ["alphafold", "rosettafold2na", "rosettafold-aa", "combfold"]
  if (quantity == 0 || (lengthTypes.includes(jobType) && lengths.length == 0)){
    return [0, []]
  }
  if (!lengthTypes.includes(jobType)) {
    return [OTHER_JOB_BASE * quantity, []]
  }

  if (lengths.length == 1) {
    lengths = Array(quantity).fill(lengths[0])
  }
  if (lengths.length != quantity) {
    quantity = lengths.length
  }
  let totLength =  lengths.reduce((accumulator, currentValue) => accumulator + currentValue);
  let avgLength = totLength / quantity
  let tot = 0
  if (lengthTypes.includes(jobType)) {
    // tot = ALPHAFOLD_COMPUTE_PER_AA * avgLength * quantity + Math.min(Math.min(ALPHAFOLD_PROFIT_PER_AA * avgLength + ALPHAFOLD_BASE, 1) * quantity, 200)
    tot = calcPriceAvgLength(quantity, avgLength);
  } else {
    tot = OTHER_JOB_BASE * quantity
  }
  let perJobCost = []
  if (lengthTypes.includes(jobType)) {
    perJobCost = lengths.map((l, i) => Math.round(l / totLength * tot * 100)/100)
  }
  let finalTotal = Math.round(perJobCost.reduce((accumulator, currentValue) => accumulator + currentValue)* 100)/100;
  return [finalTotal, perJobCost]
}

export const getUploadedPath = (file, user) => {
  let email = user.emailAddresses[0].emailAddress
  return email + "/" + file.name;
}

export const isJobThisMonth = (job) => {
  const date = new Date(job['Created']);
  const year1 = date.getFullYear()
  const month1 = date.getMonth();

  const currentDate = new Date();

  const year2 = currentDate.getUTCFullYear();
  const month2 = currentDate.getUTCMonth();
              
  return year1 === year2 && month1 === month2 && job.Type !== 'batch';  
}

export const uploadFileCustomName = async (file, user, filename) => {
  const email = user.emailAddresses[0].emailAddress;
  const CHUNK_SIZE = 4 * 1024 * 1024; // 4 MB in bytes

  if (file.size > 4500000) {
    // Notify about large file upload start
    await axios.get('/api/notifyUs', { params: { 'title': `${email} Large File Uploaded`, 'message': `Filename: ${filename}` } });

    // Split file into chunks
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    for (let i = totalChunks - 1; i >= 0; i--) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);

      const chunkFilename = `${filename}.${i + 1}.part`;
      let formData = new FormData();
      formData.append('email', email);
      formData.append('file', chunk);
      formData.append('filename', chunkFilename);

      // Upload the chunk
      try{
        // console.log("uploading chunk:", chunkFilename)
        await axios.post('/api/uploadFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data' // Inform server about multipart data
          },
          params: { "filename": chunkFilename }
        });
        // console.log("upload done:", chunkFilename)
      } catch (error) {
        console.log("ERROR:", error)
      }

      // Optionally, notify about each chunk upload
      // await axios.get('/api/notifyUs', { params: { 'title': `${email} Chunk Uploaded`, 'message': `Chunk Filename: ${chunkFilename}` } });
    }

    return;
  }

  try {
    let formData = new FormData();
    formData.append('email', email);
    formData.append('file', file);

    let resp = await axios.post('/api/uploadFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Inform server about multipart data
      },
      params: { "filename": filename }
    });

    // console.log("Upload response:", resp);

  } catch (error) {
    console.error("Error uploading file:", error.response ? error.response.data : error.message);
    await axios.get('/api/notifyUs', { params: { 'title': `${email} Upload Failed`, 'message': `Filename: ${filename}` } });
  }
};


// export const uploadFileCustomName = async (file, user, filename) => {

//   if (file.size > 4500000) {
//     axios.get('/api/notifyUs', {params:{'title': user.emailAddresses[0].emailAddress + " Large File Uploaded", 'message': 'Filename: ' + filename}})
//     const newBlob = await upload(file.name, file, {
//       access: 'public',
//       handleUploadUrl: 'api/upload/route.js',
//     });
//     axios.get('/api/notifyUs', {params:{'title': user.emailAddresses[0].emailAddress + " Large File Uploaded", 'message': 'Filename: ' + filename + " " + newBlob.url}})
//     return
//   }
  
//   const email = user.emailAddresses[0].emailAddress;

//   axios.post('/api/uploadFile', 

//   (function() {
//     let formData = new FormData();
//     formData.append('email', email); 
//     formData.append('file', file); 
//     return formData;
//   })(),
//   {
//     headers: {
//       'Content-Type': 'multipart/form-data' // inform server about multipart data
//     },
//     params:{"filename": filename}
//   },
//   );
// }

export const uploadFile = (file, user, setProgress) => {
  uploadFileCustomName(file, user, file.name)
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const COST_PER_AA = 0.002
const BASE = 0.25

export const getAlphaFoldCost = (sequence) => {
    return (sequence.length * COST_PER_AA + BASE).toFixed(2)
}

export const chargeCredits = (user, cost) => {
    const numCredits = cost;
    
    try {
        if (user.unsafeMetadata.credits < numCredits) {
          alert("not enough credits")
        }
            
        user.update({
            unsafeMetadata: {
                credits: user.unsafeMetadata.credits ? parseInt(user.unsafeMetadata.credits) - parseInt(numCredits): parseInt(numCredits),
            },
      });

    } catch (error) {
      console.log(error);
    }
}

export const formatJob = (jobName, cost, user, configStr, type, info="", status="In Queue", batch="") => {
  const email = user.emailAddresses[0].emailAddress;
  const dateString = new Date().toISOString().replace('T', ' ').replace(/\..+/, '');

  const job = {
    Id: uuidv4(),
    User: email,
    JobName: jobName,
    Sequence: configStr,
    JobStatus: status,
    Cost: 0, 
    Type: type,
    Created: dateString,
    Info: info,
    Batch: batch
  };

  return job;
}

export const addJobToQueue = (jobName, cost, user, configStr, type, info="", status="In Queue", batch="", id="") => {
  if (jobName == "") {
    jobName = Math.random().toString(36).slice(2, 7)
  }
  
  // console.log("add job", id)
  const email = user.emailAddresses[0].emailAddress;

  if ((type == "monomer" || type == "multimer") && configStr.length > 2500) {
    axios.get('/api/sendEmail', {params:{"name": jobName, "email":email, "type":type + "_TOO_LONG", "len":configStr.length, "seq":configStr}})
  } else if (type != "monomer" && type != "multimer" && batch == ""){
    axios.get('/api/sendEmail', {params:{"name": jobName, "email":email, "type":type, "len":configStr.length, "seq":configStr}})
  }

  if (cost > 0 && email != "sherryliu987@gmail.com" && email != "denizkavi@outlook.com") {
    axios.get('/api/notifyUs', {params:{'title': "We made money!", 'message': cost + " from " + email}})
  }

  // console.log("job name:", jobName)

  axios.get('/api/addJobToQueue', 
  {params:{
    "jobName": jobName, 
    "cost":cost, 
    "user":email, 
    "configStr": configStr, "type":type, "info": info, "status":status, "batch":batch, "id":id}})
    .then(response => {
    })
    .catch(error => {
      // Log the error
      console.error('Error adding job to queue:', error);
      // You can also log more specific details depending on the nature of the error
      if (error.response) {
        console.error('Data:', error.response.data);
        console.error('Status:', error.response.status);
        console.error('Headers:', error.response.headers);
      };
    })
}

export const addJobToQueueObject = (jobName, cost, user, configObj, type, info="", status="In Queue", batch="") => {
  if (jobName == "") {
    jobName = Math.random().toString(36).slice(2, 7)
  }
  cost = 0;
  const email = user.emailAddresses[0].emailAddress;
  axios.get('/api/addJobToQueueObject', 
  {params:{
    "jobName": jobName, 
    "cost":cost, 
    "user":email, 
    "configStr": JSON.stringify(configObj), "type":type, "info": info, "status":status, "batch":batch}
  });
}

function filterAminoAcids(sequence) {

  sequence = sequence.toUpperCase();

  let allowed = "ABZRNDCQEGHILKMFPSTWYV:";
  let filtered = "";

  for (let i = 0; i < sequence.length; i++) {
    if (allowed.includes(sequence[i])) {
      filtered += sequence[i];
    } 
  }

  return filtered;
}

export const checkValidSequenceInput = (sequence) => {

  let sequence_upper = sequence.toUpperCase();

  let allowed = "ABZRNDCQEGHILKMFPSTWYV:";
  let skip = " .?;-1234567890!@#$%^&*()/\n ,\t'`[]{}<>+_=~\\";
  

  let bad = new Set([]);

  for (let i = 0; i < sequence_upper.length; i++) {
    if (!skip.includes(sequence_upper[i]) && !allowed.includes(sequence_upper[i])) {
      bad.add(sequence[i])
    }
  }

  if(bad.size == 0){
    return "Good"
  }else{
    let bad_chars = "";
    for(const b of bad){
      bad_chars += "'" + b + "' "
    }
    return "Found invalid amino acids: " + bad_chars + ". Please edit your sequence."
  }

}

const getFlagsString = (settings) => {
  const flags = settings.map((setting) => setting.selected !== "auto" ? `&${setting.name}=${setting.selected}` : '');
  return flags.join("");
}

export const getFlagsDB = (settings) => {
  const flags = settings.map((setting) => setting.selected !== "auto" && setting.selected !== "none" && setting.selected !== "auto" ? `--${setting.name} ${setting.selected} ` : '');

  let flagstring = flags.join("");

  settings.map((setting) => {
    if (setting.name == "num-relax" && setting.selected !== "none") {
      flagstring += "--amber";
      //flagstring += "--use-gpu-relax";
    }
  })

  return flagstring

}

export const startLambdaForType = async (jobType) => {
  axios.get('/api/startLambda', {params:{"type":jobType}});

  // if (jobType === 'rfdiffusion') {
  //   try{
  //     axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  //     await axios.post(`https://ojcyg7rl93.execute-api.us-west-2.amazonaws.com/?jobType=${jobType}`)
  //   }catch (error){
  //     console.log(error);
  //   }    
  // }

  // try{
  //   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  //   await axios.post(`https://w5zigoc2k3.execute-api.us-west-1.amazonaws.com/?jobType=${jobType}`)
  // }catch (error){
  //   console.log(error);
  // }
}

//assuming user has enough credits, 
//deduct required credits from user's account and submit prediction job 
export const submitSequenceToAlphaFold = async (jobName, sequence, user, pay, batch="", settings=[], id="", cost=0) => {
  const flags = getFlagsString(settings);
    // console.log(flags);

    // console.log("submit alpha", id)


    sequence = filterAminoAcids(sequence);

    jobName = jobName.split(" ").join("_");
    sequence = sequence.replace(/[^ABZRNDCQEGHILKMFPSTWYV:]/g, '')
    
    const email = user.emailAddresses[0].emailAddress;

    let type = sequence.includes(":") ? 'multimer' : 'monomer'
    let flags_db = getFlagsDB(settings)

    // console.log(settings)
    let costSubmit = pay ? cost : 0
    addJobToQueue(jobName, costSubmit, user, sequence, type, flags_db, "In Queue", batch=batch, id=id)
    // console.log(jobName, "sequence:", sequence)

    const lowerThreshold = 1400;
    const upperThreshold = 2500;

    if (sequence.length > upperThreshold) {
      return
    }

    // if (email == "sherry.tamarind@gmail.com") {
    //     axios.get('/api/startLambda', {params:{"type": "alphafold_small", "vpc":"septerna"}});
    // } else 
    if (sequence.length > lowerThreshold && batch == "") {
      axios.get('/api/startLambda', {params:{"type": "alphafold_large"}});
      // try{
      //   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      //   await axios.post(`https://ojcyg7rl93.execute-api.us-west-2.amazonaws.com/?sequence=${sequence}&email=${email}&jobName=${jobName}${flags}`)
      // }catch (error){
      //   console.log(error);
      // }
      return
    } else if (batch == ""){
        axios.get('/api/startLambda', {params:{"type": "alphafold_small"}});
        // try{
        //     axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        //     await axios.post(`https://w5zigoc2k3.execute-api.us-west-1.amazonaws.com/?sequence=${sequence}&email=${email}&jobName=${jobName}${flags}&jobType=alphafold_small`)
        //   }catch (error){
        //     console.log(error);
        // }
    }
}
  
export const extractFileNamesFromFile = async (file, seq2Name, setseq2Name) => {
    const read = new FileReader();
    read.readAsBinaryString(file.file);

    read.onloadend = async function () {
        const s = read.result.split('\n');
        let descp = 0;
        let seq = "";

        for (let i in s) {
        if (s[i] == '') break;

        for (let j in s[i]) {
            if (s[i][j][0] === ">") {
            
            if (descp !== 0) {
                seq += ":";
            }

            descp++;
            break;
            } 
            else {
            seq += s[i][j];
            }
        }

        }

        seq = seq.replace(/(\r\n|\n|\r)/gm, "");

        const newObj = seq2Name;
        const fname = file.filename.split('.')[0];
        newObj[seq] = fname;
        setseq2Name(newObj);
        }
}

 
export const extractFileContents = (file, fileContents, setFileContents) => {
  const read = new FileReader();
  read.readAsBinaryString(file.file);

  read.onloadend = async function () {
      const s = read.result;
      setFileContents([...fileContents, s]);
  }
}

export const convertUTCtoLocal = (utcDateString) => {
  // Parse the UTC date string to a Date object
  var utcDate = new Date(utcDateString + "Z"); // Adding 'Z' to indicate UTC

  // Extract and format the local date and time components
  var localYear = utcDate.getFullYear();
  var localMonth = (utcDate.getMonth() + 1).toString().padStart(2, '0');
  var localDay = utcDate.getDate().toString().padStart(2, '0');
  let localHour = utcDate.getHours().toString().padStart(2, '0');
  var PM = localHour >= 12 ? "PM" : "AM";
  localHour = localHour > 12 ? localHour - 12 : localHour;
  var localMinute = utcDate.getMinutes().toString().padStart(2, '0');
  var localSecond = utcDate.getSeconds().toString().padStart(2, '0');

  // Construct the formatted local date and time string
  return `${localYear}-${localMonth}-${localDay} ${localHour}:${localMinute}${PM}`;
}

export const detectFileTypeGenerator = (typeExtensionsMap) => {
  const detectFileType = async (source, type) =>
  new Promise((resolve, reject) => {
      Object.entries(typeExtensionsMap).forEach(([ext, type_]) => {
        if (source["name"].endsWith(ext)){
          type = type_
        }
      });
      resolve(type); 
    })
  return detectFileType
}

export const similarity = (s1, s2) => {
  var longer = s1
  var shorter = s2
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  longer = longer.toLowerCase()
  shorter = shorter.toLowerCase()
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  let avgLength = (longerLength + shorter.length) / 2
  if (longer.includes(shorter) && shorter.length > 2) {
    return 1.0
  }
  // console.log(s1, s2, (avgLength - editDistance(longer, shorter)) / parseFloat(avgLength))
  return (avgLength - editDistance(longer, shorter)) / parseFloat(avgLength);
}

const editDistance = (s1, s2) => {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0)
        costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}