import { Stack, Button } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const CovalentAutodock = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);

        const [ligindices, setLigindices] = useState("4,3");
        const [ligsmart, setLigsmart] = useState("C(=O)-O-C");
        const [residue, setResidue] = useState("B:SER222");

        const [ligandFiles, setLigandFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const ligandFilePaths = ligandFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

                const config = {pdbFile: filePaths.join(","), ligandFile:ligandFilePaths.join(","), ligindices:ligindices, ligsmart:ligsmart, residue:residue};
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "covalent-autodock");
                // startLambdaForType('covalent-autodock')
                navigate("/app/results");
        }

        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind Covalent Docking Online Tool</Typography>
                <Typography variant='body'>Given a protein structure, predict the small-molecule-binding ability of each residue using a machine learning model trained on AlphaFold pairwise representations.  </Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Protein:</Typography>
                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['pdb']}/>

                <Typography variant='body'>Ligand:</Typography>
                <ChooseOrUploadFile files={ligandFiles} setFiles={setLigandFiles} types={['mol2']}/>

                <TextField value={ligindices} onChange={(e) => setLigindices(e.target.value)} label="Ligand indices"></TextField>
                <TextField value={ligsmart} onChange={(e) => setLigsmart(e.target.value)} label="Ligand SMART"></TextField>
                <TextField value={residue} onChange={(e) => setResidue(e.target.value)} label="Residue"></TextField>

                <SubmitButton redir="covalent-autodock" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
