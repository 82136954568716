
import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Tooltip, Chip, Stack} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { NUM_FREE_JOBS, isJobThisMonth } from '../utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoopIcon from '@mui/icons-material/Loop';
//import IconButton from '@mui/icons-material/IconButton';

const MonthlyCostCalculator = ({ user, jobs, tier, userInfo, setUserInfo }) => {
    const [total, setTotal] = useState("Loading");
    const [numJobs, setNumJobs] = useState(-1);

    useEffect(() => {
        if (!(user.emailAddresses[0].emailAddress.includes("adimab"))) {
            return
        }
        axios.get('/api/getOrganizationJobs', {params:{"filter": false}}).then(
            res => {
                let thisMonthJobs = res.data.filter(job => {
                    return !(job.Type == "batch") && isJobThisMonth(job);
                });
                if (user.emailAddresses[0].emailAddress.includes("adimab")) {
                    let currentDate = new Date();
                    let currentYear = currentDate.getFullYear();
                    let currentDayOfMonth = currentDate.getDate();
                    let currentMonth = currentDate.getMonth();
                    if  (currentDayOfMonth < 12){
                        currentMonth--;
                    }

                    thisMonthJobs = res.data.filter(job => {
                        return !(job.Type == "batch") && new Date(currentYear, currentMonth, 12) < new Date(job.Created) && new Date(job.Created) <= new Date(currentYear, currentMonth + 1, 12);
                    });
                }
                setNumJobs(thisMonthJobs.length);
            }
          )
    }, [])

    useEffect(() => {
        computeCost();
    }, [jobs]); // This effect depends on jobs

    const computeCost = () => { 
        let thisMonthJobs = jobs.filter(job => {
            return !(job.Type == "batch") && isJobThisMonth(job);
        });
        // console.log(thisMonthJobs)
        let totalCost = thisMonthJobs.reduce((acc, job) => {return acc + parseFloat(job.Cost, 2)}, 0)
        setTotal(totalCost.toFixed(2))
        if (!(user.emailAddresses[0].emailAddress.includes("adimab"))) {
            setNumJobs(thisMonthJobs.length);
        };
    }

    const removeCard = () => {
        axios.get('/api/notifyUs', {
            params: {
                'title': user.emailAddresses[0].emailAddress + " Removed Their Card",
                'message': "Check their outstanding balance, then delete the stripe account."
            }
        });
        Swal.fire('Your card has been removed. \n\n We will delete your payment information once/if your outstanding balance is charged to you. \n\n Thank you for using Tamarind!')
    };

    const [visible, setVisible] = useState(false);

    const rollKey = (event) => {
        Swal.fire({
        title: "Are you sure you want to replace your api key? Your current key will become invalid.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        }).then((result) => {
        if (result.isConfirmed) {
            axios.get('/api/notifyUs', {params:{'title': user.emailAddresses[0].emailAddress + " rolled api key", 'message': 'Update key'}})
            axios.get('/api/rollKey').then(() => {
                axios.get('/api/getUser', {params:{ "email": user.emailAddresses[0].emailAddress}}).then(
                    res => {
                      setUserInfo(res.data)
                    }
                  )
            })
            
        } else if (result.isDenied) {
            return;
        }
        });
    }


    const APIkey = () => { 

        const toggleVisibility = () => {
          setVisible(!visible);
        };
      
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
            <Typography variant="body2" color="text.secondary">
              API Key: {visible ? userInfo.ApiKey : '****************'}
            </Typography>
            <Button onClick={() => navigator.clipboard.writeText(userInfo.ApiKey)} style={{ minWidth: 'auto', padding: 0 }}>
              <ContentCopyIcon />
            </Button>
            <Button onClick={toggleVisibility} style={{ minWidth: 'auto', padding: 0 }}>
              {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Button>
            <Tooltip title="Roll Key">
            <Button onClick={rollKey} style={{ minWidth: 'auto', padding: 0 }}>
              <LoopIcon/>
            </Button>
            </Tooltip>
          </div>
        );
      };
          
      
    return (
        <Box >
           {tier == "Professional" ? <Stack spacing={1} sx={{ paddingY: 0, marginTop:2 }} >
                    <Typography variant="body2" color="text.secondary">
                        <b>Professional Tier</b>
                    </Typography>
                    <Chip label='Remove Payment Information' variant='outlined' onClick={removeCard} style={{ width:"35vh" }}></Chip>

                    <Typography variant="body2" color="text.secondary">
                        Total Monthly Expense: ${total}
                    </Typography>
                    <APIkey visible={visible} setVisible={setVisible}/>
                </Stack>
                :
                tier == "Premium" ? 
                <Stack spacing={1} sx={{ marginTop:2}}>
                    <Typography variant="body2" color="text.secondary">
                        <b>Premium Tier</b>
                    </Typography>
                    { numJobs != -1 && (user.emailAddresses[0].emailAddress.includes("adimab") || ["sherryliu987@gmail.com", "andrey@wobblebio.com", "amadou.gaye@nih.gov", "20194064j@gmail.com"].includes(user.emailAddresses[0].emailAddress)) ?
                        <Typography variant="body2" color="text.secondary">
                        Jobs Remaining This Month: {(userInfo["MonthlyLimit"] ? userInfo["MonthlyLimit"] : NUM_FREE_JOBS)  - numJobs} 
                        </Typography> : null
                    }
                    
                    <APIkey/>
                </Stack>
                : 
                <>
                <Stack spacing={1} sx={{ marginTop:2}} >
                    <Typography variant="body2" color="text.secondary">
                        <b>Free Tier</b>
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                        Free Jobs Remaining This Month: {(userInfo["MonthlyLimit"] ? userInfo["MonthlyLimit"] : NUM_FREE_JOBS) - jobs.length} 
                    </Typography>
                </Stack>
                </>}
        </Box>
    );
};

export default MonthlyCostCalculator;
